<template>
  <div>
    <v-card
      v-for="(value, contentType, index) in content"
      :key="index"
      class="mb-2"
    >
      <v-card-title
        class="text-left"
      >
        <span>{{ contentType }}</span>
        <v-icon
          v-if="editMode"
          @click="deleteContentType(contentType)"
        >
          mdi-delete
        </v-icon>
      </v-card-title>
      <v-card-text>
        <swagger-schema-view
          :schema="value.schema"
          :edit-mode="editMode"
        />
      </v-card-text>
    </v-card>

    <!-- 이미 추가된 메소드의 request body에 media-type을 추가하기 위한 다이얼로그 -->
    <v-dialog
      v-if="dialog.mediaType"
      v-model="dialog.mediaType"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="text-left"
        >
          <span class="text-h3">
            {{ $t('swagger.response') }} {{ dialog.detailMode === 'add' ? $t('swagger.add') : $t('swagger.modify') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-combobox
                    v-model="modify.contentType"
                    :label="$t('swagger.contentType')"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-select
                    v-model="modify.schemaType"
                    :items="selectors.schemaTypes"
                    :label="$t('swagger.schema')+' '+ $t('swagger.type')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="grey"
            @click="dialog.mediaType = false"
          >
            {{ $t('swagger.cancel') }}
          </v-btn>
          <v-btn
            @click="saveMediaType"
          >
            {{ $t('swagger.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SwaggerSchemaView from '@/components/api/swagger/SwaggerSchemaView';
import { SELECTOR_SCHEMA_TYPE } from '@/config/constants';

export default {
  name: 'swagger',
  components: { SwaggerSchemaView },
  props: {
    content: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dialog: {
      add: false,
      mediaType: false,
    },
    selectors: {
      schemaTypes: SELECTOR_SCHEMA_TYPE,
    },
    modify: {
      contentType: null,
      schemaType: null,
    },
  }),
  methods: {
    showAddContentType() {
      this.modify.contentType = null;
      this.modify.schemaType = null;
      this.dialog.mediaType = true;
    },
    // media-type 저장 버튼 클릭 시
    saveMediaType() {
      this.$set(this.content, this.modify.contentType, {
        schema: {
          type: this.modify.schemaType,
        },
      });
      this.dialog.mediaType = false;
    },
    deleteContentType(contentType) {
      this.$delete(this.content, contentType);
      this.$forceUpdate();
    }
  },
  watch: {
    content() {
      console.log('content changed');
    }
  }
}
</script>

<style scoped>

</style>
