<template>
  <div>
    <v-card
      v-if="internalMode === 'view' || internalMode === 'modify'"
    >
      <v-list-item
        class="grey lighten-4"
      >
        <v-list-item-content>
          <v-list-item-title>
            {{ post.writer.name }}
          </v-list-item-title>
        </v-list-item-content>

        <v-row
          align="center"
          justify="end"
        >
          <v-icon class="mr-1">
            mdi-clock-outline
          </v-icon>
          <span
            class="text-body-2 mr-2"
          >
          <format-date-time :value="post.createdDate"/>
        </span>
        </v-row>
      </v-list-item>

      <v-card-text>
        <markdown-preview v-if="internalMode === 'view'" :holder="holder" :preBgColor="preBgColor"
                          :text="post.content"/>
        <!--        <div-->
        <!--          v-if="internalMode === 'view'"-->
        <!--          v-html="handleNewLine(post.content)"-->
        <!--        />-->
        <v-row
          v-if="internalMode === 'modify'"
          align="center"
        >
          <v-col>
            <markdown-editor :holder="holder" :value="modify.post.content"
                             @update="(content) => modify.post.content = content"/>
            <!--            <v-textarea-->
            <!--              v-model="modify.post.content"-->
            <!--              hide-details-->
            <!--              outlined-->
            <!--            />-->
          </v-col>
          <v-col
            cols="auto"
          >
            <v-row>
              <v-col>
                <v-btn
                  small
                  @click="savePost"
                >
                  {{ $t('forum.save') }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  outlined
                  small
                  @click="clickModifyCancel"
                >
                  {{ $t('forum.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions
        v-if="internalMode === 'view'"
        class="justify-end"
      >
        <v-btn
          v-if="post.permission.modify"
          class="text-decoration-underline"
          small
          text
          @click="clickModify"
        >
          {{ $t('forum.modify') }}
        </v-btn>
        <v-btn
          v-if="post.permission.remove"
          class="text-decoration-underline"
          small
          text
          @click="clickRemove"
        >
          {{ $t('forum.delete') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-container
      v-if="internalMode === 'create'"
    >
      <v-row
        align="center"
      >
        <v-col>
          <span> {{ $t('forum.reply') }}</span>
          <markdown-editor :holder="holder" :value="modify.post.content"
                           @update="(content) => modify.post.content = content"/>
          <!--          <v-textarea-->
          <!--            v-model="modify.post.content"-->
          <!--            hide-details-->
          <!--            label="답글"-->
          <!--            outlined-->
          <!--          />-->
        </v-col>
        <v-col
          cols="auto"
        >
          <v-btn
            dense
            small
            @click="savePost"
          >
            {{ $t('forum.save') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import FormatDateTime from '@/views/components/FormatDateTime';
import forumService from '@/service/forum.service';
import MarkdownEditor from "@/components/forum/MarkdownEditor";
import MarkdownPreview from "@/components/forum/MarkdownPreview";

export default {
  name: 'ApimForumPostReply',
  components: {FormatDateTime, MarkdownEditor, MarkdownPreview},
  props: {
    forum: {
      type: Object,
      required: true,
    },
    parentPost: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      default: () => ({
        writer: {},
      }),
    },
    mode: {
      type: String,
      default: 'view',
    },
  },
  data: function() {
    return {
      internalMode: this.mode,
      modify: {
        post: {}
      },
      holder: this.$i18n.t('forum.enterReplyMsg'),
      preBgColor: '#cfcfcf',
    };
  },
  methods: {
    async savePost() {
      if (this.mode === 'create') {
        await forumService.createReply(this.forum.id, this.parentPost.id, this.modify.post);
        this.modify.post = {};
        this.$emit('replied');
      } else {
        const data = await forumService.updatePost(this.forum.id, this.modify.post.id, this.modify.post);
        this.internalMode = 'view';
        this.$emit('modified', data);
      }
    },
    async clickRemove() {
      await forumService.deletePost(this.forum.id, this.post.id);
      this.$emit('removed');
    },
    clickModify() {
      this.internalMode = 'modify';
      this.modify.post = JSON.parse(JSON.stringify(this.post));
    },
    clickModifyCancel() {
      this.internalMode = 'view';
    },
    handleNewLine(str) {
      return String(str).replace(/(?:\r\n|\r|\n)/g, '</br>');
    },
  }
}
</script>

<style scoped>

</style>
