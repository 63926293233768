<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card outlined tile class="mb-6">
          <v-card-subtitle class="text-left">
            <h3 class="pl-md-2">{{ $t('transaction.basic') }} {{ $t('transaction.info') }}</h3>
          </v-card-subtitle>
          <v-divider/>
          <v-card-text class="pt-5">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                <tr>
                  <th
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.log') }} {{ $t('transaction.id') }}
                  </th>
                  <td>
                    {{ transactionId }}
                  </td>
                </tr>
                <tr>
                  <th
                    width="20%; font-size:1rem;"
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.service') }}({{ $t('transaction.app') }}) {{ $t('transaction.name') }}
                  </th>
                  <td>
                    {{ log.appName }}
                  </td>
                </tr>
                <tr>
                  <th
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.method') }} {{ $t('transaction.summary') }} {{ $t('transaction.info') }}
                  </th>
                  <td>
                    {{ log.methodSummary }}
                  </td>
                </tr>
                <tr>
                  <th
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.method') }} {{ $t('transaction.register') }} {{ $t('transaction.uri') }}
                  </th>
                  <td>
                    <v-chip
                      color="green"
                      small
                      outlined
                    >
                      {{ log.method }}
                    </v-chip>
                    {{ log.registeredUri }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <v-card outlined tile class="mb-6">
          <v-card-subtitle class="text-left">
            <h3 class="pl-md-2">{{ $t('transaction.uri') }}</h3>
          </v-card-subtitle>
          <v-divider/>
          <v-card-text class="pt-5">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                <tr>
                  <th
                    width="20%; font-size:1rem;"
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.request') }} {{ $t('transaction.method') }}
                  </th>
                  <td>
                    <v-chip
                      color="green"
                      small
                      outlined
                    >
                      {{ log.method }}
                    </v-chip>
                    {{ log.requestUri }}{{ queryString }}
                  </td>
                </tr>
                <tr>
                  <th
                    width="20%; font-size:1rem;"
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.backend') }} {{ $t('transaction.url') }}
                  </th>
                  <td>
                    {{ backendUrl }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <v-card outlined tile class="mb-6">
          <v-card-subtitle class="text-left">
            <h3 class="pl-md-2">{{ $t('transaction.transaction') }} {{ $t('transaction.time') }}</h3>
          </v-card-subtitle>
          <v-divider/>
          <v-card-text class="pt-5">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                <tr>
                  <th
                    width="20%; font-size:1rem;"
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.transaction') }}{{ $t('transaction.time') }} (INI)
                  </th>
                  <td>
                    <format-date-time :value="startTime" format="YYYY-MM-DD HH:mm:ss.SSS"/>
                  </td>
                  <th
                    width="20%; font-size:1rem;"
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.backend') }} {{ $t('transaction.transaction') }}{{ $t('transaction.time') }} (SNI)
                  </th>
                  <td>
                    <format-date-time :value="backendStartTime" format="YYYY-MM-DD HH:mm:ss.SSS"/>
                  </td>
                </tr>
                <tr>
                  <th
                    width="20%; font-size:1rem;"
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.response') }}{{ $t('transaction.time') }} (INO)
                  </th>
                  <td>
                    <format-date-time :value="endTime" format="YYYY-MM-DD HH:mm:ss.SSS"/>
                  </td>
                  <th
                    width="20%; font-size:1rem;"
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.backend') }} {{ $t('transaction.response') }}{{ $t('transaction.time') }} (SNO)
                  </th>
                  <td>
                    <format-date-time :value="backendEndTime" format="YYYY-MM-DD HH:mm:ss.SSS"/>
                  </td>
                </tr>

                <tr>
                  <th
                    width="20%; font-size:1rem;"
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.all') }} {{ $t('transaction.theTimeRequired') }}(ms)
                  </th>
                  <td>
                    {{ elapsedTime.toLocaleString() }}
                  </td>
                  <th
                    width="20%; font-size:1rem;"
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.backend') }} {{ $t('transaction.theTimeRequired') }}(ms)
                  </th>
                  <td>
                    {{ backendElapsedTime.toLocaleString() }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
        <v-card outlined tile class="mb-6">
          <v-card-subtitle class="text-left">
            <h3 class="pl-md-2">{{ $t('transaction.response') }}</h3>
          </v-card-subtitle>
          <v-divider/>
          <v-card-text class="pt-5">
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                <tr>
                  <th
                    width="20%; font-size:1rem;"
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.response') }}{{ $t('transaction.code') }}
                  </th>
                  <td>
                    {{ log.responseCode }}
                  </td>
                  <th
                    width="20%; font-size:1rem;"
                    style="background-color: #fff"
                  >
                    {{ $t('transaction.gateway') }} {{ $t('transaction.error') }}{{ $t('transaction.code') }}
                  </th>
                  <td>
                    {{ log.errorCode }}
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="mt-0"
    >
      <v-spacer/>
      <v-col
        cols="auto"
      >
        <v-btn
          v-if="logData"
          @click="dialog.data = true"
          color="primary"
        >
          {{ $t('transaction.transaction') }} {{ $t('transaction.data') }} {{ $t('transaction.view') }}
        </v-btn>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog.data"
      persistent
      @keydown.esc="dialog.data = false"
    >
      <v-card>
        <v-card-title
          class="text-h4 text-left"
        >
          {{ $t('transaction.transaction') }} {{ $t('transaction.data') }}
        </v-card-title>
        <v-card-text>
          <transaction-data
            :log="log"
            :log-data="logData"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <!--<v-btn v-if="selectedLogData" @click="dialog.test = true" outlined>-->
          <!--  테스트-->
          <!--</v-btn>-->
          <v-btn @click="dialog.data = false" outlined>
            {{ $t('transaction.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import FormatDateTime from '@/views/components/FormatDateTime';
import request from '@/utils/request';
import TransactionData from '@/components/TransactionData';
import { unescape } from '@/utils';

export default {
  name: 'TransactionDetail',
  components: { TransactionData, FormatDateTime },
  props: {
    log: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialog: {
      data: false,
    },
    logData: null,
  }),
  computed: {
    queryString() {
      // query string은 로그 데이터에 들어있다.
      if (!this.logData || !this.logData.request || !this.logData.request.quries) return '';
      let queryString = '?';
      for (const name in this.logData.request.quries) {
        if (queryString !== '?') queryString += '&';
        queryString += `${name}=${this.logData.request.quries[name]}`;
      }
      return queryString;
    },
    startTime() {
      return this.log.startTime ? this.log.startTime : this.log.ini;
    },
    endTime() {
      return this.log.endTime ? this.log.endTime : this.log.ino;
    },
    backendStartTime() {
      return this.log.backendStartTime ? this.log.backendStartTime : this.log.sni;
    },
    backendEndTime() {
      return this.log.backendEndTime ? this.log.backendEndTime : this.log.ino;
    },
    elapsedTime() {
      if (Object.prototype.hasOwnProperty.call(this.log, 'elapsed')) return this.log.elapsed;
      if (this.log.startTime && this.log.endTime) return this.log.endTime - this.log.startTime;
      else return this.log.ino - this.log.ini;
    },
    backendElapsedTime() {
      if (Object.prototype.hasOwnProperty.call(this.log, 'backendElapsed')) return this.log.backendElapsed;
      if (this.log.backendStartTime && this.log.backendEndTime) return this.log.backendEndTime - this.log.backendStartTime;
      else return this.log.sno - this.log.sni;
    },
    backendUrl() {
      return this.log.backendUrl ? this.log.backendUrl : this.log.proxyUrl;
    },
    transactionId() {
      return this.log.txId ? this.log.txId : this.log.logSeq;
    }
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      // TODO 서버에서 appId값이 'null'문자열이 리턴되고 있음. 확인필요.
      if (this.log.appId && this.log.appId === 'null') this.log.appId = null;
      await this.fetchMethod();
      await this.fetchApp();
      await this.fetchLogData();
    },
    async fetchMethod() {
      const { data } = await request({
        method: 'GET',
        url: `/apix/apis/methods/${this.log.methodId}`
      });
      this.$set(this.log, 'methodSummary', data.summary);
      this.$set(this.log, 'method', data.httpMethod);
      this.$set(this.log, 'registeredUri', data.uri);
    },
    async fetchApp() {
      var noServiceInfoMsg = this.$t('transaction.noServiceInfoMsg');
      if (!this.log.appId || this.log.appId === 0) {
        this.$set(this.log, 'appName', `${noServiceInfoMsg}`);
      } else {
        const { data } = await request({
          url: `/apix/apps/${this.log.appId}`,
          method: 'get',
        });
        this.$set(this.log, 'appName', data.name);
      }
    },
    // request/response 데이터를 가져온다.
    async fetchLogData() {
      const { data } = await request({
        url: `/apix/transactions/${this.transactionId}/data`,
        method: 'get',
      });
      // Body가 XML인 경우 unescape가 필요하다.
      if (data.request && data.request.body) {
        data.request.body = unescape(data.request.body);
      }
      if (data.response && data.response.body) {
        data.response.body = unescape(data.response.body);
      }
      this.logData = data;
    },
  },
}
</script>
