import 'normalize.css/normalize.css';
import 'vuetify/dist/vuetify.min.css';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import './styles/tiptap/main.scss';

import Vue from 'vue';
import vuetify from './plugins/vuetify';
import VuetifyConfirm from 'vuetify-confirm';
import JsonViewer from 'vue-json-viewer';
import App from './App.vue';
import router from './router';
import store from './store';
import VueMoment from 'vue-moment';
import VueChartkick from 'vue-chartkick';
import Chart from 'chart.js';
import DatetimePicker from 'vuetify-datetime-picker';
import messaging from './service/messaging.service';

import plugin from '@/plugins/plugin';
import authority from '@/plugins/authority';

import * as config from '@/config/config';

import './router/permission';
import './registerServiceWorker';

// console.clear();
import * as filters from '@/config/filters';
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
import hasAuth from '@/directives/has-auth';
import isAdmin from '@/directives/is-admin';
import TranslationService from '@/service/translation.service';
import { i18n_translate } from '@/config/constants';

filters.initFilters();

Vue.use(mavonEditor);
Vue.use(JsonViewer);
Vue.use(VueMoment);
Vue.use(VueChartkick.use(Chart));
Vue.use(DatetimePicker);

Vue.use(plugin);
Vue.use(authority);
Vue.use(hasAuth);
Vue.use(isAdmin);

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueColor: 'primary elevation-0',
  buttonFalseColor: 'primary elevation-0',
  buttonTrueText: '확인',
  buttonFalseText: '취소',
  buttonTrueFlat: false,
  buttonFalseFlat: false,
  property: '$confirm'
});

config.initVueApp(Vue);
const i18n = config.initI18N(Vue);

Vue.prototype.$translate = i18n_translate;

new Vue({
  router,
  store,
  i18n,
  vuetify,
  provide: {
    messaging,
    translationService: new TranslationService(store, i18n),
  },
  render: (h) => h(App),
}).$mount('#app');
