<!-- API의 메소드 목록 보기 -->
<template>
  <v-card outlined tile class="mb-6">
    <v-card-subtitle class="text-left">
      <h3 class="pl-md-2">{{$t('apiMethod.request')}} {{$t('apiMethod.body')}}</h3>
    </v-card-subtitle>
    <v-divider />
    <!-- <template>
        요청 Body
        <v-spacer/>
        <simple-regist-dialog
          v-if="mode !=null"
          title='요청 Body 추가'
          :fields="requetBodyItems"
          @addItem="addItem"
        />
      </template> -->
    <v-card-text v-if="!methodRequestBody.content">
      <api-method-content
        :content="{}"
        :mode="mode"
        @change="changeMethodContent"
      />
    </v-card-text>

    <v-card-text v-else>
      <template v-for="(value, name) in methodRequestBody">
        <api-method-content
          v-if="name == 'content'"
          :key="name"
          :content="value"
          :mode="mode"
          @change="changeMethodContent"
        />
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiMethodContent from "@/components/ApiMethodContent";
import { mapGetters } from 'vuex';
// import SimpleRegistDialog from '@/components/SimpleRegistDialog';

const mediaTypeItems = [
  {
    text: "application/json",
    value: "application/json",
  },
  {
    text: "application/xml",
    value: "application/xml",
  },
];
const requetBodyItems = [
  {
    text: 'apiMethod.contentType',
    value: "mediaType",
    required: true,
    type: "select",
    placeholder: "application/json",
    selectItems: mediaTypeItems,
  },
];
const defaultRequestBody = {
  content: {
    mediaType: {
      schema: {
        type: "object",
        properties: {
          property: {
            type: "",
            example: "",
          },
        },
      },
    },
  },
};

export default {
  name: "ApiMethodRequestBody",
  props: ["requestBody", "mode"],
  computed: {},
  components: {
    "api-method-content": ApiMethodContent,
    // 'simple-regist-dialog': SimpleRegistDialog,
  },

  mounted() {
    this.methodRequestBody = { ...this.requestBody };
  },
  data: () => ({
    requetBodyItems: this.$translate(requetBodyItems),
    methodRequestBody: {},
  }),
  methods: {
    addItem(item) {
      let methodRequestBody = JSON.parse(
        JSON.stringify({ ...defaultRequestBody })
      );
      // if(item.mediaType) methodRequestBody.content.mediaType = item.mediaType;

      //console.log('addItem ', parameter);

      if (!this.methodRequestBody) this.methodRequestBody = {};
      this.methodRequestBody.content[item.mediaType] =
        methodRequestBody.content.mediaType;

      console.log("methodRequestBody ", this.methodRequestBody);
      //this.$emit('change', this.methodRequestBody);
    },
    deleteItem(mediaType) {
      //let index = this.methodRequestBody.indexOf(item)
      //this.methodRequestBody.splice(index,1);
      delete this.methodRequestBody[mediaType];
      //this.$emit('change', this.methodParameters);
    },
    changeMethodContent(content) {
      console.log("changeMethodContent", content);
      console.log("methodRequestBody", this.methodRequestBody);
      if (!this.methodRequestBody) this.methodRequestBody = {};
      this.methodRequestBody.content = { ...content };
      this.$emit("change", this.methodRequestBody);
    },
    computed: {
      ...mapGetters(['currentLanguage']),
    },
  },
  watch:{
    currentLanguage() {
      this.requetBodyItems = this.$translate(requetBodyItems);
    },
  },
};
</script>
