import request from '@/utils/request';
import { TOTAL_COUNT_HEADER_NAME } from '@/constants';

/**
 * AXIOS 응답을 처리한다. x-total-count 헤더가 있는 경우는 해당 값과 함께 리턴하고, 없는 경우는 data만 리턴한다.
 *
 * @param response
 * @return {{data, totalCount: number}|*}
 */
export function processResponse(response) {
  const { data, headers } = response;
  if (headers[TOTAL_COUNT_HEADER_NAME]) {
    return {
      data: data,
      totalCount: Number(headers[TOTAL_COUNT_HEADER_NAME]),
    };
  } else {
    return data;
  }
}

/**
 * v-data-table에서 사용하는 페이지 옵션을 서버에서 사용하는 형태로 변환한다.
 *
 * @param options
 * @return {null|URLSearchParams}
 */
export function convertDataTableOptionsToParams(options) {
  if (!options) return null;

  const { sortBy, sortDesc, page, itemsPerPage } = options;

  const params = new URLSearchParams();
  params.append('page', String(page - 1));
  params.append('size', itemsPerPage);
  if (sortBy && sortBy.length > 0) {
    for (let i = 0; i < sortBy.length; i++) {
      const direction = sortDesc[i] ? 'desc' : 'asc';
      params.append('sort', `${sortBy[i]},${direction}`);
    }
  }
  return params;
}

/**
 * v-data-table에서 사용하는 paging옵션을 spring에서 사용하는 plain object로 변환한다.
 *
 * @param options Paging Options
 */
export function convertDataTablePagingOptionsToObject(options) {
  if (!options) return null;

  const { sortBy, sortDesc, page, itemsPerPage } = options;

  const params = {
    page: page - 1,
    size: itemsPerPage,
  };
  if (sortBy && sortBy.length > 0) {
    params.sort = [];
    for (let i = 0; i < sortBy.length; i++) {
      const direction = sortDesc[i] ? 'desc' : 'asc';
      params.sort.push(`${sortBy[i]},${direction}`);
    }
  }
  return params;
}

export function convertObjectToFormData(data) {
  if (!data) return null;
  const formData = new FormData();
  for (let [key, value] of Object.entries(data)) {
    if (value === null || key === 'files' || key === 'file') continue; // file, files는 별도 처리한다.
    if (value instanceof Date) {
      formData.append(key, value.toISOString());
    } else {
      formData.append(key, value);
    }
  }
  if (data.file) formData.append('file', data.file);
  if (data.files) {
    data.files.forEach((file) => formData.append('files', file));
  }
  return formData;
}

export async function fetchCodes(groupCode) {
  const { data } = await request({
    url: `/apix/site/codes/groups/${groupCode}`,
  });
  return data;
}

export async function download(url, filename) {
  console.log('url', url);
  const { data } = await request({
    url: url,
    method: 'GET',
    responseType: 'blob',
  });
  const fileURL = window.URL.createObjectURL(new Blob([data]));
  const fileLink = document.createElement('a');

  fileLink.href = fileURL;
  fileLink.setAttribute('download', filename);
  document.body.appendChild(fileLink);

  fileLink.click();
}

export function phoneFomatter(num, type) {
  let formatNum;
  if (num.length === 11) {
    if (type === 0) {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-****-$3');
    } else {
      formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
    }
  } else if (num.length === 8) {
    formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
  } else {
    if (num.indexOf('02') === 0) {
      if (type === 0) {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-****-$3');
      } else {
        formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
      }
    } else {
      if (type === 0) {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-***-$3');
      } else {
        formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
      }
    }
  }
  return formatNum;
}
