/* 공통 데이터 */
import request from '@/utils/request';

const commons = {
  state: {
    // API 공통 정보
    api: {
      tags: [],
      scopes: [],
      securities: [], // 인증방식
    }
  },
  getters: {
    apiTags: (state) => state.api.tags,
    apiScopes: (state) => state.api.scopes,
    apiSecurities: (state) => state.api.securities,
  },
  mutations: {
    SET_API_TAGS: (state, payload) => {
      state.api.tags = payload;
    },
    SET_API_SCOPES: (state, payload) => {
      state.api.scopes = payload;
    },
    SET_API_SECURITIES: (state, payload) => {
      state.api.securities = payload;
    }
  },
  actions: {
    loadApiTags: async ({ commit }) => {
      const { data } = await request({
        url: '/apix/apis/tags',
      });
      const sorted = data.sort((a, b) => a.name.localeCompare(b.name));
      await commit('SET_API_TAGS', sorted);
    },
    loadApiScopes: async ({ commit }) => {
      const { data } = await request({
        url: '/apix/scopes',
        method: 'get',
      });
      await commit('SET_API_SCOPES', data);
    },
    loadApiSecurities: async ({ commit }) => {
      const { data } = await request({
        url: '/apix/apis/securities',
        method: 'get',
      });
      await commit('SET_API_SECURITIES', data);
    },
    initCommonsApi: async ({ dispatch }) => {
      await dispatch('loadApiTags');
      await dispatch('loadApiScopes');
      await dispatch('loadApiSecurities');
    },
  }
};

export default commons;
