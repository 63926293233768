<template>
  <v-container>
    <!-- alert 메세지 표시 -->
    <v-row v-if="alertMessages.length > 0">
      <v-col
        cols="6"
        offset="3"
        :class="alertMessages.length > 0 ? 'pt-2' : ''"
        class="pa-0 mt-0"
      >
        <template v-for="(item, index) in alertMessages">
          <v-alert
            v-model="item.show"
            v-if="item.show"
            :key="index"
            :type="item.type"
            :class="alertMessages.length - 1 === index ? 'mb-0' : 'mb-1'"
            class="py-1"
            text
            dense
            dismissible
          >
            {{ item.message }}
          </v-alert>
        </template>
      </v-col>
    </v-row>
    <!-- snackbar 표시 -->
    <v-snackbar v-model="snackbar.show" :color="snackbar.color" centered>
      {{ snackbar.message }}
      <template v-slot:action="{ attrs }">
        <v-btn x-small v-bind="attrs" text @click="snackbar.show = false">
          <v-icon>mdi-close-octagon-outline</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- 로딩 바 표시 -->
    <loading
      :active.sync="loadingbar"
      :can-cancel="true"
      is-full-page
      :width="loading.width"
      :height="loading.height"
      :color="loading.color"
      :background-colr="loading.backgroundColor"
      :opacity="0"
    />
    <!--<loading-->
    <!--  :active.sync="isLoading"-->
    <!--  :can-cancel="true"-->
    <!--  :on-cancel="onCancel"-->
    <!--  :is-full-page="fullPage"-->
    <!--/>-->
  </v-container>
</template>

<script>
// import { mapActions, mapGetters } from 'vuex';
import { mapGetters } from "vuex";
import Loading from "vue-loading-overlay";

import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "AlertMessage",
  components: {
    Loading,
  },
  data: () => ({
    loading: {
      width: 100,
      height: 100,
      color: "#ED3137",
      backgroundColor: "#ED3137",
    },
  }),
  computed: {
    ...mapGetters(["alertMessages", "snackbar", "loadingbar"]),
  },
  // methods: {
  //   ...mapActions([
  //     'addAlert'
  //   ]),
  // },
  // mounted() {
  //   this.addAlert({
  //     type: 'info',
  //     message: 'success message',
  //   });
  //   this.addAlert({
  //     type: 'success',
  //     message: 'success messagetesttesttest',
  //   });
  //   this.addAlert('test message');
  //   console.log('alert messages', this.alertMessages);
  // },
};
</script>
