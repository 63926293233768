<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
import '@/views/css/temp_common.css';
import store from '@/store';
import { getStorageToken } from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import { map } from 'rxjs/operators';
import accountService from '@/service/account.service';

const TOKEN_CHECK_INTERVAL = 10;

export default {
  name: 'App',
  inject: ['messaging', 'translationService'],
  data() {
    return {
      transactionCount: 0,
      monitoringSubscription: null,
      tokenTimer: null,
    }
  },
  computed: {
    ...mapGetters([
      'monitoringEnabled',
      'token',
      'accessTokenExpiresIn',
      'accessTokenTimeoutInSeconds',
      'serverTokenTimeoutInSeconds',
      'lastRequestTime',
      'localTokenReceivedTime',
      'user',
    ]),
    tokenTimeout() {
      return this.accessTokenTimeoutInSeconds || this.serverTokenTimeoutInSeconds;
    },
  },
  methods: {
    ...mapActions([
      'LogOut',
      'setToken',
    ]),
    async logout() {
      console.log('[App] [Token] executing logout');
      if (this.tokenTimer) clearInterval(this.tokenTimer);
      await this.LogOut();
      await this.$router.push('/signin');
    },
    toggleMonitoring() {
      if (this.monitoringEnabled) {
        this.messaging.connect();
        this.monitoringSubscription = this.messaging.watch('/topic/realtime')
          .pipe(map((message) => JSON.parse(message.body)))
          .subscribe((payload) => {
            if (!payload.type) return;
            payload.lastUpdatedTime = new Date();
            switch (payload.type) {
              case 'transaction':
                payload.id = this.transactionCount++;
                store.dispatch('addTransaction', payload);
                break;
              case 'tps':
                store.dispatch('setTpsInfo', payload);
                break;
              case 'accumulated':
                store.dispatch('setAccumulated', payload);
                break;
              case 'hourly_statistics':
                // console.log('hourly_statistics', payload);
                store.dispatch('setStatistics', payload);
                break;
              default:
            }
          });
      } else {
        if (this.monitoringSubscription) this.monitoringSubscription.unsubscribe();
        this.messaging.disconnect();
      }
    }
  },
  async mounted() {
    this.translationService.refreshTranslation();

    if (this.tokenTimer) clearInterval(this.tokenTimer);
    this.tokenTimer = setInterval(async () => {
      if (!this.user || !this.token || !this.serverTokenTimeoutInSeconds || !this.accessTokenTimeoutInSeconds || !this.lastRequestTime || !this.localTokenReceivedTime) return;

      // 이전 토큰 만료 시간(local 기준)
      const expireTime = this.localTokenReceivedTime + (this.tokenTimeout * 1000);
      // 마지막 request 기준 토큰 만료시간 - 서버 토큰 만료 시간을 기준으로 계산해야 함
      const expireTimeFromLastRequest = this.lastRequestTime + (this.serverTokenTimeoutInSeconds * 1000);

      const now = Date.now();
      const beforeExpireTime = expireTime - now; // 파기까지 남은 시간
      // console.log('[App] [Token] current token timeout:', this.tokenTimeout * 1000, 'token remains:', beforeExpireTime, 'last req remains:', (expireTimeFromLastRequest - now));

      // 0보다 크면 token 갱신 조건 중 하나임
      const expireTimeGap = expireTimeFromLastRequest - expireTime;
      if (expireTimeGap > 0) {
        const needRefreshToken = beforeExpireTime < TOKEN_CHECK_INTERVAL * 3 * 1000; // 주기의 3배보다 적게 남은 경우 토큰 갱신
        // console.log('[App] [Token] need refresh token', needRefreshToken);
        if (needRefreshToken) {
          // 마지막 호출로부터 서버에 설정된 timeout시간을 기준으로 남은 시간을 계산한다.
          const newRemainedTime = expireTimeFromLastRequest - now;
          // console.log('[App] [Token] executing refresh token - remained: ', newRemainedTime);
          try {
            const newToken = await accountService.reIssueToken({
              ...this.token,
              remainedTimeInSeconds: parseInt(newRemainedTime / 1000) + TOKEN_CHECK_INTERVAL, // interval 정도의 여유를 더 둔다.
            });
            await this.setToken(newToken);
          } catch (e) {
            // console.log('[App] [Token] Failed to re-issue token');
            await this.logout();
          }
        }
      } else {
        if (now > expireTimeFromLastRequest) {
          await this.logout();
        }
      }
    }, TOKEN_CHECK_INTERVAL * 1000);
    if (getStorageToken()) {
      await store.dispatch('GetUserInfo');
    }
    this.toggleMonitoring();
  },
  watch: {
    monitoringEnabled() {
      this.toggleMonitoring();
    }
  }
};
</script>
