<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
      >
        <apim-forum
          :forum-id="forumId"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ApimForum from '@/components/forum/ApimForum';

export default {
  name: 'ForumView',
  props: ['forumId'],
  components: { ApimForum },
  // beforeRouteUpdate(to, from, next) {
  //   console.log('to', to);
  //   console.log('from', from);
  //   console.log('next', next);
  // }
}
</script>

<style scoped>

</style>
