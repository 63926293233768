import request from '@/utils/request';

const URL_PREFIX = '/apix/roles';

const roleService = {
  async list() {
    const { data } = await request.get(URL_PREFIX);
    return data;
  },
};

export default roleService;
