<template>
  <div class="contents">
    <h2 class="text-h3 p_title" style="position: relative">
      {{ context.name }} ( {{ context.context }} )

      <v-row align="center" class="btn_area" style="position: absolute">
        <v-spacer />
        <v-col cols="auto">
          <!--<v-btn-->
          <!--  v-if="authorities.includes(Authorities.apiCreate)"-->
          <!--  class="primary mr-1"-->
          <!--  elevation="0"-->
          <!--  @click="onClickResourceReloadAll"-->
          <!--&gt;-->
          <!--  전체 리소스 갱신-->
          <!--</v-btn>-->
          <v-btn
            v-if="$hasAnyAuthority(['API_CREATE', 'ADMIN'])"
            class="primary mr-1"
            elevation="0"
            @click="onClickRegistSwaggerButton"
          >
            {{ $t('api.swagger') }} {{ $t('api.registry') }}
          </v-btn>
          <v-btn
            v-if="$hasAnyAuthority(['API_CREATE', 'ADMIN'])"
            class="primary mr-1"
            elevation="0"
            @click="onClickRegistExcelButton"
          >
            {{ $t('api.excel') }} {{ $t('api.registry') }}
          </v-btn>
          <v-btn
            class="primary mr-1"
            elevation="0"
            @click="openSwaggerWindow()"
          >
            {{ $t('api.swagger') }} {{ $t('api.ui') }}
          </v-btn>
          <v-btn
            v-if="$hasAnyAuthority(['API_CREATE', 'ADMIN'])"
            class="primary mr-1"
            elevation="0"
            @click="showTransferResourceDialog"
          >
            {{ $t('api.resource') }} {{ $t('api.transfer') }}
          </v-btn>
          <v-btn class="primary mr-1" elevation="0" @click="exportSwagger()">
            {{ $t('api.export') }}
          </v-btn>
        </v-col>
        <!-- 소스 생성 언어 선택 -->
        <v-col cols="auto">
          <v-select
            v-model="selected.codeGen.language"
            :items="selects.languages"
            :label="$t('api.sdk')"
            class="language-selector"
            dense
            hide-details
            outlined
            style="width: 140px"
          >
            <template v-slot:append-outer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    outlined
                    style="min-width: 34px; padding: 0 !important"
                  >
                    <v-icon
                      color="primary"
                      v-bind="attrs"
                      @click="downloadSwaggerCodeGen"
                      v-on="on"
                    >
                      mdi-download
                    </v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('api.sample') }} {{ $t('api.code') }} {{ $t('api.download') }}</span>
              </v-tooltip>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </h2>
    <!-- 상단 swagger 등록 버튼 -->

    <!-- 보기 선택 영역 -->
    <v-row align="center">
      <v-col cols="2">
        <v-select
          v-model="selected.type"
          :items="selects.types"
          :label="$t('api.view')"
          dense
          hide-details
          outlined
          @change="onChangeType"
        />
      </v-col>
      <v-col
        v-if="selected.type === 'resources' && $hasAnyAuthority(['API_CREATE', 'ADMIN'])"
        cols="auto"
      >
        <!-- 리소스 추가 다이얼로그 -->
        <api-resource-edit
          :contextpath="context.context"
          :sendData="resources"
          @success="addedResource"
        />
      </v-col>
      <v-spacer />
      <!-- 검색 영역 -->
      <v-col v-if="selected.type === 'resources'" cols="4">
        <div style="display: flex; align-items: center">
          <v-select
            v-model="selected.methodStatus"
            :items="selects.status"
            class="mr-2"
            dense
            hide-details
            label="status"
            outlined
            style="width: 145px"
          />
          <v-text-field
            v-if="authorities.includes(Authorities.appCreate)"
            v-model="search.uri"
            dense
            hide-details
            label="URI"
            outlined
            @keyup.enter="fetchSearch"
          ></v-text-field>
          <v-btn
            v-if="authorities.includes(Authorities.apiCreate)"
            class="primary"
            elevation="0 ml-1"
            hide-details
            @click="fetchSearch"
          >
            <v-icon>mdi-magnify</v-icon>
            {{ $t('api.search') }}
          </v-btn>
        </div>
      </v-col>
      <v-col v-if="selected.type === 'categories'">
        <v-dialog
          v-model="dialog.registCategory"
          max-width="400px"
          persistent
          @keydown.esc="dialog.registCategory = false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="authorities.includes(Authorities.apiCreate)"
              elevation="0"
              hide-details
              outlined
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
              {{ $t('api.add') }} {{ $t('api.category') }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('api.add') }} {{ $t('api.category') }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="newObject.category.name"
                    :label="$t('api.name')"
                    hide-details
                    outlined
                    required
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn outlined @click="onClickCancelButtonInCategoryRegist">
                {{ $t('api.close') }}
              </v-btn>
              <v-btn
                v-if="authorities.includes(Authorities.apiCreate)"
                class="primary"
                @click="onClickRegistButtonInCategoryRegist"
              >
                {{ $t('api.registry') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
      <v-col v-if="selected.type === 'tags'">
        <v-dialog
          v-model="dialog.registTag"
          max-width="400px"
          persistent
          @keydown.esc="dialog.registTag = false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="authorities.includes(Authorities.apiCreate)"
              elevation="0"
              hide-details
              outlined
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
              {{ $t('api.add') }} {{ $t('api.tag') }}
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ $t('api.add') }} {{ $t('api.tag') }}</span>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="newObject.tag.name"
                    :label="$t('api.name')"
                    hide-details
                    outlined
                    required
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="newObject.tag.description"
                    :label="$t('api.description')"
                    hide-details
                    outlined
                    required
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn outlined @click="onClickCancelButtonInTagRegist">
                {{ $t('api.close') }}
              </v-btn>
              <v-btn
                v-if="authorities.includes(Authorities.apiCreate)"
                class="primary"
                @click="onClickRegistButtonInTagRegist"
              >
                {{ $t('api.registry') }}
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row></v-row>
    <v-row>
      <v-col cols="12">
        <!-- 데이터가 없을 때 보여주는 이미지 -->
        <div
          v-if="totalCount === 0"
          class="text-center"
        >
          <v-img
            class="my-3 mt-12"
            contain
            height="300"
            src="./assets/blankapi.png"
          />
        </div>
        <!-- 리소스별 보기 -->
        <v-expansion-panels
          v-if="selected.type === 'resources'"
          v-model="panel.resource"
          class="details"
          focusable
          popout
        >
          <api-resource-expansion-panel
            v-for="(resource, index) in resources"
            :key="index"
            :context="context.context"
            :contextpath="contextpath"
            :method-status="selected.methodStatus"
            :resource="resource"
            @refresh="eventRefresh"
          />
        </v-expansion-panels>
        <!-- 태그별 보기 -->
        <v-expansion-panels
          v-if="selected.type === 'tags'"
          v-model="panel.tag"
          focusable
          popout
        >
          <v-expansion-panel v-for="tag in tags" :key="tag.name">
            <v-expansion-panel-header>
              <v-row align="center">
                <v-col cols="auto">
                  {{ tag.name }} | {{ tag.description }}
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click.stop.prevent="
                          openSwaggerWindow({ tagName: tag.name })
                        "
                      >
                        mdi-api
                      </v-icon>
                    </template>
                    <span>Swagger UI</span>
                  </v-tooltip>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click.stop.prevent="
                          openSwaggerDownloadDialog('tag', tag)
                        "
                      >
                        mdi-download
                      </v-icon>
                    </template>
                    <span>{{ $t('api.sample') }} {{ $t('api.code') }} {{ $t('api.download') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row v-if="tag.methods">
                <v-col>
                  <api-method-list-view
                    :methods="tag.methods"
                    v-on:showSwaggerUI="openSwaggerWindow"
                    @swagger-codegen="openSwaggerDownloadDialog"
                  />
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- 카테고리별 보기 -->
        <v-expansion-panels
          v-if="selected.type === 'categories'"
          v-model="panel.category"
          focusable
          popout
        >
          <v-expansion-panel v-for="category in categories" :key="category.id">
            <v-expansion-panel-header>
              <v-row align="center" no-gutters>
                <v-col cols="auto">
                  {{ category.name }}
                </v-col>
                <v-spacer />
                <v-col cols="auto">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        dense
                        v-bind="attrs"
                        v-on="on"
                        @click.stop.prevent="
                          onClickModifyCategoryButton(category)
                        "
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>{{ $t('api.modify') }}</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content style="margin-left: 50px">
              <!-- 카테고리 내 리소스 목록 -->
              <v-expansion-panels
                v-model="panel.categoryResource"
                focusable
                popout
                style="min-height: 30px"
              >
                <v-expansion-panel
                  v-for="resource in category.resources"
                  :key="resource.id"
                >
                  <v-expansion-panel-header>
                    <v-row align="center">
                      <v-col cols="auto">
                        {{ resource.uri }}
                      </v-col>
                      <v-spacer />
                      <v-col cols="auto">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="mr-1"
                              dense
                              v-bind="attrs"
                              v-on="on"
                              @click.stop.prevent="openSwaggerViewDialog(resource)"
                            >
                              mdi-text-box
                            </v-icon>
                          </template>
                          <span>{{ $t('api.swagger') }} {{ $t('api.document') }}</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              class="mr-1"
                              dense
                              v-bind="attrs"
                              v-on="on"
                              @click.stop.prevent="openSwaggerWindow({ resourceId: resource.id })"
                            >
                              mdi-api
                            </v-icon>
                          </template>
                          <span>Swagger UI</span>
                        </v-tooltip>
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              dense
                              v-bind="attrs"
                              v-on="on"
                              @click.stop.prevent="deleteResource(resource)"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                          <span>{{ $t('api.delete') }}</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <api-method-list-view
                      :methods="resource.methods"
                      v-on:showSwaggerUI="openSwaggerWindow"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
        <!-- 페이징 -->
        <div
          v-if="selected.type === 'resources' && pageLength > 0"
          class="text-xs-center mt-4"
        >
          <v-pagination
            v-model="paging.current"
            :length="pageLength"
            :total-visible="7"
            class="elevation-0"
            @input="fetchByResource"
          />
        </div>
      </v-col>
    </v-row>

    <!-- 카테고리 수정 다이얼로그 -->
    <v-dialog
      v-model="dialog.modifyCategory"
      max-width="600px"
      persistent
      scrollable
      @keydown.esc="dialog.modifyCategory = false"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{ $t('api.modify') }} {{ $t('api.category') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="search_div_modify_filter"
                  :label="$t('api.searchMsg')"
                  append-icon="mdi-magnify"
                  hide-details
                  single-line
                ></v-text-field>
                <v-data-table
                  v-model="selected.categoryResources"
                  :headers="headers.resources"
                  :items="resources"
                  :search="search_div_modify_filter"
                  disable-pagination
                  hide-default-footer
                  item-key="id"
                  show-select
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="blue darken-1"
            text
            @click="onClickCancelButtonInCategoryModify"
          >
            {{ $t('api.close') }}
          </v-btn>
          <v-btn
            v-if="authorities.includes(Authorities.apiUpdate)"
            color="blue darken-1"
            text
            @click="onClickSaveButtonInCategoryModify"
          >
            {{ $t('api.modify') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Swagger 파일 업로드 다이얼로그 -->
    <v-dialog
      v-model="dialog.registSwagger"
      max-width="400px"
      persistent
      @keydown.esc="dialog.registSwagger = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left">{{ $t('api.swagger') }} {{ $t('api.registry') }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="modifyObject.files"
                  :label="$t('api.swagger') + ' ' +$t('api.file')"
                  show-size
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined @click="closeRegistSwaggerDialog"> {{ $t('api.close') }}</v-btn>
          <v-btn
            v-if="authorities.includes(Authorities.apiCreate)"
            class="primary"
            @click="uploadSwaggerFile"
          >
            {{ $t('api.registry') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Excel 파일 업로드 다이얼로그 -->
    <v-dialog
      v-model="dialog.registExcel"
      max-width="400px"
      persistent
      @keydown.esc="dialog.registExcel = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left">{{ $t('api.excel') }} {{ $t('api.registry') }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                  v-model="modifyObject.excel"
                  :label="$t('api.excel') + ' ' +$t('api.file')"
                  show-size
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined @click="closeRegistExcelDialog"> {{ $t('api.close') }}</v-btn>
          <v-btn
            v-if="authorities.includes(Authorities.apiCreate)"
            class="primary"
            @click="uploadExcelFile"
          >
            {{ $t('api.registry') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Swagger CodeGen 보기 다이얼로그 -->
    <v-dialog
      v-model="dialog.swaggerCodeGenView"
      max-width="300px"
      @keydown.esc="dialog.swaggerCodeGenView = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left">
          {{ $t('api.swagger') }} {{ $t('api.code') }} {{ $t('api.sample') }} {{ $t('api.download') }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row class="mt-3" justify="center">
            <v-col cols="auto">
              <v-select
                v-model="selected.codeGen.language"
                :items="selects.languages"
                hide-details
                label="SDK"
                outlined
                style="width: 140px"
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        color="primary"
                        v-bind="attrs"
                        @click="downloadSwaggerCodeGen"
                        v-on="on"
                      >
                        mdi-download
                      </v-icon>
                    </template>
                    <span>{{ $t('api.sample') }} {{ $t('api.code') }} {{ $t('api.download') }}</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined @click="closeSwaggerCodeGenViewDialog"> {{ $t('api.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Swagger 내용 보기 다이얼로그 -->
    <v-dialog
      v-model="dialog.swaggerView"
      max-width="800px"
      persistent
      scrollable
      @keydown.esc="dialog.swaggerView = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left">{{ $t('api.swagger') }} {{ $t('api.content') }}</v-card-title>
        <v-spacer />
        <v-card-text>
          <json-viewer
            :expand-depth="5"
            :value="modifyObject.swagger"
            boxed
            copyable
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined @click="closeSwaggerViewDialog"> {{ $t('api.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 리소스 이관 다이얼로그 -->
    <v-dialog
      v-if="dialog.transfer"
      v-model="dialog.transfer"
    >
      <api-resource-transfer-view
        @close="dialog.transfer = false"
        @refresh="onChangeType"
      />
    </v-dialog>
  </div>
</template>

<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}

.language-selector >>> .v-input__append-outer {
  margin-top: 3px !important;
}
</style>

<script>
import request from '@/utils/request';
import { mapActions, mapGetters } from 'vuex';
import ApiMethodListView from '../components/ApiMethodListView';
import ApiResourceEdit from '../components/ApiResourceEdit';
import Authorities from '@/locale/ko_KR/authorities';
import Vue from 'vue';
import ApiResourceExpansionPanel from '@/components/api/ApiResourceExpansionPanel';
import apiService from '@/api/ApiService';
import { SELECTOR_METHOD_STATUS, SELECTOR_SDK_TYPES } from '@/config/constants';
import ApiResourceTransferView from '@/components/api/ApiResourceTransferView';

// window.console.log("router",router);


window.Vue = Vue;
Vue.component('api-resource-edit', {
  props: ['sendData'],
});


const VIEW_TYPES = [
  { text: 'api.resource', value: 'resources' },
  { text: 'api.tag', value: 'tags' },
  { text: 'api.category', value: 'categories' },
];

const HEADER_METHODS = [
  {
    text: 'api.method',
    value: 'httpMethod',
  },
  {
    text: 'api.uri',
    value: 'uri',
  },
  {
    text: 'api.summary',
    value: 'summary',
  },
  {
    text: 'api.security',
    value: 'securityType',
  },
  {
    text: 'api.scope',
    value: 'oauthScopes',
  },
  {
    text: 'api.note',
    value: 'actions',
  },
];

const HEADER_RESOURCE = [
  {
    text: 'api.uri',
    value: 'uri',
  },
];
const PAGE_SIZE = 10;
export default {
  props: ['contextpath'],
  name: 'ApiManagement',
  components: {
    ApiResourceTransferView,
    ApiResourceExpansionPanel,
    'api-method-list-view': ApiMethodListView,
    'api-resource-edit': ApiResourceEdit,
  },
  data: function () {
    return {
      ...mapActions(['addAlert']),
      Authorities: Authorities,
      PAGE_SIZE: PAGE_SIZE,
      headers: {
        resources: this.$translate(HEADER_RESOURCE),
        methods: this.$translate(HEADER_METHODS),
      },
      selects: {
        types: this.$translate(VIEW_TYPES),
        languages: this.$translate(SELECTOR_SDK_TYPES),
        status: this.$translate(SELECTOR_METHOD_STATUS),
      },
      dialog: {
        registTag: false,
        registCategory: false,
        registSwagger: false,
        modifyCategory: false,
        methodView: false, // 메소드 보기 다이얼로그
        swaggerView: false,
        swaggerCodeGenView: false,
        registExcel: false,
        transfer: false,
      },
      panel: {
        resource: null,
        tag: null,
        category: null,
        categoryResource: null, // 카테고리 내 리소스 판넬
      }, // resource 판넬 선택 값
      selected: {
        type: 'resources',
        categoryResources: [], // 카테고리에 포함된 resource의 ID 목록
        method: null,
        codeGen: {
          language: 'java',
          target: null, // 대상: resource, method ...
          item: null,
        },
        methodStatus: '', // 메소드 상태 값
      },
      resources: [],
      tags: [],
      categories: [],
      newObject: {
        category: {
          name: null,
        },
        tag: {
          name: null,
          description: null,
        },
      },
      modifyObject: {
        category: null,
        tag: null,
        files: null,
        swagger: null,
        excel: null,
      },
      search: {
        uri: null,
      },
      paging: {
        current: 1,
        size: 10,
      },
      totalCount: 0,
      search_filter: null,
      search_filter_status: '',
      search_div_modify_filter: null,
      currentPage: 1,
      // pageLength: 1,
      context: {
        context: '/',
        name: '',
        description: null,
        securityId: 'PUBLIC',
        ownerId: '',
        roles: [],
      },
      transfer: {
        selected: null, // 선택된 리소스 목록
      }
    };
  },
  computed: {
    ...mapGetters(['contextPath', 'local', 'port', 'authorities', 'currentLanguage']),
    pageLength() {
      return parseInt(this.totalCount / this.paging.size) + (this.totalCount % this.paging.size > 0 ? 1 : 0);
    }
  },
  async activated() {
    await this.init();
    this.selects.status = this.$translate(SELECTOR_METHOD_STATUS);
  },
  methods: {
    async fetchSearch() {
      //검색 버튼, 엔터키를 눌렀을 때 동작하는 메서드
      //fetchmethod와 page를 초기화 한다.
      this.paging.current = 1;
      await this.fetchByResource();
    },
    async init() {
      // 특정 리소스 정보 갱신을 위한 서버 API가 없다.
      const { data } = await request({
        url: `/apix/apiGroup/get?contextpath=${this.contextpath}`,
        method: 'get',
      });
      this.context = data;

      this.selected.type = 'resources';
      this.resources = [];
      this.currentPage = 1;
      this.search_filter_status = '';
      this.search_filter = '';

      await this.fetchByResource();
    },
    async fetchByResource() {
      const search = {
        page: this.paging.current - 1,
        size: this.paging.size,
        uri: this.search.uri,
        uriPrefix: this.contextpath, // group
        includeMethods: true,
        // sort: 'uri,asc'
      };
      const { totalCount, data } = await apiService.searchResources(search);
      this.totalCount = totalCount;
      this.resources = data;
    },
    async fetchByTag() {
      const { data } = await request({
        url: `/apix/apis/tags?context=${this.context.context}`,
      });
      this.tags = data;
      this.totalCount = this.tags.length;
    },
    async fetchByCategory() {
      const { data } = await request({
        url: '/apix/apis/categories',
      });
      data.forEach((c) => (c.resources = []));
      this.categories = data;
      this.panel.category = null;
      this.panel.categoryResource = null;
      this.totalCount = this.categories.length;
      // panel이 열려 있는 경우 하위 정보를 로드한다.
      // if (this.panel.category) {
      //   await this.fetchCategoryResource(this.categories[this.panel.category]);
      //   if (this.panel.categoryResource) await this.fetchMethods(this.categories[this.panel.category].resources[this.panel.categoryResource]);
      // }
    },
    async fetchMethods(resource) {
      const { data } = await request({
        url: `/apix/apis/resources/${resource.id}/methods/normal`,
      });
      resource.methods = data;
      // if(typeof(resource.methods[0].summary) === undefined){
      //   resource.methods[0].summary = "";
      // }
    },
    async fetchCategoryResource(category) {
      const { data } = await request({
        url: `/apix/apis/categories/${category.id}/resources/normal`,
      });
      data.forEach((r) => (r.methods = []));
      category.resources = data;
    },
    async SearchMethodStatus(selected, searchData) {
      let url = null;
      url = `/apix/apis/resources/search/status/method?context=${this.context.context}&searchData=${searchData}&status=${selected}`;
      const { data } = await request({
        url: url,
      });

      //data.forEach((r) => {
      //  r.methods = [
      //    {
      //      summary: "",
      //    },
      //  ];
      //  this.fetchMethods(r);
      //});
      this.resources = data;
    },
    onChangeType() {
      switch (this.selected.type) {
        case 'resources':
          this.fetchByResource();
          break;
        case 'tags':
          this.fetchByTag();
          break;
        case 'categories':
          this.fetchByCategory();
          break;
        default:
      }
    },
    //  태그 등록 창의 닫기 버튼 클릭 시
    onClickCancelButtonInTagRegist() {
      this.newObject.tag = {
        name: null,
        description: null,
      };
      this.dialog.registTag = false;
    },
    // 태그 등록 창의 등록 버튼 클릭 시
    async onClickRegistButtonInTagRegist() {
      await request({
        url: '/apix/apis/tags',
        method: 'post',
        data: this.newObject.tag,
      });
      this.onClickCancelButtonInTagRegist();
      await this.fetchByTag();
    },
    // 구분 등록 창의 닫기 버튼 클릭 시
    onClickCancelButtonInCategoryRegist() {
      this.newObject.category = {
        name: null,
      };
      this.dialog.registCategory = false;
    },
    // 구분 등록 창의 등록 버튼 클릭 시
    async onClickRegistButtonInCategoryRegist() {
      await request({
        url: '/apix/apis/categories',
        method: 'post',
        data: this.newObject.category,
      });
      this.onClickCancelButtonInCategoryRegist();
      await this.fetchByCategory();
    },
    // 카테고리 수정 버튼 클릭 시
    async onClickModifyCategoryButton(category) {
      // 카테고리에 포함된 리소스를 지정한다.
      if (!category.resources || category.resources.length === 0) {
        await this.fetchCategoryResource(category);
      }
      await this.fetchByResource();
      const includedIdLis = category.resources.map((r) => r.id);
      this.selected.categoryResources = this.resources.filter((r) =>
        includedIdLis.includes(r.id)
      );
      this.modifyObject.category = category;
      this.dialog.modifyCategory = true;
    },
    // 구분 수정 창의 닫기 버튼 클릭 시
    onClickCancelButtonInCategoryModify() {
      this.selected.categoryResources = [];
      this.modifyObject.category = null;
      this.dialog.modifyCategory = false;
    },
    // 구분 수정 창의 저장 버튼 클릭 시
    async onClickSaveButtonInCategoryModify() {
      await request({
        url: `/apix/apis/categories/${this.modifyObject.category.id}/mappings`,
        method: 'put',
        data: this.selected.categoryResources.map((r) => r.id),
      });
      await this.fetchCategoryResource(this.modifyObject.category);
      this.onClickCancelButtonInCategoryModify();
    },
    onClickRegistSwaggerButton() {
      this.dialog.registSwagger = true;
    },
    onClickRegistExcelButton() {
      this.dialog.registExcel = true;
    },
    closeRegistSwaggerDialog() {
      this.dialog.registSwagger = false;
    },
    closeRegistExcelDialog() {
      this.dialog.registExcel = false;
    },
    async uploadSwaggerFile() {
      const formData = new FormData();
      formData.append('files', this.modifyObject.files);
      await request({
        url: '/apix/apis/swagger',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      this.modifyObject.files = null;
      this.onChangeType();
      this.closeRegistSwaggerDialog();
    },
    async uploadExcelFile() {
      const formData = new FormData();
      formData.append('files', this.modifyObject.excel);
      await request({
        url: '/apix/apis/swagger/excel',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      this.modifyObject.excel = null;
      this.onChangeType();
      this.closeRegistExcelDialog();
    },
    async openSwaggerDownloadDialog(target, item) {
      this.selected.codeGen.target = target;
      this.selected.codeGen.item = item;
      this.dialog.swaggerCodeGenView = true;
    },
    closeSwaggerCodeGenViewDialog() {
      this.selected.codeGen.target = null;
      this.selected.codeGen.item = null;
      this.dialog.swaggerCodeGenView = false;
    },
    async downloadSwaggerCodeGen() {
      let targetUrl = '/apix/codegen';
      const language = this.selected.codeGen.language;
      const selected_context = this.context.context;

      // target이 없다면 전체인 걸로 간주한다.
      if (!this.selected.codeGen.target) this.selected.codeGen.target = 'all';
      if (this.selected.codeGen.item && this.selected.codeGen.target) {
        switch (this.selected.codeGen.target) {
          case 'resource':
            targetUrl = `${targetUrl}/resources/${this.selected.codeGen.item.id}?type=${language}`;
            break;
          case 'tag':
            targetUrl = `${targetUrl}/tags?type=${language}&name=${this.selected.codeGen.item.name}`;
            break;
          case 'method':
            targetUrl = `${targetUrl}/methods/${this.selected.codeGen.item.id}?type=${language}`;
            break;
          case 'category': // 현재는 없음
            targetUrl = `${targetUrl}?type=${language}`;
            break;
          default:
            // 없다면 그냥 전체를 가져오는 걸로
            targetUrl = `${targetUrl}?type=${language}`;
            break;
        }
      } else {
        targetUrl = `${targetUrl}?type=${language}&context=${selected_context}`;
      }

      const { data } = await request({
        url: targetUrl,
        method: 'get',
      });
      if (data.error) {
        this.addAlert({
          type: 'error',
          message: '오류 발생:' + data.message,
        });
        return;
      }

      const linkSource = 'data:application/zip;base64,' + data.content;
      const downloadLink = document.createElement('a');
      const fileName =
        this.selected.codeGen.target + '_' + language + '_generated.zip';

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();

      var createdSdkMsg = this.$t('api.createdSdkFileMsg');
      await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_complete.svg" /><br/><b>${createdSdkMsg}</b></div>`,
      }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
      this.closeSwaggerCodeGenViewDialog();
    },
    async onClickResourceReloadAll() {
      const { data } = await request({
        url: '/gw/refresh-resource',
        method: 'get',
      });
      console.log('refresh result', data);
    },
    async onClickResourceReload(resource) {
      const { data } = await request({
        url: `/gw/refresh-resource/${resource.id}`,

        method: 'get',
      });
      console.log('refresh result', data);
    },
    async openSwaggerViewDialog(resource) {
      const { data } = await request({
        url: `/apix/apis/resources/${resource.id}/swagger`,
      });
      this.modifyObject.swagger = data;
      this.dialog.swaggerView = true;
    },
    closeSwaggerViewDialog() {
      this.modifyObject.swagger = null;
      this.dialog.swaggerView = false;
    },
    async addedResource(resource) {
      console.log('added resource ', resource);
      var savedResourceMsg = this.$t('api.savedResourceMsg');
      await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_complete.svg" /><br/><b>${savedResourceMsg}</b></div>`,
      }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
      this.fetchByResource();
    },
    async addedMethod(resource) {
      console.log('added resource ', resource);
      var savedMethodMsg = this.$t('api.savedMethodMsg');
      await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_complete.svg" /><br/><b>${savedMethodMsg}</b></div>`,
      }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
      this.fetchByResource();
    },

    async deleteResource(resource) {
      var deleteResourceMsg = this.$t('api.deleteResourceMsg');
      try {
        const res = await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_warning.svg" />
        <br/><b>${deleteResourceMsg}</b></div>`,
          confirmYn: true,
        }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
        if (res) {
          const { data } = await request({
            url: `/apix/apis/resources/${resource.id}/delete`,
            method: 'delete',
          });
          console.log('data ::::::: ', data);
          var deletedResourceMsg = this.$t('api.deletedResourceMsg');
          await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_complete.svg" /><br/><b>${deletedResourceMsg}</b></div>`,
          }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
          this.onChangeType();
          this.fetchByResource();
        }
      } catch (error) {
        console.log('result error ::::::', error.response);
        var errorMsg = this.$t('api.errorMsg');
        if (error.response.data.detail) {
          await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_fail.svg" />
        <br/><b>${errorMsg} : '${error.response.data.detail}'</b></div>`,
          }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
        } else {
          await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_fail.svg" />
        <br/><b>${errorMsg} : '${error.message}'</b></div>`,
          }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
        }
        return;
      }
    },
    async deleteMethod(method) {
      var deleteMethodMsg = this.$t('api.deleteMethodMsg');
      const res = await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_warning.svg" />
        <br/><b>'${method.summary}' ${deleteMethodMsg}</b></div>`,
        confirmYn: true,
      }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
      if (res) {
        await request({
          url: `/apix/apis/methods/${method.id}`,
          method: 'delete',
        });
        var deletedMethodMsg = this.$t('api.deletedMethodMsg');
        await this.$confirm({
          messaage: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_complete.svg" /><br/><b>'${method.summary}' ${deletedMethodMsg}</b></div>`,
        }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
        this.onChangeType();
      }
    },
    openSwaggerWindow(item) {
      // let index =
      //   swaggerUiIndex === undefined
      //     ? "/swagger-ui/index.html"
      //     : swaggerUiIndex;
      let index = window.swaggerUiIndex || '/swagger-ui/index.html';
      console.log('index', index);

      let url = `${this.contextPath}` + index;
      let configUrl = `configUrl=${this.contextPath}/v3/api-docs/swagger-config`;
      let docUrl = `url=${this.contextPath}/apix/swagger`;

      if (process.env.NODE_ENV === 'development')
        url = `http://localhost:${this.port}` + url;

      if (item) {
        if (item.resourceId) docUrl += `/resources/${item.resourceId}`;
        else if (item.tagName)
          docUrl += `/tags?` + encodeURIComponent(`name=${item.tagName}`);
        else if (item.methodId) docUrl += `/methods/${item.methodId}`;
      } else if (this.contextpath != '/')
        docUrl += `/contextpath${this.contextpath}`;

      const fullUrl = `${url}?${configUrl}&${docUrl}`;
      const win = window.open(fullUrl, '_blank');
      win.focus();
    },
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.headers['content-disposition']; // 파일 이름
      let fileName = 'unknown';
      if (contentDisposition) {
        const [fileNameMatch] = contentDisposition
          .split(';')
          .filter((str) => str.includes('filename'));
        if (fileNameMatch) [, fileName] = fileNameMatch.split('=');
      }
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
    },
    exportSwagger() {
      request({
        method: 'get',
        url: `/apix/swagger/download?contextpath=${this.contextpath}`,
        headers: { Accept: 'application/x-yaml' },
      })
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log('error occured'));
    },
    exportSwaggerResource(resource) {
      request({
        method: 'get',
        url: '/apix/swagger/resources/' + resource.id + '/download',
        headers: { Accept: 'application/x-yaml' },
      })
        .then((response) => {
          this.forceFileDownload(response);
        })
        .catch(() => console.log('error occured'));
    },
    async eventRefresh() {
      await this.fetchByResource();
    },
    showTransferResourceDialog() {
      this.transfer.selected = null;
      this.dialog.transfer = true;
    },
  },
  watch: {
    currentLanguage() {
      // this.selects.status = this.$translate(SELECTOR_METHOD_STATUS);
      // this.selects.types = this.$translate(VIEW_TYPES);
      this.headers.resources = this.$translate(HEADER_RESOURCE);
      this.headers.methods = this.$translate(HEADER_METHODS);
      this.selects.types = this.$translate(VIEW_TYPES);
      this.selects.languages = this.$translate(SELECTOR_SDK_TYPES);
      this.selects.status = this.$translate(SELECTOR_METHOD_STATUS);

    },
    expandStatus() {
      console.log(this.expandStatus);
    },
    'panel.resource': function (value, oldValue) {
      if (typeof value === 'undefined' || value === null) return;
      if (value === oldValue) return;
      // console.log('여기', value, this.resourcesList);
      // this.fetchMethods(this.resourcesList[value]);
    },
    'panel.category': function (value, oldValue) {
      if (typeof value === 'undefined' || value === null) return;
      if (value === oldValue) return;
      this.fetchCategoryResource(this.categories[value]);
    },
    'panel.categoryResource': function (value, oldValue) {
      if (typeof value === 'undefined' || value === null) return;
      if (value === oldValue) return;
      if (!this.categories[this.panel.category]) return;
      this.fetchMethods(this.categories[this.panel.category].resources[value]);
    },
  },
};
</script>
