<template>
  <v-container>
    <div class="loc" style="background: #151826">
      <li class="mr-2">
        <v-app-bar-nav-icon @click.stop="toggleNavbar">
          <!-- <v-icon style="font-size: 30px">{{ toggleNavbarIcon }}</v-icon> -->
          <v-img
            v-bind:src="require('../assets/' + toggleNavbarIcon)"
            style="width: 20px"
          ></v-img>
        </v-app-bar-nav-icon>
      </li>
      <li v-for="(item, i) in levelList" v-bind:key="i">
        <a @click.prevent="handleLink(item)">
          {{ generateTitle(item.meta.title) }}
        </a>
      </li>
    </div>
  </v-container>
</template>

<style scoped>
i.v-icon.v-icon {
  color: white;
}
</style>

<script>
import { mapGetters } from 'vuex';

const pathToRegexp = require("path");

export default {
  name: "TheLayoutToolbar",
  components: {},
  data: () => ({
    levelList: null,
  }),
  computed: {
    ...mapGetters(["toolbarDense", "navbarShow"]),
    toggleNavbarIcon() {
      return this.navbarShow ? "menu-hidden.svg" : "menu.svg";
    },
  },
  methods: {
    toggleNavbar() {
      this.$store.dispatch("NavbarToggle");
    },
    generateTitle(title) {
      const hasKey = this.$te(`${title}`);
      if (hasKey) {
        return this.$t(`${title}`);
      }
      return title;
    },
    getBreadcrumb() {
      let matched = this.$route.matched.filter((item) => item.name);

      this.levelList = matched.filter(
        (item) => item.meta && item.meta.title && item.meta.breadcrumb !== false
      );
    },
    pathCompile(path) {
      const { params } = this.$route;
      const toPath = pathToRegexp.compile(path);
      return toPath(params);
    },
    handleLink(item) {
      console.groupCollapsed("handleLink");
      const { redirect, path } = item;
      console.log(`redirect=${redirect}, path=${path}`);
      if (redirect) {
        console.log("redirect");
        this.$router.push(redirect);
        console.groupEnd();
        return;
      }
      this.$router.push(this.pathCompile(path));
      console.groupEnd();
    },
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    },
  },
  created() {
    this.getBreadcrumb();
  },
};
</script>
