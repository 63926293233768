<template v-slot:append>
  <v-app>
    <v-main pa-0>
      <v-container fluid pa-0>
        <v-row>
          <v-col cols="5" class="ctn">
            <img src="../assets/login-logo.svg" class="login" />
          </v-col>
          <v-col cols="7" class="Rectangle">
            <span class="PRODUCT-NAME"> {{$t('signin.apiPortal')}} </span>

            <v-text-field
              prepend-inner-icon="person"
              solo
              :label="$t('signin.id')"
              v-model="model.email"
              :error="error"
              class="text-login mb-3 pt-1"
              flat
              tabindex="1"
            />
            <v-text-field
              :type="hidePassword ? 'password' : 'text'"
              :prepend-inner-icon="hidePassword ? 'lock' : 'lock_open'"
              :label="$t('signin.password')"
              v-model="model.password"
              :error="error"
              @keyup.enter="login"
              solo
              flat
              class="text-login pt-1"
              @click:prepend-inner="hidePassword = !hidePassword"
              tabindex="2"
            />
            <v-alert
              :value="showResult"
              color="warning"
              outlined
              transition="scale-transition"
            >
              {{ result }}
            </v-alert>
            <v-btn
              class="login_btn mr-5"
              :loading="loading"
              @click="login"
              color="#ed3137"
            >
              {{$t('signin.login')}}
            </v-btn>

            <!-- 회원 가입 영역 시작 -->
            <v-dialog
              v-model="dialog.register"
              persistent
              max-width="600px"
              @keydown.esc="dialog.register = false"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  color="#151826"
                  class="login_btn"
                  :loading="loading"
                >
                  {{$t('signin.join')}}
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h4 text-left">{{$t('signin.join')}}</v-card-title>
                <v-divider />
                <v-card-text>
                  <v-form
                    ref="formSignup"
                    v-model="valid.signup"
                  >
                    <v-container
                      class="signupContainer"
                    >
                      <v-row>
                        <v-col cols="12">
                          <v-simple-table class="elevation-0">
                            <template v-slot:default>
                              <tbody>
                              <tr>
                                <th width="110px">
                                  ID<span class="red--text"><strong>*</strong></span>
                                </th>
                                <td>
                                  <v-text-field
                                    ref="id"
                                    v-model="newUser.id"
                                    :rules="[
                                      !!newUser.id || $t('signin.requiredFieldMsg'),
                                      validedId.checked || validedId.checkMessage,
                                    ]"
                                    @change="onChangeRegisterId"
                                    required
                                    outlined
                                    hide-details="auto"
                                  ></v-text-field>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  {{$t('signin.name')}}<span class="red--text"><strong>*</strong></span>
                                </th>
                                <td>
                                  <v-text-field
                                    ref="name"
                                    v-model="newUser.name"
                                    required
                                    :rules="textRules"
                                    outlined
                                    hide-details="auto"
                                  ></v-text-field>
                                </td>
                              </tr>
                              <tr
                                v-if="hasCustomField('date_of_birth')"
                              >
                                <th>
                                  {{$t('signin.dateOfBirth')}}
                                  <span
                                    v-if="isRequiredCustomField('date_of_birth')"
                                    class="red--text"
                                  >
                                    <strong>*</strong>
                                  </span>
                                </th>
                                <td>
                                  <v-dialog
                                    ref="dialogDateOfBirth"
                                    v-model="dialog.dateOfBirth"
                                    :return-value.sync="dateOfBirth"
                                    persistent
                                    width="290px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-text-field
                                        v-model="dateOfBirth"
                                        prepend-icon="mdi-calendar"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                        style="max-width: 150px"
                                        hide-details="auto"
                                        dense
                                        :rules="dateOfBirthRules"
                                      />
                                    </template>
                                    <v-date-picker
                                      v-model="dateOfBirth"
                                      scrollable
                                    >
                                      <v-spacer></v-spacer>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="dialog.dateOfBirth = false"
                                      >
                                        {{$t('signin.cancel')}}
                                      </v-btn>
                                      <v-btn
                                        text
                                        color="primary"
                                        @click="$refs.dialogDateOfBirth.save(dateOfBirth)"
                                      >
                                        {{$t('signin.confirm')}}
                                      </v-btn>
                                    </v-date-picker>
                                  </v-dialog>
                                  <!--<v-text-field-->
                                  <!--  ref="name"-->
                                  <!--  v-model="newUser.name"-->
                                  <!--  required-->
                                  <!--  :rules="textRules"-->
                                  <!--  outlined-->
                                  <!--  hide-details="auto"-->
                                  <!--&gt;</v-text-field>-->
                                </td>
                              </tr>
                              <tr>
                                <th>{{$t('signin.phoneNumber')}}</th>
                                <td>
                                  <v-row
                                    v-for="(phone, index) in newUser.phones"
                                    :key="index"
                                    class="mt-0"
                                  >
                                    <v-col
                                      cols="3"
                                    >
                                      {{ phone.name }}
                                    </v-col>
                                    <v-col
                                      cols="7"
                                    >
                                      {{ phone.phoneNumber }}
                                    </v-col>
                                    <v-spacer />
                                    <v-col
                                      cols="auto"
                                    >
                                      <v-icon
                                        @click="deletePhoneNumber(index)"
                                      >
                                        mdi-delete
                                      </v-icon>
                                    </v-col>
                                  </v-row>
                                  <v-row
                                    class="mt-0 mb-2"
                                    align="center"
                                  >
                                    <v-col
                                      cols="auto"
                                    >
                                      <v-text-field
                                        v-model="newPhone.name"
                                        :label="$t('signin.label')"
                                        dense
                                        hide-details
                                        style="max-width: 100px"
                                      />
                                    </v-col>
                                    <v-col
                                      cols="auto"
                                    >
                                      <v-text-field
                                        v-model="newPhone.phoneNumber"
                                        :label="$t('signin.phoneNumber')"
                                        dense
                                        hide-details
                                        @keyup="onChangeNewPhoneNumber"
                                      />
                                    </v-col>
                                    <v-spacer />
                                    <v-col
                                      cols="auto"
                                    >
                                      <v-btn
                                        x-small
                                        @click="addNewPhoneNumber"
                                      >
                                        {{$t('signin.add')}}
                                      </v-btn>
                                    </v-col>
                                  </v-row>
                                  <!--<v-list>-->
                                  <!--  <v-list-item-->
                                  <!--    v-for="(phone, index) in newUser.phones"-->
                                  <!--    :key="index"-->
                                  <!--  >-->
                                  <!--    <v-list-item-content>-->
                                  <!--      <v-list-item-title>{{ phone.name }}</v-list-item-title>-->
                                  <!--      <v-list-item-subtitle>{{ phone.phoneNumber }}</v-list-item-subtitle>-->
                                  <!--    </v-list-item-content>-->
                                  <!--    <v-list-item-icon>-->
                                  <!--      <v-icon>-->
                                  <!--        mdi-delete-->
                                  <!--      </v-icon>-->
                                  <!--    </v-list-item-icon>-->
                                  <!--  </v-list-item>-->
                                  <!--</v-list>-->
                                  <!--<v-combobox-->
                                  <!--  multiple-->
                                  <!--  v-model="newUser.phones"-->
                                  <!--  type="number"-->
                                  <!--  append-icon-->
                                  <!--  chips-->
                                  <!--  deletable-chips-->
                                  <!--  class="tag-input"-->
                                  <!--  dense-->
                                  <!--  hide-details="auto"-->
                                  <!--&gt;-->
                                  <!--</v-combobox>-->
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  {{$t('signin.password')}}<span class="red--text"><strong>*</strong></span>
                                </th>
                                <td>
                                  <v-text-field
                                    ref="password"
                                    :rules="[
                                      () => !!newUser.password || $t('signin.requiredFieldMsg'),
                                    ]"
                                    type="password"
                                    v-model="newUser.password"
                                    required
                                    outlined
                                    hide-details="auto"
                                  ></v-text-field>
                                </td>
                              </tr>
                              <tr>
                                <th>
                                  {{$t('signin.password')}} {{$t('signin.check')}}<span class="red--text"><strong>*</strong></span>
                                </th>
                                <td>
                                  <v-text-field
                                    ref="passwordCheck"
                                    type="password"
                                    v-model="newUser.passwordCheck"
                                    :rules="[
                                      (v) => !!newUser.passwordCheck || $t('signin.requiredFieldMsg'),
                                      newUser.password === newUser.passwordCheck || $t('signin.notMatchPasswordMsg'),
                                    ]"
                                    required
                                    outlined
                                    hide-details="auto"
                                  ></v-text-field>
                                </td>
                              </tr>
                              <tr>
                                <th>{{$t('signin.email')}}</th>
                                <td>
                                  <v-text-field
                                    v-model="newUser.email"
                                    required
                                    :rules="emailRules"
                                    outlined
                                    hide-details="auto"
                                  ></v-text-field>
                                </td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                          <v-alert
                            :value="alert.showRegistError"
                            color="warning"
                            outlined
                            transition="scale-transition"
                          >
                            {{ alert.registErrorMessage }}
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-form>
                </v-card-text>
                <v-card-actions>
                  <v-spacer />
                  <v-btn
                    outlined
                    class="primary white--text"
                    @click="onClickCloseButton"
                  >
                    {{$t('signin.close')}}
                  </v-btn>
                  <v-btn
                    outlined
                    text
                    :disabled="!valid.signup"
                    @click="onClickRegisterButton"
                  >
                    {{$t('signin.register')}}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <!-- 회원 가입 영역 끝 -->
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style scoped>
.signupContainer >>> .v-text-field__details {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  min-height: 14px !important;
}

.signupContainer >>> .theme--light.v-label {
  color: rgba(0, 0, 0, 0.3);
  font-weight: 400;
}

table > tbody > tr > th {
  background-color: rgb(245, 245, 245);
  margin-top: 4px;
  margin-bottom: 4px;
}

body {
  margin: 0;
}
</style>

<script>
//import Localization from '../widget/AppLocalization.vue';
import request from '@/utils/request';
import { userAdmin, userEditor } from '@/api/mock';
import { mapActions,mapGetters } from 'vuex';
// import Roles from '@/locale/ko_KR/roles';

const DEFAULT_VALID_ID = {
  checked: false,
  checkMessage: 'signin.requiredInfo',
};

const DEFAULT_NEW_USER = {
  id: null,
  name: null,
  enabled: false,
  // roles: [
  //   {
  //     subject: Roles.common,
  //   },
  // ],
  dateOfBirth: null,
  password: null,
  email: null,
  phones: [],
};

const DEFAULT_NEW_PHONE = {
  name: '',
  phoneNumber: '',
};

const requiredFieldMsg = "signin.requiredFieldMsg";
const digitMsg = "signin.32digitMsg";
const emailNotValidMsg = "signin.emailNotValidMsg";

export default {
  name: 'SignIn',
  //components: { Localization },
  components: {
    // DashboardCoreAppBarFront: () => import("../layout/TempHeaderLogin"),
    // DashboardCoreFooter: () => import("../layout/TempFooter"),
  },
  computed: {
    ...mapGetters(['currentLanguage']),
    // validedForm: function () {
    //   return (
    //     this.newUser.password != null &&
    //     this.newUser.password === this.newUser.passwordCheck &&
    //     this.validedId.checked
    //   );
    // },
    form() {
      return {
        passwordCheck: this.newUser.passwordCheck,
        password: this.newUser.password,
        name: this.newUser.name,
        id: this.newUser.id,
      };
    },
    dateOfBirthRules() {
      var requiredFieldMsg = this.$t('signin.email');
      return !this.isRequiredCustomField('date_of_birth') ? [] : [
        (v) => !!v || `${requiredFieldMsg}`,
      ];
    }
  },
  data: function() {
    return {
      alert: {
        showRegistError: false,
        registErrorMessage: null,
      },
      customFields: {},
      valid: {
        signup: false,
      },
      hidePassword: true,
      error: false,
      showResult: false,
      result: '',
      loading: false,
      dateOfBirth: null, // 화면에 보여지는 생년월일
      model: {
        email: '',
        password: '',
      },
      dialog: {
        register: false,
        dateOfBirth: false,
      },
      validedId: {...this.$translate(DEFAULT_VALID_ID)},
      newUser: {...DEFAULT_NEW_USER},
      newPhone: {...DEFAULT_NEW_PHONE},
      textRules: [
        (v) => !!v || this.$translate(requiredFieldMsg),
        (v) => !!v && v.length <= 32 || this.$translate(digitMsg),
      ],
      emailRules: [
        (v) => !!v || this.$translate(requiredFieldMsg),
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$translate(emailNotValidMsg),
      ],
    };
  },
  async mounted() {
    await this.fetchCustomFieldInfo();
  },
  methods: {
    ...mapActions(['addAlert']),
    // 추가 필드에 대한 정보를 읽어들인다.
    async fetchCustomFieldInfo() {
      const { data } = await request({
        url: '/apix/codes/USER_REGIST_FORM',
        metod: 'get',
      });
      data.forEach((code) => {
        this.customFields[code.code] = {
          use: code.useYn === 'Y',
          display: code.displayYn === 'Y',
          required: code.requiredYn === 'Y',
        };
      });
    },
    async login() {
      var checkIdPasswordMsg = this.$t('signin.checkIdPasswordMsg');
      var loginErrorMsg = this.$t('signin.loginErrorMsg');
      var userIdCheckMsg = this.$t('signin.userIdCheckMsg');
      var idLockMsg = this.$t('signin.idLockMsg');
      try {
        if (!this.model.email || !this.model.password) {
          this.result = `${checkIdPasswordMsg}`;
          this.showResult = true;
        } else {
          await this.$store.dispatch('LoginByEmail', {
            email: this.model.email,
            password: this.model.password,
          });
          await this.$router.push(this.$route.query.redirect || '/apis');
        }
      } catch (error) {
        // console.log('sign-in', error, error.response);
        this.result = `${loginErrorMsg}`;

        if (error.response && error.response.data) {
          const data = error.response.data;
          // 비밀번호 오류인 경우
          if (data.detail && data.detail === 'Bad credentials') {
            this.result = `${userIdCheckMsg}`;
          } else if (data.errorKey && data.errorKey === 'passwordFailLimitCountOver') {
            this.result = `${idLockMsg}`;
          }
        }
        this.error = true;
        this.showResult = true;
        setTimeout(() => {
          this.showResult = false;
        }, 5000);
      }
    },
    setUserToLogin(id) {
      if (id) {
        this.model.email = userAdmin.email;
        this.model.password = userAdmin.password;
      } else {
        this.model.email = userEditor.email;
        this.model.password = userEditor.password;
      }
    },
    // 사용자 ID 필드 변경 시 ID 존재여부 체크
    async onChangeRegisterId() {
      var alreadyUseIdMsg = this.$t('signin.alreadyUseIdMsg');
      const { data } = await request({
        url: `/apix/register/${this.newUser.id}/check`,
        method: 'get',
      });
      console.log('result data ', data);

      this.validedId.checked = data;
      if (!this.validedId.checked) {
        this.validedId.checkMessage = `${alreadyUseIdMsg}`;
      }
    },
    phoneFomatter(num) {
      let formatNum = '';
      if (num.length === 11) {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      } else if (num.length === 8) {
        formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
      } else {
        if (num.indexOf('02') === 0) {
          formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
        } else {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
      }
      return formatNum;
    },
    // 등록 버튼을 눌렀을 때
    async onClickRegisterButton() {
      var registerdMemberSuccessMsg = this.$t('signin.registerdMemberSuccessMsg');
      var adminApprovalMsg = this.$t('signin.adminApprovalMsg');
      var enterRequiredValueMsg = this.$t('signin.enterRequiredValueMsg');
      if (this.valid.signup) {
        if (this.dateOfBirth) this.newUser.dateOfBirth = this.dateOfBirth.replace(/-/gi, '');
        try {
          const { data } = await request({
            url: '/apix/register/user',
            method: 'post',
            data: this.newUser,
          });
          console.log('save checkId', data);
          if (await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;">
                        <img max-width="100%" src="static/inzent/ico_complete.svg" /><br/>
                        <b>${registerdMemberSuccessMsg}</br>
                        ${adminApprovalMsg}</b>
                      </div>`,
          })) {
            this.onClickCloseButton();
          }
        } catch (error) {
          // console.log('[sign-in] register error', error, error.response);
          const { status, data } = error.response;
          if (status === 400) {
            if (Object.prototype.hasOwnProperty.call(data, 'fieldErrors')) {
              // noinspection JSUnresolvedVariable
              this.alert.registErrorMessage = data.fieldErrors[0].message;
            } else {
              this.alert.registErrorMessage = data.title;
            }
            this.alert.showRegistError = true;
            setTimeout(() => {
              this.alert.showRegistError = false;
            }, 5000);
          }
        }
      } else {
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%" src="static/inzent/ico_fail.svg" /><br/><b>${enterRequiredValueMsg}</b></div>`,
        });
      }
    },
    onClickCloseButton() {
      DEFAULT_NEW_USER.phones = [];
      this.dateOfBirth = null;
      this.newUser = { ...DEFAULT_NEW_USER };
      this.newPhone = { ...DEFAULT_NEW_PHONE };
      this.validedId = { ...DEFAULT_VALID_ID };
      this.dialog.register = false;
    },
    addNewPhoneNumber() {
      if (this.newPhone.name.length > 0 && this.newPhone.phoneNumber > 0) {
        this.newUser.phones.push({
          name: this.newPhone.name,
          phoneNumber: this.phoneFomatter(this.newPhone.phoneNumber),
        });
        this.newPhone = { ...DEFAULT_NEW_PHONE };
      }
    },
    deletePhoneNumber(index) {
      if (index >= 0) this.newUser.phones.splice(index, 1);
    },
    hasCustomField(name) {
      return this.customFields[name] && this.customFields[name].use && this.customFields[name].display;
    },
    isRequiredCustomField(name) {
      return this.hasCustomField(name) && this.customFields[name].required;
    },
    // 전화번호 입력 필드가 변경된 경우
    onChangeNewPhoneNumber() {
      this.newPhone.phoneNumber = this.newPhone.phoneNumber.replace(/[^\d]/g, '');
    }
  },
  watch: {
    currentLanguage(){
      this.validedId =  {...this.$translate(DEFAULT_VALID_ID)};
    },
    'dialog.register': function (value) {
      if (value) this.$nextTick(() => this.$refs.formSignup.validate());
    }
  }
};
</script>
<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}
</style>
