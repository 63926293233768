import request from '@/utils/request';

const accountService = {
  async reIssueToken(tokenInfo) {
    const { data } = await request.post('/apix/re-issue', tokenInfo);
    return data;
  }
};

export default accountService;
