<template>
  <div>
    <v-data-table
      :headers="computedHeaders"
      :items="parameters"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click.stop="clickModifyButton(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template
        v-if="editMode"
        v-slot:body.append="{ headers }"
      >
        <tr>
          <td
            :colspan="headers.length"
            class="text-right"
            style="border: none"
          >
            <v-btn
              color="primary"
              small
              @click="clickAddButton"
            >
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog
      v-if="dialog.detail"
      v-model="dialog.detail"
      max-width="600px"
    >
      <v-card>
        <v-card-title
          class="text-left"
        >
          <span class="text-h3">
            {{ $t('apiMethod.parameter') }} {{ dialog.detailMode === 'add' ? $t('apiMethod.add') : $t('apiMethod.modify') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                class="py-0"
                cols="6"
              >
                <v-text-field
                  v-model="editParameter.name"
                  :label="$t('apiMethod.name')"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="6"
              >
                <v-select
                  v-model="editParameter.in"
                  :items="selectors.in"
                  :label="$t('apiMethod.transfer') + ' ' + $t('apiMethod.method')"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
              >
                <v-text-field
                  v-model="editParameter.description"
                  :label="$t('apiMethod.description')"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="4"
              >
                <v-select
                  v-model="editParameter.required"
                  :items="selectors.required"
                  :label="$t('apiMethod.required') + ' ' + $t('apiMethod.status')"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="4"
              >
                <v-select
                  v-model="editParameter.schema.type"
                  :items="selectors.type"
                  :label="$t('apiMethod.type')"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="4"
              >
                <v-select
                  v-model="editParameter.allowEmptyValue"
                  :items="selectors.allowEmptyValue"
                  :label="$t('apiMethod.allowEmptyValue')"
                />
              </v-col>
              <v-col
                class="py-0"
                cols="12"
              >
                <v-text-field
                  v-model="editParameter.schema.example"
                  :label="$t('apiMethod.example')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="grey"
            @click="dialog.detail = false"
          >
            {{ $t('apiMethod.cancel') }}
          </v-btn>
          <v-btn
            @click="save"
          >
            {{ dialog.detailMode === 'add' ? $t('apiMethod.add') : $t('apiMethod.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

const header = [
  {
    text: 'apiMethod.name',
    value: 'name',
  },
  {
    text: 'apiMethod.transferWay',
    value: 'in',
  },
  {
    text: 'apiMethod.description',
    value: 'description',
  },
  {
    text: 'apiMethod.requiredStatus',
    value: 'required',
  },
  {
    text: 'apiMethod.type',
    value: 'schema.type',
  },
  {
    text: 'apiMethod.sample',
    value: 'schema.example',
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
  }
];

const selectorsIn = [
  {
    text: 'apiMethod.queryString',
    value: 'query',
  },
  {
    text: 'apiMethod.pathVariable',
    value: 'path',
  },
  {
    text: 'apiMethod.header',
    value: 'header',
  },
];

const selectorsRequired = [
  {
    text: 'apiMethod.required',
    value: true,
  },
  {
    text: 'apiMethod.notRequired',
    value: false,
  },
]

export default {
  name: 'ApiMethodParameterView',
  props: {
    parameters: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    editMode: {
      type: Boolean,
      default: true,
    }
  },
  data: function () {
    return {
      headers: this.$translate(header),
      selectors: {
        in: this.$translate(selectorsIn),
        required: this.$translate(selectorsRequired),
        type: [
          {
            text: 'string',
            value: 'string',
          },
          {
            text: 'integer',
            value: 'integer',
          },
          {
            text: 'boolean',
            value: 'boolean',
          },
          {
            text: 'number',
            value: 'number',
          },
        ],
        allowEmptyValue: [
          {
            text: this.$t('common.global.yes'),
            value: true,
          },
          {
            text: this.$t('common.global.no'),
            value: false,
          },
        ],
      },
      // parameters: [],
      editParameter: {},
      selected: {
        parameter: null,
      },
      dialog: {
        detail: false,
        detailMode: null,
      }
    };
  },
  computed: {
    ...mapGetters(['currentLanguage']),
    computedHeaders() {
      return this.editMode ? this.headers : this.headers.filter((h) => h.value !== 'actions');
    }
  },
  methods: {
    clickAddButton() {
      this.dialog.detailMode = 'add';
      this.editParameter = {
        in: 'query',
        required: false,
        schema: {
          type: 'string',
        },
        allowEmptyValue: true,
      };
      this.dialog.detail = true;
    },
    clickModifyButton(parameter) {
      this.dialog.detailMode = 'modify';
      this.selected.parameter = parameter; // 취소를 대비해 원본을 저장해두는 역할
      this.editParameter = JSON.parse(JSON.stringify(parameter)); // 원본을 복사한 후 수정한다.
      this.dialog.detail = true;
    },
    deleteItem(item) {
      const index = this.parameters.indexOf(item);
      if (index >= 0) this.parameters.splice(index, 1);
    },
    save() {
      if (this.dialog.detailMode === 'add') {
        this.parameters.push(this.editParameter);
      } else if (this.dialog.detailMode === 'modify') {
        const index = this.parameters.indexOf(this.selected.parameter);
        this.parameters.splice(index, 1, this.editParameter);
      }
      this.dialog.detail = false;
    }
  },
  watch: {
    currentLanguage() {
      this.headers = this.$translate(header);
      this.selectors.in = this.$translate(selectorsIn);
      this.selectors.required = this.$translate(selectorsRequired);
    }
  }
}
</script>

<style scoped>

</style>
