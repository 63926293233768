<template>
  <v-form
    v-model="valid"
  >
    <v-container>
      <v-row>
        <v-col
          cols="6"
          class="pb-0 pt-5"
        >
          <v-text-field
            v-model="method.operationId"
            :label="$t('apiMethod.operation')+' '+ $t('apiMethod.id')"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <v-row
        class="mt-0"
      >
        <v-col
          cols="12"
          class="py-0"
        >
          <v-text-field
            v-model="method.summary"
            :label="$t('apiMethod.summary')"
            outlined
            dense
          />
        </v-col>
      </v-row>
      <!-- 태그 -->
      <v-row
        class="mt-0"
      >
        <v-col
          cols="12"
          class="py-0"
        >
          <v-select
            v-model="method.tags"
            :items="tags"
            item-text="name"
            item-value="name"
            :label="$t('apiMethod.tag')"
            outlined
            dense
            multiple
            clearable
          >
            <template #selection="{ item }">
              <v-chip
                color="green lighten-4"
                small
                class="mr-1"
              >
                {{ item.name }}
              </v-chip>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <!-- 인증 -->
      <v-row
        class="mt-0"
      >
        <v-col
          cols="5"
          class="py-0"
        >
          <v-select
            v-model="method.securities"
            :items="securityItems"
            :label="$t('apiMethod.certified')"
            outlined
            dense
            multiple
            clearable
          >
            <template #selection="{ item }">
              <v-chip
                color="blue lighten-4"
                small
              >
                {{ item.text }}
              </v-chip>
            </template>
          </v-select>
        </v-col>
        <v-col
          v-if="isScopeEnable && this.scopes.length > 0"
          cols="7"
          class="py-0"
        >
          <v-select
            v-model="method.scopes"
            :items="scopeSelectorItems"
            label="Scope"
            multiple
            outlined
            dense
          />
        </v-col>
      </v-row>
      <!-- 파라미터, 요청데이터, 응답 -->
      <v-row>
        <v-col
          cols="12"
        >
          <v-card
            elevation="2"
          >
            <v-tabs
              vertical
              background-color="grey"
              class="pl-0 pt-0"
            >
              <v-tabs-slider color="primary"/>
              <v-tab
                class="rounded-0"
              >
                {{$t('apiMethod.parameter')}}
              </v-tab>
              <v-tab
                v-if="isUsingRequestBody"
                class="rounded-0"
              >
                {{$t('apiMethod.request')}} {{$t('apiMethod.data')}}
              </v-tab>
              <v-tab
                class="rounded-0"
              >
                {{$t('apiMethod.response')}}
              </v-tab>

              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <api-method-parameter-view
                      :parameters="method.parameters"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item
                v-if="isUsingRequestBody"
              >
                <v-card flat>
                  <v-card-text>
                    <api-method-request-body-view
                      :request-body="method.requestBody"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card flat>
                  <v-card-text>
                    <api-method-response-view
                      :responses="method.responses"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<style scoped>

</style>

<script>
import ApiMethodParameterView from '@/components/api/ApiMethodParameterView';
import ApiMethodRequestBodyView from '@/components/api/ApiMethodRequestBodyView';
import ApiMethodResponseView from '@/components/api/ApiMethodResponseView';
import { mapGetters } from 'vuex';

export default {
  name: 'ApiMethodDetailView',
  props: {
    httpMethod: {
      type: String,
      required: true,
      default: 'get',
    },
    httpUri: {
      type: String,
      required: true,
    },
    method: {
      type: Object,
    }
  },
  components: { ApiMethodResponseView, ApiMethodRequestBodyView, ApiMethodParameterView },
  data: () => ({
    valid: true,
  }),
  computed: {
    ...mapGetters({
      tags: 'apiTags',
      scopes: 'apiScopes',
      securities: 'apiSecurities',
    }),
    isUsingRequestBody() {
      return ['post', 'put'].includes(this.httpMethod);
    },
    // scope 사용 가능 여부 - 인증이 OAUTH2방식일 때
    isScopeEnable() {
      return !!this.method.securities.find(key => {
        return this.securities[key] && this.securities[key].type === 'OAUTH2';
      });
    },
    securityItems() {
      return Object.entries(this.securities).map(([key, value]) => ({
        text: `${key} (${value.type})`,
        value: `${key}`,
      }));
    },
    scopeSelectorItems() {
      return this.scopes.map((s) => ({
        text: `${s.name} (${s.scope})`,
        value: `${s.scope}`,
      }));
    },
  },
  async mounted() {
    if (!this.method.operationId) {
      const pathString = this.httpUri.replace(/[{}]/g, '').replace(/\//g, '-');
      this.method.operationId = `${this.httpMethod}${pathString}`;
    }
    if (!this.method.summary) {
      this.method.summary = this.method.operationId;
    }
  },
  methods: {}
}
</script>
