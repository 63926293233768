<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-card-text>
            <v-container>
              <v-row
                align="center"
              >
                <v-col>
                  <v-row>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="resource.uri"
                        :label="$t('apiResource.gateway') + ' '+ $t('apiResource.uri')"
                        outlined
                        hide-details
                        dense
                        :readonly="!editMode"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                    >
                      <v-text-field
                        v-model="resource.targetVersion.targetUrl"
                        :label="$t('apiResource.routing') + ' '+ $t('apiResource.url')"
                        outlined
                        hide-details
                        dense
                        :readonly="!editMode"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  v-if="editable && $hasAnyAuthority(['API_MODIFY', 'ADMIN'])"
                  cols="auto"
                >
                  <v-icon
                    @click="clickModifyEndpoint"
                  >
                    mdi-pencil
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- 메소드 탭 -->
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-tabs
            v-model="tab.method"
            align-with-title
            background-color="indigo lighten-2"
            dark
            class="pl-0 pt-0"
          >
            <v-tabs-slider color="yellow"/>
            <v-tab
              v-for="(method, index) in orderedMethods"
              :key="index"
              :disabled="Number.isInteger(tab.editIndex)"
            >
              <span>
                {{ method.httpMethod }}
              </span>
              <v-icon
                v-if="editable && index === tab.method && !Number.isInteger(tab.editIndex) && $hasAnyAuthority(['API_MODIFY', 'ADMIN'])"
                class="ml-3"
                @click.stop="clickEditButton"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                v-if="editable && index === tab.method && !Number.isInteger(tab.editIndex) && $hasAnyAuthority(['API_DELETE', 'ADMIN'])"
                @click.stop="deleteMethod(method)"
              >
                mdi-delete
              </v-icon>
            </v-tab>
            <v-spacer/>
            <v-icon
              v-if="$hasAnyAuthority(['API_CREATE', 'ADMIN'])"
              class="mr-3"
              @click.stop="clickAddMethod"
            >
              mdi-plus
            </v-icon>
          </v-tabs>
          <v-tabs-items
            v-model="tab.method"
          >
            <v-tab-item
              v-for="(method, index) in orderedMethods"
              :key="index"
            >
              <api-method-detail-view2
                :method-id="method.id"
                :original-method="method"
                :edit-mode="tab.method === tab.editIndex"
                enable-save
                @cancel="eventCancel"
                @saved="eventSaved"
              />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-col>
    </v-row>

    <!--  신규 메소드 추가 시 http method 선택 창 -->
    <v-dialog
      v-if="dialog.methodSelect"
      v-model="dialog.methodSelect"
      max-width="400px"
    >
      <v-card>
        <v-card-text
          class="py-3"
        >
          <v-container>
            <v-row
              justify="center"
              align="center"
            >
              <v-col
                cols="auto"
              >
                <v-select
                  v-model="newHttpMethod"
                  :items="creatableHttpMethods"
                  :label="$t('apiResource.method')"
                  style="max-width: 120px"
                  hide-details
                  dense
                  outlined
                />
              </v-col>
              <v-col
                cols="auto"
              >
                <v-btn
                  :disabled="!newHttpMethod"
                  @click="addNewMethod"
                >
                  {{ $t('apiResource.add') }}
                </v-btn>
              </v-col>
              <v-col
                cols="auto"
              >
                <v-btn
                  outlined
                  @click="dialog.methodSelect = false"
                >
                  {{ $t('apiResource.cancel') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!--  리소스 endpoint 수정 다이얼로그 -->
    <v-dialog
      v-if="dialog.endpoint"
      v-model="dialog.endpoint"
      max-width="600px"
    >
      <v-card>
        <v-card-title
          class="text-left"
        >
          <span class="text-h3">
             {{ $t('apiResource.endpoint') }}  {{ $t('apiResource.modify') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model="modify.endpoint.uri"
                  :label="$t('apiResource.gateway') + ' '+ $t('apiResource.uri')"
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model="modify.endpoint.targetUrl"
                  :label="$t('apiResource.routing') + ' '+ $t('apiResource.url')"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="grey"
            outlined
            @click="dialog.endpoint = false"
          >
            {{ $t('apiResource.cancel') }}
          </v-btn>
          <v-btn
            color="blue lighten-1"
            @click="saveResourceEndpoint"
          >
            {{ $t('apiResource.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import ApiMethodDetailView2 from '@/components/api/ApiMethodDetailView2';
import { SUPPORT_HTTP_METHOD } from '@/config/constants';
import apiService from '@/api/ApiService';

export default {
  name: 'ApiResourceDetailView',
  components: { ApiMethodDetailView2 },
  props: {
    resource: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    editable: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    dialog: {
      methodSelect: false,
      endpoint: false,
    },
    tab: {
      method: null,
      editIndex: null,
    },
    methods: [],
    newHttpMethod: null, // 신규 추가할 메소드
    modify: {
      endpoint: {
        uri: null,
        targetUrl: null,
      },
    },
  }),
  computed: {
    orderedMethods() {
      return SUPPORT_HTTP_METHOD.map(hm => this.methods.find(m => m.httpMethod === hm)).filter(m => !!m);
    },
    creatableHttpMethods() {
      return SUPPORT_HTTP_METHOD.filter(hm => !this.methods.some(m => m.httpMethod === hm));
    }
  },
  mounted() {
    this.fetchMethods();
  },
  methods: {
    // 하위에서 saved event가 발생한 경우
    eventSaved() {
      this.tab.editIndex = null; // 탭 이동할 수 있도록 함
      this.fetchMethods();
      this.$emit('refresh');
    },
    // 하위에서 cancel event가 발생한 경우
    eventCancel(methodId, method) {
      if (methodId === 0) {
        // 신규 추가인 경우
        const index = this.methods.indexOf(method);
        if (index >= 0) this.methods.splice(index, 1);
      }
      this.tab.editIndex = null;
    },
    async refresh() {
      await this.fetchMethods();
      this.$emit('refresh', this.resource.id);
    },
    async fetchMethods() {
      this.methods = await apiService.resourceMathods(this.resource.id);
    },
    clickAddMethod() {
      this.dialog.methodSelect = true;
    },
    addNewMethod() {
      let pathString = this.resource.uri.replace(/[{}]/g, '').replace(/\//g, '-');
      pathString = `${this.newHttpMethod}${pathString}`;
      const method = {
        id: 0,
        httpMethod: this.newHttpMethod,
        operationId: pathString,
        summary: pathString,
        targetVersion: this.resource.targetVersion,
      };
      this.methods.push(method);
      this.tab.method = this.orderedMethods.indexOf(method); // 해당 탭이 선택되도록 한다.
      this.tab.editIndex = this.tab.method; // 수정 중인 탭을 지정한다.
      this.newHttpMethod = null;
      this.dialog.methodSelect = false;
    },
    // 수정 버튼이 클릭된 경우
    clickEditButton() {
      this.tab.editIndex = this.tab.method;
    },
    // 메소드 삭제
    async deleteMethod(method) {
      var deleteMethodMsg = this.$t('apiResource.deleteMethodMsg');
      const res = await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;">
                    <img max-width="100%" src="static/inzent/ico_warning.svg" /><br/>
                    <b>${method.summary} ${deleteMethodMsg}</b>
                  </div>`,
        confirmYn: true,
      },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
      if (!res) return;
      await apiService.methodDelete(method.id);
      const index = this.methods.indexOf(method);
      if (index >= 0) {
        this.methods.splice(index, 1);
      }
      this.$emit('refresh');
    },
    // endpoint 수정 버튼 클릭 시
    clickModifyEndpoint() {
      this.modify.endpoint = {
        uri: this.resource.uri,
        targetUrl: this.resource.targetVersion.targetUrl,
      };
      this.dialog.endpoint = true;
    },
    async saveResourceEndpoint() {
      var gatewayReflectionMsg = this.$t('apiResource.gatewayReflectionMsg');
      var checkMsg = this.$t('apiResource.checkMsg');
      if (await this.$confirm({
        message: `${gatewayReflectionMsg}<br/>${checkMsg}`,
        confirmYn: true,
      },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')})) {
        await apiService.updateResourceEndpoint(this.resource.id, this.modify.endpoint);
        this.resource.uri = this.modify.endpoint.uri;
        this.resource.targetVersion.targetUrl = this.modify.endpoint.targetUrl;
        this.dialog.endpoint = false;
        this.$emit('refresh');
      }
    }
  },
}
</script>

<style scoped>

</style>
