export const SELECTOR_SCHEMA_TYPE = [
  {
    text: 'string',
    value: 'string',
  },
  {
    text: 'integer',
    value: 'integer',
  },
  {
    text: 'boolean',
    value: 'boolean',
  },
  {
    text: 'number',
    value: 'number',
  },
  {
    text: 'object',
    value: 'object',
  },
];

export const SELECTOR_METHOD_STATUS = [
  {
    text: 'api.all',
    value: '',
  },
  {
    text: 'api.develop',
    value: 'develop',
  },
  {
    text: 'api.service',
    value: 'service',
  },
  {
    text: 'api.deprecate',
    value: 'deprecate',
  },
  {
    text: 'api.outOfService',
    value: 'out_of_service',
  },
];

export const SELECTOR_SDK_TYPES = [
  { text: 'Html', value: 'html2' },
  { text: 'Java', value: 'java' },
  { text: 'C#', value: 'csharp' },
  { text: 'GO', value: 'go' },
  { text: 'JavaCXF', value: 'jaxrs-cxf-client' },
  { text: 'Kotlin', value: 'kotlin-client' },
  { text: 'PHP', value: 'php' },
  { text: 'Python', value: 'python' },
  { text: 'Scala', value: 'scala' },
  { text: 'Swift#3', value: 'swift3' },
  { text: 'Swift#4', value: 'swift4' },
  { text: 'Swift#5', value: 'swift5' },
  { text: 'Angular', value: 'typescript-angular' },
  { text: 'Fetch', value: 'typescript-fetch' },
];

export const SUPPORT_HTTP_METHOD = [
  'GET',
  'POST',
  'PUT',
  'DELETE',
  'PATCH'
];

export const DEFAULT_CONTENT_TYPES = [
  '*/*',
  'application/json',
  'application/xml',
];

export const TOTAL_COUNT_HEADER_NAME = 'x-total-count';

export function i18n_translate(list) {
  if (Array.isArray(list)) {
    return list.map(l => {
      if (Object.prototype.hasOwnProperty.call(l, 'text')) {
        return { ...l, text: this.$i18n.t(l.text) }
      } else {
        return l;
      }
    });
  }
}
