export default Object.freeze({
  forumCreate: 'FORUM_CREATE',
  forumRead: 'FORUM_READ',
  forumUpdate: 'FORUM_UPDATE',
  forumDelete: 'FORUM_DELETE',
  userCreate: 'USER_CREATE',
  userRead: 'USER_READ',
  userUpdate: 'USER_UPDATE',
  userDelete: 'USER_DELETE',
  apiCreate: 'API_CREATE',
  apiRead: 'API_READ',
  apiUpdate: 'API_UPDATE',
  apiDelete: 'API_DELETE',
  appCreate: 'APP_CREATE',
  appRead: 'APP_READ',
  appUpdate: 'APP_UPDATE',
  appDelete: 'APP_DELETE',
  organizationCreate: 'ORGANIZATION_CREATE',
  organizationRead: 'ORGANIZATION_READ',
  organizationUpdate: 'ORGANIZATION_UPDATE',
  organizationDelete: 'ORGANIZATION_DELETE',
  scopeCreate: 'SCOPE_CREATE',
  scopeRead: 'SCOPE_READ',
  scopeUpdate: 'SCOPE_UPDATE',
  scopeDelete: 'SCOPE_DELETE',
  statisticsView: 'STATISTICS_VIEW',
  roleCreate: 'ROLE_CREATE',
  roleRead: 'ROLE_READ',
  roleUpdate: 'ROLE_UPDATE',
  roleDelete: 'ROLE_DELETE',
  policyCreate: 'POLICY_CREATE',
  policyRead: 'POLICY_READ',
  policyUpdate: 'POLICY_UPDATE',
  policyDelete: 'POLICY_DELETE',
  requestCreate: 'REQUEST_CREATE',
  requestRead: 'REQUEST_READ',
  requestUpdate: 'REQUEST_UPDATE',
  requestDelete: 'REQUEST_DELETE',
  blacklistCreate: 'BLACKLIST_CREATE',
  blacklistRead: 'BLACKLIST_READ',
  blacklistUpdate: 'BLACKLIST_UPDATE',
  blacklistDelete: 'BLACKLIST_DELETE',
  mydataRead: 'MYDATA_READ',
  mydataUpdate: 'MYDATA_UPDATE',
  mydataDelete: 'MYDATA_DELETE',
  mydataCreate: 'MYDATA_CREATE',
  gatewayRead: 'GATEWAY_READ',
  gatewayUpdate: 'GATEWAY_UPDATE',
  gatewayDelete: 'GATEWAY_DELETE',
  gatewayCreate: 'GATEWAY_CREATE',
  dashboardRead: 'DASHBOARD_READ',
  dashboardUpdate: 'DASHBOARD_UPDATE',
  dashboardDelete: 'DASHBOARD_DELETE',
  dashboardCreate: 'DASHBOARD_CREATE',
  stateRead: 'STATE_READ',
  statUpdate: 'STATE_UPDATE',
  statDelete: 'STATE_DELETE',
  statCreate: 'STATE_CREATE',
  monitoringView: 'MONITORING_VIEW',
});
