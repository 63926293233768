import { getUserInfo, loginByEmail } from '@/api/login';
import request from '@/utils/request';
import { AUTH_ACCESS_TOKEN_NAME, AUTH_REFRESH_TOKEN_NAME, AUTH_TOKEN, AUTH_TOKEN_EXPIRES_IN } from '@/constants';

const user = {
  state: {
    token: localStorage.getItem(AUTH_ACCESS_TOKEN_NAME) || sessionStorage.getItem(AUTH_ACCESS_TOKEN_NAME) || '',
    roles: [],
    user: '',
    name: '',
    id: '',
    authorities: [],
    avatar: '',
    status: '',
    code: '',
    introduction: '',
    setting: {
      articlePlatform: [],
    },
    needChangePassword: false,
    account: null, // 사용자 필드별 정보가 아닌 전체 정보
    readingAccount: false, // 사용자 정보를 가져오고 있는 중인지 여부 - 권한이 없음 화면이 보이는 걸 방지하기 위해
    lastRequestTime: null,
  },

  getters: {
    token: (state) => state.token,
    accessToken: (state) => state.token && state.token.accessToken,
    accessTokenExpiresIn: (state) => state.token && state.token.accessTokenExpiresIn,
    accessTokenTimeoutInSeconds: (state) => state.token && state.token.remainedTimeInSeconds, // 토큰 만료까지 남은 시간(가변)
    refreshToken: (state) => state.token && state.token.refreshToken,
    // 로컬 시간 - 서버의 시간과 다를 수 있음
    localTokenReceivedTime: (state) => state.token && state.token.localTokenReceivedTime,
    lastRequestTime: (state) => state.lastRequestTime,

    roles: (state) => state.roles,
    name: (state) => state.name,
    user: (state) => state.user,
    avatar: (state) => state.avatar,
    status: (state) => state.status,
    introduction: (state) => state.introduction,
    setting: (state) => state.setting,
    id: (state) => state.id,
    authorities: (state) => state.authorities,
    hasAuthority: (state) => (auth) => {
      if (typeof auth === 'string') auth = [auth];
      return state.authorities && auth.some(a => state.authorities.includes(a));
    },
    needChangePassword: (state) => state.needChangePassword,
    isAdmin: (state) => state.account && state.account.isAdmin && state.account.isAdmin === 'Y', // isAdmin 필드가 'Y'인 경우
    nowReadingAccount: (state) => state.readingAccount,
    monitoringEnabled: (state) => state.authorities.includes('MONITORING_VIEW') || state.authorities.includes('ADMIN'),
  },

  mutations: {
    SET_USER_INFO: (state, user) => {
      state.account = user;
      if (user.logout) {
        state.token = '';
        state.roles = [];
        state.user = '';
        state.name = '';
        state.id = '';
        state.authorities = [];
        state.needChangePassword = null;

        localStorage.removeItem(AUTH_TOKEN);
        localStorage.removeItem(AUTH_ACCESS_TOKEN_NAME);
        localStorage.removeItem(AUTH_TOKEN_EXPIRES_IN);
        localStorage.removeItem(AUTH_REFRESH_TOKEN_NAME);
      } else {
        state.roles = user.roles || state.roles;
        state.user = user.id || state.user;
        state.name = user.name || state.name;
        state.id = user.id || state.id;
        state.authorities = user.authorities || state.authorities;
        state.needChangePassword = user.needChangePassword;
      }
    },
    SET_TOKEN: (state, tokenInfo) => {
      if (!tokenInfo.localTokenReceivedTime) {
        tokenInfo.localTokenReceivedTime = Date.now(); // 서버의 시간과 다를 수 있으므로 local시간 기록해둔다.
      }

      state.token = tokenInfo;
      if (tokenInfo) {
        localStorage.setItem(AUTH_TOKEN, JSON.stringify(tokenInfo))
        if (tokenInfo.accessToken) localStorage.setItem(AUTH_ACCESS_TOKEN_NAME, tokenInfo.accessToken);
        if (tokenInfo.accessTokenExpiresIn) localStorage.setItem(AUTH_TOKEN_EXPIRES_IN, tokenInfo.accessTokenExpiresIn);
        if (tokenInfo.refreshToken) localStorage.setItem(AUTH_REFRESH_TOKEN_NAME, tokenInfo.refreshToken);
      }
    },
    START_READING_ACCOUNT: (state) => {
      state.readingAccount = true;
    },
    FINISH_READING_ACCOUNT: (state) => {
      state.readingAccount = false;
    },
    SET_LAST_REQUEST_TIME: (state, lastRequestTime) => {
      if (state.token) state.lastRequestTime = lastRequestTime;
    },
  },

  actions: {
    // request.js에서 ajax 호출 시 사용하기 위해 action을 만들어 둠. 지속적인 세션을 유지하기 위해서
    RefreshLocalToken: async ({ commit }) => {
      const jsonToken = localStorage.getItem(AUTH_TOKEN);
      if (jsonToken) {
        await commit('SET_TOKEN', JSON.parse(jsonToken));
      }
    },
    // Login user
    LoginByEmail: async ({ commit, getters, dispatch }, payload) => {
      try {
        const tokenInfo = await loginByEmail(payload.email.trim(), payload.password);
        console.log('[vuex.user] LoginByEmail', payload, tokenInfo);
        await commit('SET_TOKEN', tokenInfo);
        // await commit('SET_USER_INFO', response.user);

        // 로그인으로 token을 가져온 후 사용자 정보를 다시 가져오도록 함
        await dispatch('GetUserInfo');

        await dispatch('GenerateRoutes', getters);
      } catch (err) {
        console.warn('[vuex.user] LoginByEmail', err);
        throw err;
      }
    },

    GetUserInfo: async ({ commit, state, dispatch }) => {
      console.log('[vuex.user] GetUserInfo');
      try {
        await commit('START_READING_ACCOUNT');
        const user = await getUserInfo(state.token);

        // Since mockjs does not support custom status codes, it can only be hacked like this
        if (!user) {
          throw new Error('가입하지 않은 아이디이거나, 잘못된 비밀번호입니다');
        }

        let isAdmin = user.isAdmin && user.isAdmin === 'Y';

        // Verify returned enabled must be true!
        if (!isAdmin && !user.enabled) {
          throw new Error('사용할 수 없는 계정입니다. 관리자에게 문의하세요.');
        }

        // Verify returned roles are a non-null array
        if (!isAdmin && user.roles && user.roles.length === 0) {
          throw new Error('권한이 없습니다. 관리자에게 문의하세요.');
        }

        await commit('SET_USER_INFO', user);

        // 서버 설정 정보 읽어오기
        await dispatch('loadServerSetting');
        // 공통 정보 읽어오기
        await dispatch('initCommonsApi');
      } catch (err) {
        console.warn('[vuex.user] GetUserInfo', err);
        throw err;
      } finally {
        await commit('FINISH_READING_ACCOUNT');
      }
    },

    LogOut: async ({ commit }) => {
      try {
        console.log('[vuex.user] LogOut');
        await commit('SET_USER_INFO', { logout: true });
        await request({
          url: '/apix/logout',
        });
      } catch (err) {
        console.warn('[vuex.user] LogOut', err);
      }
    },

    setLastRequestTime: async ({ commit }, lastRequestTime) => {
      await commit('SET_LAST_REQUEST_TIME', lastRequestTime);
    },

    setToken: async ({ commit }, token) => {
      await commit('SET_TOKEN', token);
    }

    // Dynamically modify permissions
    /* ChangeRoles: async ({ commit, dispatch }, role) => {
      try {
        console.log('[vuex.user] ChangeRoles', role);
        await commit('SET_TOKEN', role);
        const data = await getUserInfo(role);
        await commit('SET_USER_INFO', data);
        // Redraw the side menu after dynamically modifying the permissions
        await dispatch('GenerateRoutes', data);
      } catch (err) {
        console.warn('[vuex.user] ChangeRoles', err);
      }
    }, */
  },
};

export default user;
