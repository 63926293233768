<template>
  <div id="container">
    <temp-location />
    <temp-sub-nav />
    <temp-content />
  </div>
</template>

<script>
import TempLocation from "./TempLocation.vue";
import TempSubNav from "./TempSubNav.vue";
import TempContent from "./TempContent.vue";

export default {
  name: "TempContainer",
  components: {
    TempLocation,
    TempSubNav,
    TempContent,
  },
  data: () => ({}),
};
</script>
