<template>
  <app-center>
    <template v-slot:page>
      <h1 class="display-2 primary--text">
        사용 제한
      </h1>
      <p>
        권한이 없습니다.
      </p>
      <p>
        운영자에게 문의하십시오.
      </p>
    </template>
  </app-center>
</template>

<script>
import AppCenter from '@/views/widget/AppCenter.vue';

export default {
  name: 'NotAllowedIp',
  components: {
    AppCenter,
  },
  data: () => ({}),
};
</script>
