<!-- 리소스의 URL 매핑 화면 -->
<template>
  <v-simple-table
    dense
  >
    <template v-slot:default>
      <thead>
      <tr>
        <th
          style="width: 40%"
        >
          {{ $t('apiResource.uri') }}
        </th>
        <th>
          {{ $t('apiResource.target') }} {{ $t('apiResource.url') }}
        </th>
      </tr>
      </thead>
      <tbody>
      <tr
        v-for="mapping in mappings"
        :key="mapping.id"
      >
        <td>{{ mapping.uri }}</td>
        <td>
          <v-text-field
            v-model="mapping.targetUrl"
            dense
            hide-details
          />
        </td>
      </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'ApiResourceUrlMappingView',
  props: {
    resources: {
      type: Array,
      required: true,
    }
  },
  data: () => ({
    mappings: [],
  }),
  created() {
    this.mappings = this.resources.map((r) => ({
      id: r.id,
      uri: r.uri,
      targetUrl: r.targetVersion && r.targetVersion.targetUrl,
    }));
  },
  mounted() {
  }
}
</script>

<style scoped>

</style>
