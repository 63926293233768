<template>
  <div>
    <v-dialog
      v-model="dialog.registResource"
      max-width="1000px"
      persistent
      @keydown.esc="dialog.registResource = false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="$hasAnyAuthority(['API_CREATE', 'ADMIN'])"
          elevation="0"
          outlined
          v-bind="attrs"
          @click="onClickRegistResource"
          v-on="on"
        >
          <v-icon>mdi-plus</v-icon>
          {{ $t('apiResource.add') }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title
          class="text-left"
        >
          {{ $t('apiResource.add') }} {{ $t('apiResource.resource') }}
        </v-card-title>
        <v-divider />

        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <!-- API 리소스 정보 -->
            <v-col
              class="ma-0 pa-0"
              cols="12"
            >
              <v-card class="mb-6" outlined tile>
                <v-card-subtitle class="text-left">
                  <h3 class="pl-md-2">{{ $t('apiResource.resource') }}</h3>
                </v-card-subtitle>
                <v-divider />
                <v-card-text class="pt-5">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                      <tr>
                        <th style="width: 95px; font-size:1rem;">{{ $t('apiResource.server') }} {{ $t('apiResource.url') }}</th>
                        <td>
                          <v-text-field
                            v-model="api.servers.url"
                            :rules="[rules.required]"
                            dense
                            hide-details
                            outlined
                            placeholder="http://localhost:8080/api/res"
                            @blur="onBlurURL()"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style="font-size:1rem;">Path</th>
                        <td>
                          <v-text-field
                            v-model="apiResource.path"
                            dense
                            hide-details
                            outlined
                            placeholder="/api"
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style="font-size: 1rem;">Method</th>
                        <td>
                          <v-chip-group
                            v-model="selected.methods"
                            multiple
                            @change="selectedMethodChanged"
                          >
                            <v-chip
                              v-for="method in httpMethods"
                              :key="method.value"
                              :color="selected.methods.includes(method.value) ? 'success' : ''"
                              :value="method.value"
                              filter
                            >
                              {{ method.text }}
                            </v-chip>
                          </v-chip-group>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
            <!-- method 목록 탭 -->
            <v-col
              v-if="selected.methods.length > 0"
              class="ma-0 pa-0"
              cols="12"
            >
              <v-card>
                <v-tabs
                  v-model="selected.methodTab"
                  align-with-title
                  background-color="indigo"
                  class="pl-0 pt-0"
                  dark
                >
                  <v-tabs-slider color="yellow" />
                  <v-tab
                    v-for="method in checkedMethods"
                    :key="method"
                  >
                    {{ method }}
                  </v-tab>
                  <v-tabs-items
                    v-model="selected.methodTab"
                  >
                    <v-tab-item
                      v-for="method in selected.methods"
                      :key="method"
                    >
                      <api-method-detail-view
                        :http-method="method"
                        :http-uri="apiResource.path"
                        :method="apiMethods.find((m) => m.method === method)"
                      />
                    </v-tab-item>
                  </v-tabs-items>
                </v-tabs>
              </v-card>
            </v-col>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined @click="onClickCloseButtion"> {{ $t('apiResource.close') }}</v-btn>
          <v-btn class="primary" @click="onClickRegistButton"> {{ $t('apiResource.save') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>


<script>
import request from '@/utils/request';
import { mapGetters } from 'vuex';
import ApiMethodDetailView from '@/components/api/ApiMethodDetailView';

const HTTP_METHODS = [
  {
    text: 'GET',
    value: 'get',
  },
  {
    text: 'POST',
    value: 'post',
  },
  {
    text: 'PUT',
    value: 'put',
  },
  {
    text: 'DELETE',
    value: 'delete',
  },
  {
    text: 'PATCH',
    value: 'patch',
  },
];

const defaultApi = {
  info: {
    title: 'apim add resource',
    description: 'apim add resource',
    version: '1.0.0',
  },
  servers: {
    url: '',
  },
  paths: [
    {
      path: '',
      operations: [],
    },
  ],
};
export default {
  name: 'ApiResourceEdit',
  props: ['sendData', 'contextpath'],
  components: {
    ApiMethodDetailView,
  },
  // props: ['dialog'],
  computed: {
    ...mapGetters({
      allSecurities: 'apiSecurities',
    }),
    apiResource() {
      return this.api.paths[0];
    },
    apiMethods() {
      return this.apiResource.operations;
    },
    apiTargetUrl() {
      return this.api.servers.url;
    },
    checkedMethods() {
      return this.httpMethods.filter(m => this.selected.methods.includes(m.value))
        .map(m => m.value);
    },
    // 인증 목록 표시를 위한 데이터
    securityItems() {
      return Object.entries(this.securities).map(([key, value]) => ({
        text: `${key}(${value.type})`,
        value: `${key}`,
      }));
    }
  },
  data: function () {
    return {
      valid: true,
      rules: {
        required(v) {
          return !!v || 'Required';
        },
      },
      dialog: {
        registResource: false,
        security: false,
        scope: false,
        tags: false,
      },
      httpMethods: HTTP_METHODS, // 지원 HTTP 메소드 목록

      api: JSON.parse(JSON.stringify(defaultApi)), // 기본 swagger 양식

      selected: {
        methods: [],
        methodTab: null, // 선택된 method tab - 수정 대상 메소드
      }
    };
  },
  methods: {
    //저장
    async onClickRegistButton() {
      if (!this.$refs.form.validate()) {
        var requiredFiledMsg = this.$t('apiResource.requiredFiledMsg');
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;">
                      <img max-width="100%" src="static/inzent/ico_fail.svg" /><br/>
                      <b>${requiredFiledMsg}</b>
                    </div>`,
        }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
        return;
      }

      if (this.apiMethods.length === 0) {
        var methodMsg = this.$t('apiResource.methodMsg');
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;">
                      <img max-width="100%" src="static/inzent/ico_fail.svg" /><br/>
                      <b>${methodMsg}</b>
                    </div>`,
        }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
        return;
      }

      if (
        !this.apiResource.path.startsWith(this.contextpath + '/') &&
        this.apiResource.path !== this.contextpath &&
        this.contextpath !== '/'
      ) {
        var pathMsg = this.$t('apiResource.pathMsg', { path: `${this.contextpath}` });
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;">
                      <img max-width="100%" src="static/inzent/ico_fail.svg" /><br/>
                      <b>${pathMsg}</b>
                    </div>`,
        }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
        return;
      }

      this.apiResource.operations.forEach((method) => {
        if (method.securities.length > 0) {
          let security = [];
          method.securities.forEach((methodSecurity) => {
            Object.entries(this.allSecurities).forEach(([key, value]) => {
              if (key === methodSecurity) {
                const securityItem = {};
                const securityType = value.type;
                switch (securityType) {
                  case 'OAUTH2':
                    securityItem[methodSecurity] = method.scopes;
                    break;
                  case 'APIKEY':
                    securityItem[methodSecurity] = [];
                    break;
                }
                security.push(securityItem);
              }
            });
          });
          method.security = security;
        }
      });
      try {
        if (this.apiResource.operations.some(o => o.securities.length === 0)) {
          // 인증이 없는 경우 사용할 인증 방식
          const apiKeySecurityName = Object.keys(this.allSecurities).find(key => this.allSecurities[key].type === 'APIKEY');
          const securityItem = {};
          securityItem[apiKeySecurityName] = [];

          // 인증이 지정되지 않은 메소드가 있다면
          var certifiedMsg = this.$t('apiResource.certifiedMsg');
          if (apiKeySecurityName && !await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;">
                        <img max-width="100%" src="static/inzent/ico_warning.svg" /><br/>
                        <b>${certifiedMsg}</b>
                      </div>`,
            confirmYn: true,
          }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') })) {
            // OPEN API로 사용하지 않는다면 APIKEY 방식을 사용하도록 한다.
            this.apiResource.operations.filter(o => o.securities.length === 0).forEach(o => o.security = [securityItem]);
          }
        }
        await request({
          url: '/apix/apis/resource',
          method: 'post',
          data: this.api,
        });
        this.onClickCloseButtion();
        this.$emit('success', { ...this.api });
      } catch (error) {
        var errorMsg = this.$t('apiResource.errorMsg');
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;">
                      <img max-width="100%" src="static/inzent/ico_fail.svg" /><br/>
                      <b>${errorMsg} : '${error.message}'</b>
                    </div>`,
        }, { buttonTrueText: this.$t('main.ok'), buttonFalseText: this.$t('main.cancel') });
      }
    },
    // 닫기
    onClickCloseButtion() {
      this.dialog.registResource = false;
      this.selected.methods = [];
      this.selected.methodTab = null;
      this.api = { ...defaultApi };
    },
    async onClickRegistResource() {
      this.api = JSON.parse(JSON.stringify(defaultApi));
      this.$emit('open-regist-resource-dialog');

      this.apiResource.path = this.contextpath;
    },
    onBlurURL() {
      if (!this.apiResource.path && this.api.servers.url.startsWith('http')) {
        const parser = document.createElement('a');
        parser.href = this.api.servers.url;
        this.apiResource.path = parser.pathname;
      }
    },
    // 등록대상 메소드 목록이 변경될 때
    selectedMethodChanged() {
      // 선택되지 않은 메소드들 제거
      const deleted = this.apiMethods.filter((m) => !this.selected.methods.includes(m.method));
      deleted.forEach((d) => this.apiMethods.splice(this.apiMethods.indexOf(d), 1));
      // 선택되었은데 데이터가 없는 경우 추가
      const notExists = this.selected.methods.filter((m) => !this.apiMethods.find(m1 => m1.method === m));
      notExists.forEach(m => {
        this.apiMethods.push({
          method: m,
          operationId: '',
          summary: '',
          tags: [],
          securities: [],
          scopes: [],
          parameters: [],
          requestBody: {},
          responses: [
            // 기본 값 하나 추가
            {
              code: '200',
              description: this.$t('apiResource.normal'),
              mediaType: '',
            }
          ],
        });
      });
    }
  },
};
</script>
