<template>
  <v-card
    rounded
    class="my-3"
  >
    <v-card-text
      class="pa-0"
    >
      <div class="blue-grey lighten-4 pa-5">
        <h3>{{ app.name }}</h3>
        <h5 class="mt-2 font-weight-light">{{ app.description }}</h5>
      </div>
      <v-list>
        <v-list-item>
          <v-list-item-icon
            class="mr-3"
          >
            <v-tooltip left>
              <template #activator="{on, attr}">
                <v-icon
                  v-on="on"
                  v-bind="attr"
                >
                  mdi-account
                </v-icon>
              </template>
              <span>{{ $t('app.owner') }}({{ $t('app.thePersonInCharge') }})</span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ app.owner && app.owner.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AppCard',
  props: {
    app: {
      type: Object,
      required: true,
    }
  }
}
</script>

<style scoped>

</style>
