<template>
  <div>
    <v-data-table
      v-if="!!schema && schema.type === 'object'"
      :items="localProperties"
      :headers="computedHeaders"
      dense
      hide-default-footer
      disable-pagination
    >
      <template
        v-if="title"
        v-slot:top
      >
        {{ title }}
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click.stop="clickModify(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click.stop="deleteProperty(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template
        v-if="editMode"
        v-slot:body.append="{ headers }"
      >
        <tr>
          <td
            :colspan="headers.length"
            class="text-right"
            style="border: none"
          >
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                  @click="clickAdd"
                >
                  mdi-plus-box
                </v-icon>
              </template>
              <span>{{$t('swagger.property')}} {{$t('swagger.add')}}</span>
            </v-tooltip>
          </td>
        </tr>
      </template>
    </v-data-table>
    <v-chip
      v-else
    >
      {{ schema ? schema.type : '' }}
    </v-chip>

    <v-dialog
      v-if="dialog.prop"
      v-model="dialog.prop"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="text-left"
        >
          <span class="text-h3">
            {{$t('swagger.property')}} {{ dialog.prop === 'add' ? $t('swagger.add') : $t('swagger.modify') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="6"
                class="py-0"
              >
                <v-text-field
                  v-model="modify.prop.name"
                  :label="$t('swagger.name')"
                />
              </v-col>
              <!--<v-col-->
              <!--  cols="6"-->
              <!--  class="py-0"-->
              <!--&gt;-->
              <!--  <v-select-->
              <!--    v-model="editParameter.in"-->
              <!--    :items="selectors.in"-->
              <!--    label="전송방법"-->
              <!--  />-->
              <!--</v-col>-->
              <!--<v-col-->
              <!--  cols="12"-->
              <!--  class="py-0"-->
              <!--&gt;-->
              <!--  <v-text-field-->
              <!--    v-model="editParameter.description"-->
              <!--    label="설명"-->
              <!--  />-->
              <!--</v-col>-->
              <!--<v-col-->
              <!--  cols="6"-->
              <!--  class="py-0"-->
              <!--&gt;-->
              <!--  <v-select-->
              <!--    v-model="editParameter.required"-->
              <!--    :items="selectors.required"-->
              <!--    label="필수여부"-->
              <!--  />-->
              <!--</v-col>-->
              <v-col
                cols="6"
                class="py-0"
              >
                <v-select
                  v-model="modify.prop.type"
                  :items="selectors.types"
                  :label="$t('swagger.type')"
                />
              </v-col>
              <!--<v-col-->
              <!--  cols="12"-->
              <!--  class="py-0"-->
              <!--&gt;-->
              <!--  <v-text-field-->
              <!--    v-model="editParameter.schema.example"-->
              <!--    label="예제"-->
              <!--  />-->
              <!--</v-col>-->
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="grey"
            @click="dialog.prop = false"
          >
            {{$t('swagger.cancel')}}
          </v-btn>
          <v-btn
            @click="saveProp"
          >
            {{ dialog.prop === 'add' ? $t('swagger.add') : $t('swagger.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { SELECTOR_SCHEMA_TYPE } from '@/config/constants';
import {mapGetters} from "vuex";

const header = [
  {
    text: 'swagger.name',
    value: 'name',
  },
  {
    text: 'swagger.type',
    value: 'type',
  },
  {
    text: '',
    value: 'actions',
  }
];

export default {
  name: 'SwaggerSchemaView',
  props: {
    schema: {
      type: Object,
    },
    editMode: {
      type: Boolean,
      default: false,
    }
  },
  data: function() {
    return {
      headers: this.$translate(header),
      selectors: {
        types: SELECTOR_SCHEMA_TYPE,
      },
      dialog: {
        prop: false,
      },
      modify: {
        prop: {
          name: null,
          type: null,
        },
        propBackup: null,
      },
      localProperties: [],
    };
  },
  computed: {
    ...mapGetters(['currentLanguage']),
    computedHeaders() {
      return this.editMode ? this.headers : this.headers.filter((h) => h.value !== 'actions');
    },
    title() {
      if (this.schema.title) {
        return this.schema.type ? `${this.schema.title} | ${this.schema.type}` : this.schema.title;
      } else {
        return this.schema.type;
      }
    },
  },
  mounted() {
    if (this.schema && this.schema.type === 'object' && this.schema.properties) {
      this.localProperties = Object.entries(this.schema.properties).map(([key, value]) => ({
        name: key,
        type: value.type,
      }));
    }
  },
  methods: {
    // 속성 삭제
    deleteProperty(item) {
      const index = this.localProperties.indexOf(item);
      if (index >= 0) this.localProperties.splice(index, 1);
    },
    clickModify(item) {
      this.modify.propBackup = item;
      this.modify.prop = { ...item };
      this.dialog.prop = true;
    },
    // 추가 버튼 클릭 시
    clickAdd() {
      this.modify.prop = {
        name: null,
        type: 'string',
      };
      this.dialog.prop = true;
    },
    // 속성 저장
    saveProp() {
      if (this.modify.propBackup) {
        // 수정하는 경우
        Object.entries(this.modify.prop).forEach(([key, value]) => {
          this.modify.propBackup[key] = value;
        });
      } else {
        // 추가하는 경우
        this.localProperties.push(this.modify.prop);
      }
      this.modify.propBackup = null;
      this.dialog.prop = false;
    }
  },
  watch: {
    currentLanguage(){
      this.headers = this.$translate(header);
    },
    localProperties(value) {
      this.schema.properties = {};
      value.forEach((p) => {
        this.schema.properties[p.name] = {
          type: p.type,
        };
      });
    }
  }
}
</script>

<style scoped>

</style>
