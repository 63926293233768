<template>
  <v-dialog
    v-model="dialog.regist"
    persistent
    max-width="600px"
    @keydown.esc="dialog.regist = false"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-tooltip top v-bind="attrs" v-on="on">
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            class="float-right"
            v-bind="attrs"
            v-on="on"
            @click="openRegistDialog"
            >mdi-plus</v-icon
          >
        </template>
        <span>{{ title }}</span>
      </v-tooltip>
    </template>

    <v-card>
      <v-card-title class="text-h4 text-left">{{ title }}</v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-simple-table class="elevation-0">
                <template v-slot:default>
                  <!-- 등록/수정 모드인 경우 -->
                  <tbody>
                    <tr v-for="field in fields" :key="field.value">
                      <th style="width: 110px">{{ field.text }}</th>
                      <td>
                        <v-text-field
                          v-if="field.type == 'text'"
                          v-model="item[field.value]"
                          :required="field.required"
                          :placeholder="field.placeholder"
                          outlined
                          hide-details
                        />

                        <v-textarea
                          v-else-if="field.type == 'textarea'"
                          v-model="item[field.value]"
                          :required="field.required"
                          :placeholder="field.placeholder"
                          outlined
                          hide-details
                        />

                        <v-select
                          v-else-if="field.type == 'select'"
                          v-model="item[field.value]"
                          :items="field.selectItems"
                          :required="field.required"
                          :placeholder="field.placeholder"
                          outlined
                          hide-details
                        />
                        <v-text-field
                          v-else
                          v-model="item[field.value]"
                          :required="field.required"
                          :placeholder="field.placeholder"
                          outlined
                          hide-details
                        />
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn outlined @click="closeRegistDialog"> {{ $t('simpleRegistDialog.close') }} </v-btn>
        <v-btn elevation="0" class="primary mr-1" @click="clickRegistDialog">
          {{ $t('simpleRegistDialog.regist') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<style scoped>
table > tbody > tr > th {
  background-color: rgb(245, 245, 245);
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>

<script>
export default {
  name: "RegistSimpleDialog",
  components: {},
  props: ["title", "fields", "addKey"],
  data: () => ({
    dialog: {
      regist: false,
    },
    item: {},
  }),
  mounted() {
    console.log("RegistSimpleDialog title", this.title);
    console.log("RegistSimpleDialog fields", this.fields);
  },
  methods: {
    openRegistDialog() {
      this.dialog.regist = true;
    },
    closeRegistDialog() {
      this.item = {};
      this.dialog.regist = false;
    },
    clickRegistDialog() {
      this.$emit("addItem", this.item, this.addKey);
      this.closeRegistDialog();
    },
  },
};
</script>
