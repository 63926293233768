<template>
  <!-- common.css 적용 안됨 element style 사용 -->
  <v-navigation-drawer
    id="core-navigation-drawer-fronnt"
    :expand-on-hover="expandOnHover"
    :right="$vuetify.rtl"
    mobile-breakpoint="760"
    app
    width="222"
    v-bind="$attrs"
    :value="navbarShow"
    @input="stateNavbarShow"
  >
    <v-app-bar v-if="navbarLogo" :dense="toolbarDense" dark>
      <v-toolbar-title class="text-center">
        <v-avatar size="32px" tile>
          <img src="img/icons/android-icon-36x36.png" alt="VVA" />
        </v-avatar>
        <span>{{ $t("toolbar.appname") }}</span>
      </v-toolbar-title>
    </v-app-bar>
    <the-layout-drawer-list
      :dense="navbarDense"
      :routes="permissionRoutes"
      icon-show
    />
  </v-navigation-drawer>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TheLayoutDrawerList from "./TheLayoutDrawerList.vue";

export default {
  name: "TempSubNav",
  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    TheLayoutDrawerList,
  },
  data: () => ({}),
  computed: {
    ...mapState(["barColor", "barImage"]),
    ...mapGetters([
      "permissionRoutes",
      "navbarDense",
      "navbarShow",
      "navbarLogo",
      "toolbarDense",
    ]),
  },
  methods: {
    stateNavbarShow(state) {
      this.$store.dispatch("NavbarState", { state });
    },
  },
};
</script>

