<template>
  <div :class="className" :style="{ height: height, width: width }"/>
</template>

<script>
import echarts from 'echarts';
import debounce from '@/utils';

require('echarts/theme/macarons'); // echarts theme

export default {
  name: 'OptionChart',
  props: {
    className: {
      type: String,
      default: 'chart',
    },
    width: {
      type: String,
      default: '100%',
    },
    height: {
      type: String,
      default: '300px',
    },
    chartData: {
      type: Object,
      required: true,
    },
    chartClickable: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    chart: null,
  }),
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
  },
  methods: {
    setOptions(option = {}) {
      this.chart.setOption(option, true);
    },
    initChart() {
      this.chart = echarts.init(this.$el, 'macarons');
      if (this.chartClickable) {
        this.chart.on('click', (param) => {
          this.$emit('chart-click', param);
        });
      }
      this.setOptions(this.chartData);
    },
  },
  mounted() {
    this.initChart();
    this.resizeHandler = debounce(() => {
      if (this.chart) {
        this.chart.resize();
      }
    }, 100);
    window.addEventListener('resize', this.resizeHandler);
    this.$nextTick(this.resizeHandler);
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    window.removeEventListener('resize', this.resizeHandler);
    this.chart.dispose();
    this.chart = null;
  },
};
</script>
