<!-- API의 메소드 목록 보기 -->
<template>
  <div>
    <simple-regist-dialog
      v-if="mode != null"
      :title="$t('apiMethod.body') + $t('apiMethod.add')"
      :fields="bodyItems"
      @addItem="addBody"
    />
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th width="200px">{{$t('apiMethod.contentType')}}</th>
            <th>{{$t('apiMethod.body')}}</th>
            <th>{{$t('apiMethod.note')}}</th>
          </tr>
        </thead>

        <tbody v-if="!methodContent || Object.keys(methodContent).length == 0">
          <tr class="v-data-table__empty-wrapper">
            <td colspan="3">{{$t('apiMethod.noDataMsg')}}</td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-for="(value, name) in methodContent" :key="name">
            <td>
              <!-- Content-Type -->
              <v-select
                :readonly="!mode"
                :items="mediaTypeItems"
                :value="name"
                @change="updateBodyContentType(name, $event)"
                outlined
                hide-details
              />
            </td>
            <td>
              <v-card-title>
                <v-spacer />
                <simple-regist-dialog
                  v-if="mode != null"
                  :itle="$t('apiMethod.field') + $t('apiMethod.add')"
                  :fields="fieldItems"
                  :addKey="name"
                  @addItem="addItem"
                />
              </v-card-title>

              <!-- Content -->
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th width="30%">{{$t('apiMethod.key')}}</th>
                      <th width="30%">{{$t('apiMethod.type')}}</th>
                      <th width="30%">{{$t('apiMethod.sample')}}</th>
                      <th width="10%">{{$t('apiMethod.note')}}</th>
                    </tr>
                  </thead>
                  <tbody v-if="!value">
                    <tr class="v-data-table__empty-wrapper">
                      <td colspan="4">{{$t('apiMethod.noDataMsg')}}</td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr
                      v-for="(value, key) in value.schema.properties"
                      :key="key"
                    >
                      <td>
                        <!-- 키 -->
                        <template v-if="!mode">
                          {{ key }}
                        </template>
                        <v-text-field
                          v-else
                          placeholder="key"
                          :value="key"
                          @change="updatePropertyKey(name, key, $event)"
                          outlined
                          hide-details
                        />
                      </td>
                      <td>
                        <!-- 타입 -->
                        <v-select
                          placeholder="string"
                          :readonly="!mode"
                          :items="propertyTypeItems"
                          :value="value.type"
                          @change="updatePropertyType(name, key, $event)"
                          outlined
                          hide-details
                        />
                      </td>
                      <td>
                        <!-- 샘플 -->
                        <template v-if="!mode">
                          {{ value.example }}
                        </template>
                        <v-text-field
                          v-else
                          placeholder="value"
                          :value="value.example"
                          @change="updatePropertyExample(name, key, $event)"
                          hide-details
                          outlined
                        />
                      </td>
                      <td>
                        <v-tooltip top v-if="mode != null">
                          <template v-slot:activator="{ on, attrs }">
                            <v-icon
                              @click.stop.prevent="deleteItem(name, key)"
                              v-bind="attrs"
                              v-on="on"
                            >
                              mdi-delete
                            </v-icon>
                          </template>
                          <span>{{$t('apiMethod.delete')}}</span>
                        </v-tooltip>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </td>
            <td>
              <v-tooltip top v-if="mode != null">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    @click.stop.prevent="deleteBodyItem(name)"
                    v-bind="attrs"
                    v-on="on"
                    dense
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>{{$t('apiMethod.delete')}}</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import SimpleRegistDialog from "@/components/SimpleRegistDialog";
import {mapGetters} from "vuex";

const mediaTypeItems = [
  {
    text: "application/json",
    value: "application/json",
  },
  {
    text: "application/xml",
    value: "application/xml",
  },
];
const propertyTypeItems = [
  {
    text: "string",
    value: "string",
  },
  {
    text: "integer",
    value: "integer",
  },
  {
    text: "boolean",
    value: "boolean",
  },
  {
    text: "number",
    value: "number",
  },
  {
    text: "object",
    value: "object",
  },
];
const fieldItems = [
  {
    text: 'apiMethod.key',
    value: "name",
    required: true,
    type: "text",
    placeholder: "key",
  },
  {
    text: 'apiMethod.type',
    value: "type",
    required: true,
    type: "select",
    placeholder: "string",
    selectItems: propertyTypeItems,
  },
  {
    text: 'apiMethod.sample',
    value: "example",
    required: true,
    type: "text",
    placeholder: "value",
  },
];
const defaultSchema = {
  schema: {
    type: "object",
    properties: {},
  },
};
const bodyItems = [
  {
    text: 'apiMethod.contentType',
    value: "mediaType",
    required: true,
    type: "select",
    placeholder: "application/json",
    selectItems: mediaTypeItems,
  },
];
export default {
  name: "ApiMethodContent",
  props: ["content", "mode"],
  components: {
    "simple-regist-dialog": SimpleRegistDialog,
  },

  computed: {
    ...mapGetters(['currentLanguage']),
    // methodContent: {
    //   get(){
    //     return this.content
    //   },
    //   set(content){
    //     this.content = content;
    //     //return content
    //   }
    // },
  },
  mounted() {
    this.methodContent = { ...this.content };
    this.getMediaTypeItems();

    console.log("content ", this.content);
    console.log("methodContent ", this.methodContent);

    console.log("mediaTypeItems ", this.mediaTypeItems);
  },
  data: () => ({
    mediaTypeItems: this.$translate(mediaTypeItems),
    propertyTypeItems: this.$translate(propertyTypeItems),
    bodyItems: this.$translate(bodyItems),
    fieldItems: this.$translate(fieldItems),
    methodContent: {},
    // contentTest:{},
  }),
  methods: {
    updateBodyContentType(source, target) {
      //test(methodContent, $event, name)"
      console.log("source ", source);
      console.log("target ", target);

      this.methodContent[target] = this.methodContent[source];
      delete this.methodContent[source];

      console.log("this.methodContent ", this.methodContent);
      this.$emit("change", this.methodContent);

      // return target;
    },
    updatePropertyKey(mediaType, source, target) {
      console.log("mediaType ", mediaType);
      console.log("source ", source);
      console.log("target ", target);

      this.methodContent[mediaType].schema.properties[
        target
      ] = this.methodContent[mediaType].schema.properties[source];
      delete this.methodContent[mediaType].schema.properties[source];

      console.log(
        "this.properties ",
        this.methodContent[mediaType].schema.properties
      );
      this.$emit("change", this.methodContent);
    },
    updatePropertyType(mediaType, key, target) {
      console.log("mediaType ", mediaType);
      console.log("source ", key);
      console.log("target ", target);

      let temp = this.methodContent[mediaType].schema.properties[key];
      temp.type = target;
      temp.example = this.methodContent[mediaType].schema.properties[
        key
      ].example;

      this.methodContent[mediaType].schema.properties[key] = { ...temp };

      console.log(
        "this.properties ",
        this.methodContent[mediaType].schema.properties
      );
      this.$emit("change", this.methodContent);
    },
    updatePropertyExample(mediaType, key, target) {
      console.log("mediaType ", mediaType);
      console.log("source ", key);
      console.log("target ", target);

      let temp = this.methodContent[mediaType].schema.properties[key];
      temp.type = this.methodContent[mediaType].schema.properties[key].type;
      temp.example = target;

      this.methodContent[mediaType].schema.properties[key] = { ...temp };

      console.log(
        "this.properties ",
        this.methodContent[mediaType].schema.properties
      );
      this.$emit("change", this.methodContent);
    },
    addItem(item, mediaType) {
      console.log("mediaType ", mediaType);
      console.log("item ", item);

      let property = {};
      if (item.name) property.name = item.name;
      if (item.type) property.type = item.type;
      if (item.example) property.example = item.example;

      console.log("addItem ", property);

      // let temp = this.methodParameters;
      // temp.push({...parameter});
      // console.log('temp ', temp);

      if (!this.methodContent[mediaType].schema.properties)
        this.methodContent[mediaType].schema.properties = {};
      //this.methodParameters=[...temp];
      this.methodContent[mediaType].schema.properties[item.name] = property;

      this.methodContent[mediaType].schema.properties = {
        ...this.methodContent[mediaType].schema.properties,
      };

      console.log(
        "properties ",
        this.methodContent[mediaType].schema.properties
      );
      this.$emit("change", this.methodContent);
    },
    addBody(item) {
      console.log("item ", item);

      let schema = JSON.parse(JSON.stringify({ ...defaultSchema }));

      let temp = { ...this.methodContent };
      temp[item.mediaType] = schema;

      console.log("temp ", temp);

      if (!this.methodContent) this.methodContent = {};
      this.methodContent = temp;

      console.log("methodContent ", this.methodContent);
      this.$emit("change", this.methodContent);
    },
    deleteItem(mediaType, key) {
      console.log("mediaType ", mediaType);
      console.log("key ", key);
      console.log(
        "deleteItem ",
        this.methodContent[mediaType].schema.properties[key]
      );

      delete this.methodContent[mediaType].schema.properties[key];
      this.methodContent[mediaType].schema.properties = {
        ...this.methodContent[mediaType].schema.properties,
      };
      // let index = this.methodContent[mediaType].schema.properties.indexOf(item)
      // this.methodContent[mediaType].schema.properties.splice(index,1);
      console.log(
        "properties ",
        this.methodContent[mediaType].schema.properties
      );

      this.$emit("change", this.methodContent);
    },
    deleteBodyItem(mediaType) {
      console.log("mediaType ", mediaType);

      let temp = { ...this.methodContent };
      delete temp[mediaType];

      this.methodContent = temp;

      console.log("methodContent ", this.methodContent);

      this.$emit("change", this.methodContent);
    },
    getMediaTypeItems() {
      let keys = Object.keys(this.methodContent);
      console.log("keys ", keys);

      keys.forEach((methodMediaType) => {
        console.log("getMediaTypeItems methodMediaType ", methodMediaType);
        let finded = this.mediaTypeItems.find(
          (type) => type.value == methodMediaType
        );
        if (!finded) {
          this.mediaTypeItems.push({
            text: methodMediaType,
            value: methodMediaType,
          });
        }
      });
    },
  },
  watch: {
    currentLanguage() {
      this.mediaTypeItems = this.$translate(mediaTypeItems);
      this.propertyTypeItems = this.$translate(propertyTypeItems);
      this.bodyItems = this.$translate(bodyItems);
      this.fieldItems = this.$translate(fieldItems);
    },
  },
};
</script>
