<template>
  <v-card>
    <v-card-title
      v-if="title"
      :class="titleClass"
    >
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-data-table
        ref="refOrganizatonList"
        v-model="checked.organizations"
        :headers="headers.organization"
        :items="organizations"
        item-key="key"
        :search="search.query"
        :item-class="(o) => selected.organization && o.id === selected.organization.id ? 'grey lighten-3' : ''"
        :show-select="showSelect"
        :single-select="singleSelect"
        :options.sync="pagingOptions"
        :server-items-length="organizationsCount"
        :dense="dense"
        @click:row="clickOrganization"
      >
        <template v-slot:top>
          <v-container>
            <v-row>
              <v-spacer/>
              <v-col
                cols="auto"
                v-if="enableMydata"
              >
                <v-checkbox
                  v-model="checked.mydata"
                  :label="$t('organization.mydata')"
                  hide-details
                  dense
                  @change="onConditionChanged"
                />
              </v-col>
              <!-- 마이데이터인 경우 기관 구분으로 조회할 수 있도록 한다. -->
              <v-col
                cols="auto"
                v-if="checked.mydata"
              >
                <v-select
                  v-model="search.organizationType"
                  :items="selectors.mydataOrganizationTypes"
                  hide-details
                  dense
                  style="width: 280px;"
                  @change="onConditionChanged"
                />
              </v-col>
              <v-col
                cols="auto"
              >
                <v-text-field
                  v-if="!checked.mydata"
                  v-model="search.query"
                  :label="$t('organization.organization')+' '+$t('organization.name')"
                  append-icon="mdi-magnify"
                  single-line
                  dense
                  hide-details
                  clearable
                />
              </v-col>
            </v-row>
          </v-container>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-actions
      v-if="showSelect"
      class="justify-center"
    >
      <v-btn
        :disabled="!checked.organizations || checked.organizations.length === 0"
        @click="applyChecked"
      >
        {{$t('organization.application')}}
      </v-btn>
      <v-btn
        outlined
        @click="cancel"
      >
        {{$t('organization.cancel')}}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import organizationService from '@/service/organization.service';
import { convertDataTablePagingOptionsToObject } from '@/views/service/util';
import { ORGANIZATION_TYPES } from '@/config/constants-mydata';
import {mapGetters} from "vuex";

const header = [
  {
    text: 'organization.organizationName',
    value: 'name',
    filterable: true,
  },
];
export default {
  name: 'OrganizationListView',
  props: {
    title: {
      type: String,
    },
    titleClass: {
      type: String,
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    enableMydata: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'on-select', // row를 선택했을 때
    'apply-checked', // 적용 버튼을 눌렀을 때
    'cancel', // 취소 버튼을 눌렀을 때
  ],
  data: function() {
    return {
      selectors: {
        mydataOrganizationTypes: ORGANIZATION_TYPES,
      },
      headers: {
        organization: this.$translate(header),
      },
      pagingOptions: null,
      search: {
        query: null,
        organizationType: '',
      },
      organizations: [],
      organizationsCount: 0,
      selected: {
        organization: null,
      },
      checked: {
        mydata: false,
        organizations: [],
      }
    };
  },
  computed:{
    ...mapGetters(['currentLanguage']),
  },
  mounted() {
    this.fetchOrganizations();
  },
  methods: {
    async fetchOrganizations() {
      if (this.checked.mydata) {
        const search = convertDataTablePagingOptionsToObject(this.pagingOptions);
        if (this.search.organizationType) search.type = this.search.organizationType;
        const { data, totalCount } = await organizationService.mydataList(search);
        data.forEach((d) => d.key = d.code); // mydata의 경우 code값을 key로 사용한다.
        this.organizations = data;
        this.organizationsCount = totalCount;
      } else {
        const data = await organizationService.list();
        data.forEach((d) => d.key = d.id); // 일반 기관의 경우 id값을 key로 사용한다.
        this.organizations = data;
        this.organizationsCount = -1; // client-side paging으로 동작한다.
      }
    },
    clickOrganization(organization) {
      this.selected.organization = organization;
      this.$emit('on-select', organization);
    },
    applyChecked() {
      this.$emit('apply-checked', this.checked.organizations);
    },
    cancel() {
      this.$emit('cancel');
    },
    onConditionChanged() {
      if (this.pagingOptions.page === 1) {
        this.fetchOrganizations();
      } else {
        this.pagingOptions.page = 1;
      }
    }
  },
  watch: {
    currentLanguage(){
      this.headers.organization = this.$translate(header);
    },
    pagingOptions() {
      if (this.checked.mydata) {
        // mydata의 경우 server 페이징을 사용
        this.fetchOrganizations();
      }
    }
  }
}
</script>

<style scoped>

</style>
