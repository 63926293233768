import route from './route';
import components from '@/demo/locale/en_US/components';

export default {
  route,
  components,
  toolbar: {
    appname: 'Application',
    settings: '설정',
    profile: '프로필',
    logout: '로그아웃',
  },
  settings: {
    title: '테마 설정',
    default: '기본값',
    version: '버전',
    position: '요소 위치',
    theme: '테마',
    dark: '어두운 테마',
    toolbarDense: '툴바 좁게',
    navbarDense: '네비바 좁게',
    navbarLogo: '네비바 로고',
    settingsBtn: '설정 버튼',
    footer: '꼬릿말',
  },
  login: {
    titleIn: '로그인 폼',
    titleUn: 'Sing Un Form',
    signIn: '로그인',
    signUp: '회원가입',
    email: 'Email',
    password: '비밀번호',
    confirm: '확인',
  },
  errors: {
    whoops: '어이구',
    back: '빠져 나가기!',
    301: 'Moved Permanently',
    401: 'Unauthorized',
    403: 'Forbidden',
    404: 'Not Found',
    500: 'Internal Server Error',
  },
  guide: {
    description: 'The guide page is useful for some people who entered the project for '
      + 'the first time. You can briefly introduce the features of the project. Demo is based on',
    button: 'Show Guide',
  },
  ui: {
    switch: '언어 변경',
    theme: '테마 변경',
    success: '성공',
    error: '에러',
    warning: '경고',
    info: '정보',
    primary: '주',
    secondary: '부',
    default: '기본',
    accent: 'Accent',
    firstName: '이름',
    lastName: '성',
    email: 'E-mail',
  },
};
