<template>
  <v-main>
    <AlertMessage/>
    <transition name="fade-transform" mode="out-in">
      <keep-alive>
        <router-view/>
      </keep-alive>
    </transition>
  </v-main>
</template>

<script>
import AlertMessage from '@/views/components/AlertMessage';

export default {
  name: 'TheLayoutContent',
  components: {
    AlertMessage,
  },
  data: () => ({}),
};
</script>
