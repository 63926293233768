<template>
  <v-container fluid>
    <!-- 상단 조건 -->
    <v-row>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <material-stats-card
          color="info"
          icon="trending_up"
          title="TPS"
          :value="tps"
          :small-value="$t('monitoring.case')"
          sub-icon="mdi-clock"
          :sub-text="tpsLastUpdatedTime"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <material-stats-card
          color="primary"
          icon="timer"
          :title="$t('monitoring.averageResponseTime')"
          :value="averageTime"
          :small-value="$t('monitoring.seconds')"
          sub-icon="mdi-clock"
          :sub-text="tpsLastUpdatedTime"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <material-stats-card
          color="success"
          icon="history_toggle_off"
          :title="$t('monitoring.currentTimeCumulativeThroughput')"
          :value="accumulatedCurrentHour"
          :small-value="$t('monitoring.case')"
          sub-icon="mdi-clock"
          :sub-text="accumulatedLastUpdatedTime"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
        lg="3"
      >
        <material-stats-card
          color="orange"
          icon="query_builder"
          :title="$t('monitoring.cumulativeThroughputToday')"
          :value="accumulatedToday"
          :small-value="$t('monitoring.case')"
          sub-icon="mdi-clock"
          :sub-text="accumulatedLastUpdatedTime"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <material-card
          :title="$t('monitoring.throughputByTimeZone')"
          title-class="pa-2"
          color="teal lighten-2"
        >
          <option-chart
            height="170px"
            width="100%"
            :chart-data="statisticsChartOption"
          />
        </material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <material-card
          :title="$t('monitoring.transactionLog')"
          title-class="pa-2"
          color="grey darken-1"
        >
          <x-view-chart
            :logs="transactions"
          />
        </material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
</style>

<style>
</style>

<script>
import { mapGetters } from 'vuex';
import MaterialStatsCard from '@/views/components/base/MaterialStatsCard';
import XViewChart from '@/components/XViewChart';
import MaterialCard from '@/views/components/base/MaterialCard';
import OptionChart from '@/views/components/Dashboard/OptionChart';

const statisticsChartOption = {
  xAxis: {
    type: 'category',
    boundryGap: false,
    // data: [],
    // boundaryGap: false,
    // axisTick: {
    //   show: false
    // }
  },
  grid: {
    left: 10,
    right: 55,
    bottom: 20,
    top: 30,
    containLabel: true
  },
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'cross'
    },
    padding: [5, 10]
  },
  yAxis: {
    axisTick: {
      show: false
    }
  },
  legend: {
    data: ['Today', 'Previous']
  },
  series: [
    {
      name: 'Today',
      // itemStyle: {
      //   normal: {
      //     color: '#FF005A',
      //     lineStyle: {
      //       color: '#FF005A',
      //       width: 2
      //     }
      //   }
      // },
      smooth: true,
      type: 'bar',
      data: [],
      animationDuration: 2800,
      animationEasing: 'cubicInOut'
    },
    {
      name: 'Previous',
      smooth: true,
      type: 'line',
      itemStyle: {
        normal: {
          color: '#3888fa',
          lineStyle: {
            color: '#3888fa',
            width: 2
          },
          areaStyle: {
            color: '#f3f8ff'
          }
        }
      },
      data: [],
      animationDuration: 2800,
      animationEasing: 'quadraticOut'
    }
  ]
};

export default {
  name: 'MonitoringView',
  components: { OptionChart, MaterialCard, XViewChart, MaterialStatsCard },
  data: () => ({
    statisticsChartOption: statisticsChartOption,
  }),
  computed: {
    ...mapGetters([
      'transactions',
      'tpsInfo',
      'accumulated',
      'statistics',
    ]),
    tpsAndAverageTime() {
      return `${this.tps} <br/> ${this.averageTime}`;
    },
    tps() {
      return (!this.tpsInfo || !this.tpsInfo.tps) ? '0' : this.tpsInfo.tps.toLocaleString();
    },
    averageTime() {
      // ms를 소숫점 2자리에서 반올림한 초로 계산한다.
      return String((!this.tpsInfo || !this.tpsInfo.averageTime) ? 0 : Math.round(this.tpsInfo.averageTime / 10) / 100);
    },
    tpsLastUpdatedTime() {
      if (!this.tpsInfo || !this.tpsInfo.lastUpdatedTime) return 'Never updated';
      return this.$moment(new Date(this.tpsInfo.lastUpdatedTime)).format('YYYY-MM-DD HH:mm:ss');
    },
    accumulatedToday() {
      if (!this.accumulated || !this.accumulated.counts) return '0';
      return this.accumulated.counts.today.toLocaleString();
    },
    accumulatedCurrentHour() {
      if (!this.accumulated || !this.accumulated.counts) return '0';
      return this.accumulated.counts.hour.toLocaleString();
    },
    accumulatedLastUpdatedTime() {
      if (!this.accumulated || !this.accumulated.lastUpdatedTime) return 'Never updated';
      return this.$moment(new Date(this.accumulated.lastUpdatedTime)).format('YYYY-MM-DD HH:mm:ss');
    }
  },
  async mounted() {
    // console.log('statistics', this.statistics);
  },
  methods: {},
  watch: {
    statistics(val) {
      if (val.stats && val.stats.today) {
        this.statisticsChartOption.xAxis.data = val.stats.today.map((v) => this.$moment(v.executionTime).format('HH:mm'));
        this.statisticsChartOption.series[0].data = val.stats.today.map((v) => v.callCount ? v.callCount : 0);
      }
      if (val.stats && val.stats.previous) {
        this.statisticsChartOption.series[1].data = val.stats.previous.map((v) => v.callCount ? v.callCount : 0);
      }
    }
  },
};
</script>
