<!-- API의 메소드 목록 보기 -->
<template>
  <div>
    <v-data-table
      :headers="headers.methods"
      :items="filteredMethods"
      :item-class="itemRowBackground"
      class="row-pointer"
      hide-default-footer
      @click:row="viewMethod"
    >
      <template v-slot:item.methodStatusSelect="{ item }">
        <v-select
          :items="selected_methodStatus"
          v-model="item.methodStatus"
          :readonly="!$hasAnyAuthority(['API_UPDATE', 'ADMIN'])"
          @click.stop.prevent=""
          @change="changemethodStatus(item)"
          hide-details
          outlined
          dense
          style="max-width: 135px;"
        />
      </template>

      <template v-slot:item.actions="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop.prevent="viewAdditional(item)"
              v-bind="attrs"
              v-on="on"
              dense
            >
              mdi-book-open
            </v-icon>
          </template>
          <span>{{ $t('apiMethod.add') }} {{ $t('apiMethod.info') }}</span>
        </v-tooltip>
        <!-- 캐시는 GET 방식인 경우만 -->
        <v-tooltip
          v-if="item.httpMethod === 'GET' && $hasAnyAuthority(['API_MODIFY', 'ADMIN'])"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop.prevent="viewCache(item)"
              v-bind="attrs"
              v-on="on"
              dense
            >
              mdi-alpha-c-circle
            </v-icon>
          </template>
          <span>{{ $t('apiMethod.cache') }} {{ $t('apiMethod.info') }}</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop.prevent="showSwaggerUI(item)"
              v-bind="attrs"
              v-on="on"
              dense
            >
              mdi-api
            </v-icon>
          </template>
          <span>Swagger UI</span>
        </v-tooltip>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              @click.stop.prevent="openSwaggerDownloadDialog(item)"
              v-bind="attrs"
              v-on="on"
              dense
            >
              mdi-download
            </v-icon>
          </template>
          <span>Swagger CodeGEn</span>
        </v-tooltip>
        <v-tooltip
          v-if="$hasAnyAuthority(['API_DELETE', 'ADMIN'])"
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="authorities.includes(Authorities.apiDelete)"
              @click.stop.prevent="deleteMethod(item)"
              v-bind="attrs"
              v-on="on"
              dense
            >
              mdi-delete
            </v-icon>
          </template>
          <span>{{ $t('apiMethod.delete') }}</span>
        </v-tooltip>
      </template>
    </v-data-table>

    <!-- 메소드 보기 다이얼로그 -->

    <v-dialog
      v-if="dialog.methodView"
      v-model="dialog.methodView"
      scrollable
      persistent
      max-width="1200px"
      @keydown.esc="onClickCloseButtonInViewMethodDialog"
    >
      <v-card>
        <v-card-title class="text-left">
          {{ selected.method.httpMethod }}
          {{ selected.method.resourceVersion.uri }}
        </v-card-title>

        <v-card-subtitle class="mt-1">
          {{ selected.method.summary }}
        </v-card-subtitle>
        <v-divider/>
        <v-card-text>
          <v-form ref="form" v-model="valid" lazy-validation>
            <!-- Routing -->
            <v-col cols="12" class="pa-0 mb-6">
              <api-method-routing
                v-if="false"
                title="Routing"
                :targetUrl="{
                  text: $t('apiMethod.server') + ' '+$t('apiMethod.url'),
                  value: selected.method.resourceVersion.targetUrl,
                  readonly: !dialog.methodViewMode,
                  placeholder: 'http://localhost:8080/api',
                }"
                :httpMethod="{
                  text: $t('apiMethod.method'),
                  value: selected.method.httpMethod,
                  readonly: !dialog.methodViewMode,
                  placeholder: 'GET',
                }"
                :summary="{
                  text: $t('apiMethod.summary'),
                  value: selected.method.summary,
                  readonly: !dialog.methodViewMode,
                  placeholder: $t('apiMethod.summary'),
                }"
              />
              <v-card outlined tile class="mb-6">
                <v-card-subtitle class="text-left">
                  <h3 class="pl-md-2">
                    {{$t('apiMethod.tag')}}
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-show="dialog.methodViewMode != null"
                          v-on="on"
                          @click="dialog.tags = true"
                          class="float-right"
                        >
                          mdi-plus
                        </v-icon>
                      </template>
                      <span>{{$t('apiMethod.add')}} {{$t('apiMethod.tag')}}</span>
                    </v-tooltip>
                  </h3>
                </v-card-subtitle>
                <v-divider/>
                <v-card-text class="pt-5">
                  <v-simple-table v-if="methodTagType.length === 0">
                    <tBody>
                    <tr class="v-data-table__empty-wrapper">
                      <td colspan="7">{{$t('apiMethod.noDataMsg')}}.</td>
                    </tr>
                    </tBody>
                  </v-simple-table>
                  <v-chip
                    v-else
                    v-for="(name, index) in methodTagType"
                    :key="index"
                  >
                    {{ name }}
                  </v-chip>
                </v-card-text>
              </v-card>
              <v-card outlined tile>
                <v-card-subtitle class="text-left">
                  <h3 class="pl-md-2">{{$t('apiMethod.routing')}}</h3>
                </v-card-subtitle>
                <v-divider/>
                <v-card-text class="pt-5">
                  <v-simple-table>
                    <template v-slot:default>
                      <tbody>
                      <!-- Endpoint 보기 모드 -->
                      <template v-if="!dialog.methodViewMode">
                        <tr>
                          <th
                            width="10%; font-size:1rem;"
                            style="background-color: #fff"
                          >
                            {{$t('apiMethod.server')}} {{$t('apiMethod.url')}}
                          </th>
                          <td>
                            {{ selected.method.resourceVersion.targetUrl }}
                          </td>
                        </tr>
                      </template>
                      <!-- Endpoint 편집 모드 -->
                      <template v-else>
                        <tr>
                          <th
                            width="10%; font-size:1rem;"
                            style="background-color: #fff"
                          >
                            {{$t('apiMethod.server')}} {{$t('apiMethod.url')}}
                          </th>
                          <td>
                            <v-text-field
                              placeholder="http://localhost:8080"
                              outlined
                              hide-details
                              v-model="selected.method.resourceVersion.server"
                              :rules="[rules.required]"
                            />
                          </td>
                        </tr>
                        <tr>
                          <th
                            width="10%; font-size:1rem;"
                            style="background-color: #fff"
                          >
                            {{$t('apiMethod.path')}}
                          </th>
                          <td>
                            <v-text-field
                              placeholder="/api"
                              outlined
                              hide-details
                              v-model="selected.method.resourceVersion.uri"
                            />
                          </td>
                        </tr>
                      </template>
                      <!-- Method -->
                      <tr>
                        <th
                          width="10%; font-size:1rem;"
                          style="background-color: #fff"
                        >
                          {{$t('apiMethod.method')}}
                        </th>
                        <td>
                          <template v-if="!dialog.methodViewMode">
                            {{ selected.method.httpMethod }}
                          </template>
                          <v-select
                            v-else
                            placeholder="GET"
                            :items="methodItems"
                            outlined
                            hide-details
                            v-model="selected.method.httpMethod"
                            :rules="[rules.required]"
                          />
                        </td>
                      </tr>
                      <!-- Opertaion ID -->
                      <tr>
                        <th
                          width="10%; font-size:1rem;"
                          style="background-color: #fff"
                        >
                          {{$t('apiMethod.operation')}} {{$t('apiMethod.id')}}
                        </th>
                        <td>
                          <template v-if="!dialog.methodViewMode">
                            {{ selected.method.operationId }}
                          </template>
                          <v-text-field
                            v-else
                            outlined
                            hide-details
                            v-model="selected.method.operationId"
                            v-on:keypress="isLetterOrNumber($event)"
                            :rules="[rules.required]"
                          />
                        </td>
                      </tr>
                      <!-- 요약 -->
                      <tr>
                        <th
                          width="10%; font-size:1rem;"
                          style="background-color: #fff"
                        >
                          {{$t('apiMethod.summary')}}
                        </th>
                        <td>
                          <template v-if="!dialog.methodViewMode">
                            {{ selected.method.summary }}
                          </template>
                          <v-text-field
                            v-else
                            :placeholder="$t('apiMethod.summart')"
                            outlined
                            hide-details
                            v-model="selected.method.summary"
                          />
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>

            <v-card
              outlined
              tile
              v-if="(methodSecurityType != null && methodSecurityType.length != 0) || dialog.methodViewMode != null"
              class="mb-6"
            >
              <v-card-subtitle class="text-left">
                <h3 class="pl-md-2">
                  {{$t('apiMethod.certified')}}
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-show="dialog.methodViewMode != null"
                        v-on="on"
                        @click="dialog.security = true"
                        class="float-right"
                      >
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>{{$t('apiMethod.add')}} {{$t('apiMethod.certified')}}</span>
                  </v-tooltip>
                </h3>
              </v-card-subtitle>
              <v-divider/>
              <v-card-text class="pt-5">
                <v-simple-table v-if="methodSecurityType.length == 0">
                  <tBody>
                  <tr class="v-data-table__empty-wrapper">
                    <td colspan="7">{{$t('apiMethod.noDataMsg')}}</td>
                  </tr>
                  </tBody>
                </v-simple-table>
                <v-chip
                  v-else
                  v-for="name in methodSecurityType"
                  :key="name"
                  :close="dialog.methodViewMode != null"
                  @click:close="deleteMethodSecurity(methodSecurityType, name)"
                >
                  {{ name }}
                </v-chip>
              </v-card-text>
            </v-card>
            <v-card
              outlined
              tile
              class="mb-6"
              v-show="methodSecurityType.includes('oauth2')"
              v-if="(methodScopes != null && methodScopes.length != 0) || dialog.methodViewMode != null"
            >
              <v-card-subtitle>
                <h3 class="pl-md-2">
                  {{$t('apiMethod.oauth')}} {{$t('apiMethod.scope')}}
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-show="dialog.methodViewMode != null"
                        v-bind="attrs"
                        @click="dialog.scope = true"
                        v-on="on"
                        class="float-right"
                      >
                        mdi-plus
                      </v-icon>
                    </template>
                    <span>{{$t('apiMethod.add')}} {{$t('apiMethod.scope')}}</span>
                  </v-tooltip>
                </h3>
              </v-card-subtitle>
              <v-divider/>
              <v-card-text class="pt-5">
                <v-simple-table v-if="methodScopes.length == 0">
                  <tBody>
                  <tr class="v-data-table__empty-wrapper">
                    <td colspan="7">{{$t('apiMethod.noDataMsg')}}</td>
                  </tr>
                  </tBody>
                </v-simple-table>
                <v-chip
                  v-else
                  v-for="name in methodScopes"
                  :key="name"
                  :close="dialog.methodViewMode != null"
                  @click:close="methodScopes.splice(methodScopes.indexOf(name), 1)"
                >
                  {{ name }}
                </v-chip>
              </v-card-text>
            </v-card>
            <api-method-parameter
              v-if="(selected.method.parameters != null && selected.method.parameters.length != 0) || dialog.methodViewMode != null"
              :parameters="!selected.method.parameters ? [] : selected.method.parameters"
              :mode="dialog.methodViewMode"
              @change="changeMethodParameter"
            />
            <api-method-request-body
              :requestBody="!selected.method.requestBody ? {} : selected.method.requestBody"
              :mode="dialog.methodViewMode"
              @change="changeMethodRequestBody"
            />
            <api-method-responses
              :responses="!selected.method.responses ? {} : selected.method.responses"
              :mode="dialog.methodViewMode"
              @change="changeMethodResponses"
            />
          </v-form>
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn outlined @click="onClickCloseButtonInViewMethodDialog">
            {{$t('apiMethod.close')}}
          </v-btn>
          <v-btn
            v-if="!dialog.methodViewMode && authorities.includes(Authorities.apiUpdate)"
            :disabled="selected.method.methodStatus === 'service'"
            elevation="0"
            class="primary mr-1"
            @click="onClickMethodViewMode"
          >
            {{$t('apiMethod.modify')}}
          </v-btn>
          <!-- 백업 데이터 변경 자식 컴포넌트에 반영안됨 오류
          <v-btn
              v-if="dialog.methodViewMode === 'modify'"
              color="blue darken-1"
              text
              @click="showMethodViewMode"
          >
            취소
          </v-btn>
           -->
          <v-btn
            v-if="dialog.methodViewMode === 'modify'"
            elevation="0"
            class="primary mr-1"
            @click="saveMethodView"
          >
            {{$t('apiMethod.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 메소드 추가 정보 다이얼로그 -->
    <v-dialog
      v-if="dialog.additional"
      v-model="dialog.additional"
      persistent
      max-width="700px"
      @keydown.esc="dialog.additional = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left">{{$t('apiMethod.add')}} {{$t('apiMethod.info')}}</v-card-title>
        <v-divider/>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" v-if="dialog.additionalMode != null">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      class="float-right"
                      v-bind="attrs"
                      v-on="on"
                      @click="addAdditional"
                    >mdi-plus
                    </v-icon>
                  </template>
                  <span>{{$t('apiMethod.add')}}</span>
                </v-tooltip>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="additionalHeaders"
                  :items="extensionItems"
                >
                  <template v-slot:item.extKey="{ item }">
                    <template v-if="!dialog.additionalMode">
                      {{ item.extKey }}
                    </template>
                    <v-text-field
                      v-else
                      v-model="item.extKey"
                      outlined
                      hide-details
                    />
                  </template>
                  <template v-slot:item.extValue="{ item }">
                    <template v-if="!dialog.additionalMode">
                      {{ item.extValue }}
                    </template>
                    <!-- <v-text-field
                      v-else
                      v-model="item.extValue"
                      outlined
                      hide-details
                    /> -->
                    <v-textarea
                      v-else
                      v-model="item.extValue"
                      outlined
                      hide-details
                    />
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip top v-if="dialog.additionalMode != null">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          @click.stop.prevent="deleteAdditional(item)"
                          v-bind="attrs"
                          v-on="on"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>{{$t('apiMethod.delete')}}</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-if="
              !dialog.additionalMode &&
              authorities.includes(Authorities.apiUpdate)
            "
            outlined
            @click="closeAdditional"
          >
            {{$t('apiMethod.close')}}
          </v-btn>
          <v-btn
            v-if="
              !dialog.additionalMode &&
              authorities.includes(Authorities.apiUpdate)
            "
            class="primary"
            @click="modifyAdditional"
          >
            {{$t('apiMethod.modify')}}
          </v-btn>
          <v-btn
            v-if="dialog.additionalMode === 'modify'"
            outlined
            @click="cancelAdditional"
          >
            {{$t('apiMethod.cancel')}}
          </v-btn>

          <v-btn
            v-if="dialog.additionalMode === 'modify'"
            class="primary"
            @click="saveAdditional"
          >
            {{$t('apiMethod.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 캐시 정보 다이어로그 -->
    <v-dialog
      v-if="dialog.cache"
      v-model="dialog.cache"
      persistent
      max-width="500px"
      @keydown.esc="dialog.cache = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left">{{$t('apiMethod.cache')}} {{$t('apiMethod.info')}}</v-card-title>
        <v-divider/>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <template v-if="!dialog.cacheMode">
                  <v-simple-table class="elevation-0">
                    <template v-slot:default>
                      <tbody>
                      <tr>
                        <th style="width: 110px">{{$t('apiMethod.cachedOrNotCache')}}</th>
                        <td>
                          <v-select
                            :items="selected_useCache"
                            v-model="selected.method.useCache"
                            outlined
                            readonly
                            details
                            hide-details
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style="width: 110px">{{$t('apiMethod.cache')}} {{$t('apiMethod.restrictions')}}</th>
                        <td>
                          <v-text-field
                            v-model="selected.method.cacheLimit"
                            outlined
                            hide-details
                            readonly
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{{$t('apiMethod.cache')}} {{$t('apiMethod.expiration')}}</th>
                        <td colspan="3">
                          <v-text-field
                            v-model="selected.method.cacheExpire"
                            outlined
                            hide-details
                            readonly
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{{$t('apiMethod.maximum')}} {{$t('apiMethod.numberOfNodes')}}</th>
                        <td colspan="3">
                          <v-text-field
                            v-model="selected.method.cacheNodeMax"
                            outlined
                            hide-details
                            readonly
                          />
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
                <template v-if="dialog.cacheMode">
                  <v-simple-table class="elevation-0">
                    <template v-slot:default>
                      <tbody>
                      <tr>
                        <th style="width: 110px">{{$t('apiMethod.cachedOrNotCache')}}</th>
                        <td>
                          <v-select
                            :items="selected_useCache"
                            v-model="newObject.method.useCache"
                            outlined
                            details
                            hide-details
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style="width: 110px">{{$t('apiMethod.cache')}} {{$t('apiMethod.restrictions')}}</th>
                        <td>
                          <v-text-field
                            v-model="newObject.method.cacheLimit"
                            outlined
                            hide-details
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{{$t('apiMethod.cache')}} {{$t('apiMethod.expiration')}}</th>
                        <td colspan="3">
                          <v-text-field
                            v-model="newObject.method.cacheExpire"
                            outlined
                            hide-details
                          />
                        </td>
                      </tr>
                      <tr>
                        <th>{{$t('apiMethod.maximum')}} {{$t('apiMethod.numberOfNodes')}}</th>
                        <td colspan="3">
                          <v-text-field
                            v-model="newObject.method.cacheNodeMax"
                            outlined
                            hide-details
                          />
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined @click="closeCache">{{$t('apiMethod.close')}}</v-btn>
          <v-btn v-if="!dialog.cacheMode" class="primary" @click="modifyCache">
            {{$t('apiMethod.modify')}}
          </v-btn>
          <v-btn
            v-if="dialog.cacheMode === 'modify'"
            class="primary"
            @click="saveCache"
          >
            {{$t('apiMethod.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Scope Dialog-->
    <v-dialog v-model="dialog.scope" max-width="500px">
      <v-card>
        <v-card-title class="text-h4 text-left">{{$t('apiMethod.add')}} {{$t('apiMethod.scope')}}</v-card-title>
        <v-divider/>
        <v-card-text>
          {{ methodScopes }}
          <v-list>
            <v-list-item-group v-model="methodScopes" multiple>
              <v-list-item
                v-for="scope in scopes"
                :key="scope.scope"
                :value="scope.scope"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"/>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title
                    >{{ scope.name }} | {{ scope.scope }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                    >{{ scope.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined @click="dialog.scope = false">{{$t('apiMethod.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Security Dialog-->
    <v-dialog v-model="dialog.security" max-width="500px">
      <v-card>
        <v-card-title class="text-h4 text-left">{{$t('apiMethod.add')}} {{$t('apiMethod.certified')}}</v-card-title>
        <v-divider/>
        <v-card-text>
          <v-list>
            <v-list-item-group v-model="methodSecurityType" multiple>
              <v-list-item
                v-for="(schema, name) in securities"
                :key="name"
                :value="name"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"/>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined @click="dialog.security = false"> {{$t('apiMethod.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Tag Dialog-->
    <v-dialog v-model="dialog.tags" max-width="500px">
      <v-card>
        <v-card-title class="text-h4 text-left">{{$t('apiMethod.add')}} {{$t('apiMethod.tag')}}</v-card-title>
        <v-divider/>
        <v-card-text>
          {{ methodTagType }}
          <v-list>
            <v-list-item-group v-model="methodTagType" multiple>
              <v-list-item
                v-for="(tag, index) in tags"
                :key="index.name"
                :value="tag.name"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"/>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ tag.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined @click="dialog.tags = false"> {{$t('apiMethod.close')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 메소드 상세 정보 -->
    <v-dialog
      v-if="selected.method && dialog.methodDetail"
      v-model="dialog.methodDetail"
      max-width="1000px"
    >
      <v-card>
        <v-card-title
          class="grey text-left"
        >
          <v-chip>
            {{ selected.method.httpMethod }}
          </v-chip>
          {{ selected.method.uri }}
        </v-card-title>
        <v-card-text>
          <api-method-detail-view2
            ref="refMethodDetail"
            :method-id="selected.method.id"
            :edit-mode="dialog.methodDetailEditMode"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            v-if="!dialog.methodDetailEditMode"
            outlined
            @click="dialog.methodDetail = false; dialog.methodDetailEditMode = false"
          >
            {{$t('apiMethod.close')}}
          </v-btn>
          <v-btn
            v-if="!dialog.methodDetailEditMode && $hasAnyAuthority(['API_UPDATE', 'ADMIN'])"
            class="primary"
            @click="dialog.methodDetailEditMode = true"
          >
            {{$t('apiMethod.modify')}}
          </v-btn>
          <v-btn
            v-if="dialog.methodDetailEditMode"
            outlined
            @click="cancelMethodEdit"
          >
            {{$t('apiMethod.cancel')}}
          </v-btn>
          <v-btn
            v-if="dialog.methodDetailEditMode"
            class="primary"
            @click="saveMethodDetail"
          >
            {{$t('apiMethod.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<style>
table > tbody > tr > th {
  background-color: rgb(245, 245, 245);
  margin-top: 4px;
  margin-bottom: 4px;
}

.style-1 {
  background-color: rgb(208, 208, 211) !important;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.5);
  border: solid 2px #151826;
}
</style>

<script>
import request from '@/utils/request';
import { mapActions, mapGetters } from 'vuex';
import ApiMethodParameter from '@/components/ApiMethodParameter';
import ApiMethodRequestBody from '@/components/ApiMethodRequestBody';
import ApiMethodResponses from '@/components/ApiMethodResponses';
import ApiMethodRouting from '@/components/ApiMethodRouting';
import Authorities from '@/locale/ko_KR/authorities';
import ApiMethodDetailView2 from '@/components/api/ApiMethodDetailView2';
// import Vue from "vue";
// import * as config from '@/config/config';

const methodItems = [
  {
    text: 'GET',
    value: 'GET',
  },
  {
    text: 'POST',
    value: 'POST',
  },
  {
    text: 'PUT',
    value: 'PUT',
  },
  {
    text: 'DELETE',
    value: 'DELETE',
  },
  {
    text: 'PATCH',
    value: 'PATCH',
  },
];
const HEADER_METHODS = [
  {
    text: 'apiMethod.method',
    value: 'httpMethod',
  },
  // {
  //   text: "URI",
  //   value: "uri",
  // },
  {
    text: 'apiMethod.summary',
    value: 'summary',
  },
  {
    text: 'apiMethod.security',
    value: 'securityType',
  },
  {
    text: 'apiMethod.scope',
    value: 'oauthScopes',
  },
  {
    text: 'apiMethod.operationId',
    value: 'operationId',
  },
  {
    text: 'apiMethod.version',
    value: 'version',
  },
  {
    text: 'apiMethod.status',
    value: 'methodStatusSelect',
  },
  {
    text: 'apiMethod.note',
    value: 'actions',
  },
];
const mediaTypeItems = [
  {
    text: 'application/json',
    value: 'application/json',
  },
  {
    text: 'application/xml',
    value: 'application/xml',
  },
];
const propertyTypeItems = [
  {
    text: 'string',
    value: 'string',
  },
  {
    text: 'integer',
    value: 'integer',
  },
  {
    text: 'boolean',
    value: 'boolean',
  },
  {
    text: 'number',
    value: 'number',
  },
];
const inItems = [
  {
    text: 'apiMethod.queryString',
    value: 'query',
  },
  {
    text: 'apiMethod.pathVariable',
    value: 'path',
  },
  {
    text: 'apiMethod.header',
    value: 'header',
  },
];
const requiredItems = [
  {
    text: 'apiMethod.required',
    value: true,
  },
  {
    text: 'apiMethod.notRequired',
    value: false,
  },
];
const additionalHeaders = [
  {
    text: 'apiMethod.propertyKey',
    value: 'extKey',
  },
  {
    text: 'apiMethod.propertyValue',
    value: 'extValue',
  },
  {
    text: 'apiMethod.note',
    value: 'actions',
  },
];

const selectedUseCache = [
  {
    text: 'apiMethod.enable',
    value: 'Y',
  },
  {
    text: 'apiMethod.disable',
    value: 'N',
  },
];

const selectedMethodStatus = [
  {
    text: 'apiMethod.develop',
    value: 'develop',
  },
  {
    text: 'apiMethod.service',
    value: 'service',
  },
  {
    text: 'apiMethod.deprecate',
    value: 'deprecate',
  },
  {
    text: 'apiMethod.outOfService',
    value: 'out_of_service',
  },
];

// const i18n = config.initI18N(Vue);

export default {
  name: 'ApiMethodListView',
  props: {
    methods: {
      type: Array,
    },
    contextpath: {
      type: String,
    },
    methodStatus: {
      type: String,
    }
  },
  computed: {
    ...mapGetters(['authorities','currentLanguage']),
    extensionItems() {
      return !this.dialog.additionalMode
        ? this.methodExtensions
        : this.newExtensions;
    },
    filteredMethods() {
      if (this.methodStatus) {
        return this.methods.filter(m => m.methodStatus === this.methodStatus);
      } else {
        return this.methods;
      }
    }
  },
  components: {
    ApiMethodDetailView2,
    'api-method-parameter': ApiMethodParameter,
    'api-method-request-body': ApiMethodRequestBody,
    'api-method-responses': ApiMethodResponses,
    'api-method-routing': ApiMethodRouting,
  },
  data: function() {
    return {
      rules: {
        required(v) {
          return !!v || this.$i18n.t('apiMethod.required');
        },
      },
      valid: true,
      Authorities: Authorities,
      selected_useCache: this.$translate(selectedUseCache),
      selected_methodStatus: this.$translate(selectedMethodStatus),
      dialog: {
        methodView: false,
        additional: false,
        additionalMode: null,
        methodViewMode: null,
        scope: false,
        security: false,
        cache: false,
        cacheMode: null,
        tags: false,
        methodDetail: false,
        methodDetailEditMode: false,
      },
      headers: {
        methods: this.$translate(HEADER_METHODS),
      },
      selected: {
        method: null,
      },
      newObject: {
        method: null,
      },
      mediaTypeItems: this.$translate(mediaTypeItems),
      propertyTypeItems: this.$translate(propertyTypeItems),
      panel: [0],
      inItems: this.$translate(inItems),
      requiredItems: this.$translate(requiredItems),
      scopes: [],
      methodScopes: [],
      methodSecurityType: [],
      methodTagType: [],
      securities: {},
      selectedMethodBackup: {},
      methodExtensions: [],
      newExtensions: [],
      additionalHeaders: this.$translate(additionalHeaders),
      methodItems: this.$translate(methodItems),
      selectedMethodServer: '',
      tags: [],
      isSaved: false,
    };
  },
  methods: {
    ...mapActions(['addAlert']),
    itemRowBackground(item) {
      if (item.methodStatus === 'service') return 'style-1';
    },
    // method 보기
    async viewMethod(method) {
      this.selected.method = method;
      this.selectedMethodBackup = JSON.parse(JSON.stringify(method));

      this.selected.method.tags = await fetchMethodProperty(
        this.selected.method.id,
        'tags'
      );
      this.selected.method.parameters = await fetchMethodProperty(
        this.selected.method.id,
        'parameters'
      );
      this.selected.method.requestBody = await fetchMethodProperty(
        this.selected.method.id,
        'request-body'
      );
      this.selected.method.responses = await fetchMethodProperty(
        this.selected.method.id,
        'responses'
      );
      // this.dialog.methodView = true;
      this.dialog.methodDetail = true;
      await this.fetchScopes();
      await this.fetchSecurity();
      await this.fetchByTag();
      this.methodScopes = !this.selected.method.oauthScopes ? [] : this.selected.method.oauthScopes.split(' ');
      this.methodSecurityType = !this.selected.method.securityType ? [] : this.selected.method.securityType.split(' ');
      this.methodTagType = this.selected.method.tags.map((tag) => tag.name);
    },
    async deleteMethod(method) {
      var message = '';
      message = method.summary === undefined ? method.uri : method.summary;

      var deleteMethodMsg = this.$t('apiMethod.deleteMethodMsg');
      const res = await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_warning.svg" />
        <br/><b>'${message}' ${deleteMethodMsg}</b></div>`,
        confirmYn: true,
      },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});

      if (res) {
        await request({
          url: `/apix/apis/methods/${method.id}/delete`,
          method: 'delete',
        });

        var deletedMethodMsg = this.$t('apiMethod.deletedMethodMsg');
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;">
                      <img max-width="100%" src="static/inzent/ico_complete.svg" /><br/>
                      <b>${deletedMethodMsg}</b>
                    </div>`,
        },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});

        const index = this.methods.indexOf(method);
        if (index >= 0) this.methods.splice(index, 1);

        this.isSaved = true;
      }
    },
    /// cache
    viewCache(method) {
      this.selected.method = method;
      this.dialog.cache = true;
    },
    closeCache() {
      this.dialog.cache = false;
      this.dialog.cacheMode = null;
      this.selected.method = null;
    },
    modifyCache() {
      this.newObject.method = { ...this.selected.method };
      this.dialog.cacheMode = 'modify';
    },
    async saveCache() {
      const { data } = await request({
        url: `/apix/apis/methods/${this.newObject.method.id}/cache`,
        method: 'put',
        data: this.newObject.method,
      });
      const index = this.methods.indexOf(this.selected.method);
      if (index >= 0) {
        this.methods.splice(index, 1, data); // 수정대상을 삭제 후 서버로부터 넘겨받은 것을 해당 위치에 저장
      } else {
        this.methods.push(data); // 마지막에 추가
      }
      this.newObject.method = null;
      this.closeCache();
    },
    /// additional
    async viewAdditional(method) {
      this.selected.method = method;
      const { data } = await request({
        url: `/apix/apis/methods/${this.selected.method.id}/extensions`,
        method: 'get',
      });
      this.methodExtensions = data;
      this.dialog.additional = true;
    },
    closeAdditional() {
      this.cancelAdditional();
      this.dialog.additional = false;
      this.methodExtensions = [];
      this.selected.method = null;
    },
    cancelAdditional() {
      this.newExtensions = [];
      this.dialog.additionalMode = null;
    },
    modifyAdditional() {
      this.newExtensions = JSON.parse(JSON.stringify(this.methodExtensions));
      this.dialog.additionalMode = 'modify';
    },
    addAdditional() {
      if (!this.newExtensions) this.newExtensions = [];
      this.newExtensions.push({});
    },
    deleteAdditional(item) {
      const index = this.newExtensions.indexOf(item);
      this.newExtensions.splice(index, 1);
    },
    async saveAdditional() {
      try {
        const { data } = await request({
          url: `/apix/apis/methods/${this.selected.method.id}/extensions`,
          method: 'put',
          data: this.newExtensions,
        });
        this.methodExtensions = data;
        //this.closeAdditional();
        this.cancelAdditional();
        var savedMsg = this.$t('apiMethod.savedMsg');
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_complete.svg" /><br/><b>${savedMsg}</b></div>`,
        },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
      } catch (error) {
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_fail.svg" />
        <br/><b>'${error.message}'</b></div>`,
        },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
      }
    },
    async changemethodStatus(method) {
      var modifiedMethodLifeCycleMsg = this.$t('apiMethod.modifiedMethodLifeCycleMsg');
      var modifiedMethodLifeCycleContinueMsg = this.$t('apiMethod.modifiedMethodLifeCycleContinueMsg');
      const res = await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_warning.svg" />
        <br/><b>${modifiedMethodLifeCycleMsg} <br/> ${modifiedMethodLifeCycleContinueMsg}</b></div>`,
        confirmYn: true,
      },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
      if (res) {
        this.selected.method = method;
        try {
          const { data } = await request({
            url: `/apix/apis/methods/${this.selected.method.id}/status`,
            method: 'put',
            data: this.selected.method,
          });
          //await this.viewMethod(data);
          const index = this.methods.indexOf(this.selected.method);
          if (index >= 0) {
            this.methods.splice(index, 1, data); // 수정대상을 삭제 후 서버로부터 넘겨받은 것을 해당 위치에 저장
          } else {
            this.methods.push(data); // 마지막에 추가
          }
        } catch (error) {
          await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_fail.svg" />
        <br/><b>'${error.message}'</b></div>`,
          },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
        }
      } else {
        return 0;
      }
      this.isSaved = true;
    },
    async onClickCloseButtonInViewMethodDialog() {
      this.dialog.methodView = false;
      this.selected.method = null;
      this.dialog.methodViewMode = null;
      this.selectedMethodServer = null;
      if (this.isSaved) await this.$emit('reload');
      this.isSaved = false;
    },
    showSwaggerUI(item) {
      this.$emit('showSwaggerUI', { methodId: item.id });
    },
    openSwaggerDownloadDialog(item) {
      this.$emit('swagger-codegen', 'method', item);
    },
    getTest(value) {
      return value;
    },
    updateRequestBodyContent(source, target) {
      //test(selected.method.requestBody.content, $event, name)"

      this.selected.method.requestBody.content[target] =
        this.selected.method.requestBody.content[source];
      delete this.selected.method.requestBody.content[source];


      return target;
    },
    changeMethodParameter(parameters) {
      this.selected.method.parameters = [...parameters];
    },
    changeMethodRequestBody(requestBody) {
      this.selected.method.requestBody = { ...requestBody };
    },
    changeMethodResponses(responses) {
      this.selected.method.responses = { ...responses };
    },
    async saveMethodView() {
      var requiredFiledMsg = this.$t('apiMethod.requiredFiledMsg')
      var modifiedMsg = this.$t('apiResource.modifiedMsg');
      var pathMsg = this.$t('apiResource.pathMsg', { path: `${this.contextpath}` });
      if (!this.$refs.form.validate()) {
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
                    src="static/inzent/ico_fail.svg" />
                    <br/><b>${requiredFiledMsg}</b></div>`,
        },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
        return;
      }
      let requestData = {};

      //targetUri
      requestData.resourceVersion = {};

      //targetUrl = server + url
      // if(this.selected.method.resourceVersion.targetUrl.startsWith('http')) {
      //   this.selected.method.resourceVersion.targetUrl =
      //     this.selected.method.resourceVersion.server +
      //     this.selected.method.resourceVersion.uri;
      // }
      // else{
      //   this.selected.method.resourceVersion.targetUrl =
      //     this.selected.method.resourceVersion.server;
      // }
      //requestData.resourceVersion.targetUrl = this.selected.method.resourceVersion.targetUrl;

      if (!this.selected.method.resourceVersion.uri.startsWith(this.contextpath + '/') && this.selected.method.resourceVersion.uri != this.contextpath && this.contextpath != '/') {
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
                    src="static/inzent/ico_fail.svg" />
                    <br/><b>${pathMsg}</b></div>`,
        },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
        return;
      }

      this.selected.method.resourceVersion.targetUrl = this.selected.method.resourceVersion.server;
      requestData.resourceVersion.targetUrl = this.selected.method.resourceVersion.server;

      if (this.selected.method.resourceVersion.uri.length === 0 || !this.selected.method.resourceVersion.uri.startsWith('/'))
        this.selected.method.resourceVersion.uri = '/' + this.selected.method.resourceVersion.uri;
      requestData.resourceVersion.uri = this.selected.method.resourceVersion.uri;

      //httpMethod
      requestData.httpMethod = this.selected.method.httpMethod;

      // operation id
      requestData.operationId = this.selected.method.operationId;

      //summary
      requestData.summary = this.selected.method.summary;

      //securityType
      if (this.methodSecurityType.length != 0) requestData.securityType = this.methodSecurityType.join(' '); // TODO

      //oauthScopes
      if (this.methodScopes.length != 0) requestData.oauthScopes = this.methodScopes.join(' '); // TODO

      //parameters
      if (this.selected.method.parameters != null && this.selected.method.parameters != '')
        requestData.parameters = this.selected.method.parameters;

      //requestBody
      if (this.selected.method.requestBody != null && this.selected.method.requestBody != '')
        requestData.requestBody = this.selected.method.requestBody;

      //responses
      if (this.selected.method.responses != null && this.selected.method.responses != '')
        requestData.apiResponses = this.selected.method.responses;

      // 태그 수정 보류
      // if (this.methodTagType.length != 0)
      //   requestData.tags = this.methodTagType;

      requestData.tags = this.methodTagType;
      if (this.selectedMethodBackup.resourceVersion.targetUrl !== requestData.resourceVersion.targetUrl) {
        var modifedServerUrlAndOtherMethodChangedMsg = this.$t('apiResource.modifedServerUrlAndOtherMethodChangedMsg');
        var modifiedMethodLifeCycleContinueMsg = this.$t('apiResource.modifiedMethodLifeCycleContinueMsg');
        const res = await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;">
                      <img max-width="100%" src="static/inzent/ico_warning.svg" /> <br/>
                      <b>${modifedServerUrlAndOtherMethodChangedMsg}<br/> ${modifiedMethodLifeCycleContinueMsg}</b>
                    </div>`,
          confirmYn: true,
        },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
        if (res) {
          try {
            await request({
              url: `/apix/apis/methods/${this.selected.method.id}/detail`,
              method: 'put',
              data: requestData,
            });
            // this.add
            await this.$confirm({
              message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
                        src="static/inzent/ico_complete.svg" /><br/><b>${modifiedMsg}</b></div>`,
            },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
            //await this.viewMethod(data);
          } catch (error) {
            await this.$confirm({
              message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
                        src="static/inzent/ico_fail.svg" />
                        <br/><b>'${error.message}'</b></div>`,
            },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
          }
        } else {
          return 0;
        }
      } else {
        try {
          await request({
            url: `/apix/apis/methods/${this.selected.method.id}/detail`,
            method: 'put',
            data: requestData,
          });
          // this.add
          await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
                      src="static/inzent/ico_complete.svg" /><br/><b>${modifiedMsg}</b></div>`,
          },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
          //await this.viewMethod(data);
        } catch (error) {
          await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
                      src="static/inzent/ico_fail.svg" />
                      <br/><b>'${error.message}'</b></div>`,
          },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});

          //1. reset select method
          //this.viewMethod(this.selectedMethodBackup);

          //2. reset methods
          //let backupIdx = this.methods.map(m=> m.id).indexOf(this.selectedMethodBackup.id);
          //this.methods.splice(backupIdx, 1, this.selectedMethodBackup) ;

          // 수정 취소 처리 필요 (파라미터 정보 등  reset 안됨)
          //this.onClickCloseButtonInViewMethodDialog();
        }
      }
      this.dialog.methodViewMode = null;
      this.isSaved = true;
    },
    async fetchScopes() {
      const { data } = await request({
        url: '/apix/scopes',
        method: 'get',
      });
      this.scopes = data;
    },
    async fetchSecurity() {
      const { data } = await request({
        url: '/apix/apis/securities',
        method: 'get',
      });
      this.securities = data;
    },
    async fetchByTag() {
      const { data } = await request({
        url: '/apix/apis/tags',
      });
      this.tags = data;
    },
    // deleteMethodScopes(name){

    // }
    deleteMethodSecurity(methodSecurityType, name) {
      methodSecurityType.splice(methodSecurityType.indexOf(name), 1);
      if (!methodSecurityType.includes('oauth2')) {
        this.methodScope = [];
      }
      // apiMethod.securities.splice(apiMethod.securities.indexOf(name), 1);
      // if (!apiMethod.securities.includes("oauth2")) {
      //   apiMethod.scopes = [];
      // }
    },
    deleteMethodTag(methodTagType, name) {
      methodTagType.splice(methodTagType.indexOf(name), 1);
    },
    onClickMethodViewMode() {
      //let split = this.selected.method.resourceVersion.targetUrl.split(
      //  this.selected.method.resourceVersion.uri
      //);
      //this.selected.method.resourceVersion.server = split[0];

      this.selected.method.resourceVersion.server = this.selected.method.resourceVersion.targetUrl;

      this.dialog.methodViewMode = 'modify';
    },
    isLetterOrNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[a-zA-Z0-9-_$^&*%@#]*$/.test(char)) return true;
      else e.preventDefault();
    },
    async saveMethodDetail() {
      await this.$refs.refMethodDetail.save();
      this.dialog.methodDetailEditMode = false;
      this.$emit('refresh');
    },
    async cancelMethodEdit() {
      await this.$refs.refMethodDetail.cancel();
      this.dialog.methodDetailEditMode = false;
    }
  },
  watch: {
    currentLanguage() {
      this.headers.methods = this.$translate(HEADER_METHODS);
      this.mediaTypeItems = this.$translate(mediaTypeItems);
      this.propertyTypeItems = this.$translate(propertyTypeItems);
      this.inItems =  this.$translate(inItems);
      this.requiredItems = this.$translate(requiredItems);
      this.additionalHeaders = this.$translate(additionalHeaders);
      this.methodItems = this.$translate(methodItems);
      this.selected_useCache= this.$translate(selectedUseCache);
      this.selected_methodStatus= this.$translate(selectedMethodStatus);
    },
  },
};

const fetchMethodProperty = async (id, propertyName) => {
  const { data } = await request({
    url: `/apix/apis/methods/${id}/${propertyName}`,
  });
  return data;
};
</script>
