import store from '@/store';

let isAdmin = {
  bind(el) {
    const isAdmin = store.getters && store.getters.isAdmin;
    if (!isAdmin) {
      el.style.display = 'none';
    }
  }
}

const install = function (Vue) {
  Vue.directive('is-admin', isAdmin);
}

isAdmin.install = install;
export default isAdmin;
