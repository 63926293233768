<template>
  <v-footer id="dashboard-core-footer">
    <v-row align="center" no-gutters>
      <v-col
        v-for="(link, i) in links"
        :key="i"
        class="footer_menu"
        cols="auto"
      >
        <a
          :href="link.href"
          class="item"
          rel="noopener"
          target="_blank"
          v-text="link.text"
        />
      </v-col>

      <v-spacer class="hidden-sm-and-down" />

      <v-col cols="12" sm="auto">
        <div class="copyright">
          &copy; Copyright© Inzent Corp. All rights reserved.
        </div>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import {mapGetters} from "vuex";

const links= [
    {
      href: "#",
      text: 'temp.termsAndConditions',
    },
  {
    href: "#",
    text: 'temp.personalDataProcessingPolicy',
},
];

export default {
  name: "DashboardCoreFooter",
  data: function()
    {
      return {
        links: this.$translate(links)
      };
  },
  computed:{
    ...mapGetters(['currentLanguage']),
  },
  watch:{
    currentLanguage(){
      this.links = this.$translate(links);
    }
  }
};
</script>

<style lang="sass">
#dashboard-core-footer
  a
    font-size: .825rem
    font-weight: 500
    text-decoration: none
    text-transform: uppercase
</style>
