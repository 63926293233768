import store from '@/store';

let hasAuth = {
  bind(el, binding) {
    const { value } = binding;
    const hasAuthority = store.getters && store.getters.hasAuthority;
    if (!value || !hasAuthority(value)) {
      // el.parentNode && el.parentNode.removeChild(el);
      el.style.display = 'none';
    }
  }
}

const install = function (Vue) {
  Vue.directive('has-auth', hasAuth);
}

hasAuth.install = install;
export default hasAuth;
