<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
      >
        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>{{ $t('transaction.uri') }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-chip
                    color="green"
                    small
                    outlined
                  >
                    {{ log.method }}
                  </v-chip>
                  {{ log.requestUri }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
        <!--<v-card>-->
        <!--  <v-card-subtitle>URI</v-card-subtitle>-->
        <!--  <v-card-text>-->
        <!--    {{ selectedLog.requestUri }}-->
        <!--  </v-card-text>-->
        <!--</v-card>-->
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="6"
      >
        <v-card>
          <v-card-title>{{ $t('transaction.request') }} {{ $t('transaction.data') }}</v-card-title>
          <v-card-text>
            <transaction-data-header-body
              :queries="logData.request.quries"
              :headers="logData.request.headers"
              :body="logData.request.body"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="6"
      >
        <v-card>
          <v-card-title>{{ $t('transaction.response') }} {{ $t('transaction.data') }}</v-card-title>
          <v-card-text
            v-if="logData.response"
          >
            <transaction-data-header-body
              :headers="logData.response.headers"
              :body="logData.response.body"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="mt-0"
    >
      <v-spacer/>
      <v-col
        cols="auto"
      >
        <v-btn
          v-if="$hasAnyAuthority('STATISTICS_TEST') && logData"
          @click="dialog.test = true"
          color="primary"
        >
          {{ $t('transaction.test') }}
        </v-btn>
      </v-col>
    </v-row>
    <!--<v-row>-->
    <!--  <v-col-->
    <!--    cols="12"-->
    <!--  >-->
    <!--    <v-spacer/>-->
    <!--    <v-btn v-if="logData" @click="dialog.data = true" outlined>-->
    <!--      거래 데이터-->
    <!--    </v-btn>-->
    <!--  </v-col>-->
    <!--</v-row>-->

    <!-- 테스트 다이얼로그 -->
    <v-dialog
      v-if="dialog.test && logData"
      v-model="dialog.test"
      persistent
      max-width="800px"
      @keydown.esc="dialog.test = false; testResponse = null"
    >
      <v-card>
        <v-card-title
          class="text-h4 text-left"
        >
          {{ $t('transaction.transaction') }} {{ $t('transaction.test') }}
        </v-card-title>
        <v-card-text>
          <v-container fluid>
            <v-row>
              <v-col
                cols="12"
              >
                <v-card>
                  <v-card-text>
                    <v-list>
                      <v-list-item>
                        <v-list-item-content>
                          <v-list-item-title>{{ $t('transaction.uri') }}</v-list-item-title>
                          <v-list-item-subtitle>
                            <v-chip
                              color="green"
                              small
                            >
                              {{ log.method }}
                            </v-chip>
                            {{ log.requestUri }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <transaction-data-header-body
                      ref="testHeaderBody"
                      :queries="logData.request.quries"
                      :headers="logData.request.headers"
                      :body="logData.request.body"
                      editable
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-row
              v-if="testResponse"
            >
              <v-col
                cols="12"
              >
                <v-card>
                  <v-card-subtitle>{{ $t('transaction.response') }} {{ $t('transaction.data') }}</v-card-subtitle>
                  <v-card-text>
                    <transaction-data-header-body
                      :headers="testResponse.headers"
                      :body="testResponse.body"
                    />
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            @click="testApi"
            color="primary"
          >
            {{ $t('transaction.send') }}
          </v-btn>
          <v-btn @click="dialog.test = false; testResponse = null" outlined>
            {{ $t('transaction.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import TransactionDataHeaderBody from '@/components/TransactionDataHeaderBody';
import request from '@/utils/request';
import { unescape } from '@/utils';

export default {
  name: 'TransactionData',
  components: { TransactionDataHeaderBody },
  props: {
    log: {
      type: Object,
      required: true,
    },
    logData: {
      type: Object,
    }
  },
  data: () => ({
    dialog: {
      test: false,
    },
    testResponse: null,
  }),
  computed: {
    transactionId() {
      return this.log.txId ? this.log.txId : this.log.logSeq;
    }
  },
  methods: {
    // API 테스트를 수행한다.
    async testApi() {
      this.testResponse = null;
      const { data } = await request({
        url: `/apix/transactions/${this.transactionId}/test`,
        method: 'post',
        data: {
          method: this.log.method,
          uri: this.log.requestUri,
          quries: this.$refs.testHeaderBody.copyedQueries,
          headers: this.$refs.testHeaderBody.copyedHeaders,
          body: this.$refs.testHeaderBody.copyedBody,
        },
      });
      // XML 태그들이 escape되어 들어온다. 그래서 unescape를 수행한다.
      data.body = unescape(data.body);
      this.testResponse = data;
    },
  }
}
</script>
