import Vue from 'vue';

import Router from 'vue-router';

import Layout from '@/views/layout/TheLayout.vue';
// import LayoutMain from "@/views/layout/TheLayoutMain.vue";
/* Router Modules */
import authRouter from './modules/auth';
import commonRouter from './modules/common';
// import errorsRouter from './modules/errors';
// import permissionRouter from './modules/permission';
// import vuetifyRouter from '@/demo/router/vuetify';
// import nestedRouter from '@/demo/router/nested';
// // import componentsRouter from '@/demo/router/components';
// import statisticsRouter from "@/router/modules/statistics";
import supportRouter from '@/router/modules/support';
// import statisticsRouter from '@/router/modules/statistics';
// import mydataRouter from '@/router/modules/mydata';
// import userConstantRoutes from '@/router/modules/userMenu';
// import adminConstantRoutes from '@/router/modules/adminMenu';
import Authorities from '@/locale/ko_KR/authorities';
import NotAllowed from '@/views/NotAllowed';

Vue.use(Router);

/** note: sub-menu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 */

/**
 * hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu, whatever its
 *                                child routes length
 *                                if not set alwaysShow, only more than one route under the children
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    will control the page roles (you can set multiple roles)
    title: 'title'               the name show in sub-menu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar
    [expected] noCache: true     if true, the page will no be cached(default is false)
    [expected] breadcrumb: false if false, the item will hidden in breadcrumb(default is true)
    [expected] affix: true       if true, the tag will affix in the tags-view
  }
 */

//** admin이면 관리자 매뉴가 보이고, 이외 사용자는 user매뉴로 보인다. ** //
// var routes;
// var user = JSON.parse(localStorage.getItem('vva_user')).user;
// if(user === 'admin')
//   routes = adminConstantRoutes;
// else
//   routes = userConstantRoutes;
// export const constantRoutes = routes;

export const constantRoutes = [
  //기존 constantRoutes -> asyncRoutes 으로 이동 21.02.25
  ...authRouter,
  ...commonRouter,
  {
    path: '/forbidden',
    component: NotAllowed,
    hidden: true,
  },
];

export default new Router({
  // mode: 'history', // gh-pages
  scrollBehavior: () => ({y: 0}),
  routes: constantRoutes
});

export const asyncRoutes = [
  /** When your routing table is too long, you can split it into small modules */
  // permissionRouter,
  // vuetifyRouter,
  // ...componentsRouter,
  // nestedRouter,
  // errorsRouter,
  // { path: '*', redirect: '/error/404', hidden: true },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/monitoring',
        component: () => import('@/views/dashboard/MonitoringView'),
        name: 'menu.monitoring',
        meta: {
          title: 'monitoring.monitoring',
          icon: 'mdi-monitor-dashboard',
          noCache: true,
          affix: true,
        }
      }
    ],
    meta: {
      authorities: ['MONITORING_VIEW', 'ADMIN']
    }
  },
  // {
  //   path: '/landing',
  //   alias: '/land',
  //   component: () => import('@/views/auth/Landing.vue'),
  //   hidden: true
  // },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/api/:contextpath',
        alias: '/',
        component: () => import('@/views/ApiManagement'),
        name: 'Api',
        meta: {
          title: 'menu.api',
          icon: 'mdi-api',
          noCache: true,
          affix: true
        },
        hidden: true,
        props: true
      },
      {
        path: '/apis',
        alias: '/',
        component: () => import('@/views/ApiGroupManagement'),
        name: 'menu.apis',
        meta: {
          title: 'APIs',
          icon: 'mdi-api',
          noCache: true,
          affix: true
        },
      }
    ],
    meta: {
      authorities: [Authorities.apiRead]
    }
  },
  // {
  //   path: '/',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/apiTrash',
  //       component: () => import('@/views/ApiTrashManagement'),
  //       name: 'ApiTrash',
  //       meta: {
  //         title: 'API 휴지통 관리',
  //         icon: 'mdi-delete-variant',
  //         noCache: true,
  //         affix: true
  //       }
  //     }
  //   ],
  //   meta: {
  //     roles: ['운영자'],
  //     authorities: [Authorities.apiRead]
  //   }
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/request',
  //       component: () => import('@/views/RequestManagement'),
  //       name: 'Request',
  //       meta: {
  //         title: '요청 관리',
  //         icon: 'mdi-alert',
  //         noCache: true,
  //         affix: true
  //       }
  //     }
  //   ],
  //   meta: {
  //     roles: ['운영자'],
  //     authorities: [Authorities.requestRead]
  //   }
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/users',
  //       component: () => import('@/views/UserManagement'),
  //       name: 'menu.userManagement',
  //       meta: {
  //         title: 'menu.userManagement',
  //         icon: 'mdi-account-group-outline',
  //         noCache: true,
  //         affix: true
  //       }
  //     }
  //   ],
  //   meta: {
  //     authorities: [Authorities.userRead]
  //   }
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/organizations',
  //       component: () => import('@/views/OrganizationManagement'),
  //       name: 'Organizations',
  //       meta: {
  //         title: '기관 관리',
  //         icon: 'mdi-home-city',
  //         noCache: true,
  //         affix: true
  //       }
  //     }
  //   ],
  //   meta: {
  //     roles: ['운영자'],
  //     authorities: [Authorities.organizationRead]
  //   }
  // },
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/apps',
        component: () => import('@/views/AppManagement'),
        name: 'menu.app',
        meta: {
          title: 'menu.serviceApp',
          icon: 'mdi-ballot-recount',
          noCache: true,
          affix: true
        }
      },
      {
        path: '/apps/:appId',
        name: 'menu.app',
        hidden: true,
        component: () => import('@/views/app/AppManageDetailView'),
        props: (route) => ({ appId: Number(route.params.appId) }),
      },
    ],
    meta: {
      authorities: ['APP_READ'],
    }
  },
  // {
  //   path: '/',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/scopes',
  //       component: () => import('@/views/OauthScopeManagement'),
  //       name: 'Scopes',
  //       meta: {
  //         title: 'Scope 관리',
  //         icon: 'mdi-apps',
  //         noCache: true,
  //         affix: true
  //       }
  //     }
  //   ],
  //   meta: {
  //     roles: ['운영자'],
  //     authorities: [Authorities.scopeRead]
  //   }
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/dashboards',
  //       component: () => import('@/views/components/Dashboard/index'),
  //       name: 'Dashboards',
  //       meta: {
  //         title: '대시보드',
  //         icon: 'mdi-view-dashboard',
  //         noCache: true,
  //         affix: true
  //       }
  //     }
  //   ],
  //   meta: {
  //     authorities: [Authorities.dashboardRead]
  //   }
  // },
  // {
  //   path: '/',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/roles',
  //       component: () => import('@/views/RoleManagement'),
  //       name: 'Roles',
  //       meta: {
  //         title: '권한 관리',
  //         icon: 'mdi-account-details',
  //         noCache: true,
  //         affix: true
  //       }
  //     }
  //   ],
  //   meta: {
  //     roles: ['운영자'],
  //     authorities: [Authorities.roleRead]
  //   }
  // },
  //statisticsRouter,
  //{
  //  path: "/",
  //  component: Layout,
  //  children: [
  //    {
  //      path: "/policy",
  //      component: () => import("@/views/PolicyManagement"),
  //      name: "Policy",
  //      meta: {
  //        title: "정책 관리",
  //        icon: "mdi-message-text",
  //        noCache: true,
  //        affix: true
  //      }
  //    }
  //  ],
  //  meta: {
  //    roles: ["운영자"],
  //    authorities: [Authorities.policyRead]
  //  }
  //},
  supportRouter,
  // {
  //   path: '/',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/blacklist',
  //       component: () => import('@/views/BlackListManagement'),
  //       name: 'BlackList',
  //       meta: {
  //         title: '블랙리스트 관리',
  //         icon: 'mdi-account-multiple-remove',
  //         noCache: true,
  //         affix: true
  //       }
  //     }
  //   ],
  //   meta: {
  //     roles: ['운영자'],
  //     authorities: [Authorities.blacklistRead]
  //   }
  // },
  // mydataRouter,
  // {
  //   path: '/',
  //   component: Layout,
  //   children: [
  //     {
  //       path: '/systemState',
  //       component: () => import('@/views/SystemStateManagement'),
  //       name: 'SystemState',
  //       meta: {
  //         title: '시스템 상태 관리',
  //         icon: 'mdi-cube-send',
  //         noCache: true,
  //         affix: true
  //       }
  //     }
  //   ],
  //   meta: {
  //     roles: ['운영자'],
  //     authorities: [Authorities.stateRead]
  //   }
  // },
  // {
  //   path: "/",
  //   component: Layout,
  //   children: [
  //     {
  //       path: "/gateway",
  //       component: () => import("@/views/GateWayManagement"),
  //       name: "GateWay",
  //       meta: {
  //         title: "GW 관리",
  //         icon: "mdi-home-city",
  //         noCache: true,
  //         affix: true
  //       }
  //     }
  //   ],
  //   meta: {
  //     roles: ["운영자"],
  //     authorities: [Authorities.gatewayRead]
  //   }
  // }
];
