<template>
  <v-expansion-panel
    class="elevation-3"
  >
    <v-expansion-panel-header>
      <v-row
        align="center"
      >
        <v-col
          cols="auto"
        >
          {{ resource.uri }}{{ summary }}
        </v-col>
        <v-col
          cols="auto"
        >
          <v-btn
            x-small
            outlined
            @click.stop="showDetail"
          >
            {{ $t('apiResource.detail') }}
          </v-btn>
        </v-col>
        <v-spacer/>
        <v-col cols="auto">
          <v-tooltip
            v-if="$hasAnyAuthority(['API_CREATE', 'ADMIN'])"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                dense
                @click.stop="clickResourceReload"
              >
                mdi-reload
              </v-icon>
            </template>
            <span> {{ $t('apiResource.resource') }} {{ $t('apiResource.update') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                dense
                @click.stop="clickViewSwagger"
              >
                mdi-text-box
              </v-icon>
            </template>
            <span>{{ $t('apiResource.swagger') }} {{ $t('apiResource.document') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                dense
                @click.stop.prevent="openSwaggerWindow({resourceId: resource.id})"
              >
                mdi-api
              </v-icon>
            </template>
            <span>{{ $t('apiResource.swagger') }} {{ $t('apiResource.ui') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                dense
                @click.stop.prevent="openSwaggerDownloadDialog('resource', resource)"
              >
                mdi-download
              </v-icon>
            </template>
            <span>{{ $t('apiResource.sample') }} {{ $t('apiResource.code') }} {{ $t('apiResource.download') }}</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                dense
                @click.stop.prevent="exportSwaggerResource"
              >
                mdi-export
              </v-icon>
            </template>
            <span>{{ $t('apiResource.export') }}</span>
          </v-tooltip>
          <v-tooltip
            v-if="$hasAnyAuthority(['API_DELETE', 'ADMIN'])"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                dense
                @click.stop.prevent="deleteResource"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>{{ $t('apiResource.delete') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-expansion-panel-header>

    <v-expansion-panel-content>
      <api-method-list-view
        :contextpath="context"
        :methods="resource.methods"
        :method-status="methodStatus"
        @showSwaggerUI="openSwaggerWindow"
        @swagger-codegen="openSwaggerDownloadDialog"
        @refresh="refresh"
      />
    </v-expansion-panel-content>

    <!-- 신규 리소스 상세 정보 -->
    <v-dialog
      v-if="dialog.detail"
      v-model="dialog.detail"
      max-width="1000px"
    >
      <v-card>
        <v-card-title
          class="blue text-left"
        >
          {{ selected.resource.uri }}
        </v-card-title>
        <v-card-text>
          <api-resource-detail-view
            :resource="selected.resource"
            editable
            @refresh="refresh"
          />
        </v-card-text>
        <v-card-actions
          class="justify-end"
        >
          <v-btn
            outlined
            @click="dialog.detail = false"
          >
            {{ $t('apiResource.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Swagger 내용 보기 다이얼로그 -->
    <v-dialog
      v-model="dialog.swaggerView"
      max-width="800px"
      persistent
      scrollable
      @keydown.esc="dialog.swaggerView = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left">{{ $t('apiResource.swagger') }} {{ $t('apiResource.content') }}</v-card-title>
        <v-spacer/>
        <v-card-text>
          <json-viewer
            :expand-depth="5"
            :value="modifyObject.swagger"
            boxed
            copyable
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined @click="dialog.swaggerView = false"> {{ $t('apiResource.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Swagger CodeGen 보기 다이얼로그 -->
    <v-dialog
      v-model="dialog.swaggerCodeGenView"
      max-width="300px"
      @keydown.esc="dialog.swaggerCodeGenView = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left">
          {{ $t('apiResource.swagger') }} {{ $t('apiResource.code') }}{{ $t('apiResource.sample') }} {{ $t('apiResource.download') }}
        </v-card-title>
        <v-divider/>
        <v-card-text>
          <v-row class="mt-3" justify="center">
            <v-col cols="auto">
              <v-select
                v-model="selected.codeGen.language"
                :items="selects.languages"
                hide-details
                :label="$t('apiResource.sdk')"
                outlined
                style="width: 140px"
              >
                <template v-slot:append-outer>
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                        color="primary"
                        @click="downloadSwaggerCodeGen"
                      >
                        mdi-download
                      </v-icon>
                    </template>
                    <span>{{ $t('apiResource.sample') }} {{ $t('apiResource.code') }} {{ $t('apiResource.download') }}</span>
                  </v-tooltip>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn outlined @click="closeSwaggerCodeGenViewDialog"> {{ $t('apiResource.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-expansion-panel>
</template>

<script>
import ApiMethodListView from '@/components/ApiMethodListView';
import ApiResourceDetailView from '@/components/api/ApiResourceDetailView';
import request from '@/utils/request';
import { mapGetters } from 'vuex';

const SDK_TYPES = [
  { text: 'Java', value: 'java' },
  { text: 'C#', value: 'csharp' },
  { text: 'GO', value: 'go' },
  { text: 'JavaCXF', value: 'jaxrs-cxf-client' },
  { text: 'Kotlin', value: 'kotlin-client' },
  { text: 'PHP', value: 'php' },
  { text: 'Python', value: 'python' },
  { text: 'Scala', value: 'scala' },
  { text: 'Swift#3', value: 'swift3' },
  { text: 'Swift#4', value: 'swift4' },
  { text: 'Swift#5', value: 'swift5' },
  { text: 'Angular', value: 'typescript-angular' },
  { text: 'Fetch', value: 'typescript-fetch' },
];

export default {
  name: 'ApiResourceExpansionPanel',
  components: { ApiResourceDetailView, ApiMethodListView },
  props: {
    context: {
      type: String,
      required: true,
    },
    contextpath: {
      type: String,
    },
    resource: {
      type: Object,
      required: true,
    },
    // 보여줄 메소드의 상태 값
    methodStatus: {
      type: String,
    }
  },
  data: () => ({
    dialog: {
      detail: false,
      swaggerView: false,
      swaggerCodeGenView: false,
    },
    selects: {
      languages: SDK_TYPES,
    },
    selected: {
      resource: null,
      codeGen: {
        language: 'java',
        target: null, // 대상: resource, method ...
        item: null,
      },
    },
    modifyObject: {
      swagger: null,
    }
  }),
  async mounted() {
    // this.resource.methods = await apiService.resourceMathods(this.resource.id);
  },
  computed: {
    ...mapGetters([
      'port',
      'contextPath'
    ]),
    summary() {
      return !this.resource.methods || this.resource.methods.length === 0 || !this.resource.methods[0].summary ? '' : ' | ' + this.resource.methods[0].summary;
    }
  },
  methods: {
    showDetail() {
      this.selected.resource = JSON.parse(JSON.stringify(this.resource));
      this.dialog.detail = true;
    },
    refresh() {
      this.$emit('refresh');
    },
    async clickResourceReload() {
      const { data } = await request({
        url: `/gw/refresh-resource/${this.resource.id}`,

        method: 'get',
      });
      console.log('refresh result', data);
    },
    async clickViewSwagger() {
      const { data } = await request({
        url: `/apix/apis/resources/${this.resource.id}/swagger`,
      });
      this.modifyObject.swagger = data;
      this.dialog.swaggerView = true;
    },
    async openSwaggerDownloadDialog(target, item) {
      this.selected.codeGen.target = target;
      this.selected.codeGen.item = item;
      this.dialog.swaggerCodeGenView = true;
    },
    async downloadSwaggerCodeGen() {
      let targetUrl = '/apix/codegen';
      const language = this.selected.codeGen.language;

      // target이 없다면 전체인 걸로 간주한다.
      if (!this.selected.codeGen.target) this.selected.codeGen.target = 'all';
      if (this.selected.codeGen.item && this.selected.codeGen.target) {
        switch (this.selected.codeGen.target) {
          case 'resource':
            targetUrl = `${targetUrl}/resources/${this.selected.codeGen.item.id}?type=${language}`;
            break;
          case 'tag':
            targetUrl = `${targetUrl}/tags?type=${language}&name=${this.selected.codeGen.item.name}`;
            break;
          case 'method':
            targetUrl = `${targetUrl}/methods/${this.selected.codeGen.item.id}?type=${language}`;
            break;
          case 'category': // 현재는 없음
            break;
          default:
          // 없다면 그냥 전체를 가져오는 걸로
        }
      }

      const { data } = await request({
        url: targetUrl,
        method: 'get',
      });
      if (data.error) {
        var errorMsg = this.$t('apiResource.errorMsg');
        this.addAlert({
          type: 'error',
          message: `${errorMsg}:` + data.message,
        });
        return;
      }

      const linkSource = 'data:application/zip;base64,' + data.content;
      const downloadLink = document.createElement('a');
      const fileName =
        this.selected.codeGen.target + '_' + language + '_generated.zip';

      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
      var createdSdkFileMsg = this.$t('apiResource.createdSdkFileMsg');
      await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;">
                    <img max-width="100%" src="static/inzent/ico_complete.svg" /><br/>
                    <b>${createdSdkFileMsg}</b>
                  </div>`,
      },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
      this.closeSwaggerCodeGenViewDialog();
    },
    closeSwaggerCodeGenViewDialog() {
      this.selected.codeGen.target = null;
      this.selected.codeGen.item = null;
      this.dialog.swaggerCodeGenView = false;
    },
    exportSwaggerResource() {
      request({
        method: 'get',
        url: `/apix/swagger/resources/${this.resource.id}/download`,
        headers: { Accept: 'application/x-yaml' },
      }).then((response) => {
        this.forceFileDownload(response);
      }).catch(() => console.log('error occured'));
    },
    forceFileDownload(response) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      const contentDisposition = response.headers['content-disposition']; // 파일 이름
      let fileName = 'unknown';
      if (contentDisposition) {
        const [fileNameMatch] = contentDisposition
          .split(';')
          .filter((str) => str.includes('filename'));
        if (fileNameMatch) [, fileName] = fileNameMatch.split('=');
      }
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
    },
    async deleteResource() {
      try {
        var deleteResourceMsg = this.$t('apiResource.deleteResourceMsg');
        const res = await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;">
                      <img max-width="100%" src="static/inzent/ico_warning.svg" /><br/>
                      <b>${deleteResourceMsg}</b>
                    </div>`,
          confirmYn: true,
        },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
        if (res) {
          const { data } = await request({
            url: `/apix/apis/resources/${this.resource.id}/delete`,
            method: 'delete',
          });
          console.log('data ::::::: ', data);
          var deletedResourceMsg = this.$t('apiResource.deletedResourceMsg');
          await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;">
                        <img max-width="100%" src="static/inzent/ico_complete.svg" /><br/>
                        <b>${deletedResourceMsg}</b>
                      </div>`,
          },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
          // this.onChangeType();
          this.$emit('refresh');
        }
      } catch (error) {
        console.log('result error ::::::', error.response);
        var errorMsg = this.$t('apiResource.errorMsg');
        if (error.response.data.detail) {
          await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;">
                        <img max-width="100%" src="static/inzent/ico_fail.svg" /> <br/>
                        <b>${errorMsg} : '${error.response.data.detail}'</b>
                      </div>`,
          },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
        } else {
          await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;">
                        <img max-width="100%" src="static/inzent/ico_fail.svg" /><br/>
                      <b>${errorMsg} : '${error.message}'</b></div>`,
          },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
        }
      }
    },
    openSwaggerWindow(item) {
      let index = window.swaggerUiIndex || '/swagger-ui/index.html';

      let url = `${this.contextPath}` + index;
      let configUrl = `configUrl=${this.contextPath}/v3/api-docs/swagger-config`;
      let docUrl = `url=${this.contextPath}/apix/swagger`;

      if (process.env.NODE_ENV === 'development')
        url = `http://localhost:${this.port}` + url;

      if (item) {
        if (item.resourceId) docUrl += `/resources/${item.resourceId}`;
        else if (item.tagName)
          docUrl += `/tags?` + encodeURIComponent(`name=${item.tagName}`);
        else if (item.methodId) docUrl += `/methods/${item.methodId}`;
      } else if (this.contextpath !== '/')
        docUrl += `/contextpath${this.contextpath}`;

      const fullUrl = `${url}?${configUrl}&${docUrl}`;
      const win = window.open(fullUrl, '_blank');
      win.focus();
    },
  }
}
</script>

<style scoped>

</style>
