var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!!_vm.schema && _vm.schema.type === 'object')?_c('v-data-table',{attrs:{"items":_vm.localProperties,"headers":_vm.computedHeaders,"dense":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([(_vm.title)?{key:"top",fn:function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true}:null,{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.clickModify(item)}}},[_vm._v(" mdi-pencil ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.deleteProperty(item)}}},[_vm._v(" mdi-delete ")])]}},(_vm.editMode)?{key:"body.append",fn:function(ref){
var headers = ref.headers;
return [_c('tr',[_c('td',{staticClass:"text-right",staticStyle:{"border":"none"},attrs:{"colspan":headers.length}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.clickAdd}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus-box ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('swagger.property'))+" "+_vm._s(_vm.$t('swagger.add')))])])],1)])]}}:null],null,true)}):_c('v-chip',[_vm._v(" "+_vm._s(_vm.schema ? _vm.schema.type : '')+" ")]),(_vm.dialog.prop)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialog.prop),callback:function ($$v) {_vm.$set(_vm.dialog, "prop", $$v)},expression:"dialog.prop"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-left"},[_c('span',{staticClass:"text-h3"},[_vm._v(" "+_vm._s(_vm.$t('swagger.property'))+" "+_vm._s(_vm.dialog.prop === 'add' ? _vm.$t('swagger.add') : _vm.$t('swagger.modify'))+" ")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"label":_vm.$t('swagger.name')},model:{value:(_vm.modify.prop.name),callback:function ($$v) {_vm.$set(_vm.modify.prop, "name", $$v)},expression:"modify.prop.name"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.selectors.types,"label":_vm.$t('swagger.type')},model:{value:(_vm.modify.prop.type),callback:function ($$v) {_vm.$set(_vm.modify.prop, "type", $$v)},expression:"modify.prop.type"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"grey"},on:{"click":function($event){_vm.dialog.prop = false}}},[_vm._v(" "+_vm._s(_vm.$t('swagger.cancel'))+" ")]),_c('v-btn',{on:{"click":_vm.saveProp}},[_vm._v(" "+_vm._s(_vm.dialog.prop === 'add' ? _vm.$t('swagger.add') : _vm.$t('swagger.save'))+" ")])],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }