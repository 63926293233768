<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
      >
        <v-form
          ref="form"
          v-model="valid"
        >
          <v-text-field
            v-if="useOldPassword"
            tabindex="1"
            v-model="password"
            :label="$t('passwordChange.existing') +' '+$t('passwordChange.password')"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="passwordRule"
            hide-details="auto"
            dense
            required
            class="mb-4"
            @click:append="showPassword = !showPassword"
          />
          <v-text-field
            tabindex="2"
            v-model="newPassword"
            :label="$t('passwordChange.new') +' '+ $t('passwordChange.password')"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="newPasswordRule"
            hide-details="auto"
            dense
            required
            class="mb-4"
            @click:append="showPassword = !showPassword"
          />
          <v-text-field
            tabindex="3"
            v-model="newPassword2"
            :label="$t('passwordChange.new') +' '+ $t('passwordChange.password') +' ' +$t('passwordChange.check')"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="newPasswordRule2"
            hide-details="auto"
            dense
            required
            class="mb-4"
            @click:append="showPassword = !showPassword"
          />
          <v-alert
            :value="alert.visible"
            color="warning"
            outlined
            transition="scale-transition"
            class="mt-2"
          >
            {{ alert.message }}
          </v-alert>
        </v-form>
      </v-col>
    </v-row>
    <v-row
      justify="center"
    >
      <v-col
        cols="auto"
      >
        <v-btn
          tabindex="4"
          class="primary"
          :disabled="!valid"
          @click="changePassword"
        >
          {{$t('passwordChange.change')}}
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
        v-if="showCloseButton"
      >
        <v-btn
          tabindex="5"
          class="primary"
          @click="onClickClose"
        >
          {{$t('passwordChange.close')}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import request from '@/utils/request';

export default {
  name: 'PasswordChange',
  props: {
    useOldPassword: {
      type: Boolean,
      default: false,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    alert: {
      message: null,
      visible: false,
    },
    valid: false,
    showPassword: false,
    password: null, // 기존 비밀번호
    newPassword: null, // 신규 비밀번호
    newPassword2: null, // 신규 비밀번호 체크
  }),
  mounted() {
    this.$refs.form.validate();
  },
  computed: {
    ...mapGetters([
      'user',
    ]),
    passwordRule() {
      var requiredFieldMsg = this.$t('passwordChange.requiredFieldMsg');
      return [
        v => !!v || `${requiredFieldMsg}`,
      ];
    },
    newPasswordRule() {
      var requiredFieldMsg = this.$t('passwordChange.requiredFieldMsg');
      return [
        v => !!v || `${requiredFieldMsg}`,
      ];
    },
    newPasswordRule2() {
      var requiredFieldMsg = this.$t('passwordChange.requiredFieldMsg');
      var matchPasswordMsg = this.$t('passwordChange.matchPasswordMsg');
      return [
        v => !!v || `${requiredFieldMsg}`,
        v => v === this.newPassword || `${matchPasswordMsg}`,
      ];
    },
  },
  methods: {
    async changePassword() {
      try {
        await request({
          url: `/apix/users/${this.user}/change-password`,
          method: 'put',
          data: {
            password: this.newPassword,
          },
        });
        this.showPassword = false;
        this.password = null;
        this.newPassword = null;
        this.newPassword2 = null;
        this.$emit('password-changed');
      } catch (error) {
        const { status, data } = error.response;
        if (status === 400) {
          if (Object.prototype.hasOwnProperty.call(data, 'fieldErrors')) {
            // noinspection JSUnresolvedVariable
            this.alert.message = data.fieldErrors[0].message;
          } else {
            this.alert.message = data.title;
          }
          this.alert.visible = true;
          setTimeout(() => {
            this.alert.visible = false;
          }, 5000);
        }
      }
    },
    onClickClose() {
      this.$emit('click-close');
    }
  }
}
</script>
