<template>
  <v-card>
    <v-card-title>
      {{ forum.title }}
    </v-card-title>
    <v-divider />
    <v-card-text>
      <v-data-table
        :headers="header.posts"
        :items="posts"
        :footer-props="{
          itemsPerPageOptions: $store.state.pagination.rowsPerPageItems
        }"
        :options.sync="pageOptions"
        :server-items-length="totalCount"
        @click:row="onSelectPost"
      >
        <template v-slot:item.writer="{ item }">
          {{ item.writer && (item.writer.name || item.writer.id) }}
        </template>

        <template v-slot:item.createdDate="{ item }">
          <format-date-time :value="item.createdDate" />
        </template>

        <template v-slot:footer.prepend
                  v-if="permission.write"
        >
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                small
                class="mr-0"
                v-bind="attrs"
                v-on="on"
                @click="clickWrite"
              >
                <v-icon>
                  mdi-plus
                </v-icon>
              </v-btn>
            </template>
            <span>{{$t('forum.write')}}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>

    <v-dialog
      v-if="dialog.post"
      v-model="dialog.post"
      max-width="900"
      scrollable
    >
      <apim-forum-post
        :forum="forum"
        :post-id="selected.post.id"
        @click:cancel="dialog.post = false"
        @refresh="fetchPosts"
        @click:close="closeDialogPost"
      />
    </v-dialog>
  </v-card>
</template>

<script>
import forumService from '@/service/forum.service';
import FormatDateTime from '@/views/components/FormatDateTime';
import ApimForumPost from '@/components/forum/ApimForumPost';
import { convertDataTablePagingOptionsToObject } from '@/views/service/util';
import {mapGetters} from "vuex";

const header = [
  {
    text: 'forum.no',
    value: 'id',
    sortable: false,
    width: 100,
  },
  {
    text:'forum.title',
    value: 'title',
  },
  {
    text: 'forum.numberOfReadings',
    value: 'readCount',
    width: 110,
  },
  {
    text: 'forum.writer',
    value: 'writer',
    width: 150,
    sortable: false,
  },
  {
    text: 'forum.createdTime',
    value: 'createdDate',
    width: 160,
  },
  // {
  //   text: '비고',
  //   value: 'actions',
  // },
];

function convertForumId(forumAlias) {
  switch (forumAlias) {
    case 'notice':
      return 1;
    case 'faq':
      return 2;
    case 'qna':
      return 3;
    default:
      return -1;
  }
}

export default {
  name: 'ApimForum',
  components: { ApimForumPost, FormatDateTime },
  props: {
    forumId: {
      type: [String, Number],
    }
  },
  data: function() {
    return {
      header: {
        posts: this.$translate(header)
      },
      dialog: {
        post: false,
      },
      internalForumId: isNaN(this.forumId) ? convertForumId(this.forumId) : this.forumId,
      forum: {},
      permission: {
        read: false,
        write: false,
      },
      pageOptions: null,
      posts: [],
      totalCount: 0,
      selected: {
        post: null,
      }
    };
  },
  computed:{
    ...mapGetters(['currentLanguage']),
  },
  watch: {
    currentLanguage(){
      this.header.posts = this.$translate(header);
    },
    async forumId() {
      this.internalForumId = isNaN(this.forumId) ? convertForumId(this.forumId) : this.forumId;
      this.forum = await forumService.info(this.internalForumId);
      this.permission = this.forum.permission;
      await this.fetchPosts();
    },
    async pageOptions() {
      await this.fetchPosts();
    }
  },
  methods: {
    async fetchPosts() {
      if (this.permission.read) {
        const search = convertDataTablePagingOptionsToObject(this.pageOptions);
        const { data, totalCount } = await forumService.listPosts(this.internalForumId, search);
        this.posts = data;
        this.totalCount = totalCount;
      }
    },
    onSelectPost(post) {
      this.selected.post = post;
      this.dialog.post = true;
    },
    closeDialogPost() {
      this.fetchPosts();
      this.dialog.post = false;
    },
    clickWrite() {
      this.selected.post = {};
      this.dialog.post = true;
    }
  },
  async mounted() {
    this.forum = await forumService.info(this.internalForumId);
    this.permission = this.forum.permission;
    await this.fetchPosts();
  },
}
</script>

<style scoped>
</style>
