<template>
  <mavon-editor
    ref="mdEdit"
    v-model="textValue"
    :ishljs="true"
    :placeholder="holder"
    :subfield="false"
    :toolbars="toolbars"
    box-shadow-style="0 0 0 0 rgba(0,0,0,0)"
    language="en"
    @imgAdd="$imgAdd"
    @imgDel="$imgDel"
  />
</template>

<script>

import forumService from "@/service/forum.service";

export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      required: false,
      default: '',
    },
    holder: {
      type: [String, Number, Boolean],
      required: false,
      default: '',
    },
  },
  data() {
    return {
      textValue: this.value,
      toolbars: {
        bold: true,
        italic: true,
        header: true,
        underline: true,
        strikethrough: true,
        mark: true,
        superscript: true,
        subscript: true,
        quote: true,
        ol: true,
        ul: true,
        link: true,
        imagelink: true,
        code: true,
        table: true,
        fullscreen: true,
        readmodel: true,
        htmlcode: true,
        help: true,
        /* 1.3.5 */
        undo: true,
        redo: true,
        trash: true,
        /* 1.4.2 */
        navigation: true,
        /* 2.1.8 */
        alignleft: true,
        aligncenter: true,
        alignright: true,
        /* 2.2.1 */
        subfield: true,
        preview: true
      },
      img_list: {},
      formData: {},
    };
  },
  methods: {
    async $imgAdd(pos, $file) {
      this.img_list[pos] = $file;
      this.formData = new FormData();
      for (let _img in this.img_list) {
        this.formData.append('files', this.img_list[_img]);
      }
      const data = await forumService.fileUpload(this.formData);
      if (data.status === 200) {
        this.$refs.mdEdit.$imglst2Url([[data.data.length, `/apix/files/${data.data[0]}`]]);
      }
    },
    $imgDel(pos) {
      delete this.img_list[pos];
    },
  },
  watch: {
    value() {
      this.textValue = this.value;
    },
    textValue() {
      this.$emit('update', this.textValue);
    },
  }
};
</script>

<style>
</style>
