// export const AUTH_TOKEN_NAME = 'imanager-authenticationToken';
// export const PROBLEM_BASE_URL = 'http://www.inzent.com';
// export const TOTAL_COUNT_HEADER_NAME = 'x-total-count';
// export const REQUESTED_URL_STORAGE_KEY = 'requested-url';
//
// export const CONTEXT_PATH = '/ApiPortal';
// export const CONTEXT_PATH = '';

module.exports = {
  AUTH_TOKEN: 'apiportal-token',
  AUTH_ACCESS_TOKEN_NAME: 'apiportal-accessToken',
  AUTH_TOKEN_EXPIRES_IN: 'apiportal-accessToken-expires',
  AUTH_REFRESH_TOKEN_NAME: 'apiportal-refreshToken',
  // AUTH_TOKEN_NAME: 'imanager-authenticationToken',
  PROBLEM_BASE_URL: 'http://www.inzent.com',
  TOTAL_COUNT_HEADER_NAME: 'x-total-count',
  REQUESTED_URL_STORAGE_KEY: 'requested-url',

  CONTEXT_PATH: '',
  // CONTEXT_PATH: '/ApiPortal',
};
