<!-- API의 메소드 목록 보기 -->
<template>
  <v-card outlined tile>
    <v-card-subtitle class="text-left">
      <h3 class="pl-md-2">{{ title }}</h3>
    </v-card-subtitle>
    <v-divider />
    <v-card-text class="pt-5">
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr>
              <th width="15%; font-size:1rem;">{{ targetUrl.text }}</th>
              <td>
                <template v-if="targetUrl.readonly">
                  {{ item[targetUrl.item][targetUrl.value] }}
                </template>
                <v-text-field
                  v-else
                  :placeholder="targetUrl.placeholder"
                  outlined
                  hide-details
                  v-model="item[targetUrl.item][targetUrl.value]"
                  :rules="[httpMethod.required ? rules.required : true]"
                />
              </td>
            </tr>
            <tr>
              <th width="15%; font-size:1rem;">{{ httpMethod.text }}</th>
              <td>
                <template v-if="httpMethod.readonly">
                  {{ item[httpMethod.item][httpMethod.value] }}
                </template>
                <v-select
                  v-else
                  v-model="item[httpMethod.item][httpMethod.value]"
                  :items="httpMethod.selectItems"
                  :placeholder="httpMethod.placeholder"
                  outlined
                  hide-details
                  :rules="[httpMethod.required ? rules.required : true]"
                />
              </td>
            </tr>

            <tr>
              <th width="15%; font-size:1rem;">{{ operationId.text }}</th>
              <td>
                <template v-if="operationId.readonly">
                  {{ item[operationId.item][operationId.value] }}
                </template>
                <v-text-field
                  v-else
                  :placeholder="operationId.placeholder"
                  outlined
                  hide-details
                  v-model="item[operationId.item][operationId.value]"
                  v-on:keypress="isLetterOrNumber($event)"
                  :rules="[operationId.required ? rules.required : true]"
                />
              </td>
            </tr>
            <tr>
              <th width="15%; font-size:1rem;">{{ summary.text }}</th>
              <td>
                <template v-if="summary.readonly">
                  {{ item[summary.item][summary.value] }}
                </template>
                <v-text-field
                  v-else
                  :placeholder="summary.placeholder"
                  outlined
                  hide-details
                  v-model="item[summary.item][summary.value]"
                  :rules="[summary.required ? rules.required : true]"
                />
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ApiMethodRouting",
  props: ["title", "targetUrl", "httpMethod", "operationId", "summary", "item"],
  computed: {},
  components: {},
  mounted() {
    // console.log('ApiMethodInfo httpMethod',this.httpMethod);
    // console.log('ApiMethodInfo summary',this.summary);
    // console.log('ApiMethodInfo mode',this.mode);
  },

  data: function() {
    return{
      rules: {
        required(v) {
          return !!v || 'Required!';
        },
      },
    };
  },
  methods: {
    isLetterOrNumber(e) {
      let char = String.fromCharCode(e.keyCode);
      if (/^[a-zA-Z0-9-_$^&*%@#]*$/.test(char)) return true;
      else e.preventDefault();
    }
  },
};
</script>
