<template>
  <mavon-editor
    v-model="text"
    :placeholder="holder"
    :previewBackground="preBgColor"
    :subfield="false"
    :toolbarsFlag="false"
    defaultOpen="preview"
    language="en"
    ref="mdEdit"
  />
</template>

<script>

export default {
  props: {
    text: {
      type: [String, Number, Boolean],
      required: false,
      default: '',
    },
    holder: {
      type: [String, Number, Boolean],
      required: false,
      default: '',
    },
    preBgColor: {
      type: [String, Number, Boolean],
      required: false,
      default: '',
    },
  },
  mounted() {
    // init: replace <img src="base64">
    this.$nextTick(() => {
      console.log(this.$refs.mdEdit);
      // this.$refs.mdEdit.$imgUpdateByUrl('0', base64);
    })
  },
  data() {
    return {
      toolbars: {},
      bgColor: ''
    };
  },
  methods: {
  }
};
</script>
