import request, { processResponse } from '@/utils/request';

const organizationService = {
  async list() {
    const { data } = await request({
      url: '/apix/organizations',
    });
    return data;
  },
  async apps(organizationId) {
    const { data } = await request({
      url: `/apix/organizations/${organizationId}/apps`
    });
    return data;
  },
  async mydataList(search) {
    return processResponse(await request({
      method: 'get',
      url: '/apix/mydata/organizations',
      params: search,
    }));
  }
};

export default organizationService;
