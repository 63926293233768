<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        class="py-0"
      >
        <v-select
          v-model="requestBody.mediaType"
          :items="selectors.contentType"
          :label="$t('apiMethod.contentType')"
        />
      </v-col>
      <v-col
        v-if="requestBody.mediaType"
        cols="12"
        class="py-0"
      >
        <v-textarea
          v-model="requestBody.sample"
          :label="$t('apiMethod.example')"
          outlined
          hide-details
          dense
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
const selectorsContentType=[
  {
    text: 'apiMethod.none',
    value: '',
  },
  {
    text: 'application/json',
    value: 'application/json',
  },
  {
    text: 'application/xml',
    value: 'application/xml',
  },
  {
    text: 'multipart/form-data',
    value: 'multipart/form-data',
  },
];
export default {
  name: 'ApiMethodRequestBodyView',
  props: {
    requestBody: {
      type: Object,
      required: true,
      default: () => ({
        mediaType: '',
        sample: null,
      }),
    }
  },
  data: function() {
    return {
      selectors: {
        contentType: this.$translate(selectorsContentType),
      },
    };
  },
  computed:{
    ...mapGetters(['currentLanguage']),
  },
  watch:{
    currentLanguage(){
      this.selectors.contentType = this.$translate(selectorsContentType);
    }
  }
}
</script>

<style scoped>

</style>
