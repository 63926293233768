<template>
  <v-container fluid>
    <v-row
      v-if="showCounter"
    >
      <v-spacer/>
      <v-col
        cols="auto"
        class="pb-0"
        style="margin-right: 25px"
      >
        {{ logs.length.toLocaleString() }}
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        :style="showCounter ? 'margin-top: -30px' : ''"
      >
        <svg v-if="!!logs" id="chart" ref="chart"/>
      </v-col>
    </v-row>
    <v-row
      v-if="brush.selection && brushSelectedLogs"
    >
      <v-col
        cols="12"
      >
        <v-data-table
          :headers="headers.log"
          :items="brushSelectedLogs"
          dense
          @click:row="showTransactionDetail"
        >
          <template v-slot:item.startTime="{ item }">
            <format-date-time :value="xValue(item)"/>
          </template>
          <template v-slot:item.elapsed="{ item }">
            {{ yValue(item).toLocaleString() }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      v-if="dialog.detail"
      v-model="dialog.detail"
      max-width="900px"
      max-height="900px"
      persistent
      @keydown.esc="dialog.detail = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left">{{ $t('xViewChart.transactionLog') }} {{ $t('xViewChart.detailInfo') }}</v-card-title>
        <v-divider/>
        <v-card-text>
          <transaction-detail
            :log="selected.log"
          />
        </v-card-text>
        <v-card-actions class="justify-center">
          <!--<v-btn v-if="selectedLogData" @click="dialog.data = true" outlined>-->
          <!--  거래 데이터-->
          <!--</v-btn>-->
          <v-btn v-if="dialog.detail" @click="dialog.detail = false" outlined>
            {{ $t('xViewChart.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
.domain {
  display: none;
}

.tick line {
  stroke: #C0C0BB;
}

.tick text {
  fill: #8E8883;
  font-size: 10pt;
  font-family: sans-serif;
}

.axis-label {
  fill: #635F5D;
  font-size: 15pt;
  font-family: sans-serif;
}
</style>

<style scoped>
div.tooltip {
  position: absolute;
  text-align: center;
  width: 60px;
  height: 28px;
  padding: 2px;
  font: 12px sans-serif;
  background: lightsteelblue;
  border: 0px;
  border-radius: 8px;
  pointer-events: none;
}
</style>

<script>
import * as d3 from 'd3';
import FormatDateTime from '@/views/components/FormatDateTime';
import TransactionDetail from '@/components/TransactionDetail';
import debounce from '@/utils';
import {mapGetters} from "vuex";

const margin = {
  left: 90,
  right: 30,
  top: 20,
  bottom: 40,
};

const colors = ['200', '400', '401', '402', '403', '404', '500'];

const statusColor = d3.scaleOrdinal()
  .unknown('blue')
  .domain(colors)
  .range(['black', 'orange', 'orange', 'orange', 'orange', 'orange', 'red']);

const header = [
  {
    text: 'xViewChart.requestUri',
    value: 'requestUri',
  },
  {
    text: 'xViewChart.responseCode',
    value: 'responseCode',
  },
  {
    text: 'xViewChart.requestTime',
    value: 'startTime',
  },
  {
    text: 'xViewChart.theTimeRequiredUnit',
    value: 'elapsed',
  },
];

export default {
  name: 'XViewChart',
  components: { TransactionDetail, FormatDateTime },
  props: {
    showCounter: {
      type: Boolean,
      default: false,
    },
    logs: {
      type: Array,
    },
    fromTime: {
      type: Date,
    },
    durationInMinutes: {
      type: Number,
      default: 10,
    }
  },
  data: function(){
    return {
      dialog: {
        detail: false,
      },
      headers: {
        log:this.$translate(header),
      },
      selected: {
        log: null,
      },
      isBrushed: false,
      isMouseOver: false,
      filter: {
        xMin: null,
      },
      brush: {
        selection: null, // brush에 의해 선택된 값
      }
    };
  },
  computed: {
    ...mapGetters(['currentLanguage','currentLanguage']),
    filteredLogs() {
      if (!this.filter.xMin) return this.logs;
      return this.logs.filter((d) => this.xValue(d).getTime() >= this.filter.xMin.getTime());
    },
    pause() {
      return this.isBrushed || this.isMouseOver;
    },
    // brush에서 선택된 영역만 필터링
    brushSelectedLogs() {
      if (!this.brush.selection) return null;
      return this.logs.filter((log) => {
        const time = this.xValue(log).getTime();
        const elapsed = this.yValue(log);
        return time >= this.brush.selection[0][0].getTime() && time <= this.brush.selection[1][0].getTime()
          && elapsed <= this.brush.selection[0][1] && elapsed >= this.brush.selection[1][1];
      });
    },
  },
  watch: {
    currentLanguage(){
      this.headers.log = this.$translate(header);
    },
    'logs': function () {
      this.plot();
    },
  },
  methods: {
    initChart() {
      let responseTime = this.$t('xViewChart.responseTime');
      this.resetSize();
      this.g = this.svg.append('g')
        .attr('transform', `translate(${margin.left},${margin.top})`);

      this.xAxisG = this.g.append('g')
        .attr('transform', `translate(0, ${this.innerHeight})`);

      this.yAxisG = this.g.append('g');

      // this.xAxisG.append('text')
      //   .attr('class', 'axis-label')
      //   .attr('x', this.innerWidth / 2)
      //   .attr('y', 100)
      //   .text('Time');

      this.yAxisG.append('text')
        .attr('class', 'axis-label')
        .attr('x', -this.innerHeight / 2)
        .attr('y', -60)
        .attr('transform', `rotate(-90)`)
        .style('text-anchor', 'middle')
        .text(`${responseTime}` +  '(ms)');

      this.xScale = d3.scaleTime();
      this.yScale = d3.scaleLinear();

      this.xAxis = d3.axisBottom()
        .scale(this.xScale)
        .ticks(5)
        .tickPadding(15)
        .tickSize(-this.innerHeight)
        .tickFormat(d3.timeFormat('%H:%M'));

      // if (this.durationInMinutes <= 10) this.xAxis.ticks(d3.utcMinute.every(1));
      // else if (this.durationInMinutes <= 60) this.xAxis.ticks(d3.utcMinute.every(5));
      // else if (this.durationInMinutes <= 180) this.xAxis.ticks(d3.utcMinute.every(30));
      // else this.xAxis.ticks(d3.utcMinute.every(60));

      this.yAxis = d3.axisLeft()
        .scale(this.yScale)
        .ticks(5)
        .tickPadding(15)
        .tickSize(-this.innerWidth);

      const brush = d3.brush()
        .extent([[0, 0], [this.innerWidth, this.innerHeight]])
        .on('end', this.brushed);
      this.g.append('g')
        .attr('class', 'brush')
        .call(brush);

      this.tooltip = d3.select('body').append('div')
        .style('position', 'absolute')
        // .style('text-align', 'center')
        .style('padding', '6px')
        .style('font', '12px sans-serif')
        .style('background', 'lightsteelblue')
        .style('border', '2px solid blue')
        .style('border-radius', '8px')
        .style('pointer-events', 'none')
        .style('opacity', 0);
    },
    resetSize() {
      if (!this.$el.parentElement) return;
      // 넓이 구하는 방법을 잘 모르겠음ㅠ
      const style = window.getComputedStyle(this.$el.parentElement);
      const padding = parseFloat(style.paddingLeft) + parseFloat(style.paddingRight);

      const width = this.$el.parentElement.clientWidth - padding;
      const height = 300;
      this.innerWidth = width - margin.left - margin.right;
      this.innerHeight = height - margin.top - margin.bottom;

      this.svg = d3.select('#chart')
        .style('width', width + 'px')
        .style('height', height + 'px');

      if (this.g) {
        this.g.attr('transform', `translate(${margin.left},${margin.top})`);
      }
      if (this.yAxis) {
        this.yAxis.tickSize(-this.innerWidth);
      }
    },
    xValue(d) {
      const time = d.startTime ? d.startTime : d.ini;
      return new Date(time);
    },
    yValue(d) {
      if (d.elapsed) return d.elapsed;
      return d.startTime && d.endTime ? +d.endTime - d.startTime : +d.ino - d.ini;
    },// x축의 범위를 구한다.
    xDomain() {
      if (this.fromTime) return [this.fromTime, new Date(this.fromTime.getTime() + (this.durationInMinutes * 60000))];
      else {
        // 현재 시간을 기준으로 한다.
        const now = new Date();
        return [new Date(now.getTime() - (this.durationInMinutes * 60000)), now];
      }
    },
    plot() {
      if (this.pause) return;
      const xMinMax = this.xDomain();
      this.filter.xMin = xMinMax[0];

      // x축 계산
      this.xScale
        .domain(xMinMax)
        .range([0, this.innerWidth]);
      // .nice();

      let yHighValue = d3.max([d3.max(this.filteredLogs, this.yValue), 1000]);

      // y축 계산
      this.yScale
        .domain([0, yHighValue])
        .range([this.innerHeight, 0])
        .nice();

      // 모든 점을 제거
      this.g.selectAll('circle').remove();

      // 점 그리기
      this.g.selectAll('circle').data(this.filteredLogs).enter()
        .append('circle')
        .attr('cx', d => this.xScale(this.xValue(d)))
        .attr('cy', d => this.yScale(this.yValue(d)))
        .attr('r', (d) => {
          // 응답코드가 200인 경우 반지름을 3, 그 외의 경우는 반지름을 4로 한다.
          return d.responseCode && d.responseCode.split(':')[0] === '200' ? 3 : 4;
        })
        .attr('fill-opacity', 0.6)
        .attr('fill', (d) => {
          // 컬러를 구한다.
          return d.responseCode ? statusColor(d.responseCode.split(':')[0]) : 'blue';
        })
        .attr('stroke', 'green')
        .attr('stroke-width', 0)
        .on('mouseover', (e, d) => {
          this.isMouseOver = true;
          d3.select(e.currentTarget)
            .transition()
            .duration(500)
            .attr('stroke-width', 3);
          // tooltip 표현
          this.tooltip.transition()
            .duration(200)
            .style('opacity', .9);
          this.tooltip.html(this.tooltipMessage(d))
            .style('left', `${e.pageX - 35}px`)
            .style('top', `${e.pageY - 75}px`)
        })
        .on('mouseout', (e) => {
          this.isMouseOver = false;
          d3.select(e.currentTarget)
            .transition()
            .duration(500)
            .attr('stroke-width', 0);
          // tooltip 제거
          this.tooltip.transition()
            .duration(200)
            .style('opacity', 0);
        })
        .on('click', (e, d) => {
          this.clickPoint(d);
        });

      this.xAxisG.call(this.xAxis);
      this.yAxisG.call(this.yAxis);
    },
    // brush event 처리
    brushed(event) {
      if (event.selection) {
        this.isBrushed = true;
        this.brush.selection = [
          [this.xScale.invert(event.selection[0][0]), this.yScale.invert(event.selection[0][1])], // x1, y1
          [this.xScale.invert(event.selection[1][0]), this.yScale.invert(event.selection[1][1])], // x2, y2
        ];
      } else {
        this.isBrushed = false;
        this.brush.selection = null;
      }
      this.$emit('brushed', this.brush.selection);
    },
    // 점을 클릭했을 때
    clickPoint(point) {
      this.$emit('click-point', point);
      this.showTransactionDetail(point);
      this.isMouseOver = false;
    },
    // tooltip에 보여줄 내용
    tooltipMessage(point) {
      return `<b>${point.requestUri}</b><br/>
              ${this.$moment(this.xValue(point)).format('YYYY-MM-DD HH:mm:ss')}<br/>
              <b>${this.yValue(point).toLocaleString()}</b> ms`;
    },
    // transaction 상세 정보를 보여준다.
    showTransactionDetail(log) {
      this.selected.log = log;
      this.dialog.detail = true;
    }
  },
  async mounted() {
    this.initChart();
    if (!this.fromTime) {
      this.timer = setInterval(this.plot, 1000); // 매 초마다 차트를 다시 그린다.
    }
    this.resizeHandler = debounce(() => {
      this.resetSize();
    }, 100);
    window.addEventListener('resize', this.resizeHandler);
    this.$nextTick(this.resizeHandler);
  },
  beforeDestroy() {
    window.addEventListener('resize', this.resizeHandler);
    if (this.timer) clearInterval(this.timer);
  },
};
</script>
