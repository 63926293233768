import request, { processResponse } from '@/utils/request';

const apiService = {
  async searchResources(condition) {
    return processResponse(await request({
      url: '/apix/apis/resources/query',
      params: condition,
    }));
  },
  async resources() {
    const { data } = await request({
      url: '/apix/apis/resources',
    });
    return data;
  },
  async resourceMathods(resourceId) {
    const { data } = await request({
      url: `/apix/apis/resources/${resourceId}/methods`,
    });
    return data;
  },
  async methods() {
    const { data } = await request({
      url: '/apix/apis/methods',
    });
    return data;
  },
  async method(id) {
    const { data } = await request({
      url: `/apix/apis/methods/${id}`,
    });
    return data;
  },
  async methodTags(id) {
    const { data } = await request({
      url: `/apix/apis/methods/${id}/tags`,
    });
    return data;
  },
  async methodParameters(id) {
    const { data } = await request({
      url: `/apix/apis/methods/${id}/parameters`,
    });
    return data;
  },
  async methodRequestBody(id) {
    const { data } = await request({
      url: `/apix/apis/methods/${id}/request-body`,
    });
    return data;
  },
  async methodResponses(id) {
    const { data } = await request({
      url: `/apix/apis/methods/${id}/responses`,
    });
    return data;
  },
  async methodDelete(id) {
    await request({
      method: 'delete',
      url: `/apix/apis/methods/${id}/delete`,
    });
  },
  // 메소드 추가
  async methodAdd(data) {
    await request({
      method: 'post',
      url: '/apix/apis/resource',
      data: data,
    });
  },
  // 메소드 수정
  async methodSave(methodId, body) {
    const { data } = await request({
      method: 'put',
      url: `/apix/apis/methods/${methodId}/detail`,
      data: body,
    });
    return data;
  },
  // resource의 endpoint 변경
  async updateResourceEndpoint(resourceId, data) {
    await request({
      method: 'put',
      url: `/apix/apis/resources/${resourceId}/update-endpoint`,
      data: data,
    });
  }
};

export default apiService;
