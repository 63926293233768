<template>
  <v-container fluid>
    <v-row>
      <v-col
        :cols="multiSelect ? 6 : 12"
      >
        <v-card>
          <v-card-title
            v-if="multiSelect"
            class="subtitle-2 text-left"
          >
            {{$t('apiResource.resource')}} {{$t('apiResource.list')}}
          </v-card-title>
          <v-card-text>
            <v-data-table
              v-model="selected.checkedResources"
              :headers="header.resource"
              :items="resources"
              :options.sync="pageOptions"
              :server-items-length="totalCount"
              :show-select="singleSelect || multiSelect"
              :single-select="singleSelect"
              :dense="dense"
              :footer-props="{
                'items-per-page-options': [5, 10, 20],
              }"
              @click:row="clickResource"
            >
              <template v-slot:top>
                <v-row
                  class="mb-1"
                >
                  <v-spacer />
                  <v-col
                    :cols="multiSelect ? 6 : 4"
                  >
                    <v-text-field
                      v-model="search.query"
                      :label="$t('apiResource.uri')"
                      dense
                      hide-details
                      clearable
                      @keyup.enter="searchChanged"
                    />
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        v-if="multiSelect"
        cols="6"
      >
        <v-row>
          <v-col
            cols="12"
          >
            <v-card>
              <v-card-title
                v-if="multiSelect"
                class="subtitle-2 text-left"
              >
                {{$t('apiResource.selected')}} {{$t('apiResource.resource')}} {{$t('apiResource.list')}}
              </v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="header.resource"
                  :items="selected.checkedResources"
                  sort-by="uri"
                  :dense="dense"
                  @click:row="clickResource"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-spacer />
          <v-col
            cols="auto"
          >
            <v-btn
              :disabled="selected.checkedResources.length === 0"
              @click="clickSelect"
            >
              {{$t('apiResource.select')}}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      v-if="singleSelect || showClose"
      justify="center"
    >
      <v-col
        v-if="singleSelect"
        cols="auto"
      >
        <v-btn
          :disabled="selected.checkedResources.length !== 1"
          @click="clickSelect"
        >
          {{$t('apiResource.select')}}
        </v-btn>
      </v-col>
      <v-col
        v-if="showClose"
        cols="auto"
      >
        <v-btn
          outlined
        >
          {{$t('apiResource.close')}}
        </v-btn>
      </v-col>
    </v-row>

    <!-- resource 상세 보기 -->
    <v-dialog
      v-if="dialog.detail"
      v-model="dialog.detail"
    >
      <v-card>
        <v-card-title
          class="blue text-left"
        >
          {{ selected.reesource.uri }}
        </v-card-title>
        <v-card-text>
          <api-resource-detail-view
            :resource="selected.reesource"
          />
        </v-card-text>
        <v-card-actions
          class="justify-center"
        >
          <v-btn
            outlined
            @click="() => { this.dialog.detail = false; this.selected.reesource = false; }"
          >
            {{$t('apiResource.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import apiService from '@/service/api.service';
import { convertDataTablePagingOptionsToObject } from '@/views/service/util';
import ApiResourceDetailView from '@/components/api/ApiResourceDetailView';
import {mapGetters} from "vuex";

const header =[
  {
    text: 'apiResource.uri',
    value: 'uri',
  },
];
export default {
  name: 'ApiResourceListView',
  components: { ApiResourceDetailView },
  props: {
    selectedItems: {
      type: Array,
      default: () => [],
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    multiSelect: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    // 닫기 버튼 표시 여부
    showClose: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      dialog: {
        detail: false,
      },
      header: {
        resource: this.$translate(header),
      },
      search: {
        query: null,
      },
      resources: [],
      totalCount: 0,
      pageOptions: null,
      selected: {
        reesource: null,
        checkedResources: [...this.selectedItems], // 선택된 리소스 목록
      }
    }
  },
  mounted() {
  },
  methods: {
    async fetchResources() {
      const params = convertDataTablePagingOptionsToObject(this.pageOptions);
      params.includeMethods = true;
      if (this.search.query) params.uri = this.search.query;
      const { data, totalCount } = await apiService.query(params);
      this.resources = data;
      this.totalCount = totalCount;
    },
    clickResource(item) {
      this.selected.reesource = item;
      this.dialog.detail = true;
    },
    // 선택 모드에서 선택 완료 시
    clickSelect() {
      this.$emit('select', this.selected.checkedResources);
    },
    // 닫기 버튼 활성화 시 닫기를 클릭했을 때
    clickClose() {
      this.$emit('close');
    },
    searchChanged() {
      if (this.pageOptions.page === 1) this.fetchResources(); // 자동으로 실행되지 않기 때문에 직접 조회한다.
      else this.pageOptions.page = 1; // watch에 의해 자동으로 실행된다.
    }
  },
  computed:{
    ...mapGetters(['currentLanguage']),
  },
  watch: {
    currentLanguage(){
      this.header.resource = this.$translate(header);
    },
    pageOptions() {
      this.fetchResources();
    }
  }
}
</script>

<style scoped>

</style>
