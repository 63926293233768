/** When your routing table is too long, you can split it into small modules */
import Layout from "@/views/layout/TheLayout.vue";

const commonRouter = [
  {
    path: "/",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/mypage",
        component: () => import("@/views/common/Mypage"),
        name: "menu.mypage",
        meta: {
          title: "menu.mypage",
          icon: "mdi-account-group-outline",
          noCache: true,
          affix: true
        },
        hidden: true
      }
    ]
  }
];

export default commonRouter;
