export const ORGANIZATION_TYPES = [
  { text: '전체', value: '' },
  { text: '정보제공자 (API 자체구축)', value: '01' },
  { text: '정보제공자 (중계기관 이용)', value: '02' },
  { text: '마이데이터사업자', value: '03' },
  { text: '중계기관', value: '04' },
  { text: '통합인증기관(인증서 본인확인기관)', value: '05' },
  { text: '통합인증기관(전자서명인증사업자)', value: '06' },
  { text: '종합포털', value: '07' },
  { text: '기타', value: '99' },
];
