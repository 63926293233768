<template>
  <v-container fluid>
    <v-row>
      <v-col
        cols="12"
      >
        <span class="Header-1-Bold">{{ $t('app.service') }} ({{ $t('app.app') }})</span>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer />
      <v-col
        cols="auto"
        class="pt-0"
      >
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-has-auth="'APP_CREATE'"
              v-bind="attrs"
              v-on="on"
              color="#000"
              large
              @click="onClickAppReloadAll"
            >
              mdi-reload
            </v-icon>
          </template>
          <span>{{ $t('app.gateway') }} {{ $t('app.apply') }}</span>
        </v-tooltip>
        <v-tooltip left>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-has-auth="'APP_CREATE'"
              v-bind="attrs"
              v-on="on"
              color="#000"
              large
              @click="clickCreateApp"
            >
              mdi-plus-box
            </v-icon>
          </template>
          <span>{{ $t('app.app') }} {{ $t('app.register') }}</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="4"
        v-for="app in apps"
        :key="app.id"
      >
        <app-card
          :app="app"
          style="cursor: pointer"
          @click.native="clickApp(app)"
        />
      </v-col>
    </v-row>

    <!-- 앱 추가 다이얼로그 -->
    <v-dialog
      v-model="dialog.create"
      max-width="600"
    >
      <v-card>
        <v-card-title
          class="grey lighten-2 text-left"
        >
          APP {{ $t('app.register') }}
        </v-card-title>
        <v-card-text>
          <v-form
            v-model="formValid"
            lazy-validation
          >
            <v-simple-table class="elevation-0">
              <template v-slot:default>
                <tbody>
                <tr>
                  <th>
                    {{ $t('app.name') }}
                    <span class="red--text"><strong>*</strong></span>
                  </th>
                  <td>
                    <v-text-field
                      ref="name"
                      v-model="editing.app.name"
                      :rules="[
                        (v) => !!v || $t('app.fieldRequiredMsg'),
                      ]"
                      hide-details="auto"
                      outlined
                    />
                  </td>
                </tr>
                <tr>
                  <th>{{ $t('app.description') }}</th>
                  <td>
                    <v-textarea
                      v-model="editing.app.description"
                      class="my-1"
                      hide-details
                      outlined
                    />
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="primary"
            :disabled="!formValid"
            @click="createApp"
          >
            {{ $t('app.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
table > tbody > tr > th {
  background-color: rgb(245, 245, 245);
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>

<script>
import request from '@/utils/request';
import AppCard from '@/components/app/AppCard';
import appService from '@/service/app.service';

export default {
  name: 'AppManagement',
  components: { AppCard },
  data: () => ({
    dialog: {
      create: false,
    },
    apps: [],
    formValid: true,
    editing: {
      app: {
        name: '',
        description: '',
      }
    }
  }),
  methods: {
    async fetchApps() {
      this.apps = await appService.list();
      // this.apps.forEach((app) => {
      //   app.organizationName = this.organizationName(app);
      //   app.ownerName = this.ownerName(app);
      // });
    },
    async onClickAppReloadAll() {
      await request({
        url: '/gw/refresh-application',
        method: 'get',
      });
    },
    clickAddNewApp() {
      this.$refs.refAppListView.createNew();
    },
    clickApp(app) {
      this.$router.push(`/apps/${app.id}`);
    },
    clickCreateApp() {
      this.editing.app = {
        app: '',
        description: '',
      };
      this.dialog.create = true;
    },
    async createApp() {
      await appService.create(this.editing.app);
      await this.fetchApps();
      this.dialog.create = false;
    }
  },
  activated() {
    this.fetchApps();
  },
};
</script>
