import request, {processResponse} from '@/utils/request';

const URL_PREFIX = '/apix/forums';

const forumService = {
  async info(forumId) {
    const {data} = await request.get(`${URL_PREFIX}/${forumId}`);
    return data;
  },
  async listPosts(forumId, params) {
    return processResponse(await request.get(`${URL_PREFIX}/${forumId}/posts`, {
      params: params,
    }));
  },
  async readPost(forumId, postId) {
    const {data} = await request.get(`${URL_PREFIX}/${forumId}/posts/${postId}`);
    return data;
  },
  async writePost(forumId, body) {
    const {data} = await request.post(`${URL_PREFIX}/${forumId}/posts`, body);
    return data;
  },
  async updatePost(forumId, postId, body) {
    const {data} = await request.put(`${URL_PREFIX}/${forumId}/posts/${postId}`, body);
    return data;
  },
  async deletePost(forumId, postId) {
    await request.delete(`${URL_PREFIX}/${forumId}/posts/${postId}`);
  },
  async createReply(forumId, parentPostId, body) {
    const {data} = await request.post(`${URL_PREFIX}/${forumId}/posts/${parentPostId}/replies`, body);
    return data;
  },
  async fileUpload(formdata) {
    return await request.post(`/apix/files`, formdata);
  },
};

export default forumService;
