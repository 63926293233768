<template>
  <v-app id="intro">
    <v-main>
      <v-parallax
        id="scrolling-hd"
        height="520"
        src="@/assets/intro_main_bg.svg"
      >
        <div class="offset-md-1 main_copy">
          {{$t('intro.openApi')}} <br/>
          {{$t('intro.forDevelopers')}}
        </div>
      </v-parallax>
    </v-main>
    <dashboard-core-app-bar-front/>
    <v-container style="max-width: 1200px !important">
      <!--      <div class="mx-auto pa-0 d-sm-flex d-block" style="max-width: 1200px">-->
      <!--        <v-subheader class="intro_title"> <b>API </b> List </v-subheader>-->
      <!--      </div>-->
      <!--      <div-->
      <!--        max-width="1200"-->
      <!--        class="d-sm-flex mx-auto justify-space-between"-->
      <!--        no-gauter-->
      <!--      >-->
      <!--        <template v-for="apilist in apiLists">-->
      <!--          <v-card-->
      <!--            :key="apilist.title"-->
      <!--            tile-->
      <!--            elevation="0"-->
      <!--            max-width="350"-->
      <!--            class="mt-0 mb-3 mx-4"-->
      <!--          >-->
      <!--            <v-list-item>-->
      <!--              <v-list-item-content class="text-wrap d-inlin-block">-->
      <!--                <v-img-->
      <!--                  max-width="350"-->
      <!--                  height="155"-->
      <!--                  :src="apilist.imgsrc"-->
      <!--                  aspect-ratio="1"-->
      <!--                />-->
      <!--                <v-list-item-title class="pt-4 pl-5">{{-->
      <!--                  apilist.title-->
      <!--                }}</v-list-item-title>-->
      <!--                <v-list-item-subtitle class="py-3 px-5 text-wrap">{{-->
      <!--                  apilist.subtitle-->
      <!--                }}</v-list-item-subtitle>-->
      <!--              </v-list-item-content>-->
      <!--            </v-list-item>-->
      <!--          </v-card>-->
      <!--        </template>-->
      <!--      </div>-->
      <div
        class="mx-auto pa-0 d-sm-flex d-block"
        style="max-width: 1200px; width: 100%"
      >
        <v-subheader class="intro_title mt-5">
          <span>{{$t('intro.notice')}}</span>
          <v-btn class="ml-auto" float-right text @click="forumClick"> {{$t('intro.seeMore')}} ></v-btn>
        </v-subheader>
      </div>
      <div
        class="d-sm-flex mx-auto mb-16"
        max-width="1200"
        no-gauter
      >
        <template v-for="notice in showNotices">
          <v-card
            :key="notice.id"
            class="notice mt-0 mb-3 mx-4"
            elevation="0"
            max-width="350"
            style="width: 100%"
            tile
            @click="noticeClick(notice)"
          >
            <v-list-item>
              <v-list-item-content class="text-wrap d-inlin-block">
                <v-list-item-title class="pt-4 px-3">
                  {{ notice.title }}
                </v-list-item-title>
                <v-list-item-subtitle class="pt-3 px-3">
                  {{$t('intro.numberOfViews')}} : {{ notice.readCount }}
                </v-list-item-subtitle>
                <v-list-item-subtitle class="pt-3 px-3 pb-3 wrap-text">
                  {{ notice.content }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </template>
      </div>
    </v-container>
    <dashboard-core-footer/>

    <!-- 공지사항 상세 다이얼로그 -->
    <v-dialog
      v-if="selectedNotice"
      v-model="dialog.detail"
      max-height="900px"
      max-width="900px"
      persistent
      @keydown.esc="dialog.detail = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left">{{$t('intro.notice')}}</v-card-title>
        <v-divider/>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-simple-table class="elevation-0">
                  <template>
                    <!-- 보기 모드인 경우 -->
                    <tbody v-if="dialog.detail">
                    <tr>
                      <th style="width: 110px">{{$t('intro.writer')}}</th>
                      <td>
                        {{ selectedNotice.writer.id }}
                      </td>
                      <th style="width: 110px">{{$t('intro.numberOfReading')}}</th>
                      <td>
                        {{ selectedNotice.readCount }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{$t('intro.title')}}</th>
                      <td colspan="3">
                        {{ selectedNotice.title }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{$t('intro.content')}}</th>
                      <td colspan="3" style="word-break:break-all;">
                        <markdown-preview
                          :text="selectedNotice.content"/>
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn v-if="dialog.detail" outlined @click="dialog.detail = false; fetchPost();">
            {{$t('intro.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 공지사항 목록 다이얼로그 -->
    <v-dialog
      v-model="dialog.addDetail"
      max-height="900px"
      max-width="900px"
      persistent
      @keydown.esc="dialog.addDetail = false"
    >
      <v-card>
        <v-card-title>
          {{$t('intro.notice')}} {{$t('intro.list')}}
        </v-card-title>
        <v-divider/>
        <!-- 목록 -->
        <v-card-text>
          <v-data-table
            :headers="headers.post"
            :item-class="(item) => !selectedNotice || selectedNotice !== item ? '' : 'white'"
            :items="notices"
            @click:row="noticeClick"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn v-if="dialog.addDetail" outlined @click="dialog.addDetail = false; fetchPost();">
            {{$t('intro.close')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>
<style>
.wrap-text {
  word-wrap: break-word;
}
</style>
<script>
import {mapGetters} from 'vuex';
import request from '@/utils/request';
import MarkdownPreview from "@/components/forum/MarkdownPreview";

const HEADER_POST = [
  {
    text: 'intro.no',
    value: 'id',
    sortable: false,
  },
  {
    text: 'intro.title',
    value: 'title',
  },
  {
    text: 'intro.numberOfReadings',
    value: 'readCount',
  },
  {
    text: 'intro.writer',
    value: 'writer.id',
  },
];
export default {
  name: 'Intro',

  components: {
    DashboardCoreAppBarFront: () => import('../layout/TempHeaderMain'),
    DashboardCoreFooter: () => import('../layout/TempFooter'),
    MarkdownPreview
  },
  computed: {
    ...mapGetters(['user', 'name', 'avatar', 'status','currentLanguage']),
  },

  data() {
    return {
      headers: {
        post: this.$translate(HEADER_POST),
      },
      // apiLists: [
      //   {
      //     imgsrc: require('@/assets/intro_img1.svg'),
      //     title: '도시생활지도',
      //     subtitle:
      //       '생활에 유익한 정보를 지도에서 볼 수 있도록 오픈 API를 제공합니다.',
      //   },
      //   {
      //     imgsrc: require('@/assets/intro_img2.svg'),
      //     title: '오픈뱅킹',
      //     subtitle:
      //       '금융서비스를 편리하게 개발할 수 있도록 표준화된 인프라를 제공합니다.',
      //   },
      //   {
      //     imgsrc: require('@/assets/intro_img3.svg'),
      //     title: '정책 뉴스',
      //     subtitle:
      //       '기관명, 주제/분야 등 입력을 통해 정책뉴스 목록과 상세정보 등 다양한 정보를 제공합니다.',
      //   },
      // ],
      notices: [],
      showNotices: [],
      selectedNotice: [],
      dialog: {
        detail: false,
        addDetail: false
      },
    };
  },
  mounted() {
    console.log('mounted');
    this.fetchPost();
  },
  methods: {
    async fetchPost() {
      var notExistPostMsg = this.$t('intro.notExistPostMsg');
      console.log('fetchPost');
      const {data} = await request({
        url: '/apix/forums/1/posts',
        method: 'get',
      });
      this.notices = data;
      this.showNotices = data.slice(0, 3);

      console.log('Noticde Data', data);
      if (this.notices.length === 0) {
        this.showNotices = [{
          'title': `${notExistPostMsg}`,
          'content': `${notExistPostMsg}`,
          'readCount': 0,
        }];
      }
    },
    forumClick() {
      this.dialog.addDetail = true;
      this.fetchPost();
    },
    async noticeClick(notice) {
      const {data} = await request({
        url: `/apix/forums/1/posts/${notice.id}`,
        method: 'get',
      });
      this.selectedNotice = data;
      this.dialog.detail = true;
    }
  },
  watch:{
    currentLanguage(){
      this.headers.post = this.$translate(HEADER_POST);
    }
  }
};
</script>
