<template>
  <v-container>
    <v-row
      justify="center"
    >
      <v-col
        cols="4"
      >
        <v-card
          min-width="300px"
        >
          <v-card-title
            class="warning"
          >
            {{$t('passwordChange.password')}} {{$t('passwordChange.change')}}
          </v-card-title>
          <v-card-text>
            <div
              class="subtitle-1 mt-3 mb-7"
            >
              {{$t('passwordChange.changePasswordContinueUsingMsg')}}
            </div>
            <password-change
              @password-changed="onPasswordChanged"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex';
import PasswordChange from '@/components/PasswordChange';

export default {
  name: 'PasswordChangeView',
  components: { PasswordChange },
  data: () => ({}),
  computed: {},
  methods: {
    ...mapActions([
      'addAlert',
      'GetUserInfo'
    ]),
    async onPasswordChanged() {
      var changedPasswordMsg = this.$t('passwordChange.changedPasswordMsg');
      this.addAlert(`${changedPasswordMsg}`);
      await this.GetUserInfo();
    }
  }
}
</script>
