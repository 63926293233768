<!-- 앱 상세보기 화면 -->
<template>
  <v-container
    v-if="app"
    fluid
  >
    <v-row>
      <v-col
        cols="12"
      >
        <span class="Header-1-Bold">{{ $t('app.service') }} ({{ $t('app.app') }}) - {{ app.name }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <!-- 보기 모드인 경우 -->
            <template v-if="!internalMode">
              <!-- 기본 정보 -->
              <v-simple-table class="elevation-0">
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <th style="width: 150px">{{ $t('app.name') }}</th>
                    <td>
                      {{ app.name }}
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('app.app') }}{{ $t('app.key') }}</th>
                    <td>
                      {{ keyAndSecret && keyAndSecret.appKey }}
                      <v-dialog
                        v-if="keyAndSecret && keyAndSecret.appSecret"
                        v-model="dialog.secret"
                        max-width="350px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="primary"
                            dark
                            style="background-color: rgb(117, 117, 117)"
                            x-small
                          >
                            {{ $t('app.secret') }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            class="headline"
                            style="background-color: rgb(245, 245, 245)"
                          >
                            {{ $t('app.app') }} {{ $t('app.secret') }}
                          </v-card-title>
                          <v-card-text
                          >{{ keyAndSecret.appSecret }}
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('app.scope') }}</th>
                    <td>
                      <v-chip
                        v-for="scope in app.scopes"
                        :key="scope.scope"
                        class="mr-1 mt-1"
                        color="green"
                        small
                        text-color="white"
                      >
                        {{ scope }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <th>Callback URLS</th>
                    <td>
                      <v-chip
                        v-for="url in app.callbackUrl"
                        :key="url"
                        class="ma-2 mt-1"
                        color="cyan"
                        label
                        small
                        text-color="white"
                      >
                        {{ url }}
                      </v-chip>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('app.description') }}</th>
                    <td>
                      {{ app.description }}
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br/>
              <!-- 쿼터 정보 -->
              <v-simple-table class="elevation-0">
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <th style="width: 150px">{{ $t('app.quota') }}</th>
                    <td>
                      <v-row
                        align="center"
                      >
                        <v-col cols="3">
                          <v-select
                            v-model="app.quotaType"
                            :items="select_quota_type"
                            hide-details
                            outlined
                            readonly
                            dense
                          />

                        </v-col>
                        <v-col cols="3">
                          <v-select
                            v-model="app.quotaPeriod"
                            :items="select_quota_period"
                            hide-details
                            outlined
                            readonly
                            dense
                          />
                        </v-col>
                        <v-col>
                          {{ app.quotaLimit }} {{ $t('app.case') }}
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br/>
              <!-- IP 정보 -->
              <v-simple-table class="elevation-0">
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <th style="width: 150px">{{ $t('app.allowed') }} {{ $t('app.ip') }}</th>
                    <td>
                      {{ app.allowIp }}
                    </td>
                  </tr>
                  <tr>
                    <th>Resource</th>
                    <td>
                      <v-chip
                        v-for="resource in app.apiResources"
                        :key="resource.resourceId"
                        :color="color(resource.acceptYn)"
                        class="mr-1 mt-1"
                        small
                        text-color="white"
                      >
                        {{ resource.uri }} -
                        {{ requestStatus(resource.acceptYn) }}
                      </v-chip>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>

            <!-- 수정 모드인 경우 -->
            <template v-if="internalMode === 'modify'">
              <!-- 기본 정보 -->
              <v-simple-table class="elevation-0">
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <th style="width: 110px">{{ $t('app.id') }}</th>
                    <td>
                      {{ app.id }}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      {{ $t('app.name') }}
                      <span class="red--text"><strong>*</strong></span>
                    </th>
                    <td>
                      <v-text-field
                        ref="name"
                        v-model="modifying.app.name"
                        :error-messages="errorMessages"
                        :rules="[
                            () => !!modifying.app.name || $t('app.fieldRequiredMsg'),
                          ]"
                        hide-details
                        outlined
                        dense
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('app.app') }}{{ $t('app.key') }}</th>
                    <td>
                      {{ keyAndSecret && keyAndSecret.appKey }}
                      <v-dialog
                        v-if="keyAndSecret && keyAndSecret.appSecret"
                        v-model="dialog.secret"
                        max-width="350px"
                        @keydown.esc="dialog.secret = false"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            class="primary"
                            dark
                            style="background-color: rgb(117, 117, 117)"
                            x-small
                          >
                            {{ $t('app.secret') }}
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title class="headline">
                            {{ $t('app.app') }} {{ $t('app.secret') }}
                          </v-card-title>
                          <v-card-text>
                            {{ keyAndSecret.appSecret }}
                          </v-card-text>
                        </v-card>
                      </v-dialog>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('app.scope') }}</th>
                    <td>
                      <v-row align="center">
                        <v-col>
                          <v-chip
                            v-for="scope in modifying.app.scopes"
                            :key="scope.scope"
                            class="mr-1 mt-1"
                            color="green"
                            small
                            text-color="white"
                          >
                            {{ scope }}
                          </v-chip>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn
                            v-if="internalMode && authorities.includes(Authorities.appUpdate)"
                            class="primary"
                            small
                            style="background-color: rgb(117, 117, 117)"
                            @click="dialog.scope = true"
                          >
                            {{ $t('app.scope') }} {{ $t('app.add') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('app.callback') }} {{ $t('app.url') }}</th>
                    <td>
                      <v-chip
                        v-for="(url, index) in modifying.app.callbackUrl"
                        :key="url"
                        class="ma-2 mt-1"
                        close
                        color="cyan"
                        label
                        small
                        text-color="white"
                        @click:close="onDeleteCallbackUrl(index)"
                      >
                        {{ url }}
                      </v-chip>
                      <template v-if="internalMode">
                        <v-text-field
                          v-model="inputCallbackUrl"
                          hide-details
                          outlined
                          dense
                          :placeholder="$t('app.urlEnterMsg')"
                          @keydown.enter="addCallbackUrl"
                        />
                      </template>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('app.description') }}</th>
                    <td>
                      <v-textarea
                        v-model="modifying.app.description"
                        class="my-1"
                        hide-details
                        outlined
                        dense
                        rows="2"
                      />
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <br/>
              <!-- 쿼터 정보 -->
              <!--<v-simple-table class="elevation-0">-->
              <!--  <template v-slot:default>-->
              <!--    <tbody>-->
              <!--    <tr>-->
              <!--      <th style="width: 110px">쿼터 타입</th>-->
              <!--      <td>-->
              <!--        <v-select-->
              <!--          v-model="modifying.app.quotaType"-->
              <!--          :items="select_quota_type"-->
              <!--          hide-details-->
              <!--          outlined-->
              <!--          dense-->
              <!--        >-->
              <!--        </v-select>-->
              <!--      </td>-->
              <!--    </tr>-->
              <!--    <tr>-->
              <!--      <th>쿼터주기</th>-->
              <!--      <td>-->
              <!--        <v-select-->
              <!--          v-model="modifying.app.quotaPeriod"-->
              <!--          :items="select_quota_period"-->
              <!--          hide-details-->
              <!--          outlined-->
              <!--          dense-->
              <!--        >-->
              <!--        </v-select>-->
              <!--      </td>-->
              <!--    </tr>-->
              <!--    <tr>-->
              <!--      <th>쿼터 제한</th>-->
              <!--      <td>-->
              <!--        <v-text-field-->
              <!--          v-model="modifying.app.quotaLimit"-->
              <!--          hide-details-->
              <!--          outlined-->
              <!--          dense-->
              <!--          @input="acceptNumber"-->
              <!--        />-->
              <!--      </td>-->
              <!--    </tr>-->
              <!--    </tbody>-->
              <!--  </template>-->
              <!--</v-simple-table>-->
              <!--<br />-->
              <!-- IP 정보, Resource 정보 -->
              <v-simple-table class="elevation-0">
                <template v-slot:default>
                  <tbody>
                  <tr>
                    <th style="width: 110px">{{ $t('app.allowed') }} {{ $t('app.ip') }}</th>
                    <td>
                      {{ modifying.app.allowIp }}
                    </td>
                  </tr>
                  <tr v-if="!modifying.app.onRequestIp">
                    <th style="width: 110px">{{ $t('app.allowed') }} {{ $t('app.ip') }} {{ $t('app.application') }}</th>
                    <td>
                      <v-row align="center">
                        <v-col>
                          <v-text-field
                            v-model="modifying.app.requestIp"
                            hide-details
                            outlined
                            dense
                          />
                        </v-col>
                        <v-col cols="auto">
                          <v-btn
                            v-if="authorities.includes(Authorities.appUpdate)"
                            :disabled="!modifying.app.requestIp"
                            class="primary"
                            small
                            @click="clickRequestIPButton"
                          >
                            {{ $t('app.ip') }} {{ $t('app.request') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('app.resource') }} {{ $t('app.list') }}</th>
                    <td>
                      <v-row align="center">
                        <v-col>
                          <v-chip
                            v-for="(resource, index) in modifying.app.apiResources"
                            :key="resource.id"
                            :color="color(resource.acceptYn)"
                            class="mr-1 mt-1"
                            close
                            small
                            text-color="white"
                            @click:close="onDeleteRequestResource(index)"
                          >
                            {{ resource.uri }} -
                            {{ requestStatus(resource.acceptYn) }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                  <tr>
                    <th>{{ $t('app.resource') }} {{ $t('app.request') }}</th>
                    <td>
                      <v-row align="center">
                        <v-col>
                          <template v-if="selected.type === 'resources'">
                            <v-chip
                              v-for="resource in modifying.app
                                      .newResources"
                              :key="resource.id"
                              :color="color('R')"
                              class="mr-1 mt-1"
                              small
                              text-color="white"
                            >
                              {{ resource.uri }} - {{ $t('app.request') }} [{{ $t('app.resource') }}]
                            </v-chip>
                          </template>
                          <template v-if="selected.type === 'categories'">
                            <v-chip
                              v-for="category in newCategoriesResources"
                              :key="category"
                              :color="color('R')"
                              class="mr-1 mt-1"
                              small
                              text-color="white"
                            >
                              {{ category.name }} - {{ $t('app.request') }} [{{ $t('app.category') }}]
                            </v-chip>
                          </template>
                        </v-col>
                        <v-col cols="auto">
                          <v-btn
                            v-if="internalMode"
                            v-has-auth="Authorities.appUpdate"
                            class="primary"
                            small
                            @click="resourceDialog"
                          >
                            {{ $t('app.resource') }} {{ $t('app.request') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </td>
                  </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-spacer/>
      <v-col
        cols="auto"
      >
        <v-btn
          v-if="!internalMode"
          v-has-auth="Authorities.appUpdate"
          class="primary mr-2"
          elevation="0"
          @click="onClickModifyButton"
        >
          {{ $t('app.modify') }}
        </v-btn>
        <v-btn
          v-if="internalMode"
          outlined
          class="mr-2"
          @click="onClickCancelButton"
        >
          {{ $t('app.cancel') }}
        </v-btn>
        <v-btn
          v-if="internalMode"
          class="primary mr-2"
          elevation="0"
          @click="save"
        >
          {{ $t('app.save') }}
        </v-btn>
        <v-btn
          v-if="!internalMode" outlined @click="clickToList"
        >
          {{ $t('app.list') }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- 삭제 대상 코드 - 이전 코드 -->
    <v-card
      v-if="!app"
    >
      <v-card-title class="text-h4 text-left">{{ $t('app.app') }}</v-card-title>
      <v-divider/>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- 등록 모드인 경우 -->
              <template v-if="internalMode === 'create'">
              </template>

            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          v-if="!internalMode"
          v-has-auth="Authorities.appUpdate"
          class="primary"
          elevation="0"
          @click="onClickModifyButton"
        >
          {{ $t('app.modify') }}
        </v-btn>
        <v-btn v-if="internalMode" outlined @click="onClickCancelButton">
          {{ $t('app.cacnel') }}
        </v-btn>
        <v-btn
          v-if="internalMode"
          class="primary"
          elevation="0"
          @click="save"
        >
          {{ $t('app.save') }}
        </v-btn>
        <v-btn
          v-if="!internalMode" outlined @click="clickClose"
        >
          {{ $t('app.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- Resource Dialog-->
    <v-dialog
      v-if="dialog.resource"
      v-model="dialog.resource"
      max-width="500px"
      @keydown.esc="dialog.resource = false"
      scrollable
    >
      <v-card outlined tile>
        <v-card-title> {{ $t('app.resource') }} {{ $t('app.edit') }}</v-card-title>
        <v-card-text>
          <v-col cols="12">
            <v-select
              v-model="selected.type"
              :items="selects.types"
              hide-details
              :label="$t('app.view')"
              outlined
              @change="onChangeType"
            />
          </v-col>
          <v-text-field
            v-model="resource_search_filter"
            class="mx-3"
            clearable
            outlined
            prepend-inner-icon="search"
            @click:clear="clearSearch"
          >
          </v-text-field>
          <v-col v-if="selected.type === 'resources'">
            <v-list>
              <v-list-item-group
                v-model="modifying.app.newResources"
                multiple
              >
                <v-list-item
                  v-for="resource in filteredItems"
                  :key="resource.id"
                  :value="resource"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"/>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ $t('app.uri') }} : {{ resource.uri }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-for="item in resource.methods"
                        :key="item.id"
                        :value="item"
                      >
                        - {{ item.httpMethod }} | {{ item.summary }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
          <v-col v-if="selected.type === 'categories'">
            <v-list>
              <v-list-item-group
                v-model="newCategoriesResources"
                multiple
              >
                <v-list-item
                  v-for="category in categories"
                  :key="category.id"
                  :value="category"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"/>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ category.id }} | {{ category.name }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                        v-for="item in category.resources"
                        :key="item.id"
                        :value="item"
                      >
                        - {{ $t('app.uri') }} : {{ item.uri }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-col>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            class="primary"
            elevation="0"
            @click="dialog.resource = false"
          >
            {{ $t('app.close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 기관 선택 -->
    <v-dialog
      v-if="dialog.organization"
      v-model="dialog.organization"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          {{ $t('app.organization') }} {{ $t('app.select') }}
        </v-card-title>
        <v-card-text>
          <organization-list-view
            @on-select="(o) => this.selected.organization = o"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            outlined
            @click="dialog.organization = false"
          >
            {{ $t('app.cancel') }}
          </v-btn>
          <v-btn
            :disabled="!selected.organization"
            @click="applyOrganization"
          >
            {{ $t('app.apply') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Scope Dialog-->
    <v-dialog
      v-if="dialog.scope"
      v-model="dialog.scope"
      max-width="500px"
      @keydown.esc="dialog.scope = false"
    >
      <v-card>
        <v-card-title> {{ $t('app.scope') }} {{ $t('app.edit') }}</v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item-group v-model="modifying.app.scopes" multiple>
              <v-list-item
                v-for="scope in scopes"
                :key="scope.scope"
                :value="scope.scope"
              >
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox :input-value="active"/>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ scope.name }} | {{ scope.scope }}
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      {{ scope.description }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn class="primary" @click="dialog.scope = false"> {{ $t('app.close') }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import appService from '@/service/app.service';
import request from '@/utils/request';
import authorities from '@/locale/ko_KR/authorities';
import {mapGetters} from 'vuex';
import _ from 'lodash';
import OrganizationListView from '@/components/organization/OrganizationListView';

// const DEFAULT_APP = {
//   name: '',
//   // organization: {
//   //   name: '',
//   // },
//   keyAndSecret: {
//     appKey: null,
//     appSecret: null,
//   },
//   callbackUrl: [],
//   scopes: [],
//   owner_id: null,
// };

export default {
  name: 'AppManageDetailView',
  components: {OrganizationListView},
  props: {
    appId: {
      type: Number,
    },
    mode: {
      type: String,
    },
  },
  data: function () {
    return {
      Authorities: authorities,
      select_quota_type: [
        {
          text: this.$i18n.t('app.transaction'),
          value: 'transaction',
        },
        {
          text: this.$i18n.t('app.bandWidth'),
          value: 'bandwidth',
        },
      ],
      select_quota_period: [
        {
          text: this.$i18n.t('app.forDays'),
          value: 'day',
        },
        {
          text: this.$i18n.t('app.perHour'),
          value: 'hour',
        },
        {
          text: this.$i18n.t('app.perMinute'),
          value: 'min',
        },
        {
          text: this.$i18n.t('app.monthly'),
          value: 'month',
        },
      ],
      dialog: {
        organization: false,
        scope: false,
        secret: false,
        resource: false,
      },
      selects: {
        types: [
          {text: this.$i18n.t('app.resource'), value: 'resources'},
          {text: this.$i18n.t('app.category'), value: 'categories'},
        ],
      },
      resource_search_filter: '',
      internalMode: null,
      app: null,
      keyAndSecret: null,
      selected: {
        token: null,
        type: 'resources',
        organization: null,
      },
      modifying: {
        app: null,
      },
      inputCallbackUrl: null,
      errorMessages: '',
      formHasErrors: false,
      idx: 0,
      categories: [],
      newCategoriesResources: [],
      scopes: [],
      resources: [],
      deleteResources: [],
    };
  },
  computed: {
    ...mapGetters({
      loginUserId: 'id',
      loginUserName: 'name',
    }),
    ...mapGetters(['authorities']),
    filteredItems() {
      return _.orderBy(
        this.resources.filter((item) => {
          if (!this.resource_search_filter) return this.resources;
          return item.uri
            .toLowerCase()
            .includes(this.resource_search_filter.toLowerCase());
          //|| item.action.toLowerCase().includes(this.resource_search_filter.toLowerCase()));
        }),
        'headline'
      );
    },
    form() {
      return {
        name: this.modifying.app.name,
      };
    },
  },
  async mounted() {
    this.internalMode = this.mode;
    if (this.mode === 'create') {
      this.app = {};
      this.modifying.app = {
        name: null,
        description: null,
        //organization: null,
        creatorId: this.loginUserId,
        owner: {
          id: this.loginUserId,
          name: this.loginUserName,
        },
      };
    } else {
      await this.fetchApp();
    }
    await this.fetchScopes();
    await this.fetchResources();
  },
  async activated() {
    await this.fetchApp();
    await this.fetchScopes();
    await this.fetchResources();
  },
  methods: {
    async fetchApp() {
      // 상세정보를 읽어온다.
      this.app = await appService.get(this.appId);

      this.keyAndSecret = await appService.getKeyAndSecret(this.appId);
      // this.selectedAppBackup = item;
      //
      // this.selectedApp = { ...this.selectedAppBackup };
      // await this.fetchAppResources();

      //selected app 할 때 요청테이블에서 조회 해서
      //this.selectedApp.onRequestIp = this.onRequestIp;
      console.log('this.app 최종 : ', this.app);
      // await this.fetchAppIp();
      // this.dialog.detail = true; // 상세 보기 다이얼로그 열기
      const {data} = await request({
        method: 'get',
        url: `/apix/requests/appRequest/${this.appId}`,
      });
      this.app.onRequestIp = data.length > 0;
    },
    async fetchScopes() {
      const {data} = await request({
        url: '/apix/scopes',
        method: 'get',
      });
      this.scopes = data;
    },
    async fetchResources() {
      const {data} = await request({
        url: '/apix/apis/resources',
        method: 'get',
      });
      this.resources = data;
    },
    async fetchByCategory() {
      const {data} = await request({
        url: '/apix/apis/categories',
      });
      data.forEach((c) => (c.resources = []));
      this.categories = data;
      for (const cate of this.categories) {
        await this.fetchCategoryResource(cate);
      }
    },
    onClickCancelButton() {
      this.internalMode = null;
    },
    onClickModifyButton() {
      this.internalMode = 'modify';
      this.inputCallbackUrl = null;
      this.modifying.app = JSON.parse(JSON.stringify(this.app));
    },
    clickClose() {
      this.$emit('close');
    },
    clickToList() {
      this.$router.push('/apps');
    },
    onDeleteCallbackUrl(index) {
      this.modifying.app.callbackUrl.splice(index, 1);
    },
    addCallbackUrl() {
      if (!this.modifying.app.callbackUrl.includes(this.inputCallbackUrl))
        this.modifying.app.callbackUrl.push(this.inputCallbackUrl);
      this.inputCallbackUrl = null;
    },
    async save() {
      this.modifying.app.ownerId = this.loginUserId;
      var savedAppMsg = this.$t('app.savedAppMsg');
      var fieldRequiredMsg = this.$t('app.fieldRequiredMsg');
      if (!this.requiredValidate()) {
        let url = '/apix/apps';
        let method = 'post';

        if (this.modifying.app.id) {
          url = `/apix/apps/${this.modifying.app.id}`;
          method = 'put';
        }
        await request({
          url: url,
          method: method,
          data: this.modifying.app,
        });

        if (this.modifying.app.newResources != null && this.modifying.app.newResources.length > 0)
          await this.clickResourceRequestButton();

        if (this.newCategoriesResources != null && this.newCategoriesResources.length > 0) {
          const result = [];
          this.newCategoriesResources.forEach(function (resource) {
            resource.resources.forEach(function (word) {
              let me = {
                id: word.id,
                uri: word.uri,
              };
              result.push(me);
              return result;
            });
          });
          this.modifying.app.newResources = result;
          await this.clickResourceRequestButton();
        }

        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;">
                      <img max-width="100%" src="static/inzent/ico_complete.svg" /><br/>
                      <b>${savedAppMsg}</b>
                    </div>`,
        });

        //delete resource할거있나 확임
        if (this.deleteResources != null && this.deleteResources.length > 0)
          await this.deleteResource();

        this.internalMode = null;
        await this.fetchApp();
      } else {
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;">
                      <img max-width="100%" src="static/inzent/ico_fail.svg" /><br/>
                      <b>${fieldRequiredMsg}</b>
                    </div>`,
        });
      }
    },
    async resourceDialog() {
      var resourceNotFoundMsg = this.$t('app.resourceNotFoundMsg');
      if (this.modifying.app.apiResources != null && this.resources != null) {
        if (this.modifying.app.apiResources.length === this.resources.length) {
          await this.$confirm({
            message: `<div style="text-align:center; padding-bottom:15px;">
                        <img max-width="100%" src="static/inzent/ico_fail.svg" /><br/>
                        <b>${resourceNotFoundMsg}</b>
                      </div>`,
          });
          return;
        }
        for (
          let index = 0;
          index < this.modifying.app.apiResources.length;
          index++
        ) {
          for (let idx = 0; idx < this.resources.length; idx++) {
            if (this.modifying.app.apiResources[index].resourceId === this.resources[idx].id)
              this.resources.splice(idx, 1);
          }
        }
      }
      this.dialog.resource = true;
    },    //Chip 별 색깔 변경
    color(acceptYn) {
      let color = null;
      switch (acceptYn) {
        case 'Y':
          color = 'green';
          break;
        case 'R':
        case undefined:
          color = 'orange';
          break;
        case 'N':
          color = 'red';
          break;
        case 'W':
          color = 'blue';
      }
      return color;
    },
    requestStatus(acceptYn) {
      let status = null;
      switch (acceptYn) {
        case 'Y':
          status = this.$t('app.approval');
          break;
        case 'R':
        case undefined:
          status = this.$t('app.request');
          break;
        case 'N':
          status = this.$t('app.refusal');
          break;
        case 'W':
          status = this.$t('app.collection');
          break;
      }
      return status;
    },
    acceptNumber() {
      const x = this.modifying.app.quotaLimit.replace(/\D/g, '');
      console.log('this.value:', this.value, 'x:', x);
      this.modifying.app.quotaLimit = x;
    },
    clearSearch() {
      this.resource_search_filter = '';
    },
    onChangeType() {
      switch (this.selected.type) {
        case 'resources':
          this.fetchResources();
          break;
        case 'categories':
          this.fetchByCategory();
          break;
        default:
      }
    },
    // ip 요청
    async clickRequestIPButton() {
      let url = `/apix/apps/requestIp`;
      let method = 'put';
      let requestData = {
        id: this.modifying.app.id,
        name: this.modifying.app.name,
        allowIp: this.modifying.app.allowIp,
        requestIp: this.modifying.app.requestIp,
      };
      var requestedIpMsg = this.$t('app.requestedIpMsg');

      const {data} = await request({
        url: url,
        method: method,
        data: requestData,
      });
      console.log('request ip', data);
      await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;">
                    <img max-width="100%" src="static/inzent/ico_complete.svg" /><br/>
                    <b>${requestedIpMsg}</b>
                  </div>`,
      });
      this.modifying.app.onRequestIp = true;
    },
    requiredValidate() {
      this.formHasErrors = false;
      this.idx = 0;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        if (this.$refs[f] !== undefined) {
          this.$refs[f].validate(true);
          if (!this.$refs[f].validate(true)) this.idx = 1;
        }
      });
      return this.idx;
    },
    // app resource 삭제
    async deleteResource() {
      let requestData = {
        appId: this.app.id,
        resources: this.deleteResources,
      };
      await request({
        url: `/apix/appres`,
        method: 'delete',
        data: requestData,
      });

      this.dialog.detail = false;
      this.dialog.detailMode = null;
      this.deleteResources = [];
    },
    // APP 리소스 추가 요청
    async clickResourceRequestButton() {
      let url = `/apix/appres`;
      let method = 'post';
      let requestData = {
        appId: this.app.id,
        resources: this.modifying.app.newResources,
      };
      var requestedResourceMsg = this.$t('app.requestedResourceMsg');
      const {data} = await request({
        url: url,
        method: method,
        data: requestData,
      });
      console.log('save result', data);
      await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;">
                    <img max-width="100%" src="static/inzent/ico_complete.svg" /><br/>
                    <b>${requestedResourceMsg}</b>
                  </div>`,
      });
      this.dialog.resource = false;
      console.log('dialog status', this.dialog);
    },
    //resource 삭제 시
    onDeleteRequestResource(index) {
      this.deleteResources.push({
        appId: this.modifying.app.id,
        resourceId: this.modifying.app.apiResources[index].resourceId,
        acceptYn: this.modifying.app.apiResources[index].acceptYn,
      });
      this.modifying.app.apiResources.splice(index, 1);
    },
    clickOrganizationChange() {
      this.dialog.organization = true;
    },
    applyOrganization() {
      this.$set(this.modifying.app, 'organization', this.selected.organization);
      // this.modifying.app.organization = this.selected.organization;
      this.dialog.organization = false;
    }
  }
}
</script>

<style scoped>

</style>
