<!-- API의 메소드 목록 보기 -->
<template>
  <v-card outlined tile class="details">
    <v-card-subtitle v-if="responses" class="text-left">
      <template>
        <h3 class="pl-md-2">
          {{ $t('apiMethod.response') }}
          <simple-regist-dialog
            v-if="mode != null"
            :title="$t('apiMethod.add') + ' ' + $t('apiMethod.response')"
            :fields="[
              {
                text: $t('apiMethod.response') + ' '+ $t('apiMethod.code'),
                value: 'code',
                placeholder: '200',
              },
            ]"
            @addItem="addItem"
          />
        </h3>
      </template>
    </v-card-subtitle>
    <v-divider />

    <v-card-text>
      <v-expansion-panels v-model="panel" focusable popout multiple>
        <!-- 응답 -->
        <v-expansion-panel v-for="(value, name) in methodResponses" :key="name">
          <v-expansion-panel-header>
            <!-- 응답코드 -->
            {{ name }}
            <v-spacer />
            <div class="text-right mr-2">
              <v-dialog
                v-if="mode != null"
                v-model="dialog.code"
                persistent
                max-width="600px"
                @keydown.esc="dialog.code = false"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-tooltip top v-bind="attrs" v-on="on">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        @click.stop.prevent="openCodeDialog(name)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-pencil
                      </v-icon>
                    </template>
                    <span>{{$t('apiMethod.modify')}}</span>
                  </v-tooltip>
                </template>
                <v-card outlined tile class="mb-6">
                  <v-card-title class="text-left">
                    <h4>{{$t('apiMethod.response')}} {{$t('apiMethod.code')}} {{$t('apiMethod.modify')}}</h4>
                  </v-card-title>
                  <v-divider />
                  <v-card-text>
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          v-model="newCode"
                          :label="$t('apiMethod.response') + ' ' + $t('apiMethod.code')"
                          placeholder="200"
                          outlined
                          hide-details
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn outlined @click="closeCodeDialog"> {{$t('apiMethod.close')}} </v-btn>
                    <v-btn
                      elevation="0"
                      class="primary mr-1"
                      @click="updateResponseCode(name)"
                    >
                      {{$t('apiMethod.modify')}}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-tooltip top v-if="mode != null">
                <template v-slot:activator="{ on, attrs }" v-if="mode != null">
                  <v-icon
                    @click.stop.prevent="deleteItem(name)"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-delete
                  </v-icon>
                </template>
                <span>{{$t('apiMethod.delete')}}</span>
              </v-tooltip>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list>
              <!-- 설명   -->
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="col-md-2 font-weight-bold">
                    {{$t('apiMethod.description')}}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="!mode" class="col-md-10">
                    {{ value.description }}
                  </v-list-item-subtitle>
                  <v-text-field
                    v-else
                    :placeholder="$t('apiMethod.description')"
                    :value="value.description"
                    @change="updateResponseDescription(name, $event)"
                    hide-details
                    outlined
                  />
                </v-list-item-content>
              </v-list-item>

              <!-- 응답 Body   -->
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="col-md-12">
                    {{$t('apiMethod.body')}}
                    <!-- <v-spacer/>
                    <simple-regist-dialog
                      v-if="mode !=null"
                      title='응답 Body 추가'
                      :fields="responseBodyItems"
                      :addKey="name"
                      @addItem="addBodyItem"
                    /> -->
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <!-- Body -->
                    <api-method-content
                      :content="value.content"
                      :mode="mode"
                      @change="changeMethodContent"
                    />
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-card-text>
  </v-card>
</template>

<script>
import ApiMethodContent from "@/components/ApiMethodContent";
import SimpleRegistDialog from "@/components/SimpleRegistDialog";
import { mapGetters } from 'vuex';

const mediaTypeItems = [
  {
    text: "application/json",
    value: "application/json",
  },
  {
    text: "application/xml",
    value: "application/xml",
  },
];

const inItems = [
  {
    text: 'apiMethod.queryString',
    value: "query",
  },
  {
    text: 'apiMethod.pathVariable',
    value: "path",
  },
  {
    text: 'apiMethod.header',
    value: "header",
  },
];
const responseBodyItems = [
  {
    text:'apiMethod.contentType',
    value: "mediaType",
    required: true,
    type: "select",
    placeholder: "application/json",
    selectItems: mediaTypeItems,
  },
];

// const defaultCodeResponse={
//   description : '',
//   content : {
//     'application/json' : {
//       schema : {
//         type : 'object',
//         properties : {
//         }
//       }
//     }
//   }
// }
// ;

const defaultCodeResponse = {
  description: "",
  content: {},
};
const defaultResponseBody = {
  schema: {
    type: "object",
    properties: {},
  },
};
export default {
  name: "ApiMethodResponse",
  props: ["responses", "mode"],
  computed: {},
  components: {
    "api-method-content": ApiMethodContent,
    "simple-regist-dialog": SimpleRegistDialog,
  },
  mounted() {
    this.methodResponses = { ...this.responses };
  },
  data: () => ({
    dialog: {
      code: false,
    },
    inItems: this.$translate(inItems),
    panel: [0],
    methodResponses: {},
    newCode: "",
    newCodeResponse: {},
    responseBodyItems: this.$translate(responseBodyItems),
  }),
  methods: {
    updateResponseDescription(code, target) {
      this.methodContent[code].description = target;
      console.log(this.methodContent[code].description);
    },
    openCodeDialog(code) {
      this.dialog.code = true;
      this.newCode = code;
    },
    closeCodeDialog() {
      this.newCode = "";
      this.dialog.code = false;
    },
    updateResponseCode(code) {
      console.log(code);
      console.log(this.newCode);

      console.log(this.methodResponses);
      this.methodResponses[this.newCode] = { ...this.methodResponses[code] };
      delete this.methodResponses[code];

      console.log("updateResponseCode", this.methodResponses);
      this.closeCodeDialog();

      this.$emit("change", this.methodResponses);
    },

    changeMethodContent(content) {
      console.log("changeMethodContent", content);
      this.responses.content = { ...content };
      this.$emit("change", this.methodResponses);
    },
    addItem(item) {
      let codeResponse = JSON.parse(JSON.stringify({ ...defaultCodeResponse }));

      console.log("item ", item);
      console.log("methodResponses ", this.methodResponses);

      //  this.methodResponses[item.code] = codeResponse;
      let temp = { ...this.methodResponses };
      temp[item.code] = codeResponse;
      this.methodResponses = temp;
      console.log("addItem", this.methodResponses);
      this.$emit("change", this.methodResponses);
    },
    addBodyItem(item, code) {
      console.log("item ", item);
      console.log("code ", this.code);

      let responseBody = JSON.parse(JSON.stringify({ ...defaultResponseBody }));
      // if(item.mediaType) requestBody.content.mediaType = item.mediaType;

      //console.log('addItem ', parameter);
      let temp = { ...this.methodResponses };
      // let temp = {...this.methodResponses[code]};
      if (!temp[code].content) temp[code].content = {};
      temp[code].content[item.mediaType] = responseBody;

      // if(!this.methodResponses[code]) this.methodResponses[code]={};
      console.log("temp ", temp);

      this.methodResponses = { ...temp };

      console.log("methodResponses ", this.methodResponses);
      this.$emit("change", this.methodResponses);
    },
    deleteItem(code) {
      let temp = { ...this.methodResponses };
      delete temp[code];

      this.methodResponses = temp;
      this.$emit("change", this.methodResponses);
    },
    computed: {
      ...mapGetters(['authorities','currentLanguage']),
    },
  },
  watch:{
    currentLanguage() {
      this.inItems = this.$translate(inItems);
      this.responseBodyItems = this.$translate(responseBodyItems);
    },
  }
};
</script>
