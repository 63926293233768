// import { loginByEmail, getUserInfo } from '@/api/login';

/*
 * message = {
 *   type:
 *   message:
 *   duration:
 *   (show):
 * }
 */

const DEFAULT_DURATION = 5;

const ALERT_AVAILABLE_TYPES = [
  'success',
  'info',
  'warning',
  'error',
];

const alerts = {
  state: {
    messages: [],
    snackbar: {
      show: false,
      color: 'deep-purple accent-4',
      timeout: 5,
      message: null,
    },
    loadingbar: false,
  },

  getters: {
    alertMessages: (state) => {
      return state.messages.filter((s) => s.show);
    },
    snackbar: (state) => {
      return state.snackbar;
    },
    loadingbar: (state) => {
      return state.loadingbar;
    }
  },

  mutations: {
    ADD_ALERT: (state, payload) => {
      payload.show = true;
      if (!('duration' in payload)) {
        payload.duration = DEFAULT_DURATION;
      }
      state.messages.push(payload);
    },
    REMOVE_ALERT: (state, payload) => {
      const index = state.messages.indexOf(payload);
      if (index >= 0) {
        state.messages.splice(index, 1);
      }
    },
    SHOW_SNACKBAR: (state, payload) => {
      state.snackbar.message = payload.message;
      state.snackbar.color = payload.color;
      state.snackbar.show = true;
    },
    SHOW_LOADING: (state) => {
      state.loadingbar = true;
    },
    HIDE_LOADING: (state) => {
      state.loadingbar = false;
    }
  },

  actions: {
    addAlert: (context, payload) => {
      if (typeof payload === 'string') {
        // string 형식인 경우 기본 값으로 설정한다.
        payload = {
          type: 'info',
          message: payload,
        };
      } else {
        if (payload.type && !ALERT_AVAILABLE_TYPES.includes(payload.type)) {
          payload.type = 'info'; // type이 잘못 지정된 경우 'info'를 사용하도록 한다.
        }
      }
      context.commit('ADD_ALERT', payload);
      if (payload.duration && payload.duration > 0) {
        setTimeout(() => {
          context.commit('REMOVE_ALERT', payload);
        }, payload.duration * 1000);
      }
    },
    showSnackbar: (context, payload) => {
      if (typeof payload === 'string') {
        payload = {
          message: payload,
          color: 'success',
        }
      }
      context.commit('SHOW_SNACKBAR', payload);
    },
    showErrorSnackbar: (context, payload) => {
      if (payload instanceof Error) {
        payload = {
          message: payload.response.data.detail || payload.response.data.title,
          color: 'error',
        }
      } else if (typeof payload === 'string') {
        payload = {
          message: payload,
          color: 'error',
        }
      }
      context.commit('SHOW_SNACKBAR', payload);
    },
    showLoading: (context) => {
      context.commit('SHOW_LOADING');
    },
    hideLoading: (context) => {
      context.commit('HIDE_LOADING');
    }
  },
};

export default alerts;
