<!-- API의 메소드 목록 보기 -->
<template>
  <v-card outlined tile class="mb-6" v-if="methodParameters">
    <v-card-subtitle class="text-left">
      <h3 class="pl-md-2">
        {{ $t('apiMethod.parameter') }}
        <simple-regist-dialog
          v-if="mode != null"
          :title="$t('apiMethod.add')+' '+$t('apiMethod.parameter')"
          :fields="parameterHeaders"
          @addItem="addItem"
        />
      </h3>
    </v-card-subtitle>
    <v-divider />
    <v-card-text>
      <!-- Parameter -->
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th width="20%">{{ $t('apiMethod.name') }}</th>
              <th width="20%">{{ $t('apiMethod.transfer') }} {{ $t('apiMethod.method') }}</th>
              <th width="20%">{{ $t('apiMethod.description') }}</th>
              <th width="10%">{{ $t('apiMethod.required') }} {{ $t('apiMethod.status') }}</th>
              <th width="10%">{{ $t('apiMethod.type') }}</th>
              <th width="10%">{{ $t('apiMethod.sample') }}</th>
              <th width="10%">{{ $t('apiMethod.note') }}</th>
            </tr>
          </thead>
          <tbody v-if="!methodParameters || methodParameters.length == 0">
            <tr class="v-data-table__empty-wrapper">
              <td colspan="7">{{ $t('apiMethod.noDataMsg') }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr v-for="item in methodParameters" :key="item.id">
              <td>
                <!-- 키 -->
                <template v-if="!mode">
                  {{ item.name }}
                </template>
                <v-text-field
                  v-else
                  placeholder="key"
                  v-model="item.name"
                  outlined
                  hide-details
                  @change="$emit('change', methodParameters)"
                />
              </td>
              <td>
                <!-- 전송 방법 -->
                <v-select
                  v-if="!mode"
                  readonly
                  placeholder="Query String"
                  :items="inItems"
                  v-model="item.in"
                  outlined
                  hide-details
                  @change="$emit('change', methodParameters)"
                />
                <v-select
                  v-else
                  placeholder="Query String"
                  :items="inItems"
                  v-model="item.in"
                  outlined
                  hide-details
                  @change="$emit('change', methodParameters)"
                />
              </td>
              <td>
                <!-- 설명 -->
                <template v-if="!mode">
                  {{ item.description }}
                </template>
                <v-text-field
                  v-else
                  :placeholder="$t('apiMethod.description')"
                  v-model="item.description"
                  outlined
                  hide-details
                  @change="$emit('change', methodParameters)"
                />
              </td>
              <td>
                <!-- 필수여부 -->
                <v-select
                  v-if="!mode"
                  readonly
                  :placeholder="$t('apiMethod.required')"
                  :items="requiredItems"
                  v-model="item.required"
                  outlined
                  hide-details
                  @change="$emit('change', methodParameters)"
                />
                <v-select
                  v-else
                  :placeholder="$t('apiMethod.required')"
                  :items="requiredItems"
                  v-model="item.required"
                  outlined
                  hide-details
                  @change="$emit('change', methodParameters)"
                />
              </td>
              <td>
                <!-- 타입 -->
                <v-select
                  v-if="!mode"
                  readonly
                  placeholder="string"
                  :items="propertyTypeItems"
                  v-model="item.schema.type"
                  outlined
                  hide-details
                  @change="$emit('change', methodParameters)"
                />
                <v-select
                  v-else
                  placeholder="string"
                  :items="propertyTypeItems"
                  v-model="item.schema.type"
                  outlined
                  hide-details
                  @change="$emit('change', methodParameters)"
                />
              </td>
              <td>
                <!-- 샘플 -->
                <template v-if="!mode">
                  {{ !item.schema ? "" : item.example }}
                </template>
                <v-text-field
                  v-else
                  placeholder="value"
                  v-model="item.example"
                  outlined
                  hide-details
                  @change="$emit('change', methodParameters)"
                />
              </td>
              <td>
                <v-tooltip top v-if="mode != null">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click.stop.prevent="deleteItem(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>{{ $t('apiMethod.delete') }}</span>
                </v-tooltip>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>
<style>
.theme--light.v-select {
  font-size: 0.875rem;
}
.theme--light.v-select .v-select__selection--disabled {
  color: rgba(0, 0, 0, 0.87);
}
</style>
<script>
import SimpleRegistDialog from "@/components/SimpleRegistDialog";
import { mapGetters } from 'vuex';

const inItems = [
  {
    text: 'apiMethod.queryString',
    value: "query",
  },
  {
    text: 'apiMethod.pathVariable',
    value: "path",
  },
  {
    text: 'apiMethod.header',
    value: "header",
  },
];
const requiredItems = [
  {
    text: 'apiMethod.required',
    value: true,
  },
  {
    text: 'apiMethod.notRequired',
    value: false,
  },
];
const propertyTypeItems = [
  {
    text: "string",
    value: "string",
  },
  {
    text: "integer",
    value: "integer",
  },
  {
    text: "boolean",
    value: "boolean",
  },
  {
    text: "number",
    value: "number",
  },
];
const parameterHeaders = [
  {
    text: 'apiMethod.name',
    value: "name",
    required: true,
    type: "text",
    placeholder: 'apiMethod.key',
    width: "20%",
  },
  {
    text: 'apiMethod.transfer'+' '+'apiMethod.way',
    value: "in",
    required: true,
    type: "select",
    placeholder: 'apiMethod.pathVariable',
    selectItems: inItems,
    width: "20%",
  },
  {
    text: 'apiMethod.description',
    value: "description",
    required: true,
    placeholder: 'apiMethod.parameter'+'apiMethod.description',
    type: "text",
    width: "20%",
  },
  {
    text: 'apiMethod.required'+' '+'apiMethod.status',
    value: "required",
    required: true,
    type: "select",
    placeholder: 'apiMethod.required',
    selectItems: requiredItems,
    width: "10%",
  },
  {
    text: 'apiMethod.type',
    value: "type",
    required: true,
    type: "select",
    placeholder: "string",
    selectItems: propertyTypeItems,
    width: "10%",
  },
  {
    text: 'apiMethod.sample',
    value: "sample",
    required: true,
    placeholder: "value",
    type: "text",
    width: "10%",
  },
];
const defaultParameter = {
  name: "",
  in: "",
  description: "",
  required: false,
  style: "SIMPLE",
  explode: false,
  schema: {
    type: "",
    example: "",
  },
};
export default {
    name: "ApiMethodParameter",
  props: ["parameters", "mode"],
  computed: {
    ...mapGetters(['currentLanguage']),
    // methodParameters: {
    //   get(){
    //     return this.parameters
    //   },
    //   set(parameters){
    //     // this.parameters=[...parameters];
    //     return parameters
    //   }
    // },
  },
  mounted() {
    this.methodParameters = [...this.parameters];
    this.methodParameters.forEach((param) => {
      if (param.style != null) param.style = param.style.toUpperCase();
    });

    console.log("methodParameters ", this.methodParameters);
  },
  components: {
    "simple-regist-dialog": SimpleRegistDialog,
  },
  data: () => ({
    methodParameters: [],
    parameterHeaders: this.$translate(parameterHeaders),
    inItems: this.$translate(inItems),
    requiredItems:this.$translate(requiredItems),
    propertyTypeItems: this.$translate(propertyTypeItems),
  }),
  methods: {
    addItem(item) {
      let parameter = JSON.parse(JSON.stringify({ ...defaultParameter }));
      if (item.name) parameter.name = item.name;
      if (item.in) parameter.in = item.in;
      if (item.description) parameter.description = item.description;
      if (item.required) parameter.required = item.required;
      if (item.type) parameter.schema.type = item.type;
      if (item.sample) parameter.schema.example = item.sample;

      console.log("addItem ", parameter);

      // let temp = this.methodParameters;
      // temp.push({...parameter});
      // console.log('temp ', temp);

      if (!this.methodParameters) this.methodParameters = [];
      this.methodParameters.push({ ...parameter });
      //this.methodParameters=[...temp];

      console.log("methodParameters ", this.methodParameters);
      this.$emit("change", this.methodParameters);
    },
    deleteItem(item) {
      let index = this.methodParameters.indexOf(item);
      this.methodParameters.splice(index, 1);
      this.$emit("change", this.methodParameters);
    },
  },
  watch: {
    currentLanguage() {
      this.parameterHeaders = this.$translate(parameterHeaders);
      this.inItems = this.$translate(inItems);
      this.requiredItems = this.$translate(requiredItems);
      this.propertyTypeItems = this.$translate(propertyTypeItems);
    },
  }
};
</script>
