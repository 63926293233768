import Vue from 'vue';
import Vuex from 'vuex';
import syncStorage from './plugins/syncStorage';
import permission from './modules/permission';
import settings from './modules/settings';
import user from './modules/user';
import alerts from '@/store/modules/alerts';
import serverSetting from '@/store/modules/serverSetting';
import realtime from './modules/realtime';
import commons from '@/store/modules/commons';
import translationStore from '@/store/modules/translation-store';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    permission,
    settings,
    user,
    alerts,
    serverSetting,
    realtime,
    commons,
    translationStore,
  },

  plugins: [syncStorage({})],

  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    pagination:{
      rowsPerPageItems: [5, 10, 20, 50],
    }
  },
  mutations: {
    SET_BAR_IMAGE(state, payload) {
      state.barImage = payload;
    },
    SET_DRAWER(state, payload) {
      state.drawer = payload;
    }
  },
  actions: {},
  getters: {},
});

export default store;
