<template>
  <div>
    <v-card
      v-if="!isCreate && !isModify"
    >
      <div
        class="grey lighten-2"
      >
        <v-list-item two-line>
          <v-list-item-content
            class="pb-0"
          >
            <v-list-item-title
              class="text-h3"
            >
              {{ post.title }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          class="grow"
        >
          <v-list-item-content
            class="pt-0"
          >
            {{ post.writer.name }}
          </v-list-item-content>

          <v-row
            align="center"
            class="mr-3"
            justify="end"
          >
            <v-icon
              class="mr-1"
            >
              mdi-text-box-outline
            </v-icon>
            <span class="subheading mr-4">
              {{ post.readCount }}
            </span>
            <v-icon
              class="mr-1"
            >
              mdi-clock-outline
            </v-icon>
            <span>
              <format-date-time :value="post.createdDate"/>
            </span>
          </v-row>
        </v-list-item>
      </div>

      <v-divider/>

      <v-card-text>
        <markdown-preview :text="post.content"/>
      </v-card-text>

      <v-card-text>
        <!-- 답글 보기 -->
        <v-row
          v-for="reply in post.replies"
          :key="reply.id"
        >
          <v-col>
            <apim-forum-post-reply
              :forum="forum"
              :parent-post="post"
              :post="reply"
              @modified="(item) => updateReply(reply, item)"
              @removed="fetchPost"
            />
          </v-col>
        </v-row>
        <!-- 답글 쓰기 -->
        <v-row
          v-if="post.permission.reply"
        >
          <v-col>
            <apim-forum-post-reply
              :forum="forum"
              :parent-post="post"
              mode="create"
              @replied="fetchPost"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider/>

      <v-card-actions
        class="justify-end"
      >
        <v-btn
          v-if="post.permission.modify"
          @click="clickModify"
        >
          {{ $t('forum.modify') }}
        </v-btn>
        <v-btn
          v-if="post.permission.remove"
          color="warning"
          @click="clickDelete"
        >
          {{ $t('forum.delete') }}
        </v-btn>
        <v-btn
          outlined
          @click="$emit('click:close')"
        >
          {{ $t('forum.close') }}
        </v-btn>
      </v-card-actions>
    </v-card>

    <!-- 글쓰기 / 글수정 -->
    <v-card
      v-else
    >
      <v-card-title>
        <v-text-field
          v-model="post.title"
          :label="$t('forum.title')"
        />
      </v-card-title>
      <v-card-text>
        <template>
          <div class="panel">
            <div class="panel-nav">
              <ul class="tab">
                <li :class="{ active: activeTab === 1 }" class="tab-item">
                  <a href="#" @click.prevent="activeTab = 1">{{ $t('forum.write') }}</a>
                </li>
                <li :class="{ active: activeTab === 2 }" class="tab-item">
                  <a href="#" @click.prevent="activeTab = 2">{{ $t('forum.preview') }}</a>
                </li>
              </ul>
            </div>
            <div v-show="activeTab === 1" class="panel-body">
              <markdown-editor :value="post.content" @update="(content) => post.content = content"/>
            </div>
            <div v-show="activeTab === 2" class="panel-body">
              <markdown-preview :text="post.content"/>
            </div>
          </div>
        </template>
      </v-card-text>

      <v-divider/>

      <v-card-actions
        class="justify-end"
      >
        <v-btn
          @click="savePost"
        >
          {{ $t('forum.save') }}
        </v-btn>
        <v-btn
          outlined
          @click="clickCancel"
        >
          {{ $t('forum.cancel') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import forumService from '@/service/forum.service';
import FormatDateTime from '@/views/components/FormatDateTime';
import ApimForumPostReply from '@/components/forum/ApimForumPostReply';
import MarkdownEditor from "@/components/forum/MarkdownEditor";
import MarkdownPreview from "@/components/forum/MarkdownPreview";

export default {
  name: 'ApimForumPost',
  components: {ApimForumPostReply, FormatDateTime, MarkdownEditor, MarkdownPreview},
  props: {
    forum: {
      type: Object,
      required: true,
    },
    postId: {
      type: Number,
    },
  },
  data() {
    return {
      isModify: false,
      post: {
        permission: {},
        writer: {}
      },
      backup: {
        post: null,
      },
      activeTab: 1,
    };
  },
  computed: {
    isCreate() {
      return !this.postId && !this.post.id;
    }
  },
  methods: {
    async fetchPost() {
      this.post = await forumService.readPost(this.forum.id, this.post.id || this.postId);
    },
    async savePost() {
      if (this.post.id) {
        this.post = await forumService.updatePost(this.forum.id, this.post.id, this.post);
      } else {
        this.post = await forumService.writePost(this.forum.id, this.post);
      }
      this.isModify = false;
      this.$emit('refresh');
    },
    clickModify() {
      this.backup.post = JSON.parse(JSON.stringify(this.post));
      this.isModify = true;
    },
    clickCancel() {
      if (this.post.id) {
        // 수정인 경우
        this.post = this.backup.post;
        this.isModify = false;
      } else {
        // 글쓰기인 경우
        this.$emit('click:cancel');
      }
    },
    async clickDelete() {
      var deletePostMsg = this.$t('forum.deletePostMsg');
      if (await this.$confirm({
        message: `${deletePostMsg}`,
        confirmYn: true,
      },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')})) {
        await forumService.deletePost(this.forum.id, this.post.id);
        this.$emit('click:close');
      }
    },
    // 답글 변경을 반영한다(서버에서 다시 가져오지 않도록).
    updateReply(original, newly) {
      const index = this.post.replies.indexOf(original);
      if (index >= 0) {
        this.post.replies.splice(index, 1, newly);
      }
    },
  },
  async mounted() {
    if (!this.isCreate) await this.fetchPost();
  }
}
</script>

<style scoped>
.panel-nav {
  list-style: none;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

.panel-nav li {
  float: left;
  line-height: 38px;
  overflow: hidden;
  padding: 0;
  position: relative;

}

.panel-nav li a {
  background-color: #f8f8f8;
  border: 1px solid #e0e0e0;
  border-right: none;
  color: #888;
  font-weight: 500;
  display: block;
  letter-spacing: 0;
  outline: none;
  padding: 0 20px;
  text-decoration: none;
  -webkit-transition: all 200ms ease-in-out;
  -moz-transition: all 200ms ease-in-out;
  -o-transition: all 200ms ease-in-out;
  -ms-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
}

.panel-nav li:first-child a {
  border-left: 1px solid #e0e0e0;
}

.panel-nav li:last-child a {
  border-right: 1px solid #e0e0e0;
}

.panel-nav li.active a {
  color: #333;
  font-weight: 500;
}

.panel-nav li.active i:before {
  color: #555;
}

.panel-nav li i:before {
  color: #909090;
}

.panel-nav li span {
  margin-left: -7px;
}

.panel-nav li.active a i {
  opacity: 1;
  filter: alpha(opacity=100);
}

.panel-nav li i {
  opacity: 0.64;
  filter: alpha(opacity=64);
  margin: 10px 0 0 0;
}

.panel-nav li a:hover {
  background: #fff;
}

.panel-nav li.active a {
  background: #fff;
  border-bottom: 1px solid #fff;
}

.panel {
  border-top-width: 1px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  border-left-width: 0px;
  margin: -1px 0 20px;
  overflow: hidden;
  width: 100%;
  line-height: 21px;
}

.tab {
  padding-left: 0px;
}
</style>
