import request, { processResponse } from '@/utils/request';

const apiService = {
  // 리소스 목록 조회
  async query(params) {
    return processResponse(await request({
      url: '/apix/apis/resources/query',
      method: 'get',
      params: params,
    }));
  },
  // 이관 데이터를 생성한 후 다운로드 한다.
  async makeTransferData(postdata) {
    const { data } = await request({
      method: 'post',
      url: '/apix/apis/resources/transfer-data',
      data: postdata,
      responseType: 'blob',
    });
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'transfer.json');
    link.click();
  }
};

export default apiService;
