'use strict';

import SockJS from 'sockjs-client';
import { RxStomp } from '@stomp/rx-stomp';

class MessagingService {
  constructor(rxStomp) {
    this._rxStomp = rxStomp;
  }

  connect() {
    const stompConfig = {
      connectHeaders: {},
      webSocketFactory: () => new SockJS('websocket'),
      reconnectDelay: 1000,
    };

    this._rxStomp = new RxStomp();
    this._rxStomp.configure(stompConfig);
    this._rxStomp.activate();
  }

  watch(endpoint) {
    return this.rxStomp.watch(endpoint);
  }

  disconnect() {
    if (this._rxStomp) {
      if (this._rxStomp.connected()) this._rxStomp.deactivate();
      this._rxStomp = null;
    }
  }

  get rxStomp() {
    return this._rxStomp;
  }
}

const messagingService = new MessagingService();
export default messagingService;
