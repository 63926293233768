import router, { asyncRoutes, constantRoutes } from '@/router';

/**
 * Through meta.role determines whether the current user rights match
 * @param roles
 * @param route
 */
//** hasPermission 역할 **///
// const hasPermission = (roles, route) => {
//   if (route.meta && route.meta.roles) {
//     return roles.some((role) => route.meta.roles.includes(role.subject));
//   }
//   return true;
// };

//** hasPermission 권한 **///
const hasPermission = (authorities, route) => {
  if (route.meta && route.meta.authorities) {
    return authorities.some((authority) => route.meta.authorities.includes(authority));
  }
  return true;
};

/**
 * Recursively filter asynchronous routing tables to return routing tables
 * that meet user role permissions
 * @param roles
 * @param routes asyncRoutes
 */
//** hasPermission 역할 **///
// export const filterAsyncRoutes = (roles, routes) => {
//   const res = [];
//   routes.forEach((route) => {
//     const tmp = { ...route };

//     if (hasPermission(roles, tmp)) {
//       if (tmp.children) {
//         tmp.children = filterAsyncRoutes(roles, tmp.children);
//       }
//       res.push(tmp);
//     }

//   });
//   return res;
// };

//** hasPermission 권한 **///
export const filterAsyncRoutes = (authorities, routes) => {
  const res = [];
  routes.forEach((route) => {
    const tmp = { ...route };

    if (hasPermission(authorities, tmp)) {
      if (tmp.children) {
        tmp.children = filterAsyncRoutes(authorities, tmp.children);
      }
      res.push(tmp);
    }
  });
  return res;
};


const permission = {
  state: {
    routes: [],
    addRoutes: [],
  },

  getters: {
    permissionRoutes: (state) => state.routes,
    addRoutes: (state) => state.addRoutes,
  },

  mutations: {
    SET_ROUTES: (state, routes) => {
      state.addRoutes = routes;
      state.routes = constantRoutes.concat(routes);
    },
  },
  actions: {
    //** hasPermission 역할 **///
    // GenerateRoutes: async ({ commit }, { roles }) => {
    //   try {
    //     console.groupCollapsed(`[vuex.permission] GenerateRoutes [${roles}]`);
    //     let accessedRoutes;
    //     if (!roles && roles.includes('admin')) {
    //       accessedRoutes = asyncRoutes;
    //     } else {
    //       accessedRoutes = filterAsyncRoutes(roles, asyncRoutes);
    //     }
    //     commit('SET_ROUTES', accessedRoutes);
    //     // Apply selected allowed routes
    //     router.addRoutes(accessedRoutes);
    //     console.log('[vuex.permission] accessedRoutes ', constantRoutes);
    //     console.groupEnd();
    //   } catch (err) {
    //     console.warn('[vuex.permission] GenerateRoutes', err);
    //   }
    // },

    //** hasPermission 권한 **///
    GenerateRoutes: async ({ commit }, { authorities }) => {
      try {
        console.groupCollapsed(`[vuex.permission] GenerateRoutes [${authorities}]`);
        let accessedRoutes;
        // if (!roles && roles.includes('admin')) {

        if (!authorities) {
          accessedRoutes = asyncRoutes;
        } else {
          accessedRoutes = filterAsyncRoutes(authorities, asyncRoutes);
        }
        commit('SET_ROUTES', accessedRoutes);
        // Apply selected allowed routes
        router.addRoutes(accessedRoutes);
        console.log('[vuex.permission] accessedRoutes ', constantRoutes);
        console.groupEnd();
      } catch (err) {
        console.warn('[vuex.permission] GenerateRoutes', err);
      }
    },

  },
};

export default permission;
