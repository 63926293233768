<template>
  <div>
    <v-expansion-panels
      v-model="panels.opened"
    >
      <v-expansion-panel
        v-for="(value, code, index) in responses"
        :key="index"
      >
        <v-expansion-panel-header>
          <v-row>
            <v-col
              cols="auto"
            >
              {{ code }} {{ value.description }}
            </v-col>
            <v-spacer/>
            <v-col
              v-if="editMode && panels.opened === index"
              cols="auto"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="clickAddMediaType(index)"
                  >
                    mdi-plus-box
                  </v-icon>
                </template>
                <span>{{ $t('apiMethod.add') }} {{ $t('apiMethod.mediaType') }}</span>
              </v-tooltip>
            </v-col>
            <v-col
              v-if="editMode"
              cols="auto"
              class="mr-3"
            >
              <v-icon
                @click.stop="deleteResponseCode(code)"
              >
                mdi-delete
              </v-icon>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <swagger-content-view
            :ref="'refSwaggerContentView' + index"
            :content="value.content"
            :edit-mode="editMode"
          />
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>

    <v-container
      v-if="editMode"
    >
      <v-row>
        <v-spacer/>
        <v-col
          cols="auto"
        >
          <v-btn
            small
            @click="addResponse"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-if="dialog.add"
      v-model="dialog.add"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="text-left"
        >
          <span class="text-h3">
            {{ $t('apiMethod.add') }} {{ $t('apiMethod.response') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-form
            v-model="form.validAddResponse"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <apim-combobox-regex
                    v-model="modify.code"
                    :items="existingResponseCodes"
                    :label="$t('apiMethod.add') +' ' + $t('apiMethod.code')"
                    dense
                    :regex="/[0-9]/"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model="modifyDescription"
                    :label="$t('apiMethod.description')"
                    :readonly="existingResponseCodes.includes(modify.code)"
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-combobox
                    v-model="modify.contentType"
                    :items="existingContentTypes"
                    :label="$t('apiMethod.contentType')"
                    :rules="[
                      (v) => !!v || $t('apiMethod.requiredMsg'),
                      (v) => !isExistingContentType(v) || $t('apiMethod.alreadyUseMsg')
                    ]"
                  />
                </v-col>
                <v-col
                  cols="6"
                  class="py-0"
                >
                  <v-select
                    v-model="modify.schemaType"
                    :items="selectors.schemaTypes"
                    :label="$t('apiMethod.schema') +' ' + $t('apiMethod.type')"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="grey"
            @click="dialog.add = false"
          >
            {{ $t('apiMethod.cancel') }}
          </v-btn>
          <v-btn
            :disabled="!form.validAddResponse"
            @click="saveResponse"
          >
            {{ $t('apiMethod.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SwaggerContentView from '@/components/api/swagger/SwaggerContentView';
import { SELECTOR_SCHEMA_TYPE } from '@/config/constants';
import ApimComboboxRegex from '@/components/core/ApimComboboxRegex';

export default {
  name: 'ApiMethodResponseView2',
  components: { ApimComboboxRegex, SwaggerContentView },
  props: {
    responses: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    selectors: {
      schemaTypes: SELECTOR_SCHEMA_TYPE,
    },
    dialog: {
      add: false,
    },
    panels: {
      opened: null,
    },
    form: {
      validAddResponse: false,
    },
    modify: {
      code: null,
      description: null,
      contentType: null,
      schemaType: 'string',
    },
  }),
  computed: {
    existingResponseCodes() {
      return Object.keys(this.responses);
    },
    modifyDescription: {
      get() {
        return this.existingResponseCodes.includes(this.modify.code) ? this.responses[this.modify.code].description : this.modify.description;
      },
      set(newValue) {
        this.modify.description = newValue;
      },
    },
    existingContentTypes() {
      if (!this.modify.code || !this.existingResponseCodes.includes(this.modify.code)) return [];
      const content = this.responses[this.modify.code].content;
      return content ? Object.keys(content) : [];
    }
  },
  methods: {
    addResponse() {
      this.modify = {
        code: null,
        description: null,
        contentType: null,
        schemaType: 'string',
      };
      this.dialog.add = true;
    },
    isExistingContentType(v) {
      const trim = trimContentType(v);
      return this.existingContentTypes.map((c) => trimContentType(c)).some((c) => c === trim);
    },
    saveResponse() {
      if (!this.form.validAddResponse) return;
      if (!this.existingResponseCodes.includes(this.modify.code)) {
        this.responses[this.modify.code] = {
          description: this.modify.description,
          // content: {}
        };
        this.$set(this.responses[this.modify.code], 'content', {});
      }
      console.log('content type', this.modify.contentType);
      this.$set(this.responses[this.modify.code].content, this.modify.contentType, {
        schema: {
          type: this.modify.schemaType,
        },
      });
      if (this.modify.schemaType === 'object') {
        this.$set(this.responses[this.modify.code].content[this.modify.contentType].schema, 'properties', {});
      }
      this.dialog.add = false;
    },
    deleteResponseCode(code) {
      this.$delete(this.responses, code);
    },
    clickAddMediaType(index) {
      // 판넬이 열린 경우 해당 콤포넌트가 생성된다.
      if (this.$refs['refSwaggerContentView' + index] && this.$refs['refSwaggerContentView' + index].length > 0) {
        this.$refs['refSwaggerContentView' + index][0].showAddContentType();
      }
    },
    filterNumber(event) {
      event = (event) ? event : window.event;
      let expect = event.target.value.toString() + event.key.toString();
      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        event.preventDefault();
      } else {
        return true;
      }
    }
  }
}

/**
 * Content-Type의 mime부분만 잘라낸다.
 * @param v ex) application/json;charset=UTF-8
 * @return {string|*} ex) application/json
 */
function trimContentType(v) {
  if (!v) return v;
  const index = v.indexOf(';');
  return index >= 0 ? v.substring(0, index).trim() : v.trim();
}
</script>

<style scoped>

</style>
