import request from '@/utils/request';

const appService = {
  async list() {
    const { data } = await request({
      url: '/apix/apps',
      method: 'get',
    });
    return data;
  },
  async get(appId) {
    const { data } = await request({
      method: 'get',
      url: `/apix/apps/${appId}`,
    });
    return data;
  },
  async getKeyAndSecret(appId) {
    const { data } = await request({
      method: 'get',
      url: `/apix/apps/${appId}/key`,
    });
    return data;
  },
  async create(app) {
    const { data } = await request.post(`/apix/apps`, app);
    return data;
  }
};

export default appService;
