var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('span',{staticClass:"Header-1-Bold"},[_vm._v(_vm._s(_vm.$t('app.service'))+" ("+_vm._s(_vm.$t('app.app'))+")")])])],1),_c('v-row',[_c('v-spacer'),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"has-auth",rawName:"v-has-auth",value:('APP_CREATE'),expression:"'APP_CREATE'"}],attrs:{"color":"#000","large":""},on:{"click":_vm.onClickAppReloadAll}},'v-icon',attrs,false),on),[_vm._v(" mdi-reload ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('app.gateway'))+" "+_vm._s(_vm.$t('app.apply')))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({directives:[{name:"has-auth",rawName:"v-has-auth",value:('APP_CREATE'),expression:"'APP_CREATE'"}],attrs:{"color":"#000","large":""},on:{"click":_vm.clickCreateApp}},'v-icon',attrs,false),on),[_vm._v(" mdi-plus-box ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('app.app'))+" "+_vm._s(_vm.$t('app.register')))])])],1)],1),_c('v-row',_vm._l((_vm.apps),function(app){return _c('v-col',{key:app.id,attrs:{"cols":"4"}},[_c('app-card',{staticStyle:{"cursor":"pointer"},attrs:{"app":app},nativeOn:{"click":function($event){return _vm.clickApp(app)}}})],1)}),1),_c('v-dialog',{attrs:{"max-width":"600"},model:{value:(_vm.dialog.create),callback:function ($$v) {_vm.$set(_vm.dialog, "create", $$v)},expression:"dialog.create"}},[_c('v-card',[_c('v-card-title',{staticClass:"grey lighten-2 text-left"},[_vm._v(" APP "+_vm._s(_vm.$t('app.register'))+" ")]),_c('v-card-text',[_c('v-form',{attrs:{"lazy-validation":""},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-simple-table',{staticClass:"elevation-0",scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('tbody',[_c('tr',[_c('th',[_vm._v(" "+_vm._s(_vm.$t('app.name'))+" "),_c('span',{staticClass:"red--text"},[_c('strong',[_vm._v("*")])])]),_c('td',[_c('v-text-field',{ref:"name",attrs:{"rules":[
                      function (v) { return !!v || _vm.$t('app.fieldRequiredMsg'); } ],"hide-details":"auto","outlined":""},model:{value:(_vm.editing.app.name),callback:function ($$v) {_vm.$set(_vm.editing.app, "name", $$v)},expression:"editing.app.name"}})],1)]),_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t('app.description')))]),_c('td',[_c('v-textarea',{staticClass:"my-1",attrs:{"hide-details":"","outlined":""},model:{value:(_vm.editing.app.description),callback:function ($$v) {_vm.$set(_vm.editing.app, "description", $$v)},expression:"editing.app.description"}})],1)])])]},proxy:true}])})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"primary",attrs:{"disabled":!_vm.formValid},on:{"click":_vm.createApp}},[_vm._v(" "+_vm._s(_vm.$t('app.save'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }