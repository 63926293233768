const realtime = {
  state: {
    transactions: [],
    tpsInfo: {},
    accumulated: {},
    statistics: {
      stats: {
        today: [],
        previous: [],
      }
    },
  },

  getters: {
    transactions: (state) => state.transactions,
    tpsInfo: (state) => state.tpsInfo,
    accumulated: (state) => state.accumulated,
    statistics: (state) => state.statistics,
  },

  mutations: {
    ADD_TRANSACTION: (state, payload) => {
      state.transactions.push(payload);
      // TODO 나중에 제거하는 로직을 넣어야 한다.
      if (state.transactions.length > 1000) {
        state.transactions.shift();
      }
    },
    SET_TPS_INFO: (state, payload) => {
      state.tpsInfo = payload;
    },
    SET_ACCUMULATED: (state, payload) => {
      state.accumulated = payload;
    },
    SET_STATISTICS: (state, payload) => {
      state.statistics = payload;
    }
  },
  actions: {
    addTransaction: (context, payload) => {
      context.commit('ADD_TRANSACTION', payload);
    },
    setTpsInfo: (context, payload) => {
      context.commit('SET_TPS_INFO', payload);
    },
    setAccumulated: (context, payload) => {
      context.commit('SET_ACCUMULATED', payload);
    },
    setStatistics: (context, payload) => {
      context.commit('SET_STATISTICS', payload);
    }
  },
};

export default realtime;
