<template>
  <div class="wrapper">
    <temp-header/>
    <temp-container
      v-if="!needChangePassword"
    />
    <v-main
      v-else
    >
      <password-change-view/>
    </v-main>
    <temp-footer/>
  </div>
</template>

<style scoped>
</style>

<script>
import TempHeader from './TempHeaderMain.vue';
import TempContainer from './TempContainer.vue';
import TempFooter from './TempFooter.vue';
import { mapGetters } from 'vuex';
import PasswordChangeView from '@/views/auth/PasswordChangeView';

export default {
  name: 'TheLayout',
  components: {
    PasswordChangeView,
    TempHeader,
    TempContainer,
    TempFooter,
  },
  data: () => ({}),
  computed: {
    ...mapGetters([
      'needChangePassword',
    ]),
  }
};
</script>
