<template>
  <v-app-bar
    id="app-bar-front"
    app
    class="front"
    color="white"
    fixed
    flat
    height="80"
    style="left: 0 !important; z-index: 10"
  >
    <div
      class="logo hidden-sm-and-down"
      style="position: absolute; cursor: pointer;"
      @click="intro"
    >
      <v-img :src="'./img/logo.png'" />
      <!--<v-img src="@/views/assets/apim_logo.png"/>-->
    </div>
    <div class="logo_symbol hidden-md-and-up" style="position: absolute">
      <v-img src="@/assets/logo_inzent_symbol.svg" />
    </div>
    <v-toolbar-items
      align="center"
      class="gnb d-inline-flex justify-center text-center"
      no-gutters
    >
      <!--
      <v-col sm="9">
        <v-row
          v-for="(link, i) in links"
          :key="i"
          class="menuitem d-inline-flex"
        >
          <a @click="apilist" v-text="link.text" />
        </v-row>
      </v-col>
      -->
    </v-toolbar-items>
    <v-spacer />
    <div
      class="d-flex ml-auto text-no-wrap"
      style="position: absolute; right: 10px;"
    >
      <!-- account name -->
      <v-tooltip left>
        <template #activator="{on, attrs}">
          <v-img
            v-if="loginUserId"
            class="mr-5"
            src="../assets/mypage.svg"
            style="cursor: pointer;"
            v-bind="attrs"
            @click="profile"
            v-on="on"
          />
        </template>
        <span>{{ loginUserName }}</span>
      </v-tooltip>
      <!-- 운영자 화면 버튼 -->
      <v-btn
        v-if="isAdmin"
        :href="'./admin'"
        class="mr-5"
        outlined
        small
        style="cursor: pointer"
        target="_blank"
      >
        admin
      </v-btn>
      <!-- 언어 선택 -->
      <v-select
        v-model="language"
        :items="lanaguageLabels"
        class="mr-2"
        dense
        hide-details
        style="z-index: 100; max-width: 90px"
        @change="onChangeLanguage"
      />
      <!-- 로그아웃 버튼 -->
      <v-img
        v-if="loginUserId"
        class="mr-5"
        src="../assets/logout.svg"
        style="cursor: pointer;"
        @click="logout" />
      <!-- 로그인 버튼 -->
      <v-btn
        v-if="!loginUserId"
        class="mr-5" text
        @click="login"
      >
        <span>{{ $t('temp.login') }}</span>
      </v-btn>
    </div>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  name: 'TempHeaderMain',
  inject: ['translationService'],
  data: () => ({
    language: 'ko',
  }),
  computed: {
    ...mapState(['drawer']),
    ...mapGetters({
      loginUserId: 'id',
      loginUserName: 'name',
      isAdmin: 'isAdmin',
    }),
    ...mapGetters(['languages', 'currentLanguage']),
    lanaguageLabels() {
      return Object.keys(this.languages).map((key) => ({
        text: this.languages[key].name,
        value: key,
      }));
    }
  },
  methods: {
    ...mapMutations({
      setDrawer: 'SET_DRAWER',
    }),
    login() {
      this.$router.push('/signin');
    },
    logout() {
      this.$store.dispatch('LogOut');
      this.$router.push('/intro');
    },
    profile() {
      this.$router.push('/mypage');
    },
    apilist() {
      this.$router.push({ name: 'sub-index' });
    },
    intro() {
      this.$router.push('/apis');
    },
    onChangeLanguage() {
      this.translationService.refreshTranslation(this.language);
    }
  },
};
</script>
