<template>
  <div>
    {{ this.formated }}
  </div>
</template>

<script>
const DEFAULT_FORMAT = "YYYY-MM-DD HH:mm";

export default {
  name: "FormatDateTime",
  props: ["value", "format"],
  computed: {
    formated() {
      if (!this.value) return "-";
      const format = this.format ? this.format : DEFAULT_FORMAT;
      return this.$moment(this.value).format(format);
    },
  },
};
</script>
