<!-- API 리소스 이관 화면 -->
<template>
  <v-card>
    <v-card-title
      class="primary white--text"
    >
      {{ $t('apiResource.resource') }} {{ $t('apiResource.data') }} {{ $t('apiResource.transfer') }}
    </v-card-title>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-tabs
              v-model="tab"
              align-with-title
              background-color="grey"
              dark
              class="pl-0 pt-0"
            >
              <v-tabs-slider color="yellow"/>
              <v-tab>
                {{ $t('apiResource.export') }}
              </v-tab>
              <v-tab>
                {{ $t('apiResource.import') }}
              </v-tab>
            </v-tabs>
            <v-tabs-items
              v-model="tab"
            >
              <v-tab-item>
                <v-card
                  outlined
                  min-height="300"
                >
                  <v-card-text>
                    <api-resource-list-view
                      v-if="!transfer.selected"
                      multi-select
                      dense
                      @select="onSelectedTransferResources"
                    />
                    <api-resource-url-mapping-view
                      ref="refApiResourceUrlMappingView"
                      v-if="transfer.selected"
                      :resources="transfer.selected"
                    />
                  </v-card-text>
                </v-card>
              </v-tab-item>
              <v-tab-item>
                <v-card
                  outlined
                  min-height="300"
                >
                  <v-card-text>
                    <v-container>
                      <v-row
                        justify="center"
                        align="center"
                      >
                        <v-col cols="3">
                          <v-file-input
                            v-model="transfer.files"
                            :label="$t('apiResource.transfer') +' '+$t('apiResource.file')+($t('apiResource.json'))"
                            show-size
                          />
                        </v-col>
                        <v-col
                          cols="auto"
                        >
                          <v-btn
                            :disabled="!transfer.files || transfer.files.length === 0"
                            @click="uploadTransferDataFiles"
                          >
                            {{ $t('apiResource.registry') }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions
      class="justify-center"
    >
      <v-col
        cols="auto"
        v-if="transfer.selected"
      >
        <v-btn
          @click="createResourceTransferData"
        >
          {{ $t('apiResource.create') }}
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
      >
        <v-btn
          outlined
          @click="clickClose"
        >
          {{ $t('apiResource.close') }}
        </v-btn>
      </v-col>
    </v-card-actions>
  </v-card>
</template>

<script>
import apiService from '@/service/api.service';
import ApiResourceListView from '@/components/api/ApiResourceListView';
import ApiResourceUrlMappingView from '@/components/api/ApiResourceUrlMappingView';
import request from '@/utils/request';

export default {
  name: 'ApiResourceTransferView',
  components: { ApiResourceUrlMappingView, ApiResourceListView },
  data: () => ({
    tab: null,
    transfer: {
      selected: null,
      files: null, // import 시 파일
    },
  }),
  methods: {
    // 리소스 이관 화면에서 리소스를 선택한 후
    onSelectedTransferResources(resources) {
      this.transfer.selected = resources;
    },
    async createResourceTransferData() {
      await apiService.makeTransferData(this.$refs.refApiResourceUrlMappingView.mappings);
      this.clickClose();
    },
    async uploadTransferDataFiles() {
      const formData = new FormData();
      formData.append('files', this.transfer.files);
      await request({
        url: '/apix/apis/resources/transfer-data/save',
        method: 'post',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      });
      this.transfer.files = null;
      this.$emit('refresh'); // 등록이 완료된 후 refresh 이벤트를 발생시킨다.
    },
    clickClose() {
      this.$emit('close');
    }
  }
}
</script>

<style scoped>

</style>
