<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="12" lg="6" md="8" sm="10">
        <v-card ref="form">
          <v-card-title style="background-color: rgb(245, 245, 245)">
            <div class="display-2 font-weight-light">{{$t('mypage.mypage')}}</div>
          </v-card-title
          >
          <v-card-text>
            <v-simple-table class="elevation-3 mt-15">
              <template v-slot:default>
                <tbody>
                <tr>
                  <th width="110px">
                    {{$t('mypage.id')}}
                  </th>
                  <td>
                    <v-text-field
                      :ref="$t('mypage.id')"
                      v-model="userInfo.id"
                      hide-details="auto"
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <th>
                    {{$t('mypage.name')}}
                  </th>
                  <td>
                    <v-text-field
                      :ref="$t('mypage.name')"
                      v-model="userInfo.name"
                      :readonly="!mode"
                      :rules="textRules"
                      hide-details="auto"
                      required
                    ></v-text-field>
                  </td>
                </tr>
                <tr
                  v-if="hasCustomField('date_of_birth')"
                >
                  <th>
                    {{$t('mypage.dateOfBirth')}}
                    <span
                      v-if="isRequiredCustomField('date_of_birth')"
                      class="red--text"
                    >
                                    <strong>*</strong>
                                  </span>
                  </th>
                  <td>
                    <v-dialog
                      ref="dialogDateOfBirth"
                      v-model="dialog.dateOfBirth"
                      :return-value.sync="dateOfBirth"
                      persistent
                      width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="dateOfBirth"
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!mode"
                          :rules="dateOfBirthRules"
                          dense
                          hide-details="auto"
                          prepend-icon="mdi-calendar"
                          readonly
                          style="max-width: 150px"
                        />
                      </template>
                      <v-date-picker
                        v-model="dateOfBirth"
                        scrollable
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          color="primary"
                          text
                          @click="dialog.dateOfBirth = false"
                        >
                          {{$t('mypage.cancel')}}
                        </v-btn>
                        <v-btn
                          color="primary"
                          text
                          @click="$refs.dialogDateOfBirth.save(dateOfBirth)"
                        >
                          {{$t('mypage.confirm')}}
                        </v-btn>
                      </v-date-picker>
                    </v-dialog>
                  </td>
                </tr>
                <tr>
                  <th>{{$t('mypage.phoneNumber')}}</th>
                  <td>
                    <v-row
                      v-for="(phone, index) in userInfo.phones"
                      :key="index"
                      class="mt-0"
                    >
                      <v-col
                        cols="3"
                      >
                        {{ phone.name }}
                      </v-col>
                      <v-col
                        cols="7"
                      >
                        {{ phone.phoneNumber }}
                      </v-col>
                      <v-spacer/>
                      <v-col
                        cols="auto"
                      >
                        <v-icon
                          :disabled="!mode"
                          @click="deletePhoneNumber(index)"
                        >
                          mdi-delete
                        </v-icon>
                      </v-col>
                    </v-row>
                    <v-row
                      align="center"
                      class="mt-0 mb-2"
                    >
                      <v-col
                        cols="auto"
                      >
                        <v-text-field
                          v-model="phoneInfo.name"
                          :readonly="!mode"
                          dense
                          hide-details
                          :label="$t('mypage.label')"
                          style="max-width: 100px"
                        />
                      </v-col>
                      <v-col
                        cols="auto"
                      >
                        <v-text-field
                          v-model="phoneInfo.phoneNumber"
                          :readonly="!mode"
                          dense
                          hide-details
                          :label="$t('mypage.phoneNumber')"
                          @keyup="onChangeNewPhoneNumber"
                        />
                      </v-col>
                      <v-spacer/>
                      <v-col
                        cols="auto"
                      >
                        <v-btn
                          :disabled="!mode"
                          x-small
                          @click="addNewPhoneNumber"
                        >
                          {{$t('mypage.add')}}
                        </v-btn>
                      </v-col>
                    </v-row>
                  </td>
                </tr>
                <tr>
                  <th>{{$t('mypage.email')}}</th>
                  <td>
                    <v-text-field
                      v-model="userInfo.email"
                      :readonly="!mode"
                      :rules="emailRules"
                      hide-details="auto"
                      required
                    ></v-text-field>
                  </td>
                </tr>
                <tr>
                  <th>{{$t('mypage.password')}} {{$t('mypage.change')}}</th>
                  <td colspan="3">
                    <v-dialog
                      v-model="dialog.changePw"
                      max-width="400px"
                      persistent
                      @keydown.esc="dialog.changePw = false"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :disabled="!mode"
                          @click="showPasswordChangeDialog"
                        >
                          {{$t('mypage.password')}} {{$t('mypage.change')}}
                        </v-btn>
                      </template>
                      <v-card>
                        <v-card-title
                          style="background-color: rgb(245, 245, 245)"
                        >
                          <span class="headline">{{$t('mypage.password')}} {{$t('mypage.change')}}</span>
                        </v-card-title>
                        <v-card-text>
                          <password-change
                            show-close-button
                            @password-changed="onPasswordChanged"
                            @click-close="onClosePasswordChange"
                          />
                        </v-card-text>
                      </v-card>
                    </v-dialog>
                  </td>
                </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider class="mt-12"></v-divider>
          <v-card-actions>
            <v-col class="text-right" cols="12">
              <v-btn
                v-if="!mode"
                class="mr-0"
                color="#151826"
                @click="onClickModifyButton"
              >
                {{$t('mypage.modify')}}
              </v-btn>
              <v-btn
                v-if="mode"
                class="mr-4"
                color="white"
                @click="onClickCancelButton"
              >
                {{$t('mypage.cancel')}}
              </v-btn>
              <v-btn
                v-if="mode"
                class="mr-0"
                color="#151826"
                @click="onClickSaveButton"
              >
                {{$t('mypage.save')}}
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-snackbar v-model="showResult" :timeout="2000" top>
      {{ result }}
    </v-snackbar>
  </v-container>
</template>

<style scoped>
table > tbody > tr > th {
  background-color: rgb(245, 245, 245);
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>

<script>
import request from '@/utils/request';
import {mapActions, mapGetters} from 'vuex';
import PasswordChange from '@/components/PasswordChange';

const DEFAULT_PHONE_INFO = {
  name: '',
  phoneNumber: '',
};

export default {
  name: 'AppManagement',
  components: {PasswordChange},
  data: function() {
    return {
      dialog: {
        changePw: false,
      },
      showResult: false,
      result: '',
      mode: false,
      passwordMode: false,
      userInfo: {},
      changePw: {
        password: null,
        passwordCheck: null,
      },
      phones: [],
      phoneInfo: {...DEFAULT_PHONE_INFO},
      textRules: [
        (v) => !!v || this.$i18n.t('mypage.requiredField'),
        (v) => !!v && v.length <= 32 || this.$i18n.t('mypage.32digitMsg'),
      ],
      emailRules: [
        (v) => !!v || this.$i18n.t('mypage.requiredField'),
        (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || this.$i18n.t('mypage.emailNotValidMsg'),
      ],
      customFields: {},
      dateOfBirth: null, // 화면에 보여지는 생년월일
    };
  },
  computed: {
    ...mapGetters(['user', 'name', 'avatar', 'status']),
    ...mapGetters({
      loginUserId: 'id',
    }),
    dateOfBirthRules() {
      return !this.isRequiredCustomField('date_of_birth') ? [] : [
        (v) => !!v || this.$i18n.t('mypage.requiredField'),
      ];
    }
  },
  methods: {
    ...mapActions(['addAlert']),
    // 추가 필드에 대한 정보
    async fetchCustomFieldInfo() {
      const {data} = await request({
        url: '/apix/codes/USER_REGIST_FORM',
        metod: 'get',
      });
      data.forEach((code) => {
        this.customFields[code.code] = {
          use: code.useYn === 'Y',
          display: code.displayYn === 'Y',
          required: code.requiredYn === 'Y',
        };
      });
    },
    async fetchUser() {
      const {data} = await request({
        url: `/apix/users/${this.user}`,
        method: 'get',
      });
      this.userInfo = data;
      //this.userInfo.phones = this.userInfo.phones.map((p) => p.phoneNumber);
      //날짜에 하이픈 넣기
      if (this.userInfo.dateOfBirth.length === 8)
        this.dateOfBirth = this.userInfo.dateOfBirth.replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
    },
    onSelectOrganizationChange(item) {
      console.log('select', item);
    },
    // 수정 버튼 클릭 시
    onClickModifyButton() {
      this.mode = true;
      this.inputCallbackUrl = null;
    },
    // 수정 취소 버튼 클릭 시
    onClickCancelButton() {
      this.mode = false;
    },
    phoneFormatter(num) {
      let formatNum = '';
      if (num.length === 11) {
        formatNum = num.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
      } else if (num.length === 8) {
        formatNum = num.replace(/(\d{4})(\d{4})/, '$1-$2');
      } else {
        if (num.indexOf('02') === 0) {
          formatNum = num.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
        } else {
          formatNum = num.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
      }
      return formatNum;
    },
    async onClickSaveButton() {
      var saved = this.$t('mypage.saved');
      try {
        if (this.changePw.password === this.changePw.passwordCheck) {
          // this.userPhone = []
          // const max = this.userInfo.phones.length;
          //
          // for (let i = 0; i < max; i++) {
          //   this.userPhone.push({
          //     name: this.userInfo.phones[i].name,
          //     phoneNumber: this.phoneFormatter(this.userInfo.phones[i].phoneNumber),
          //   });
          // }
          // this.userInfo.phones = this.userPhone;
          if (this.dateOfBirth) this.userInfo.dateOfBirth = this.dateOfBirth.replace(/-/gi, '');
          const {data} = await request({
            url: `/apix/users/${this.user}`,
            method: 'put',
            data: this.userInfo,
          });
          console.log('save result', data);
          this.addAlert({
            type: 'success',
            message: `${saved}`,
          });
          this.mode = false;
          await this.fetchUser();
        }
      }catch (error) {
        this.error = true;
        this.showResult = true;
      }

    },
    passwordField() {
      this.passwordMode = true;
    },
    showPasswordChangeDialog() {
      this.changePw = {
        password: null,
      };
    },
    // 비밀번호가 변경되었을 때
    onPasswordChanged() {
      this.dialog.changePw = false;
    },
    // 비밀번호 변경 창 닫기
    onClosePasswordChange() {
      this.dialog.changePw = false;
    },
    addNewPhoneNumber() {
      if (this.phoneInfo.name.length > 0 && this.phoneInfo.phoneNumber > 0) {
        this.userInfo.phones.push({
          name: this.phoneInfo.name,
          phoneNumber: this.phoneFormatter(this.phoneInfo.phoneNumber),
        });
        this.phoneInfo = {...DEFAULT_PHONE_INFO};
      }
    },
    deletePhoneNumber(index) {
      if (index >= 0) this.userInfo.phones.splice(index, 1);
    },
    // 전화번호 입력 필드가 변경된 경우
    onChangeNewPhoneNumber() {
      this.phoneInfo.phoneNumber = this.phoneInfo.phoneNumber.replace(/[^\d]/g, '');
    },
    hasCustomField(name) {
      return this.customFields[name] && this.customFields[name].use && this.customFields[name].display;
    },
    isRequiredCustomField(name) {
      return this.hasCustomField(name) && this.customFields[name].required;
    },

  },
  async activated() {
    await this.fetchCustomFieldInfo();
    await this.fetchUser();
  },
};
</script>
