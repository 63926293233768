<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="responses"
      hide-default-footer
      disable-pagination
    >
      <template v-slot:item.sample="{ item }">
        <v-icon
          v-if="item.mediaType && item.sample"
          small
          @click.stop="showExample(item)"
        >
          {{$t('apiMethod.description')}}
        </v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          small
          @click.stop="clickModifyButton(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
          small
          @click.stop="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>
      <template v-slot:body.append="{ headers }">
        <tr>
          <td
            :colspan="headers.length"
            class="text-right"
            style="border: none"
          >
            <v-btn
              small
              color="primary"
              @click="clickAddButton"
            >
              <v-icon>mdi-plus-box</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>

    <v-dialog
      v-if="dialog.detail"
      v-model="dialog.detail"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="text-left"
        >
          <span class="text-h5">{{$t('apiMethod.response')}}{{$t('apiMethod.code')}} {{ dialog.detailMode === 'add' ? $t('apiMethod.add') : $t('apiMethod.modify') }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model="editResponse.code"
                  :label="$t('apiMethod.response')+$t('apiMethod.code')"
                  style="max-width: 200px"
                />
              </v-col>
              <v-col
                cols="12"
                class="py-0"
              >
                <v-text-field
                  v-model="editResponse.description"
                  :label="$t('apiMethod.description')"
                />
              </v-col>
              <v-col
                cols="6"
                class="py-0"
              >
                <v-select
                  v-model="editResponse.mediaType"
                  :items="selectors.contentType"
                  label="Content-Type"
                />
              </v-col>
              <v-col
                v-if="editResponse.mediaType !== ''"
                cols="12"
                class="py-0"
              >
                <v-textarea
                  v-model="editResponse.sample"
                  :label="$t('apiMethod.example')"
                  dense
                  outlined
                  :placeholder="placeHolderExample"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="grey"
            @click="dialog.detail = false"
          >
            {{$t('apiMethod.cancel')}}
          </v-btn>
          <v-btn
            @click="save"
          >
            {{ dialog.detailMode === 'add' ? $t('apiMethod.add') : $t('apiMethod.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-if="dialog.sample"
      v-model="dialog.sample"
      max-width="400px"
    >
      <v-card>
        <v-card-text>
          <pre>{{ selected.response.sample }}</pre>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

const header = [
  {
    text: 'apiMethod.responseCode',
    value: 'code',
    required: true,
    type: 'text',
    placeholder: '200',
  },
  {
    text: 'apiMethod.description',
    value: 'description',
    required: true,
    type: 'text',
    placeholder: 'apiMethod.responseDescription',
  },
  {
    text: 'apiMethod.contentType',
    value: 'mediaType',
    type: 'select',
    placeholder: 'application/json',
  },
  {
    text: 'apiMethod.example',
    value: 'sample',
    type: 'textarea',
    placeholder: '{"key":"value"}',
  },
  {
    text: '',
    value: 'actions',
    sortable: false,
  }
];

const selectorsContentType=[
  {
    text: 'apiMethod.none',
    value: '',
  },
  {
    text: 'application/json',
    value: 'application/json',
  },
  {
    text: 'application/xml',
    value: 'application/xml',
  },
];

export default {
  name: 'ApiMethodResponseView',
  props: {
    responses: {
      type: Array,
      required: true,
      default: () => ([]),
    }
  },
  data: function(){
    return {
      headers: this.$translate(header),
      selectors: {
        contentType: this.$translate(selectorsContentType),
      },
      dialog: {
        detail: false,
        detailMode: null,
        sample: false,
      },
      editResponse: null,
      selected: {
        response: null,
      }
    };
  },
  computed: {
    ...mapGetters(['currentLanguage']),
    placeHolderExample() {
      switch (this.editResponse.mediaType) {
        case 'application/json':
          return '{"key":"value"}';
        case 'application/xml':
          return '<data>\n\t<key>key</key>\n\t<value>value</value>\n</data>';
        default:
          return '';
      }
    },
  },
  methods: {
    clickAddButton() {
      this.editResponse = {
        code: '200',
        mediaType: '',
      };
      this.dialog.detailMode = 'add';
      this.dialog.detail = true;
    },
    clickModifyButton(response) {
      this.selected.response = response; // 백업용
      this.editResponse = JSON.parse(JSON.stringify(response));
      this.dialog.detailMode = 'modify';
      this.dialog.detail = true;
    },
    deleteItem(item) {
      this.responses.splice(this.responses.indexOf(item), 1);
    },
    save() {
      if (this.dialog.detailMode === 'add') {
        this.responses.push(this.editResponse);
      } else if (this.dialog.detailMode === 'modify') {
        const index = this.responses.indexOf(this.selected.response);
        this.responses.splice(index, 1, this.editResponse);
      }
      this.dialog.detail = false;
    },
    showExample(item) {
      this.selected.response = item;
      this.dialog.sample = true;
    }
  },
  watch:{
    currentLanguage(){
      this.headers= this.$translate(header);
      this.selectors.contentType = this.$translate(selectorsContentType);
    }
  }
}
</script>

<style scoped>

</style>
