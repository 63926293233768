<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            공지사항 목록
            <v-spacer />
            <v-tooltip left>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  large
                  v-bind="attrs"
                  v-on="on"
                  @click="clickRegist"
                  v-if="authorities.includes(Authorities.forumCreate)"
                  color="#000"
                >
                  mdi-plus-box</v-icon
                >
              </template>
              <span>공지사항 등록</span>
            </v-tooltip>
          </v-card-title>
          <v-divider />
          <!-- 목록 -->
          <v-card-text>
            <!-- 검색 -->
            <v-row class="mb-1">
              <v-spacer />
              <v-col cols="3">
                <div style="display: flex">
                  <v-text-field
                    v-model="search_filter"
                    label="검색어를 입력해주세요."
                    single-line
                    dense
                    hide-details
                    outlined
                    @keyup.enter="filteredItems"
                    autocomplete="search-repsource-api"
                  ></v-text-field>
                  <v-btn
                    v-if="authorities.includes(Authorities.apiCreate)"
                    elevation="0 ml-1"
                    class="primary"
                    hide-details
                    readonly
                    style="min-width: 30px"
                  >
                    <v-icon>mdi-magnify</v-icon>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <!-- 게시글 목록 -->
            <v-data-table
              :headers="headers.post"
              :items="data"
              :item-class="(item) => !selected.post || selected.post !== item ? '' : 'primary'"
              :server-items-length="data.totalCount"
              :options.sync="search.options"
              :search="search_filter"
              @click:row="selectPost"
            >
              <template v-slot:item.no="{ item, index }">
                {{ (search.options.page - 1) * search.options.itemsPerPage + index + 1 }}
              </template>
              <template v-slot:item.actions="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-if="authorities.includes(Authorities.forumDelete)"
                      @click.stop.prevent="deletePost(item)"
                      v-bind="attrs"
                      v-on="on"
                      dense
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                  <span>삭제</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <apim-forum
          forum-id="notice"
        />
      </v-col>
    </v-row>

    <!-- 게시글 상세 다이얼로그 -->
    <v-dialog
      v-model="dialog.detail"
      v-if="selected.post"
      max-width="900px"
      persistent
      @keydown.esc="dialog.detail = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left">공지사항</v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-simple-table class="elevation-0">
                  <template>
                    <!-- 보기 모드인 경우 -->
                    <tbody v-if="!dialog.detailMode">
                      <tr>
                        <th style="width: 110px">작성자</th>
                        <td>
                          {{ selected.post.writer.id }}
                        </td>
                        <th style="width: 110px">읽은 횟수</th>
                        <td>
                          {{ selected.post.readCount }}
                        </td>
                      </tr>
                      <tr>
                        <th>제목</th>
                        <td colspan="3">
                          {{ selected.post.title }}
                        </td>
                      </tr>
                      <tr>
                        <th>내용</th>
                        <td colspan="3">
                          <v-card min-height="100px" flat>
                            <p v-html="handleNewLine(selected.post.content)"></p>
                          </v-card>
                        </td>
                      </tr>
                    </tbody>
                    <!-- 등록/수정 모드인 경우 -->
                    <tbody v-if="dialog.detailMode">
                      <tr>
                        <th style="width: 110px">작성자</th>
                        <td>
                          {{ loginUserName }}
                        </td>
                      </tr>
                      <tr>
                        <th style="width: 110px">
                          제목 <span class="red--text"><strong>*</strong></span>
                        </th>
                        <td colspan="3">
                          <v-text-field
                            ref="title"
                            :rules="[
                              () =>
                                !!modifying.post.title ||
                                'This field is required',
                            ]"
                            :error-messages="errorMessages"
                            v-model="modifying.post.title"
                            outlined
                            hide-details
                          />
                        </td>
                      </tr>
                      <tr>
                        <th style="width: 110px">
                          내용 <span class="red--text"><strong>*</strong></span>
                        </th>
                        <td colspan="3">
                          <v-textarea
                            ref="content"
                            :rules="[
                              () =>
                                !!modifying.post.content ||
                                'This field is required',
                            ]"
                            :error-messages="errorMessages"
                            v-model="modifying.post.content"
                            class="my-1"
                            outlined
                            hide-details
                          />
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            v-if="
              !dialog.detailMode &&
              authorities.includes(Authorities.forumUpdate)
            "
            @click="clickDialogModify"
            elevation="0"
            class="primary"
          >
            수정
          </v-btn>
          <v-btn v-if="dialog.detailMode" @click="clickDialogCancel" outlined>
            취소
          </v-btn>
          <v-btn
            v-if="
              dialog.detailMode &&
              (authorities.includes(Authorities.forumCreate) ||
                authorities.includes(Authorities.forumUpdate))
            "
            @click="updatePost"
            elevation="0"
            class="primary"
          >
            저장
          </v-btn>
          <v-btn v-if="!dialog.detailMode" @click="closeDialog" outlined>
            닫기
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style scoped>
table > tbody > tr > th {
  background-color: rgb(245, 245, 245);
  margin-top: 4px;
  margin-bottom: 4px;
}
</style>

<script>
import request from "@/utils/request";
import { mapActions, mapGetters } from "vuex";
import Authorities from "@/locale/ko_KR/authorities";
import ApimForum from '@/components/forum/ApimForum';

const HEADER_POST = [
  {
    text: "No.",
    value: "id",
    sortable: false,
  },
  {
    text: "제목",
    value: "title",
  },
  {
    text: "읽은 횟수",
    value: "readCount",
  },
  {
    text: "작성자",
    value: "writer.id",
  },
  {
    text: "비고",
    value: "actions",
  },
];

export default {
  name: "NoticeManagement",
  components: { ApimForum },
  data: () => ({
    search_filter: "",
    Authorities: Authorities,
    detail: {
      dates: false,
    },
    dialog: {
      regist: false,
      detail: false,
      detailMode: null,
    },
    headers: {
      post: HEADER_POST,
    },
    selection: {
      division: null,
      status: null,
      periodTypes: [
        {
          text: "게시일",
          value: "publish",
        },
        {
          text: "등록일",
          value: "regist",
        },
      ],
    },
    menu: {
      dates: false,
      publishDate: false,
      publishTime: false,
    },
    search: {
      options: null,
      periodType: null,
      dates: [],
      writer: null,
      query: null,
    },
    modifying: {
      post: {
        title: null,
        content: null,
      },
    },
    selected: {
      post: null,
    },
    data: [],
    errorMessages: "",
    formHasErrors: false,
    idx: 0,
  }),
  computed: {
    ...mapGetters({
      loginUserId: "id",
      loginUserName: "name",
      codeNoticeTypes: "codeNoticeTypes",
      codeNoticeStateTypes: "codeNoticeStateTypes",
    }),
    ...mapGetters({
      loginUserId: "id",
      loginUserName: "name",
    }),
    ...mapGetters(["authorities"]),
    form() {
      return {
        title: this.modifying.post.title,
        content: this.modifying.post.content,
      };
    },
  },
  methods: {
    ...mapActions([]),
    handleNewLine(str) {
      console.log("기존 ::: " + str + "바뀜 ::: " + String(str).replace(/(?:\r\n|\r|\n)/g,"</br>"));
      return String(str).replace(/(?:\r\n|\r|\n)/g,"</br>");
    },
    async fetchPost() {
      console.log("fetchPost");
      const { data } = await request({
        url: "/apix/forums/1/posts",
        method: "get",
      });
      this.data = data;
      console.log("Noticde Data", data);
    },
    async selectPost(item) {
      const { data } = await request({
        url: `/apix/forums/1/posts/${item.id}`,
        method: "get",
      });
      this.selected.post = data;
      this.dialog.detail = true;

      this.fetchPost();
    },
    async deletePost(item) {
      const res = await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_complete.svg" /><br/><b>${item.title} 게시물을 삭제하시겠습니까?</b></div>`,
        confirmYn: true,
      });
      if (res) {
        await request({
          url: `/apix/forums/1/posts/${item.id}`,
          method: "delete",
        });
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_complete.svg" /><br/><b>${item.title} 게시물이 삭제되었습니다.</b></div>`,
        });
        await this.fetchPost();
        this.dialog.detail = false;
        this.selected.post = null;
      }
    },
    async updatePost() {
      if (!this.requiredValidate()) {
        let url = `/apix/forums/1/posts`;
        let method = "post";
        if (this.modifying.post.id) {
          url = `/apix/forums/1/posts/${this.modifying.post.id}`;
          method = "put";
        }

        const { data } = await request({
          url: url,
          method: method,
          data: this.modifying.post,
        });
        console.log("save result", data);
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_complete.svg" /><br/><b>게시물이 저장되었습니다.</b></div>`,
        });
        this.dialog.detail = false;
        this.dialog.detailMode = null;
        this.modifying.post = null;
        await this.fetchPost();
      } else {
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_fail.svg" />
        <br/><b>필수 입력값을 입력해주세요.</b></div>`,
        });
      }
    },
    async clickDialogCancel() {
      if (this.needDialogCancel) {
        if (
          !(await this.$confirm({
            message: "수정중인 내용이 삭제됩니다. 취소하시겠습니까?",
            confirmYn: true,
          }))
        ) {
          return;
        }
      }
      if (this.dialog.detailMode === "create") {
        this.dialog.detail = false;
      }
      this.dialog.detailMode = null;
      this.modifying.post = null;
    },
    clickDialogModify() {
      this.modifying.post = { ...this.selected.post };
      this.dialog.detailMode = "modify";
    },
    async closeDialog() {
      this.dialog.detail = false;
      this.selected.post = null;
      this.dialog.detailMode = null;
      this.modifying.post = null;
    },
    clickRegist() {
      this.selected.post = {
        title: null,
        content: null,
        writer: {
          id: this.loginUserId,
          name: this.loginUserName,
        },
        forum: {
          id: 1,
        },
      };
      (this.dateRangeTextDetail = null),
        (this.modifying.post = { ...this.selected.post });
      this.dialog.detailMode = "create";
      this.dialog.detail = true;
    },
    requiredValidate() {
      this.formHasErrors = false;
      this.idx = 0;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        if (this.$refs[f] != undefined) {
          this.$refs[f].validate(true);
          if (!this.$refs[f].validate(true)) this.idx = 1;
        }
      });
      return this.idx;
    },
  },
  watch: {
    "search.options": {
      handler() {
        this.fetchPost();
      },
      deep: true,
    },
    name() {
      this.errorMessages = "";
    },
  },
};
</script>
