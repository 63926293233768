<template>
  <v-container fluid>
    <v-card flat
            tile>
      <v-card-title>
        <span class="Header-1-Bold">APIs</span>
        <v-spacer />
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              v-if="$hasAnyAuthority(['API_CREATE', 'ADMIN'])"
              color="#000"
              large
              v-bind="attrs"
              @click="onClickCreateButton"
              v-on="on"
            >
              mdi-plus-box
            </v-icon>
          </template>
          <span>{{$t('apiGroup.service')}} {{$t('apiGroup.register')}}</span>
        </v-tooltip>
      </v-card-title>
      <v-container
        fluid
      >
        <v-row>
          <v-col
            v-for="item in services" :key="item.id"
            cols="12"
            md="4"
            sm="6"

          >
            <template>
              <v-card style="cursor: pointer; "
                      @click="enter(item)">
                <v-toolbar
                  style=" background-color: rgba(21, 24, 38, 0.3); height: 50px;"

                >
                  <v-toolbar-title class="ml-4 mb-3"
                  ><h2 v-bind:class="item.name">{{ item.name }} ( {{ item.context }} )</h2></v-toolbar-title
                  >
                  <v-spacer />
                  <v-card-actions class="justify-center">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="$hasAnyAuthority(['API_UPDATE', 'ADMIN'])"
                          class="ma-2 mb-6"
                          dense
                          v-bind="attrs"
                          v-on="on"
                          @click.stop.prevent="onClickModifyButton(item)"
                        >
                          mdi-pencil
                        </v-icon>
                      </template>
                      <span>{{$t('apiGroup.modify')}}</span>
                    </v-tooltip>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-if="$hasAnyAuthority(['API_DELETE', 'ADMIN'])"
                          class="ma-2 mb-6"
                          dense
                          v-bind="attrs"
                          v-on="on"
                          @click.stop.prevent="onClickDeleteButton(item)"
                        >
                          mdi-delete
                        </v-icon>
                      </template>
                      <span>{{$t('apiGroup.delete')}}</span>
                    </v-tooltip>
                  </v-card-actions>
                </v-toolbar>

                <v-card-text class="text-truncate">
                  <span class="Header-3-Light"> {{ $t('apiGroup.summary') }} : {{ item.description }} </span>
                </v-card-text>
                <v-card-text v-if="item.securityId === 'PUBLIC'">
                  <span class="Header-3-Light"> {{ $t('apiGroup.disclosure') }} : {{ $t('apiGroup.public') }} </span>
                </v-card-text>
                <v-card-text v-if="item.securityId === 'PRIVATE'">
                  <span class="Header-3-Light"> {{ $t('apiGroup.disclosure') }} : {{ $t('apiGroup.private') }} </span>
                </v-card-text>

              </v-card>
            </template>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <!-- 서비스 등록 다이얼로그 시작 -->
    <v-dialog
      v-model="dialog.register"
      max-width="600px"
      persistent
      @keydown.esc="dialog.register = false"
    >
      <v-card>
        <v-card-title class="text-h4 text-left"
        >{{ modifyingApiGroup.name }}
          {{ mode === 'create' ? $t('apiGroup.register') : '' }}
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-simple-table class="elevation-0">
                  <template v-slot:default>
                    <!-- 보기 모드인 경우 -->
                    <tbody v-if="mode">
                    <tr>
                      <th style="width: 110px">
                        {{ $t('apiGroup.context') }}
                        <span class="red--text"><strong>*</strong></span>
                      </th>
                      <td>
                        <v-text-field
                          ref="context"
                          v-model="modifyingApiGroup.context"
                          :error-messages="errorMessages"
                          :readonly="mode === 'modify'"
                          :rules="[
                              () =>
                                !!modifyingApiGroup.context ||
                                $t('apiGroup.requiredMsg'),
                            ]"
                          hide-details
                          outlined
                          required
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 110px">
                        {{ $t('apiGroup.name') }}
                        <span class="red--text"><strong>*</strong></span>
                      </th>
                      <td>
                        <v-text-field
                          ref="name"
                          v-model="modifyingApiGroup.name"
                          :error-messages="errorMessages"
                          :rules="[
                              () =>
                                !!modifyingApiGroup.name ||
                                $t('apiGroup.requiredMsg'),
                            ]"
                          hide-details
                          outlined
                          required
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 110px">
                        {{ $t('apiGroup.disclosure') }}
                        <span class="red--text"><strong>*</strong></span>
                      </th>
                      <td>
                        <v-radio-group
                          v-model="modifyingApiGroup.securityId"
                          row
                        >
                          <v-radio  :label ="$t('apiGroup.public')" value="PUBLIC"></v-radio>
                          <v-radio :label="$t('apiGroup.private')" value="PRIVATE"></v-radio>
                        </v-radio-group>
                      </td>
                    </tr>
                    <tr></tr>
                    <tr v-if="modifyingApiGroup.ownerId">
                      <th style="width: 110px">
                        {{ $t('apiGroup.owner') }}
                        <span class="red--text"><strong>*</strong></span>
                      </th>
                      <td>
                        {{ modifyingApiGroup.ownerId }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t('apiGroup.role') }}</th>
                      <td colspan="3" style="width: 1000px">
                        <!--                        <v-dialog-->
                        <!--                          v-model="dialog.changeRole"-->
                        <!--                          persistent-->
                        <!--                          max-width="400"-->
                        <!--                          @keydown.esc="dialog.changeRole = false"-->
                        <!--                        >-->
                        <!--                          <template v-slot:activator="{ on, attrs }">-->
                        <!--                            <template>-->
                        <!--                              <v-chip-group-->
                        <!--                                multiple-->
                        <!--                                active-class="primary&#45;&#45;text"-->
                        <!--                              >-->
                        <!--                                <v-chip-->
                        <!--                                  v-for="(item, i) in modifyingApiGroup.roles"-->
                        <!--                                  :key="i"-->
                        <!--                                >-->
                        <!--                                  {{ item.subject }}-->
                        <!--                                </v-chip>-->
                        <!--                                <v-icon-->
                        <!--                                  large-->
                        <!--                                  v-bind="attrs"-->
                        <!--                                  v-on="on"-->
                        <!--                                  @click="onClickChangeRoleButton"-->
                        <!--                                  color="#000"-->
                        <!--                                >-->
                        <!--                                  mdi-plus-box-->
                        <!--                                </v-icon>-->
                        <!--                              </v-chip-group>-->
                        <!--                            </template>-->
                        <!--                          </template>-->
                        <!--                          <template>-->
                        <!--                            <v-card class="mx-auto">-->
                        <!--                              <v-card-title class="pl-4"-->
                        <!--                              >역할 추가 / 삭제-->
                        <!--                              </v-card-title-->
                        <!--                              >-->
                        <!--                              <v-list shaped>-->
                        <!--                                <v-list-item-group multiple>-->
                        <!--                                  <template v-for="(item, i) in roles">-->
                        <!--                                    <v-divider-->
                        <!--                                      v-if="!item"-->
                        <!--                                      :key="i.id"-->
                        <!--                                    ></v-divider>-->

                        <!--                                    <v-list-item-->
                        <!--                                      v-else-->
                        <!--                                      :key="`item-${i}`"-->
                        <!--                                      :value="item"-->
                        <!--                                    >-->
                        <!--                                      <template>-->
                        <!--                                        <v-list-item-content>-->
                        <!--                                          <v-list-item-title-->
                        <!--                                            v-text="item.subject"-->
                        <!--                                          ></v-list-item-title>-->
                        <!--                                        </v-list-item-content>-->

                        <!--                                        <v-list-item-action>-->
                        <!--                                          <v-checkbox-->
                        <!--                                            v-model="selectedRoles"-->
                        <!--                                            :value="item.id"-->
                        <!--                                            @click="checkedRole(item)"-->
                        <!--                                          ></v-checkbox>-->
                        <!--                                        </v-list-item-action>-->
                        <!--                                      </template>-->
                        <!--                                    </v-list-item>-->
                        <!--                                  </template>-->
                        <!--                                </v-list-item-group>-->
                        <!--                              </v-list>-->
                        <!--                              <v-card-actions class="justify-center">-->
                        <!--                                <v-btn-->
                        <!--                                  outlined-->
                        <!--                                  @click="dialog.changeRole = false"-->
                        <!--                                >-->
                        <!--                                  닫기-->
                        <!--                                </v-btn>-->
                        <!--                              </v-card-actions>-->
                        <!--                            </v-card>-->
                        <!--                          </template>-->
                        <!--                        </v-dialog>-->
                        <v-row>
                          <v-col
                            cols="auto"
                          >
                            <v-chip
                              v-for="role in modifyingApiGroup.roles"
                              :key="role.id"
                              class="mr-1"
                            >
                              {{ role.subject }}
                            </v-chip>
                          </v-col>
                          <v-spacer />
                          <!--                          v-if="mode === 'modify'"-->
                          <v-col
                            cols="auto"
                          >
                            <v-btn
                              small
                              @click="dialog.role = true"
                            >
                              {{ $t('apiGroup.change') }}
                            </v-btn>
                          </v-col>
                        </v-row>
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t('apiGroup.description') }}</th>
                      <td colspan="3">
                        <v-textarea
                          v-model="modifyingApiGroup.description"
                          dense
                          hide-details
                          outlined
                        />
                      </td>
                    </tr>
                    </tbody>
                    <tbody v-if="!mode">
                    <tr>
                      <th style="width: 110px">{{ $t('apiGroup.context') }}</th>
                      <td>
                        {{ modifyingApiGroup.context }}
                      </td>
                    </tr>
                    <tr>
                      <th style="width: 110px">{{ $t('apiGroup.name') }}</th>
                      <td>
                        {{ modifyingApiGroup.name }}
                      </td>
                    </tr>
                    <tr>
                      <th>{{ $t('apiGroup.description') }}</th>
                      <td style="text-align: center">
                        <v-textarea
                          v-model="modifyingApiGroup.description"
                          dense
                          hide-details
                          outlined
                          readonly
                          style="max-width: 420px"
                        />
                      </td>
                    </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn outlined @click="onClickCloseButton">
            {{ mode ? $t('apiGroup.cancel') : $t('apiGroup.close') }}
          </v-btn>
          <v-btn
            v-if="
              !mode &&
              selectedApiGroup &&
              authorities.includes(Authorities.apiUpdate)
            "
            class="primary"
            elevation="0"
            @click="onClickModifyButton"
          >
            {{$t('apiGroup.close')}}
          </v-btn>
          <v-btn
            v-if="
              mode &&
              (authorities.includes(Authorities.apiCreate) ||
                authorities.includes(Authorities.apiUpdate))
            "
            class="primary"
            elevation="0"
            @click="onClickSaveButton"
          >
            {{$t('apiGroup.save')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 서비스 등록 다이얼로그 끝 -->
    <v-dialog
      v-if="dialog.role"
      v-model="dialog.role"
      max-width="650px"
    >
      <role-selection-card
        :selected="modifyingApiGroup.roles"
        :title="$t('userRoleAppointed')"
        @select="onSelectRoles"
      />
    </v-dialog>
  </v-container>
</template>
<style>
.v-card {
  margin-top: 0px;
}
</style>
<script>
import request from '@/utils/request';
import { mapActions, mapGetters } from 'vuex';
import Authorities from '@/locale/ko_KR/authorities';
import RoleSelectionCard from '@/components/user/RoleSelectionCard';

const DEFAULT_API_GROUP = {
  context: '/',
  name: '',
  description: null,
  securityId: 'PUBLIC',
  ownerId: '',
  roles: [],
};

export default {
  components: { RoleSelectionCard },
  data: () => ({
    loading: false,
    selection: 1,
    Authorities: Authorities,
    mode: null,
    dialog: {
      register: false,
      detail: false,
      // changeRole: false,
      role: false,
    },
    services: [],
    search_filter: null,
    modifyingApiGroup: { ...DEFAULT_API_GROUP }, // 현재 수정중인 APIGROUP
    selectedApiGroup: null,
    errorMessages: '',
    formHasErrors: false,
    idx: 0,
    selectedRoles: [],
  }),
  async activated() {
    await this.fetchByService();
    await this.fetchRoles();
  },
  computed: {
    ...mapGetters(['authorities']),
    form() {
      return {
        scope: this.modifyingApiGroup.scope,
        name: this.modifyingApiGroup.name,
      };
    },
  },
  methods: {
    ...mapActions([]),
    async fetchByService() {
      let url = '/apix/apiGroup/';
      //if (this.search_filter)
      //url = `/apix/apiGroup/search?searchData=${this.search_filter}`;

      const { data } = await request({
        url: url,
      });
      // methods 배열을 미리 선언해 둔다.
      data.forEach((r) => {
        r.methods = [];
      });
      this.currentPage = 1;
      this.services = data;
    },
    async enter(item) {
      console.log(item);
      await this.$router.push({
        name: 'Api',
        params: { contextpath: item.context },
      });
    },
    // 추가 버튼 클릭 시
    onClickCreateButton() {
      this.mode = 'create';
      this.dialog.register = true;
      this.modifyingApiGroup = { ...DEFAULT_API_GROUP };
      this.selectedRoles = [];
    },
    onClickModifyButton(item) {
      this.dialog.register = true;
      this.mode = 'modify';
      this.modifyingApiGroup = item;

      if (item.roles != undefined) {
        //roles 아이디만 추출하여 저장
        this.selectedRoles = item.roles.map((p) => p.id);
      }
    },
    // 수정/등록 dialog 닫기 버튼 클릭 시
    async onClickCloseButton() {
      this.dialog.register = false;
      this.selectedApiGroup = null;
      this.mode = null;

      await this.fetchByService();
    },
    async onClickDeleteButton(item) {
      var message = '';
      message = item.summary === undefined ? item.name : item.summary;

      var deleteMsg = this.$t('apiGroup.deleteMsg');
      var deletedMsg = this.$t('apiGroup.deletedMsg');

      const res = await this.$confirm({
        message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_warning.svg" />
        <br/><b>'${message}' ${deleteMsg}</b></div>`,
        confirmYn: true,
      },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});

      if (res) {
        await request({
          url: `/apix/apiGroup/${item.id}`,
          method: 'delete',
        });
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_complete.svg" /><br/><b>${deletedMsg}</b></div>`,
        });
      }
      await this.fetchByService();
    },
    // API_GROUP 등록 dialog의 저장 버튼 클릭 시
    async onClickSaveButton() {
      var savedMsg = this.$t('apiGroup.savedMsg');
      var requiredFieldMsg = this.$t('apiGroup.requiredFieldMsg');

      if (!this.requiredValidate()) {
        let url = '/apix/apiGroup';
        let method = 'post';
        if (this.mode === 'modify') {
          url = `/apix/apiGroup`;
          method = 'put';
        }
        const { data } = await request({
          url: url,
          method: method,
          data: this.modifyingApiGroup,
        });
        console.log('save result', data);
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_complete.svg" /><br/><b>${savedMsg}</b></div>`,
        },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
        this.dialog.register = false;
        this.mode = null;
        await this.fetchByService();
      } else {
        await this.$confirm({
          message: `<div style="text-align:center; padding-bottom:15px;"><img max-width="100%"
        src="static/inzent/ico_fail.svg" />
        <br/><b>${requiredFieldMsg}</b></div>`,
        },{buttonTrueText:this.$t('main.ok'),buttonFalseText:this.$t('main.cancel')});
      }
    },
    requiredValidate() {
      this.formHasErrors = false;
      this.idx = 0;
      Object.keys(this.form).forEach((f) => {
        if (!this.form[f]) this.formHasErrors = true;
        if (this.$refs[f] != undefined) {
          this.$refs[f].validate(true);
          if (!this.$refs[f].validate(true)) this.idx = 1;
        }
      });
      return this.idx;
    },
    async fetchRoles() {
      const { data } = await request({
        url: '/apix/roles',
        method: 'get',
      });
      this.roles = data;
    },
    // //역할 추가 및 삭제 로직
    // async checkedRole(item) {
    //   console.log("들어옴", item);
    //   if (this.selectedRoles.includes(item.id)) {
    //     this.modifyingApiGroup.roles.push(item);
    //   } else {
    //     this.modifyingApiGroup.roles.pop(item);
    //   }
    // },
    // onClickChangeRoleButton() {
    // },
    //trucateText(value) {
    //  if (!value) return value;

    //  const length = 50;
    //  return value.length <= length
    //    ? value
    //    : value.substring(0, length) + "...";
    //},
    onSelectRoles(roles) {
      this.modifyingApiGroup.roles = roles;
      this.dialog.role = false;
    }
  },
};
</script>
