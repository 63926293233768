import request from '@/utils/request';

const serverSetting = {
  state: {
    activeProfiles: [
      'local'
    ],
    contextPath: '',
    port: 8080,
    local: true,
    tokenTimeoutInSeconds: null,
  },

  getters: {
    contextPath: (state) => state.contextPath,
    local: (state) => state.local,
    port: (state) => state.port,
    serverTokenTimeoutInSeconds: (state) => state.tokenTimeoutInSeconds, // 서버에 설정된 access token timeout 값
  },

  mutations: {
    SET_SERVER_SETTING: (state, payload) => {
      if (payload) {
        state.activeProfiles = payload.activeProfiles;
        state.contextPath = payload.contextPath;
        state.port = payload.port;
        state.local = payload.local;
        state.tokenTimeoutInSeconds = payload.tokenTimeoutInSeconds;
      }
    }
  },

  actions: {
    loadServerSetting: async ({ commit }) => {
      const { data } = await request({
        url: '/apix/server/setting',
      });
      await commit('SET_SERVER_SETTING', data);
    }
  }
};

export default serverSetting;
