import axios from 'axios';
import dayjs from 'dayjs';

export default class TranslationService {
  constructor(store, i18n) {
    this.store = store;
    this.i18n = i18n;
  }

  refreshTranslation(newLanguage) {
    let currentLanguage = this.store.getters.currentLanguage;
    currentLanguage = newLanguage ? newLanguage : 'ko';
    if (this.i18n && !this.i18n.messages[currentLanguage]) {
      this.i18n.setLocaleMessage(currentLanguage, {});
      axios.get(`i18n/${currentLanguage}.json`).then((res) => {
        if (res.data) {
          this.i18n.setLocaleMessage(currentLanguage, res.data);
          this.setLocale(currentLanguage);
        }
      });
    } else if (this.i18n) {
      this.setLocale(currentLanguage);
    }
  }

  setLocale(lang) {
    dayjs.locale(lang);
    this.i18n.locale = lang;
    this.store.commit('currentLanguage', lang);
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
  }
}
