<template>
  <v-card>
    <v-card-title
      v-if="title"
      :class="titieClass"
    >
      {{ title }}
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-model="internal.selected"
        :headers="header.role"
        :items="roles"
        :dense="dense"
        show-select
        hide-default-footer
        disable-pagination
      />
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn
        color="primary"
        @click="clickSelect"
      >
        {{ $t('role.select') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import roleService from '@/service/role.service';
import {mapGetters} from "vuex";

const header = [
  {
    text: 'role.role',
    value: 'subject',
  },
  {
    text: 'role.description',
    value: 'description',
  },
];

export default {
  name: 'RoleSelectionCard',
  props: {
    title: String,
    titieClass: {
      type: String,
      default: 'text-left grey lighten-1',
    },
    dense: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => ([]),
    }
  },
  data: function() {
    return {
      header: {
        role: this.$translate(header),
      },
      roles: [],
      internal: {
        selected: [...this.selected],
      },
    };
  },
  computed:{
    ...mapGetters(['currentLanguage']),
  },
  methods: {
    async fetchRoles() {
      this.roles = await roleService.list();
    },
    clickSelect() {
      this.$emit('select', this.internal.selected);
    }
  },
  async mounted() {
    await this.fetchRoles();
  },
  watch:{
    currentLanguage(){
      this.header.role = this.$translate(header);
    }
  }
}
</script>

<style scoped>

</style>
