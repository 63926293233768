<template>
  <div>
    <v-expansion-panels>
      <v-expansion-panel
        v-if="!!requestBody && !!requestBody.content"
      >
        <v-expansion-panel-header>
          <!--{{ computedDescription }}-->
          <v-row>
            <v-col
              cols="auto"
            >
              {{ computedDescription }}
            </v-col>
            <v-spacer/>
            <v-col
              cols="auto"
              class="mr-3"
            >
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                    @click.stop="$refs.refSwaggerContentView.showAddContentType()"
                  >
                    mdi-plus-box
                  </v-icon>
                </template>
                <span>{{ $t('apiMethod.add') }} {{ $t('apiMethod.mediaType') }}</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <swagger-content-view
            v-if="!isCreate"
            ref="refSwaggerContentView"
            :content="requestBody.content"
            :edit-mode="editMode"
          />
          <div
            v-if="isCreate"
          >
            <v-card
              v-for="(value, type) in requestBody.content"
              :key="type"
            >
              <v-card-title>
                {{ type }}
              </v-card-title>
              <v-card-text>
                <v-textarea
                  v-model="requestBody.sample"
                  :label="$t('apiMethod.sample')"
                  outlined
                  hide-details
                  dense
                />
              </v-card-text>
            </v-card>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-container
      v-if="editMode"
    >
      <v-row>
        <v-spacer/>
        <v-col
          cols="auto"
        >
          <v-btn
            v-if="!requestBody.content"
            small
            @click="clickAdd"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
          <v-btn
            v-if="requestBody.content"
            small
            @click="clickModify"
          >
            <v-icon>mdi-pencil</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <!-- 메소드 추가 시 request body 추가 다이얼로그 -->
    <v-dialog
      v-if="dialog.add"
      v-model="dialog.add"
      max-width="500px"
    >
      <v-card>
        <v-card-title
          class="text-left"
        >
          {{ $t('apiMethod.add') }} {{ $t('apiMethod.request') }}{{ $t('apiMethod.type') }}
        </v-card-title>
        <v-card-text>
          <v-form>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  class="py-0"
                >
                  <v-text-field
                    v-model="modify.description"
                    :label="$t('apiMethod.description')"
                    outlined
                    hide-details
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="pt-2 pb-0"
                >
                  <v-combobox
                    v-model="modify.contentType"
                    :label="$t('apiMethod.contentType')"
                    outlined
                    hide-details
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  class="py-2"
                >
                  <v-textarea
                    v-model="modify.sample"
                    :label="$t('apiMethod.example')"
                    outlined
                    hide-details
                    dense
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn
            color="grey"
            @click="dialog.add = false"
          >
            {{ $t('apiMethod.cancel') }}
          </v-btn>
          <v-btn
            @click="save"
          >
            {{ $t('apiMethod.save') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SwaggerContentView from '@/components/api/swagger/SwaggerContentView';
import { SELECTOR_SCHEMA_TYPE } from '@/config/constants';

export default {
  name: 'ApiMethodRequestBodyView2',
  components: { SwaggerContentView },
  props: {
    requestBody: {
      type: Object,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    // 기존 호환 때문. 서로 전송하는 형식이나 입력하는 방식이 다르다.
    isCreate: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    dialog: {
      add: false,
      mediaType: false,
    },
    selectors: {
      schemaTypes: SELECTOR_SCHEMA_TYPE,
    },
    modify: {
      description: null,
      contentType: null,
      sample: null,
      schemaType: null,
    }
  }),
  computed: {
    computedDescription() {
      return this.requestBody.description || this.$t('apiMethod.notNamed');
    }
  },
  mounted() {
  },
  methods: {
    clickAdd() {
      this.modify = {
        description: null,
        contentType: null,
        sample: null,
      };
      this.dialog.add = true;
    },
    clickModify() {
      this.modify = {
        description: this.requestBody.description,
        contentType: Object.keys(this.requestBody.content)[0],
        sample: this.requestBody.sample,
      }
      this.dialog.add = true;
    },
    save() {
      this.requestBody.description = this.modify.description;
      if (!this.requestBody.content) this.requestBody.content = {};
      this.requestBody.content[this.modify.contentType] = {};
      this.requestBody.sample = this.modify.sample; // 기존 버전 호환을 위한 값
      this.dialog.add = false;
    },
  }
}
</script>

<style scoped>

</style>
