<template>
  <v-container>
    <!-- Operation ID -->
    <v-row>
      <v-col
        cols="6"
        class="pb-0 pt-5"
      >
        <v-text-field
          v-model="method.operationId"
          :label="$t('apiMethod.operation') + ' ' +$t('apiMethod.id')"
          :readonly="!editMode"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <!-- 요약 -->
    <v-row
      class="mt-0"
    >
      <v-col
        cols="12"
        class="py-0"
      >
        <v-text-field
          v-model="method.summary"
          :label="$t('apiMethod.summary')"
          :readonly="!editMode"
          outlined
          dense
        />
      </v-col>
    </v-row>
    <!-- 태그 -->
    <v-row
      class="mt-0"
    >
      <v-col
        cols="12"
        class="py-0"
      >
        <v-autocomplete
          v-model="tags"
          :items="allTags"
          item-text="name"
          item-value="name"
          :label="$t('apiMethod.tag')"
          outlined
          dense
          multiple
          :readonly="!editMode"
          :clearable="editMode"
          @change="changed"
        >
          <template #selection="{ item }">
            <v-chip
              color="green lighten-4"
              small
              class="mr-1"
              :close="editMode"
              @click:close="removeChip(tags, item.name)"
            >
              {{ item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <v-row
      class="mt-0"
    >
      <v-col
        cols="5"
        class="py-0"
      >
        <v-autocomplete
          v-model="securities"
          :items="securitySelectorItems"
          :label="$t('apiMethod.certified')"
          multiple
          outlined
          dense
          :readonly="!editMode"
          :clearable="editMode"
        >
          <template #selection="{ item }">
            <v-chip
              color="blue lighten-4"
              small
              :close="editMode"
              @click:close="removeChip(securities, item.value)"
            >
              {{ item.text }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
      <!-- scope -->
      <v-col
        cols="7"
        class="py-0"
      >
        <v-autocomplete
          v-model="scopes"
          :items="scopeSelectorItems"
          :label="$t('apiMethod.scope')"
          multiple
          outlined
          dense
          :readonly="!editMode"
          :clearable="editMode"
        >

          <template #selection="{ item }">
            <v-chip
              color="brown lighten-4"
              small
              :close="editMode"
              @click:close="removeChip(scopes, item.value)"
            >
              {{ item.text }}
            </v-chip>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <!-- 파라미터, 요청데이터, 응답 -->
    <v-row>
      <v-col
        cols="12"
      >
        <v-card
          elevation="2"
        >
          <v-tabs
            vertical
            background-color="grey"
            class="pl-0 pt-0"
          >
            <v-tabs-slider color="primary"/>
            <v-tab
              class="rounded-0"
            >
              {{ $t('apiMethod.parameter') }}
            </v-tab>
            <v-tab
              v-if="isUsingRequestBody"
              class="rounded-0"
            >
              {{ $t('apiMethod.request') }} {{ $t('apiMethod.data') }}
            </v-tab>
            <v-tab
              class="rounded-0"
            >
              {{ $t('apiMethod.response') }}
            </v-tab>

            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <api-method-parameter-view
                    :parameters="parameters"
                    :edit-mode="editMode"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item
              v-if="isUsingRequestBody"
            >
              <v-card flat>
                <v-card-text>
                  <api-method-request-body-view2
                    :request-body="requestBody"
                    :edit-mode="editMode"
                    :is-create="methodId === 0"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <api-method-response-view2
                    :responses="responses"
                    :edit-mode="editMode"
                  />
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      v-if="enableSave && editMode"
    >
      <v-spacer/>
      <v-col
        cols="auto"
      >
        <v-btn
          outlined
          @click="cancel"
        >
          {{ $t('apiMethod.cancel') }}
        </v-btn>
      </v-col>
      <v-col
        cols="auto"
      >
        <v-btn
          @click="save"
        >
          {{ $t('apiMethod.save') }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import apiService from '@/api/ApiService';
import { mapGetters } from 'vuex';
import ApiMethodParameterView from '@/components/api/ApiMethodParameterView';
import ApiMethodResponseView2 from '@/components/api/ApiMethodResponseView2';
import ApiMethodRequestBodyView2 from '@/components/api/ApiMethodRequestBodyView2';

export default {
  name: 'ApiMethodDetailView2',
  components: {
    ApiMethodRequestBodyView2,
    ApiMethodResponseView2,
    ApiMethodParameterView
  },
  props: {
    methodId: {
      type: Number,
      required: true,
    },
    originalMethod: {
      type: Object,
      required: false,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    // 콤포넌트 자체 저장 기능 사용 여부, 외부 컴포넌트에서 저장을 하는 경우도 있기 때문
    enableSave: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    method: {},
    tags: [],
    securities: [],
    scopes: [],
    parameters: [],
    requestBody: {},
    responses: [],
  }),
  computed: {
    ...mapGetters({
      allTags: 'apiTags',
      allScopes: 'apiScopes',
      allSecurities: 'apiSecurities',
    }),
    isUsingRequestBody() {
      return ['POST', 'PUT', 'post', 'put','patch'].includes(this.method.httpMethod);
    },
    securitySelectorItems() {
      return Object.entries(this.allSecurities).map(([key, value]) => ({
        text: `${key} (${value.type})`,
        value: `${key}`,
      }));
    },
    scopeSelectorItems() {
      return this.allScopes.map((s) => ({
        text: `${s.name} (${s.scope})`,
        value: `${s.scope}`,
      }));
    }
  },
  mounted() {
    this.fetchMethod();
  },
  methods: {
    changed() {
      console.log('tags', this.tags);
    },
    async fetchMethod() {
      if (this.methodId === 0) {
        // 신규 추가인 경우
        this.method = this.originalMethod ? this.originalMethod : {};
        this.tags = [];
        this.parameters = [];
        this.requestBody = {};
        this.responses = {};

        this.scopes = [];
        this.securities = [];
      } else {
        this.method = await apiService.method(this.methodId);
        this.tags = (await apiService.methodTags(this.methodId)).map((t) => t.name);
        // parameter.style의 값은 나중에 저장할 때 대문자여야 한다.
        var data = await apiService.methodParameters(this.methodId);
        if(Array.isArray(data)) {
          data.forEach((p) => {
            if (p.style) p.style = p.style.toUpperCase();
          });
        } else data = [];
        this.parameters = data;
        this.requestBody = await apiService.methodRequestBody(this.methodId);
        this.responses = await apiService.methodResponses(this.methodId);

        this.scopes = this.method.oauthScopes ? this.method.oauthScopes.split(' ') : [];
        this.securities = this.method.securityType ? this.method.securityType.split(' ') : [];
      }
    },
    async save() {
      if (this.methodId === 0) {
        // 신규 추가인 경우. TODO 기존 서버API와의 호환이 필요하다. 당분간은....
        const operation = {
          method: this.method.httpMethod.toLowerCase(),
          operationId: this.method.operationId,
          summary: this.method.summary,
          securities: this.securities,
          scopes: this.scopes,
          parameters: this.parameters,
          // requestBody: this.requestBody,
          // responses: this.responses,
        }
        // 기존 서버 API에서 request-body는 하나만 등록할 수 있다.
        operation.requestBody = {};
        if (this.requestBody.description) operation.requestBody.description = this.requestBody.description;
        if (this.requestBody.sample) operation.requestBody.sample = this.requestBody.sample;
        if (this.requestBody.content) {
          const contentTypes = Object.keys(this.requestBody.content);
          if (contentTypes.length > 0) {
            operation.requestBody.mediaType = contentTypes[0]; // 기존 서버 API 호환 때문
          }
        }
        // 기존 서버API가 추가 시에는 responses에 배열이 사용되고 있다.
        operation.responses = Object.entries(this.responses).map(([key, value]) => ({
          code: key,
          description: value.description,
          mediaType: value.content ? Object.keys(value.content).join(' ') : '',
        }));

        const reqData = JSON.parse(JSON.stringify(DEFAULT_API));
        reqData.servers.url = this.method.targetVersion.targetUrl;
        reqData.paths[0].path = this.method.targetVersion.uri;
        reqData.paths[0].operations[0] = operation;

        await apiService.methodAdd(reqData);
      } else {
        // 수정인 경우
        const reqData = {
          resourceVersion: { ...this.method.resourceVersion },
          httpMethod: this.method.httpMethod,
          operationId: this.method.operationId,
          summary: this.method.summary,
          tags: this.tags,
          securityType: this.securities.join(' '),
          oauthScopes: this.scopes.join(' '),
          parameters: this.parameters,
          apiResponses: this.responses,
        };
        if (this.requestBody) reqData.requestBody = this.requestBody;
        console.log('request', JSON.stringify(reqData));
        await apiService.methodSave(this.method.id, reqData);
      }
      this.$emit('saved', this.methodId, this.originalMethod);
    },
    cancel() {
      // 수정인 경우 method 정보를 다시 읽어온다.
      if (this.method.id !== 0) this.fetchMethod();
      this.$emit('cancel', this.methodId, this.originalMethod);
    },
    removeChip(arr, value) {
      const index = arr.indexOf(value);
      if (index >= 0) arr.splice(index, 1);
    }
  },
  watch: {
    methodId() {
      this.fetchMethod();
    }
  }
}

const DEFAULT_API = {
  info: {
    title: 'apim add resource',
    description: 'apim add resource',
    version: '1.0.0',
  },
  servers: {
    url: '',
    // description: "",
  },
  paths: [
    {
      path: '',
      // summary: "",
      // description: "",
      operations: [],
    },
  ],
};
</script>

<style scoped>

</style>
