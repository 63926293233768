<template>
  <v-card
    flat
  >
    <v-card-subtitle
      v-if="copyedQueries"
    >
      {{ $t('transaction.query') }}
    </v-card-subtitle>
    <v-card-text
      v-if="copyedQueries"
    >
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
          <tr
            v-for="(value, name) in copyedQueries"
            :key="name"
          >
            <td
              style="width: 170px"
            >
              <v-icon
                v-if="editable"
                @click="removeQuery(name)"
              >
                {{ $t('transaction.delete') }}
              </v-icon>
              {{ name }}
            </td>
            <td
              v-if="!editable"
            >
              {{ value }}
            </td>
            <td
              v-if="editable"
              colspan="2"
            >
              <v-text-field
                v-model="copyedQueries[name]"
                hide-details
                dense
              />
            </td>
          </tr>
          <tr
            v-if="editable"
          >
            <td>
              <v-text-field
                v-model="newQuery.key"
                :placeholder="$t('transaction.key')"
                hide-details
                dense
              />
            <td>
              <v-text-field
                v-model="newQuery.value"
                :placeholder="$t('transaction.value')"
                hide-details
                dense
              />
            </td>
            <td
              style="width: 32px; padding: 0"
            >
              <v-btn
                x-small
                @click="addQuery"
              >
                {{$t('transaction.add')}}
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-subtitle
      v-if="copyedHeaders"
    >
      {{$t('transaction.header')}}
    </v-card-subtitle>
    <v-card-text
      v-if="copyedHeaders"
    >
      <v-simple-table dense>
        <template v-slot:default>
          <tbody>
          <tr
            v-for="(value, name) in copyedHeaders"
            :key="name"
          >
            <td
              style="width: 170px"
            >
              <v-icon
                v-if="editable"
                @click="removeHeader(name)"
              >
                {{$t('transaction.delete')}}
              </v-icon>
              {{ name }}
            </td>
            <td
              v-if="!editable"
            >
              {{ value }}
            </td>
            <td
              v-if="editable"
              colspan="2"
            >
              <v-text-field
                v-model="copyedHeaders[name]"
                hide-details
                dense
              />
            </td>
          </tr>
          <tr
            v-if="editable"
          >
            <td>
              <v-text-field
                v-model="newHeader.key"
                :placeholder="$t('transaction.key')"
                hide-details
                dense
              />
            <td>
              <v-text-field
                v-model="newHeader.value"
                :placeholder="$t('transaction.value')"
                hide-details
                dense
              />
            </td>
            <td
              style="width: 32px; padding: 0"
            >
              <v-btn
                x-small
                @click="addHeader"
              >
                {{ $t('transaction.add') }}
              </v-btn>
            </td>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-card-subtitle
      v-if="copyedBody"
    >
      {{ $t('transaction.body') }}
    </v-card-subtitle>
    <v-card-text
      v-if="copyedBody"
    >
      <v-textarea
        v-model="copyedBody"
        outlined
        :readonly="!editable"
      />
    </v-card-text>
    <!--<v-container-->
    <!--&gt;-->
    <!--  <v-row>-->
    <!--    <v-col-->
    <!--      cols="12"-->
    <!--      class="py-0"-->
    <!--    >-->
    <!--    </v-col>-->
    <!--  </v-row>-->
    <!--</v-container>-->
  </v-card>
</template>

<script>

export default {
  name: 'TransactionDataHeaderBody',
  props: {
    queries: {
      type: Object,
    },
    headers: {
      type: Object,
    },
    body: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: false,
    }
  },
  components: {},
  data: () => ({
    copyedQueries: {},
    copyedHeaders: {},
    copyedBody: null,
    newQuery: {
      key: null,
      value: null,
    },
    newHeader: {
      key: null,
      value: null,
    }
  }),
  computed: {},
  mounted() {
    this.copyedQueries = this.queries ? { ...this.queries } : null;
    this.copyedHeaders = this.headers ? { ...this.headers } : null;
    this.copyedBody = this.body;
  },
  methods: {
    addQuery() {
      if (!this.newQuery.key || !this.newQuery.value) return;
      this.copyedQueries[this.newQuery.key] = this.newQuery.value;
      this.newQuery.key = null;
      this.newQuery.value = null;
    },
    addHeader() {
      if (!this.newHeader.key || !this.newHeader.value) return;
      this.copyedHeaders[this.newHeader.key] = this.newHeader.value;
      this.newHeader.key = null;
      this.newHeader.value = null;
    },
    removeQuery(name) {
      this.$delete(this.copyedQueries, name);
    },
    removeHeader(name) {
      this.$delete(this.copyedHeaders, name);
    },
  }
}
</script>
