/** When your routing table is too long, you can split it into small modules */

const authRouter = [
  {
    path: "/intro",
    alias: "/intro",
    component: () => import("@/views/auth/Intro.vue"),
    hidden: true
  },
  {
    path: "/signin",
    alias: "/login",
    component: () => import("@/views/auth/SignIn.vue"),
    hidden: true
  },
  {
    path: "/signup",
    alias: "/registration",
    component: () => import("@/views/auth/SignUp.vue"),
    hidden: true
  },
  {
    path: "/notices",
    component: () => import("@/views/NoticeManagement"),
    name: "Forums",
    hidden: true
  },
];

export default authRouter;
