import VueI18n from 'vue-i18n';
import { emitter } from '@/config/bus.ts';

export function initI18N(vue) {
  vue.use(VueI18n);
  return new VueI18n({});
}

export function initVueApp(vue) {
  vue.prototype.$emitter = emitter;
}
