<template>
  <div>
    <v-combobox
      v-bind="computedProps"
      v-on="$listeners"
      :search-input.sync="test"
      @keyup="onKeyup"
    />
  </div>
</template>

<script>
import { VCombobox } from 'vuetify/lib';

export default {
  name: 'ApimComboboxRegex',
  extends: VCombobox,
  props: {
    regex: RegExp,
    default: null,
  },
  data: () => ({
    test: null,
  }),
  computed: {
    computedProps() {
      return {
        ...this.$props,
        regex: this.regex ? new RegExp(this.regex.source) : null,
      }
    }
  },
  methods: {
    onKeyup(e) {
      this.test = this.replace(e.target.value);
    },
    replace(original) {
      if (!original) return original;
      let matched = '';
      for (let i = 0; i < original.length; i++) {
        if (this.regex.test(original.charAt(i))) matched += original.charAt(i);
      }
      return matched;
    }
  }
}
</script>

<style scoped>

</style>
