import router from './index';
import store from '../store';
import authRouter from './modules/auth';

const whiteList = ['/landing', '/land', '/forbidden']
  .concat(Array.from(authRouter, route => route.path))
  .concat(Array.from(authRouter, route => route.alias))
  .filter(route => route); // remove undefined element

console.log('[router.whiteList]', whiteList);

/**
 * Check user has permission for this routes.
 * 'admin' permission passed directly.
 * @param {Array} roles
 * @param {Array} permissionRoles
 */

//** hasPermission 역할 **///
// function hasPermission(roles, permissionRoles) {
//   if (!roles && roles.includes('admin')) return true;
//   if (!permissionRoles) return true;
//   return roles.some((role) => permissionRoles.includes(role));
// }

//
//** hasPermission 권한 **///
//function hasPermission(authorities, permissionAuthorities) {
//  if (!authorities) return true;

//  if (!permissionAuthorities) return true;
//  return authorities.some(authority => permissionAuthorities.includes(authority));
//}

// router.beforeEach(async (to, from, next) => {
router.beforeEach(async (to, from, next) => {
  if (to.path === '/forbidden') {
    next();
    return;
  }
  if (!store.getters.token) {
    await store.dispatch('RefreshLocalToken');
    if (store.getters.token && !store.getters.user) {
      await store.dispatch('GetUserInfo');
      await store.dispatch('GenerateRoutes', { roles: store.getters.roles });
    }
  }
  if (store.getters.token) {
    console.log('[router.permission - beforeEach] token exist');
    if (store.getters.user) {
      console.log('[router.permission - beforeEach] user exist: ', store.getters.user);
      next();
    } else {
      console.log('[router.permission - beforeEach] user not exist');
      try {
        await store.dispatch('GetUserInfo');
        next();
      } catch (e) {
        next(`/signin`);
      }
    }
  } else {
    console.log('[router.permission - beforeEach] token not exist');
    if (whiteList.includes(to.path)) {
      next();
    } else {
      next(`/intro?redirect=${to.path}`);
    }
  }
});

//router.beforeEach(async (to, from, next) => {
//  let logMsg = "[router.beforeEach]";
//  try {
//    // determine if there has token
//    if (store.getters.token) {
//      logMsg += "\t[token]";
//      if (whiteList.includes(to.path)) {
//        logMsg += "\t[whiteList]";
//        next({ path: "/" });
//      } else {
//        logMsg += "\t[!whiteList]";
//        if (!store.getters.roles || store.getters.roles.length === 0) {
//          logMsg += `\t[roles=${store.getters.roles}]`;
//          // Determine whether the current user has pulled the user_info information
//          console.log("path:" + to.path);

//          await store.dispatch("GetUserInfo");
//          if (!store.getters.user || !store.getters.user.roles) {
//            logMsg += "\t[LogOut]\t[next /]";
//            await store.dispatch("LogOut");
//            next({ path: "/intro" });
//          }

//          // note: roles must be a object array! such as:
//          // [{id: '1', name: 'editor'}, {id: '2', name: 'developer'}]
//          await store.dispatch("GenerateRoutes", store.getters.user);
//          if (!store.getters.permissionRoutes) {
//            logMsg += "\t[Redirect]\t[next /]";
//            next({ path: "/" });
//          }

//          // Hack method to ensure that addRoutes is complete,
//          // set the replace: true so the navigation will not leave a history record
//          next({ ...to, replace: true });
//        } else {
//          logMsg += `\t[roles=${store.getters.roles}]`;
//          // No need to dynamically change permissions can be directly next()
//          // delete the following permission judgment ↓

//          //** hasPermission 역할 **///
//          // if (hasPermission(store.getters.roles, to.meta.roles)) {
//          //   logMsg += `\t[Permission=${to.meta.roles}]\t[next]`;
//          //   next();
//          // } else {
//          //   logMsg += `\t[!Permission=${to.meta.roles}]\t[next /401]`;
//          //   next({ path: '/401', replace: true, query: { noGoBack: true } });
//          // }

//          //** hasPermission 권한 **///
//          if (hasPermission(store.getters.authorities, to.meta.authorities)) {
//            logMsg += `\t[Permission=${to.meta.authorities}]\t[next]`;
//            next();
//          } else {
//            logMsg += `\t[!Permission=${to.meta.authorities}]\t[next /401]`;
//            next({ path: "/401", replace: true, query: { noGoBack: true } });
//          }
//        }
//      }
//    } else {
//      logMsg += "\t[!token]";
//      if (whiteList.includes(to.path)) {
//        logMsg += "\t[whiteList]";
//        next();
//      } else {
//        logMsg += "\t[!whiteList]";
//        // next(`/singin?redirect=${to.path}`);
//        next(`/intro?redirect=${to.path}`);
//      }
//    }
//  } catch (err) {
//    console.warn(`[router.beforeEach]\t${to.path}: ${err}`);
//  }
//  console.log(logMsg, to.path);
//});

router.afterEach(async () => {
});
