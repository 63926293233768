import router from '../router';
import axios from 'axios';
import { AUTH_ACCESS_TOKEN_NAME } from '@/constants';
import store from '../store';
import { TOTAL_COUNT_HEADER_NAME } from '@/config/constants';
import qs from 'qs';
import { useBus } from '@/config/bus.ts';

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 0,
  paramsSerializer: (params) => qs.stringify(params, { indices: false }),
  // withCredentials: true // send cookies when cross-domain requests
});

// Request interceptors
service.interceptors.request.use(
  async (config) => {

    await store.dispatch('showLoading');

    // Add X-Access-Token header to every request, you can add other custom headers here
    const accessToken = localStorage.getItem(AUTH_ACCESS_TOKEN_NAME) || sessionStorage.getItem(AUTH_ACCESS_TOKEN_NAME);
    // store.dispatch('RefreshLocalToken', accessToken).then(() => {
    // });
    if (accessToken) {
      // token이 없다면 storage의 token으로 store를 채운다.
      if (!store.getters.token) {
        await store.dispatch('RefreshLocalToken');
      }

      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${accessToken}`;
      config.headers['X-Auth-Token'] = accessToken;
    }
    // APP이 서'/portal'에 실행되므로 상위 디렉토리를 사용한다.
    config.url = '.' + config.url;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

service.interceptors.response.use(async (response) => {
  await store.dispatch('hideLoading');
  // 토큰 재발행인 경우 시간을 기록하지 않는다.
  if (!response.config || !response.config.url || !response.config.url.includes('re-issue'))
    await store.dispatch('setLastRequestTime', Date.now());

  // console.log('response', response);
  // if (response.headers[TOTAL_COUNT_HEADER_NAME]) response['totalCount'] = Number(response.headers[TOTAL_COUNT_HEADER_NAME]);
  // console.log('response', response);
  return response;
}, async (error) => {
  await store.dispatch('hideLoading');
  console.log('error', error, error.response, error.response.status);
  if (error.response) {
    if (error.response.status === 401) {
      useBus().bus.emit('logout');  // 로그아웃 이벤트 발행 - 운영자화면에서 현재 사용됨
      if (window.mode !== 'admin') await router.push({ path: '/signin' });  // 운영자가 아닌 경우에는 로그인 화면으로 이동한다.
    }
    if (error.response.status === 403) {
      await router.push({ path: '/forbidden' });
    }
  }
  return Promise.reject(error);
});

export default service;

/**
 * axios 리턴 결과를 처리한다. 페이징이 있는 경우는 { data, totalCount }를 리턴하고, 없는 경우는 data만 리턴한다.
 * @param response
 * @returns {{data, totalCount: number}|*}
 */
export function processResponse(response) {
  const { data, headers } = response;
  if (headers[TOTAL_COUNT_HEADER_NAME]) {
    return {
      data: data,
      totalCount: Number(headers[TOTAL_COUNT_HEADER_NAME]),
    };
  } else {
    return data;
  }
}
