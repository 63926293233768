var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.forum.title)+" ")]),_c('v-divider'),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.header.posts,"items":_vm.posts,"footer-props":{
        itemsPerPageOptions: _vm.$store.state.pagination.rowsPerPageItems
      },"options":_vm.pageOptions,"server-items-length":_vm.totalCount},on:{"update:options":function($event){_vm.pageOptions=$event},"click:row":_vm.onSelectPost},scopedSlots:_vm._u([{key:"item.writer",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.writer && (item.writer.name || item.writer.id))+" ")]}},{key:"item.createdDate",fn:function(ref){
      var item = ref.item;
return [_c('format-date-time',{attrs:{"value":item.createdDate}})]}},(_vm.permission.write)?{key:"footer.prepend",fn:function(){return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-0",attrs:{"color":"primary","small":""},on:{"click":_vm.clickWrite}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-plus ")])],1)]}}],null,false,1902519480)},[_c('span',[_vm._v(_vm._s(_vm.$t('forum.write')))])])]},proxy:true}:null],null,true)})],1),(_vm.dialog.post)?_c('v-dialog',{attrs:{"max-width":"900","scrollable":""},model:{value:(_vm.dialog.post),callback:function ($$v) {_vm.$set(_vm.dialog, "post", $$v)},expression:"dialog.post"}},[_c('apim-forum-post',{attrs:{"forum":_vm.forum,"post-id":_vm.selected.post.id},on:{"click:cancel":function($event){_vm.dialog.post = false},"refresh":_vm.fetchPosts,"click:close":_vm.closeDialogPost}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }