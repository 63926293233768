import store from '@/store';

export default {
    install(Vue) {
        Vue.prototype.$hasAnyAuthority = function (authorities) {
            if (typeof authorities === 'string') {
                authorities = [authorities];
            }
            return authorities.some(auth => store.getters.authorities.includes(auth));
        }
    }
}
