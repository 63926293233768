import Layout from '@/views/layout/TheLayout.vue';
import Authorities from '@/locale/ko_KR/authorities';

const supportRouter = {
  path: '/support',
  component: Layout,
  redirect: '/support',
  name: 'Support',
  meta: {
    title: 'menu.customerSupport',
    icon: 'mdi-account-switch-outline',
    authorities: [Authorities.forumRead]
  },
  children: [
    {
      path: ':forumId',
      component: () => import('@/views/ForumView'),
      name: 'menu.forums',
      // meta: { title: '공지사항', noCache: true },
      hidden: true,
      props: true,
      // dynamics: [
      //   {
      //     title: '공지사항',
      //     path: 'notice',
      //   },
      //   {
      //     title: 'FAQ',
      //     path: 'faq',
      //   },
      //   {
      //     title: 'Q&A',
      //     path: 'qna',
      //   },
      // ],
    },
    {
      path: 'notice',
      // component: () => import('@/views/ForumView'),
      // name: 'Forums',
      meta: { title: 'menu.notice', noCache: true },
    },
    {
      path: 'faq',
      // component: () => import('@/views/ForumView'),
      // name: 'Forums',
      meta: { title: 'menu.faq', noCache: true }
    },
    {
      path: 'qna',
      // component: () => import('@/views/ForumView'),
      // name: 'Forums',
      meta: { title: 'menu.qna', noCache: true }
    }
  ]
};

export default supportRouter;
