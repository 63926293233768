const translationStore = {
  state: {
    currentLanguage: localStorage.getItem('currentLanguage') || 'ko',
    languages: {
      en: { name: 'English' },
      ko: { name: '한국어' },
    },
  },
  getters: {
    currentLanguage: state => state.currentLanguage,
    languages: state => state.languages,
  },
  mutations: {
    currentLanguage(state, newLanguage) {
      state.currentLanguage = newLanguage;
      localStorage.setItem('currentLanguage', newLanguage);
    },
  },
}

export default translationStore;
