/**
 * Vuetify theme options.
 */
import request from '@/utils/request';

/**
 * Login by email and password
 *
 * @param {String} email user email
 * @param {String} password user password
 */
export const loginByEmail = async (email, password) => {
  console.log(`[loginByEmail] email ${email}`);
  const { data } = await request({
    url: '/apix/login',
    method: 'post',
    data: {
      id: email,
      password: password,
    },
  });
  return data;
  // let user = {};
  // try {
  //   if (userEditor.email === email && userEditor.password === password) {
  //     user = userEditor;
  //   } else if (userAdmin.email === email && userAdmin.password === password) {
  //     user = userAdmin;
  //   }
  //   if (!user || !user.token) {
  //     throw new Error('User is not found');
  //   }
  // } catch (err) {
  //   console.warn(`[loginByEmail] ${err}`);
  // }
  // return { user };
};

/**
 * Get user information by token
 *
 * @param {String} token user token
 */
export const getUserInfo = async (token) => {
  console.log(`[getUserInfo] token ${token}`);
  const { data } = await request({
    url: '/apix/account',
  });
  return data;
};
